import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';

// import { Setting } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';

import { AiOutlineSetting } from "react-icons/ai";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#D28816",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CraftIQ() {
  const navigate = useNavigate();

  

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${splashBG})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light"
            ? t.palette.grey[50]
            : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* <div
        style={{
          // alignItems: "flex-end",
          display: "flex",
          // justifyContent: "flex-end",s
          alignItems: "flex-end",
          marginLeft: "90%",
          marginRight: 20,
            marginTop: 20,
        }}
      >
        <Button
              onClick={() => {
                console.log('clicked');
              }}>
        <AiOutlineSetting
          style={{
            width: 40,
            height: 40,
            
            color: "#fff",
          }}
        /></Button>
      </div> */}

      <Box sx={{ width: "90%" }}>
      <center><img
            style={{
             // height: '5%',
              width: 130,
              marginBottom:10
            }}
            src={require("../images/ciqLogo.png")}
          /></center>
        {/* <p style={{ fontSize: 30, color: "#D28816" }}>CRAFTIQ</p> */}
        <Grid
          container
          //height={200}
          spacing={{ xs: 3, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >

          <Grid item xs={6} >
            <Button style={{ backgroundColor: "#D28816" }} type="submit" fullWidth variant="contained"
              onClick={() => {
                navigate('MyCraft')
              }}>
              <p style={{ fontSize: 16, color: "#fff" }}>MY CRAFT</p>
            </Button>
            {/* <Item ><p style={{ fontSize: 16, color: "#fff" }}>MY CRAFT</p></Item> */}
          </Grid>

          <Grid item xs={6}>
            <Button style={{ backgroundColor: "#D28816" }} type="submit" fullWidth variant="contained"
              onClick={() => {
                navigate('CraftiqLibrary')
              }}>
              <p style={{ fontSize: 16, color: "#fff" }}>CRAFTIQ LIBRARY</p>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button style={{ backgroundColor: "#D28816" }} type="submit" fullWidth variant="contained"
              onClick={() => {
                navigate('Conversions')
              }}>
              <p style={{ fontSize: 16, color: "#fff" }}>CONVERSIONS</p>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button style={{ backgroundColor: "#D28816" }} type="submit" fullWidth variant="contained"
              onClick={() => {
                navigate('BackBar')
              }}>
              <p style={{ fontSize: 16, color: "#fff" }}>BACK BAR</p>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button style={{ backgroundColor: "#D28816" }} type="submit" fullWidth variant="contained"
              onClick={() => {
                navigate('LearnIQ')
              }}>
              <p style={{ fontSize: 16, color: "#fff" }}>LEARN IQ</p>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button style={{ backgroundColor: "#D28816" }} type="submit" fullWidth variant="contained"
              onClick={() => {
                navigate('NetworksTeams')
              }}>
              <p style={{ fontSize: 16, color: "#fff" }}>NETWORKS & TEAMS</p>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

// import * as React from "react";
// import { experimentalStyled as styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f7a828",
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// export default function CraftIQ() {
//   return (
//     <Box
//       sx={{
//         height: "100%",
//         width: "100%",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         backgroundColor: "#694b1b",
//       }}
//     >
//       <Box sx={{ flexGrow: 1 }}>
//         <Grid
//           container
//           columnSpacing={{xs:1,}}
//           spacing={{ xs: 2, md: 3 }}
//           columns={{ xs: 4, sm: 8, md: 12 }}
//         >
//           {/* <Typography noWrap> */}
//             {Array.from(Array(12)).map((_, index) => (
//               <Grid item xs={2} sm={4} md={4} key={index}>
//                 <Item>xs=2</Item>
//               </Grid>
//             ))}
//           {/* </Typography> */}
//         </Grid>
//       </Box>
//     </Box>
//   );
// }
