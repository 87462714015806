

import React, { useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { db, auth } from '../../src/Database_fb';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

// import { Setting } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
import splashBG from '../images/splashBG.png';

export default function LearnIQ() {
  const [learnIQArray, setlearnIQArray] = useState([]);
  const [loading, setloading] = React.useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    FetchLearnIQ()
  }, [])

  function FetchLearnIQ() {
    const user = auth.currentUser;
    const subscribe = db.collection("LearnIQ")
      .orderBy('timestamp', 'desc')
      .onSnapshot((doc) => {
        const crafts = [];
        doc.forEach((snap) => {
          crafts.push({ key: snap.id, ...snap.data() });
        });
        let sorted = crafts.sort((a, b) => a.name.localeCompare(b.name))
        //console.log('sorted', sorted);
        setlearnIQArray(sorted);
        setloading(false)
      });
  }
  return (
    <>

      <Box
        sx={{
          overflow: 'auto',
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: 5
        }}
      >
        <Box sx={{ width: "90%" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>

            <p style={{ fontSize: 25, color: "#f7a828" }}>LEARNIQ</p>
            <div
              style={{
                width: 20,
                height: 20,
              }}
            >
            </div>
          </div>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {learnIQArray.map((prop, key) => {
              return (
                <Grid item xs={6}>
                  <Button style={{ backgroundColor: "#D28816", height: '50px' }} type="submit" fullWidth variant="contained"
                    onClick={() => {
                      console.log('clicked');
                      navigate('Learncategories', { state: { key: prop.key, name: prop.name } });
                    }}>
                    {prop.name}
                  </Button>
                  {/* <Item>LIQUOR LAWS</Item> */}
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
}
