import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { BsPlusCircle } from "react-icons/bs";

import TextField from "@mui/material/TextField";
import Select from 'react-select';
import Button from "@mui/material/Button";
import { AiOutlineArrowLeft, AiOutlineRight } from "react-icons/ai";
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import {
  ShoppingBottleSizeItems,
  ShoppingFloatSizeItems,
  ShoppingBottleSizeItemsDD

} from "../context/craftUtil";
import { ListOfAmount, ListOfFloatGarnishAmount, dilutionBottleSize, floatBottleSize, garnishBottleSize } from "../context/conversionUtil";
import { db, auth, frbase } from './../Database_fb';
import FlatList from 'flatlist-react';
import ActionSheet from "actionsheet-react";
import Utility from "../utility";
Modal.setAppElement(document.getElementById('root'));
const Recipe = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const ref = useRef();
  const [orderModal, setorderModal] = useState(false);
  const [render, setrender] = useState(["1"]);
  const [loading, setloading] = useState(false);
  const [itemrender, setitemrender] = useState([...location.state.myItemArray]);
  const [floatRender, setfloatRender] = useState([...location.state.floatArray]);
  const [garnishrender, setgarnishrender] = useState([...location.state.garnishArray]);
  const [dilutionrender, setdilutionrender] = useState([...location.state.dilutionArray]);
  const [viewOnly, setviewOnly] = useState(location.state.viewOnly);

  const [recipeModal, setrecipeModal] = useState(false);
  const [recipeName, setrecipeName] = useState(location.state.craftName);
  const [dociID, setdociID] = useState(location.state.dociID)

  const [totalAmount, settotalAmount] = useState(0)
  const [TotalOunces, setTotalOunces] = useState(location.state.TotalOunces)
  const [TotalYield, setTotalYield] = useState(location.state.TotalYield)
  const [TotalYieldWithDilution, setTotalYieldWithDilution] = useState(location.state.TotalYield)
  const [selectedBatchType, setselectedBatchType] = useState(location.state.selectedBatchType)
  const [TotalOunceWithDilution, setTotalOunceWithDilution] = useState(location.state.TotalOunceWithDilution)
  const [selectedBottleType, setselectedBottleType] = useState(location.state.selectedBottleType)
  const [selectedIngredient, setselectedIngredient] = useState(location.state.selectedIngredient)
  const [selectedBottleTypeVal, setselectedBottleTypeVal] = useState(location.state.selectedBottleTypeVal)
  const [selectedIngredientVal, setselectedIngredientVal] = useState(location.state.selectedIngredientVal)
  const [batchQuantity, setbatchQuantity] = useState(location.state.QTYValue)
  const [AssumedBatchValue, setAssumedBatchValue] = useState(location.state.AssumedBatchValue)
  const [ingrediantArray, setingrediantArray] = useState([...location.state.ingrediantArray])
  const [isShopping, setisShopping] = useState(location.state.isShopping)
  const [finishWithOrder, setfinishWithOrder] = useState(false)


  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setviewOnly(location.state.viewOnly)
    let itemsArry = [...itemrender]
    itemsArry.forEach((item) => {
      item.amount = ListOfAmount((isShopping) ? "shopping" : "", item.bottleSize, item.bottleSizeValue, item.yields)
      console.log('item.amount', item.amount);
    })
    setitemrender(itemsArry)

    let diluArry = [...dilutionrender]
    diluArry.forEach((item) => {
      item.amount = ListOfAmount((isShopping) ? "shopping" : "", item.bottleSize, item.bottleSizeValue, item.yields)
      item.bottleSize = dilutionBottleSize(item.amount, item.yields)
    })
    setdilutionrender(diluArry)
    let yielddilu = totalOunceYieldValue(itemsArry, diluArry)

    let floatArry = [...floatRender]
    floatArry.forEach((item) => {
      console.log(item);
      item.amount = ListOfFloatGarnishAmount((isShopping) ? "shopping" : "", item.itemname, item.unitName, item.bottleSize, item.bottleSizeValue, item.yields, item.totalOunces, yielddilu)
      item.bottleSize = floatBottleSize(item.amount, item.yields, item.bottleSize, item.unitName)
    })
    setfloatRender(floatArry)

    let garnishArry = [...garnishrender]
    garnishArry.forEach((item) => {
      item.amount = ListOfFloatGarnishAmount((isShopping) ? "shopping" : "", item.itemname, item.unitName, item.bottleSize, item.bottleSizeValue, item.yields, item.totalOunces, yielddilu)
      item.bottleSize = garnishBottleSize(item.itemname, item.amount, item.yields, item.bottleSize, item.unitName)
    })
    setgarnishrender(garnishArry)

  }, []);


  function totalOunceYieldValue(itemsArry, diluArry) {
    var amt = 0
    var yields = 0
    var ounces = 0
    itemsArry.forEach((item) => {
      amt = amt + item.amount
      ounces = ounces + item.totalOunces
      yields = yields + item.yields
    })
    let dilu = diluArry[0]
    if (dilu.itemname.length > 0) {
      amt = amt + dilu.amount
      ounces = ounces + dilu.totalOunces
      yields = yields + dilu.yields
    }


    setTotalOunces(ounces)
    settotalAmount(amt)
    setTotalYield(yields)
    setTotalYieldWithDilution(Math.round(yields / ounces))

    return Math.round(yields / ounces)

  }

  function uploadDataToServer(craftname) {

    setloading(true)
    if (craftname.length > 0) {
      if (dociID != null) {
        let Ref = db.collection("MyCrafts").doc(dociID)
        Ref.update({
          craftname: craftname,
          totalOunces: TotalOunces.toString(),
          totalYields: TotalYield,
          batchType: selectedBatchType,
          bottleSize: selectedBottleType,
          quantity: batchQuantity.toString(),
          ingrediants: ingrediantArray,
          totalDilution: TotalOunceWithDilution,
          assumed: AssumedBatchValue,
          ingrediantVal: selectedIngredientVal,
          updatedAt: frbase.firestore.FieldValue.serverTimestamp()
        }).then(() => {
          addUpdateItems(dociID)
        })
      }
      else {
        let data =
        {
          craftname: craftname,
          status: "active",
          uid: frbase.auth().currentUser.uid,
          totalOunces: TotalOunces.toString(),
          totalYields: TotalYield,
          batchType: selectedBatchType,
          bottleSize: selectedBottleType,
          quantity: batchQuantity.toString(),
          ingrediants: ingrediantArray,
          totalDilution: TotalOunceWithDilution,
          assumed: AssumedBatchValue,
          ingrediantVal: selectedIngredientVal,
          shared: false,
          timestamp: frbase.firestore.FieldValue.serverTimestamp(),
          updatedAt: frbase.firestore.FieldValue.serverTimestamp()
        }
        db.collection("MyCrafts").add(data).then((ref) => {
          addUpdateItems(ref.id)
        }).then(() => {
          addUpdateItems(dociID)
        })
      }
    }
  }

  function addUpdateItems(docid) {
    const user = frbase.auth().currentUser;
    var indexo = 0
    let combinedArray = [...itemrender, ...dilutionrender, ...garnishrender, ...floatRender];

    if (dociID != null) {
      db
        .collection("MyCrafts")
        .doc(dociID)
        .collection("items")
        .get()
        .then((documentSnapshot) => {
          var bar = new Promise((resolve, reject) => {
            documentSnapshot.forEach((snap, index) => {
              snap.ref.delete()
              if (index === documentSnapshot.size - 1) resolve();
            });
          });
          bar.then(() => {
            Utility.sendUpdatePushNotification(docid, recipeName, user.uid)
            uploadItems(combinedArray, docid, indexo)
          });
        })
    }
    else {
      uploadItems(combinedArray, docid, indexo)
    }

  }
  function uploadItems(combinedArray, docid, indexo) {
    combinedArray.forEach((item) => {
      let docRef = db
        .collection("MyCrafts").doc(docid).collection("items")
      let data =
      {
        itemname: item.itemname,
        unitName: item.unitName,
        bottleSize: item.bottleSize,
        type: item.type,
        unitValue: item.unitValue,
        Quantity: item.Quantity,
        totalOunces: item.totalOunces,
        yields: item.yields,
        amount: item.amount,
        bottleSizeValue: item.bottleSizeValue,
        order: item.order
      }
      docRef.add(data).then((ref) => {
        indexo = indexo + 1
        if (indexo == combinedArray.length) {
          setloading(false)
          if (finishWithOrder) {
            setorderModal(true)
          }
          else {
            navigate("MyCraft");
          }
        }
      })
    })
  }

  function renderFlatItem() {
    return (
      <div
        style={{
          backgroundColor: "white",
          flex: 1,
        }}
      >
        <FlatList
          list={itemrender}
          renderItem={renderItem}
        ></FlatList>



        <div
          style={{
            backgroundColor: "black",
            height: 30,
            alignItems: "center",
            display: 'flex',
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 13,
              fontWeight: "300",
              marginLeft: 10,
            }}
          >
            Dilution
          </p>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: 'flex',
            height: 40,
            flexDirection: "row",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              flexDirection: "row",
              width: '40%',
            }}
          >
            <p

              style={{
                fontSize: 12,
                fontWeight: "500",
                left: 10,
                color: "black"
              }}
            >
              {" "}
              {dilutionrender[0].itemname}
            </p>
          </div>

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              flexDirection: "row",
              width: '10%',
            }}
          ></div>

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: '30%',
            }}
          >
            {/* <Select
              isDisabled={viewOnly || (dilutionrender[0].itemname.length == 0)}
              placeholder={'Select size'}
              //placeholder={'chocolate'}
              // value={item.sizeName}
              onChange={DDitem => {

                let unit = ShoppingBottleSizeItems()[DDitem.value]
                let backup = [...dilutionrender]
                backup[0].bottleSize = DDitem.value
                backup[0].bottleSizeValue = unit
                backup[0].amount = ListOfAmount((isShopping) ? "shopping" : "", DDitem.value, unit, dilutionrender[0].yields)
                setdilutionrender(backup, () => {
                  totalOunceYieldValue(itemrender, dilutionrender)
                });
              }}
              defaultValue={(dilutionrender[0].itemname.length == 0) ? "" : dilutionrender[0].bottleSize}
              options={ShoppingBottleSizeItemsDD()}
            /> */}
          </div>

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              flexDirection: "row",
              width: '10%',
            }}
          ></div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              width: '10%',
            }}
          >
            <p

              style={{
                fontSize: 17,
                fontWeight: "600",
                // fontSize: RFValue(12),
                // fontWeight: "300",
                //right: 25,
                color: "black"
              }}
            >
              {(dilutionrender.length > 0) ? dilutionrender[0].totalOunces.toFixed(2) : ''}
            </p>

          </div>

        </div>
        <div
          style={{
            backgroundColor: "black",
            height: 30,
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "300",
              marginLeft: 10,
            }}
          >
            Float / Muddle / other
          </p>
        </div>
        <FlatList
          list={floatRender}
          renderItem={renderItem}
        ></FlatList>
        
        <div
          style={{
            backgroundColor: "black",
            height: 30,
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "300",
              marginLeft: 10,
            }}
          >
            Garnish
          </p>
        </div>
        <FlatList
          list={garnishrender}
          renderItem={renderItem}
        ></FlatList>

        <div
          style={{
            height: 100,
          }}
        >
          <div
            style={{
              backgroundColor: "#e3e3e1",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: 'flex',
                justifyContent: "flex-end",
              }}
            >
              <p

                style={{
                  textAlignVertical: 'center',
                  fontSize: 14,
                  fontWeight: "500",
                  color: "white",
                  textAlign: "center",
                  width: 100,
                  backgroundColor: "#363d47",
                  right: 5,
                }}
              >
                Total Ounces
              </p>
              <p

                style={{
                  textAlignVertical: 'center',
                  fontSize: 14,
                  fontWeight: "500",
                  color: "white",
                  textAlign: "center",
                  width: 100,
                  backgroundColor: "#363d47",
                  right: 2,
                }}
              >
                Total Amt
              </p>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: 'flex',
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <p

                style={{
                  textAlignVertical: 'center',
                  fontSize: 14,
                  fontWeight: "300",
                  color: "black",
                  textAlign: "center",
                  width: 100,
                  // backgroundColor: "#363d47",
                  right: 5,
                }}
              >
                {TotalYield.toFixed(2)}
              </p>
              <p

                style={{
                  textAlignVertical: 'center',
                  fontSize: 14,
                  fontWeight: "300",
                  color: "black",
                  textAlign: "center",
                  width: 100,
                  // backgroundColor: "#363d47",
                  right: 2,
                  marginBottom: 5,
                }}
              >
                {totalAmount.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div >
    )
  }

  function capitalizeFirstLetter(str) {
    const capitalized = str.replace(/^./, str[0].toUpperCase());
    return (str == 'ounce') ? capitalized : str;
  }


  function renderItem(item, index) {
    // console.log("item",item);
    return (
      <>
        <div
          key={index.toString()}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            display: 'flex',
            borderBottomWidth: 1,
            borderBottomColor: "#80b3ff"
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: '40%',
              display: 'flex',
            }}
          >
            <p
              style={{
                fontSize: 13,
                fontWeight: "500",
                //fontSize: RFValue(12),
                //fontWeight: "300",
                textAlign: "center",
                color: "black"
              }}
            >
              {item.itemname}
            </p>
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: '10%',
              display: 'flex',
            }}
          >
            <p

              style={{
                fontSize: 14,
                fontWeight: "500",
                //fontSize: RFValue(12),
                //fontWeight: "300",
                textAlign: "center",
                color: "black"
                //   alignSelf:"center",
              }}
            >
              {(item.itemname.length > 0) ? item.totalOunces.toFixed(2) : ""}
            </p>
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: '30%',
              //display: 'flex',
            }}
          >

            <Select
              isDisabled={viewOnly || (item.itemname.length == 0)}
              placeholder={(item.itemname.length == 0) ? "" : item.bottleSize}
              value={(item.itemname.length == 0) ? "" : capitalizeFirstLetter(item.bottleSize)}
              onChange={DDitem => {
                if (item.type == 'item') {
                  let unit = ShoppingBottleSizeItems()[DDitem.value]
                  let backup = [...itemrender]
                  backup[index].bottleSize = DDitem.value
                  backup[index].bottleSizeValue = unit
                  backup[index].amount = ListOfAmount((isShopping) ? "shopping" : "", DDitem.value, unit, item.yields)
                  setitemrender(backup)
                  totalOunceYieldValue(backup, dilutionrender)
                }
                if (item.type == 'float') {
                  let unit = ShoppingBottleSizeItems()[DDitem.value]
                  let backup = [...floatRender]
                  backup[index].bottleSize = DDitem.value
                  backup[index].bottleSizeValue = unit
                  backup[index].amount = ListOfFloatGarnishAmount((isShopping) ? "shopping" : "", item.itemname, item.unitName, DDitem.value, unit, item.yields, item.totalOunces, TotalYieldWithDilution)
                  setfloatRender(backup)
                  // totalOunceYieldValue()


                }
                if (item.type == 'garnish') {
                  let unit = ShoppingBottleSizeItems()[DDitem.value]
                  let backup = [...garnishrender]
                  backup[index].bottleSize = DDitem.value
                  backup[index].bottleSizeValue = unit
                  backup[index].amount = ListOfFloatGarnishAmount((isShopping) ? "shopping" : "", item.itemname, item.unitName, DDitem.value, unit, item.yields, item.totalOunces, TotalYieldWithDilution)
                  setgarnishrender(backup)
                  // totalOunceYieldValue()

                }
              }}
              options={ShoppingBottleSizeItemsDD()}
            />
          </div>
          <div
            style={{
              width: '10%',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              // height: 40,
            }}
          >
            <p

              style={{
                fontSize: 14,
                fontWeight: "500",
                //fontSize: RFValue(12),
                // fontWeight: "300",
                color: "black",
                textAlignVertical: 'center'
                // height:80
              }}
            >
              {(item.itemname.length > 0) ? item.yields.toFixed(2) : ""}
            </p>
          </div>
          <div
            style={{
              width: '10%',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              // height: 40,
              marginRight: 10,
            }}
          >
            <p

              style={{
                fontSize: 15,
                fontWeight: "500",
                // fontSize: RFValue(12),
                // fontWeight: "300",
                color: "black",
                textAlignVertical: 'center',
                // width:80,
                // height:80
              }}
            >
              {(item.itemname.length > 0) ? item.amount.toFixed(2) : ""}
            </p>
          </div>

        </div>
        <div
          style={{
            backgroundColor: "blue",
            opacity: 0.5,
            width: '100%',
            height: 1
          }}
        />
      </>
    );
  }

  const handleOpen = () => {
    ref.current.open();
  };

  const handleClose = () => {
    ref.current.close();
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <>
      <div style={{ flex: 1 }}>
        <div
          style={{
            flexDirection: "row",
            display: 'flex',

            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => {
              navigate(-1)
            }}>
            <AiOutlineArrowLeft
              style={{
                width: 20,
                height: 20,

                color: "#fff",
              }}
            /></Button>

          <p
            style={{
              fontSize: 20,
              color: "white",
              fontWeight: "400",
              //backgroundColor:'red',
              //width: 200, 
              left: 10,
              textAlign: 'center'
            }}
          >
            {location.state.headername}
          </p>
          {!viewOnly ? <Button
            disabled={viewOnly}
            onClick={() => ref.current.show()}>
            <p
              style={{
                fontSize: 15,
                color: "white",
                fontWeight: "400",
              }}
            >
              Finish
            </p>
          </Button> : <div style={{
            height: 18,
            width: 20,
          }}>
          </div>}
        </div>
        <div
          style={{
            flex: 1,
            backgroundColor: "white",
            top: 10,
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#363d47",
                height: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  backgroundColor: "#61656d",
                  justifyContent: "center",
                  alignItems: "center",
                  width: '24.9%',
                  borderRight: 1,
                  borderColor: "red",
                }}
              >
                <p
                  style={{
                    fontSize: 10,
                    color: "white",
                    fontWeight: "300",
                  }}
                >
                  Batch Type
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "grey",
                  width: '0.1%',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: "center",
                  alignItems: "center",
                  width: '24.9%',
                  borderRightWidth: 3,
                  borderColor: "#8e9299",
                }}
              >
                <p
                  style={{
                    fontSize: 10,
                    color: "white",
                    fontWeight: "300",
                  }}
                >
                  Batch bottle
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "grey",
                  width: '0.1%',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: "center",
                  alignItems: "center",
                  width: '24.9%',
                  borderRightWidth: 3,
                  borderColor: "#8e9299",
                  //left: -7,
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    color: "white",
                  }}
                >
                  Qty
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "grey",
                  width: '0.1%',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: "center",
                  alignItems: "center",
                  width: '25%'
                  // right: 10,
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    color: "white",
                    fontWeight: "400",
                    right: 5
                  }}
                >
                  Main ingredient
                </p>
              </div>
            </div>
            <div
              style={{
                // height:50,
                // backgroundColor:"red",
                borderBottomWidth: 1,
                display: 'flex',
                borderColor: "red",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: '25%',
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: "400",
                    textAlign: "center",
                    color: "black"
                  }}
                >
                  {selectedBatchType}
                </p>
              </div>

              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: '25%',
                }}
              >
                <p

                  style={{
                    fontSize: 12,
                    fontWeight: "400",
                    textAlign: "center",
                    color: "black"
                  }}
                >
                  {selectedBottleType}
                </p>
              </div>

              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: '25%',
                }}
              >
                <p

                  style={{
                    fontSize: 12,
                    fontWeight: "400",
                    textAlign: "center", color: "black"
                  }}
                >
                  {batchQuantity}
                </p>
              </div>

              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: '25%',
                }}
              >
                <p

                  style={{
                    fontSize: 12,
                    fontWeight: "400",
                    textAlign: "center",
                    color: "black"
                  }}
                >
                  {selectedIngredient}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "red",
              opacity: 0.5,
              width: '100%',
              height: 1
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              //backgroundColor:"grey",
              height: 35,

            }}
          >
            <div
              style={{
                width: '40%',
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                //height: 30,
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "black",
                }}
              >
                Item
              </p>
            </div>
            <div
              style={{
                width: '10%',
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                height: 30,
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "black",
                }}
              >
                Ounces
              </p>
            </div>
            <div
              style={{
                width: '30%',
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                height: 30,
              }}
            >
              <p
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "black",
                }}
              >
                Bottle Size
              </p>
            </div>
            <div
              style={{
                width: '10%',
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                height: 40,
              }}
            >
              <p
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "black",
                  // width:80,
                  // height:80
                }}
              >
                Batch Qty
              </p>
            </div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: '10%',
                display: 'flex',
              }}
            >
              <p
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "black",
                  // width:80,
                  // height:80
                }}
              >
                Amt
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "blue",
              opacity: 0.5,
              width: '100%',
              height: 1
            }}
          />
          <div
            style={{
              backgroundColor: "white",
              flex: 1,
            }}
          >
            <FlatList
              //  scrollEnabled={false}
              list={render}
              renderItem={renderFlatItem}
            ></FlatList>
          </div>
        </div>



      </div>


      <Modal
        isOpen={recipeModal}
        onRequestClose={() => {
          setrecipeModal(false)
        }}
        style={customStyles}
        contentLabel=""
      >
        <h2 >Add Recipe name</h2>
        <div
          style={{
            // width: "90%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'

          }}
        >
          <TextField
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: '#f7a828',
              }
            }}
            inputProps={{
              style: {
                height: "5px",
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: 10, }}
            required
            value={recipeName}
            id="recipe"
            name="recipe"
            autoFocus
            hiddenLabel={true}
            onChange={event => {
              const { value } = event.target;
              setrecipeName(value)
            }}
          />
        </div>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setrecipeModal(false);
            uploadDataToServer(recipeName)
          }}
        >
          Submit
        </Button>

        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setrecipeModal(false);
          }}
        >
          Cancel
        </Button>
      </Modal>

      <ActionSheet ref={ref}>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setrecipeModal(false);
            uploadDataToServer(recipeName)
          }}
        >
          Submit
        </Button>

        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setrecipeModal(false);
          }}
        >
          Cancel
        </Button>
      </ActionSheet>

    </>
  )
}

export default Recipe;
