import React, { useEffect, useRef, useState } from "react";
import { db, auth, frbase } from '../../src/Database_fb';

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight, AiOutlineSetting } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import Itemmanager from "../context/Itemmanager";
import Floatmanager from "../context/Floatmanager";
import Garnishmanager from "../context/Garnishmanager";
import Modal from 'react-modal';
import CraftDialog from "../Component/CraftDialog";
import { useAlert } from 'react-alert-with-buttons'

var batchCraft = null
export default function NetworkRecipes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalVisible, setmodalVisible] = useState(false);
  const [loading, setloading] = useState(true);
  const [shareModal, setshareModal] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const alert = useAlert()

  const [recipeName, setrecipeName] = useState("");
  const [myCraft, setmyCraft] = useState([]);

  const [docid, setdocid] = useState("");
  const [SearchData, setSearchData] = useState([]);
  const [isSearching, setisSearching] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(0);
  const [selectedItem, setselectedItem] = useState(null);
  //const [batchCraft, setbatchCraft] = useState(null);
  const [itemrender, setitemrender] = useState([]);
  const [floatRender, setfloatRender] = useState([]);
  const [garnishrender, setgarnishrender] = useState([]);
  const [dilutionrender, setdilutionrender] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const user = frbase.auth().currentUser;
    const subscribe = db
      .collection("MyNetwork")
      .doc(location.state.networkid)
      .collection('recipes')
      .onSnapshot((doc) => {
        const crafts = [];
        doc.forEach((snap) => {
          crafts.push({ key: snap.id, ...snap.data() });
        });
        setmyCraft(crafts);
        setloading(false)
      });
    return subscribe;
  }, []);


  function deleteCraft() {
    const subscribe = db
      .collection("MyNetwork")
      .doc(location.state.networkid)
      .collection("recipes")
      .doc(selectedItem.key)
      .delete()
      .then(() => {
        myCraft.splice(selectedIndex, 1);
        setmyCraft([...myCraft]);

      });
    return subscribe;
  }

  function loadCraftItems() {
    setloading(true)
    db
      .collection("MyCrafts").doc(docid)
      .get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          let craft = documentSnapshot.data()
          batchCraft = craft
          // setTimeout(() => {
          loadItems('MyCrafts')
          // }, 500);
        }
        else {
          loadLibraryCraftItems()
        }
      })

  }

  function loadLibraryCraftItems() {
    setloading(true)
    db
      .collection("Library").doc(docid)
      .get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          let craft = documentSnapshot.data()
          batchCraft = craft
          // setTimeout(() => {
          loadItems('Library')
          // }, 500);
        }
        else {
          showAlert("hmmm! Something is wrong with this recipe. It may be deleted")
        }


      })

  }

  function loadItems(tablename) {
    db
      .collection(tablename).doc(docid).collection("items").orderBy("order", 'desc')
      .get().then((documentSnapshot) => {

        let itemarry = [];
        let dilutionarry = [];
        let floatarry = [];
        let garnisharry = [];
        documentSnapshot.forEach((snap) => {

          let item = snap.data()
          if (item.type == "item") {
            let it = Itemmanager.getInstance();
            itemarry.push(it.setItems(item, snap.id))
          }
          if (item.type == "dilution") {
            let dil = Itemmanager.getInstance();
            dilutionarry.push(dil.setItems(item, snap.id))
          }
          if (item.type == "garnish") {
            let gar = Garnishmanager.getInstance();
            garnisharry.push(gar.setItems(item, snap.id))
          }
          if (item.type == "float") {
            let flo = Floatmanager.getInstance();
            floatarry.push(flo.setItems(item, snap.id))
          }
        })

        let sortedItems = itemarry.sort((a, b) => (a.order > b.order) ? 1 : -1)
        setitemrender(sortedItems)
        let sorteddilution = dilutionarry.sort((a, b) => (a.order > b.order) ? 1 : -1)
        setdilutionrender(sorteddilution)
        let sortFloat = floatarry.sort((a, b) => (a.order > b.order) ? 1 : -1)
        setfloatRender(sortFloat)
        let sortGarnish = garnisharry.sort((a, b) => (a.order > b.order) ? 1 : -1)
        setgarnishrender(sortGarnish)
        startCopypressed(sortedItems, sorteddilution, sortFloat, sortGarnish)
      })
  }

  function startCopypressed(sortedItems, sorteddilution, sortFloat, sortGarnish) {

    const user = frbase.auth().currentUser;
    let Ref = db
      .collection("MyCrafts")
    Ref.add({
      craftname: recipeName,
      status: 'active',
      uid: user.uid,
      totalOunces: batchCraft.totalOunces.toString(),
      totalYields: batchCraft.totalYields,
      batchType: batchCraft.batchType,
      bottleSize: batchCraft.bottleSize,
      quantity: batchCraft.quantity.toString(),
      ingrediants: batchCraft.ingrediants,
      totalDilution: batchCraft.totalDilution,
      assumed: batchCraft.assumed,
      ingrediantVal: batchCraft.ingrediantVal,
      imageUrl: batchCraft.imageUrl,
      videoUrl: batchCraft.videoUrl,
      mediatype: batchCraft.mediatype,
      timestamp: frbase.firestore.FieldValue.serverTimestamp(),
      updatedAt: frbase.firestore.FieldValue.serverTimestamp()
    }).then((ref) => {
      addUpdateItems(ref.id, sortedItems, sorteddilution, sortFloat, sortGarnish)
    })


  }

  function addUpdateItems(docID, sortedItems, sorteddilution, sortFloat, sortGarnish) {
    var index = 0
    let combinedArray = [...sortedItems, ...sorteddilution, ...sortGarnish, ...sortFloat];

    combinedArray.forEach((item) => {
      let docRef = db
        .collection("MyCrafts").doc(docID).collection("items")
      let data =
      {
        itemname: item.itemname,
        unitName: item.unitName,
        bottleSize: item.bottleSize,
        type: item.type,
        unitValue: item.unitValue,
        Quantity: item.Quantity,
        totalOunces: item.totalOunces,
        yields: item.yields,
        amount: item.amount,
        bottleSizeValue: item.bottleSizeValue,
        order: item.order
      }
      docRef.add(data).then((ref) => {
        index = index + 1
        if (index == combinedArray.length) {
          setloading(false)
          showAlert("Great! Recipie batched to your craft")
        }
      })
    })
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  function showAlert(message) {
    alert.open({
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            alert.close()
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });

  }

  return (
    <>

      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>
            <p style={{ fontSize: 30, color: "#D28816" }}>{location.state.networkname} Recipe</p>
            <div
              style={{
                flexDirection: 'row',
                alignItems: "flex-end",
                marginHorizontal: 20,
                marginTop: 5,
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  navigate("/MyCraft", {
                    state: {
                      networkid: location.state.networkid,
                      networkname: location.state.networkname,
                      fromNetwork: true,
                    }
                  });
                }}
                style={{
                  height: 60,
                  width: 60,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffac30",
                  borderRadius: 30,

                }}
              >
                <img
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  src={require("../images/addNote.png")}
                />
              </Button>
            </div>
          </div>

          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {myCraft.map((prop, index) => {
              return (
                <Grid item xs={6} >

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 5,
                      height: "60px",
                      cursor: 'pointer',
                      //width: "100%",
                      //backgroundColor: "#fff",
                      // marginBottom: 10,
                      // marginLeft: (index % 2) ? 10 : 0
                      backgroundImage: `url(${list_item_bg})`,
                      backgroundRepeat: "repeat",

                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => {

                      setselectedIndex(index);
                      setmodalVisible(true);
                      setrecipeName(prop.craftname);
                      setdocid(prop.recipeID);
                      setselectedItem(prop)
                    }}
                  >
                    <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                    <div style={{ width: '5%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                      <p
                        // className="App"
                        style={{
                          fontSize: 12,
                          //height: 40,
                          color: "#000",
                          textAlign: "start",
                          //marginLeft: '35%',
                          //marginTop: '32%'

                        }}
                      >
                        #{index + 1}
                        {/* <br/>{craf.craftname} */}
                      </p>
                    </div>
                    <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.craftname}
                      </p>
                    </div>

                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      />
                    </div>

                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>





        <Modal
          isOpen={modalVisible}
          onRequestClose={() => {
            setmodalVisible(false)
          }}
          style={customStyles}
          contentLabel=""
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              display: 'flex'
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "70%",
                alignItems: "center",
                // display: 'flex',
                borderRadius: 15,
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: 'flex',
                  width: "100%",
                  //  backgroundColor:"blue",
                  height: 60,
                  borderBottomWidth: 1,
                  borderBottomColor: "grey",
                }}
              >
                <h4
                  style={{
                    fontSize: 15,
                    fontWeight: "300",
                    color: "grey",
                  }}
                >
                  Please Select type
                </h4>
              </div>
              <Button
                onClick={() => {
                  setmodalVisible(false);
                  loadCraftItems()
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Batch it
                </p>
              </Button>
              <Button
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {

                  setmodalVisible(false);
                  navigate("/Recipe", {
                    state:
                    {
                      dociID: docid,
                      recipeName: recipeName,
                      viewOnly: true,
                    }
                  });
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  View
                </p>
              </Button>
              <Button
                onClick={() => {
                  setmodalVisible(false);
                  setTimeout(() => {
                    setshareModal(true)
                  }, 200);

                }}
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Share Recipe
                </p>
              </Button>

              <Button
                onClick={() => {
                  setmodalVisible(false);
                  setshowDelete(true)
                }}
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Delete
                </p>
              </Button>
              <Button
                onClick={() => setmodalVisible(false)}
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "600",
                    color: "#1c68ff",
                  }}
                >
                  Cancel
                </p>
              </Button>
            </div>
          </div>

        </Modal>


        <Modal

          isOpen={shareModal}

          // onAfterOpen={afterOpenModal}
          onRequestClose={() => {
            setshareModal(false)
          }}
          style={customStyles}
          contentLabel=""
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              flex: 1,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "70%",
                alignItems: "center",
                // display: 'flex',
                borderRadius: 15,
              }}
            >

              <Button
                onClick={() => {
                  setshareModal(false);
                  setloading(true)
                  db
                    .collection("MyCrafts").doc(selectedItem.recipeID)
                    .get()
                    .then((documentSnapshot) => {
                      if (documentSnapshot.exists) {
                        let craft = { key: documentSnapshot.id, ...documentSnapshot.data() }
                        navigate("/ShareRecipe", {
                          state: {
                            RecipeName: selectedItem.craftname,
                            dociID: selectedItem.recipeID,
                            item: craft
                          }
                        });
                      }
                      else {
                       alert('No recipe found')
                      }
                      setloading(false)
                    })
                  
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Share with User
                </p>
              </Button>
              {/* <Button
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setshareModal(false);
                  navigate("Recipe", {
                    state: {
                      dociID: selectedItem.key,
                      recipeName: selectedItem.craftname,
                      viewOnly: true,
                      shareOnline: true
                    }
                  });
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Share Online
                </p>
              </Button> */}
              <Button
                onClick={() => {
                  setshareModal(false);
                  navigate("/NetworksTeams", {
                    state: {
                      RecipeName: selectedItem.craftname,
                      dociID: selectedItem.recipeID,
                      shareRecipe: true
                    }
                  });
                }}
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Share with Network
                </p>
              </Button>

              <Button
                onClick={() => setshareModal(false)}
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "600",
                    color: "#1c68ff",
                  }}
                >
                  Cancel
                </p>
              </Button>
            </div>
          </div>
        </Modal>

      </Box>
      <CraftDialog
        show={showDelete}
        title={'Delete Recipe'}
        body={'Are you sure you want to delete this?'}
        handleToClose={() => {
          setshowDelete(false)
        }}
        handleToOk={() => {
          setshowDelete(false)
          deleteCraft();
        }}
      />

    </>
  );
}

