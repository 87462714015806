import React, { useEffect, useRef, useState } from "react";
import { db, auth } from '../Database_fb';
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import { useLocation } from 'react-router-dom';

export default function Learncategories(props) {
  const navigate = useNavigate();
  const [learnIQArray, setlearnIQArray] = useState([]);
  const location = useLocation();
  const [loading, setloading] = React.useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });    FetchSubcategories()
  }, [])

  function FetchSubcategories() {
    const user = auth.currentUser;
    db.collection("LearnIQ")
      .doc(location.state.key)
      .collection('SubCategories')
      .orderBy('timestamp', 'desc')
      .onSnapshot((doc) => {
        const crafts = [];
        doc.forEach((snap) => {
          crafts.push({ key: snap.id, ...snap.data() });
        });
        let sorted = crafts.sort((a, b) => a.name.localeCompare(b.name))
        //console.log('sorted', sorted);
        setlearnIQArray(sorted);
        setloading(false)
      });
  }


  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>

            <p style={{ fontSize: 25, color: "#f7a828" }}>{location.state.name}</p>
            <div
              style={{
                width: 20,
                height: 20,
              }}
            >
            </div>
          </div>
          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {learnIQArray.map((prop, index) => {
              return (
                <Grid item xs={6} >

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 5,
                      height: "60px",
                      cursor: 'pointer',
                      //width: "100%",
                      //backgroundColor: "#fff",
                      // marginBottom: 10,
                      // marginLeft: (index % 2) ? 10 : 0
                      backgroundImage: `url(${list_item_bg})`,
                      backgroundRepeat: "repeat",

                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => {
                      if (prop.pdfUrl != null) {
                        navigate('LoadLearniqPDF', { state: { pdfurl: prop.pdfUrl, weburl: prop.webUrl, videourl: prop.videoUrl } })
                      }
                      else{
                        alert('There is no content here')
                      }
                    }}
                  >
                    <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                    <div style={{ width: '5%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                      <p
                        // className="App"
                        style={{
                          fontSize: 12,
                          //height: 40,
                          color: "#000",
                          textAlign: "start",
                          //marginLeft: '35%',
                          //marginTop: '32%'

                        }}
                      >
                        #{index + 1}
                        {/* <br/>{craf.craftname} */}
                      </p>
                    </div>
                    <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.name}
                      </p>
                    </div>

                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      />
                    </div>

                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>


      </Box>
    </>
  );
}

