// import logo from "./logo.svg";

import "./App.css";
// import Stack from "@mui/material/Stack";
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Signup from "./Screens/Signup";
import Login from "./Screens/Login";
import Notfound from "./Screens/Notfound";
import Conversions from "./Screens/Conversions";
import CraftIQ from "./Screens/CraftIQ";
import LearnIQ from "./Screens/LearnIQ";
import MyCraft from "./Screens/MyCraft";
import Recipe from "./Screens/Recipe";
import CostRecipe from "./Screens/CostRecipe";
import ShareRecipe from "./Screens/ShareRecipe";
import Learncategories from "./Screens/Learncategories";
import Test from "./Screens/Test";
import CraftiqLibrary from "./Screens/CraftiqLibrary";
import NetworksTeams from "./Screens/NetworksTeams";
import NetworkRecipes from "./Screens/NetworkRecipes";
import NetworkUsers from "./Screens/NetworkUsers";
import NetworkSettings from "./Screens/NetworkSettings";
import Tutorial from "./Screens/Tutorial";
import EditNetworks from "./Screens/EditNetworks";
import BackBar from "./Screens/BackBar";
import Setting from "./Screens/Setting";
import Profile from "./Screens/Profile";
import About from "./Screens/About";
import LoadLearniqPDF from "./Screens/ViewLearnIQ/LoadLearniqPDF";
import ItemDetails from "./Screens/ItemDetails";
import Premium from "./Screens/Premium";
import NetworkSubscription from "./Screens/NetworkSubscription";
import axios from 'axios';

import { db, auth, frbase, messaging } from './Database_fb';

import EventEmitter from "reactjs-eventemitter";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import Navbar from './Component/Navbar';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { AlertProvider } from 'react-alert-with-buttons'
import Utility from "./utility";
import PDFTemplate from "./Component/HTML/PDFTemplate";
import { getToken, onMessage } from "firebase/messaging";
import { async } from "@firebase/util";

// importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-app-compat.js')
// importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging-compat.js')
function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(auth.currentUser != null);
  const setAuth = (value) => {
    setIsAuthenticated(value);
    //alert(value);
  };

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);



  const [user] = useAuthState(getAuth());


  // function loadAllUsers() {
  //   var allusers = []
  //   db
  //     .collection("users")
  //     .get()
  //     .then((doc) => {
  //       doc.forEach((document) => {
  //         let data = document.data()
  //         if (data.fcmToken.length > 0) {
  //           db.collection("users")
  //             .doc(document.id)
  //             .update({
  //               tokens: frbase.firestore.FieldValue.arrayUnion(data.fcmToken),
  //             })
  //             .then(() => {
  //             });
  //         }
  //       });
  //     });
  // }

  // async function requestPermission() {
  //   const sw = await window.navigator.serviceWorker.register('/sw.js')
  //   window.Notification.requestPermission( async (permission) => {
  //     if (permission === 'granted') {
  //       //sw.showNotification(title)
  //       const token = await getToken(messaging, {
  //         serviceWorkerRegistration: sw
  //       })

  //       console.log('token',token);
  //     }
  //   })
  // }

  
  useEffect(() => {

   // requestPermission()
   const user = auth.currentUser;
    

    if (user != null) {
      Utility.loadBackbar() 
      Utility.loadUser()
      Utility.loadPremiumUser()
    }

    EventEmitter.subscribe('Logout', event => {
      setAuth(false);
    })


    getToken(messaging, { vapidKey: Utility.FIREBASE_API_KEY }).then((currentToken) => {
      console.log('current token for client: ', currentToken);
      if (currentToken) {
        Utility.FCMToken = currentToken

        alert(currentToken)
       
        if (user != null) {
          // const user = auth.currentUser;
          db.collection("users")
            .doc(user.uid)
            .update({
              tokens: frbase.firestore.FieldValue.arrayUnion(Utility.FCMToken),
            })
            .then(() => {
            });
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // 
        }
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      setShow(true);
      setNotification({ title: payload.notification.title, body: payload.notification.body })
    });
    // messaging.getToken({ vapidKey: Utility.FIREBASE_API_KEY }).then((currentToken) => {
    //   console.log('currentToken', currentToken);
    //   if (currentToken) {
    //     Utility.FCMToken = currentToken
    //     console.log('current token for client: ', currentToken);
    //     const user = auth.currentUser;
    //     if (user != null) {
    //       // const user = auth.currentUser;
    //       db.collection("users")
    //         .doc(user.uid)
    //         .update({
    //           tokens: frbase.firestore.FieldValue.arrayUnion(Utility.FCMToken),
    //         })
    //         .then(() => {
    //         });
    //     }
    //     setTokenFound(true);
    //     // Track the token -> client mapping, by sending to backend server
    //     // show on the UI that permission is secured
    //   } else {
    //     console.log('No registration token available. Request permission to generate one.');
    //     setTokenFound(false);
    //     // shows on the UI that permission is required 
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // catch error while creating client token
    // })

    // messaging.onMessage((payload) => {
    //   setShow(true);
    //   setNotification({ title: payload.notification.title, body: payload.notification.body })
    //   // console.log(payload);
    // });



  }, [user])

  const theme = createTheme({
    palette: {
      primary: {
        main: '#b88244'
      }
    }
  });

  return (

    <MuiThemeProvider theme={theme}>
      <AlertProvider containerStyle={{ width: '90%' }} x>
        <Router>

          {user ?
            <>
              <Navbar sticky="top" setAuth={setAuth} />
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <CraftIQ />
                  }
                />
                <Route
                  exact
                  path="/LearnIQ"
                  element={
                    <LearnIQ />
                  }
                />

                <Route
                  exact
                  path="/MyCraft"
                  element={
                    <MyCraft />
                  }
                />
                <Route
                  exact
                  path="/Recipe"
                  element={
                    <Recipe />
                  }
                />
                <Route
                  exact
                  path="/ShareRecipe"
                  element={
                    <ShareRecipe />
                  }
                />
                <Route
                  exact
                  path="/Conversions"
                  element={
                    <Conversions />
                  }
                />
                <Route
                  exact
                  path="/NetworksTeams/EditNetworks"
                  element={
                    <EditNetworks />
                  }
                />

                <Route
                  exact
                  path="/NetworksTeams/EditNetworks/NetworkSettings"
                  element={
                    <NetworkSettings />
                  }
                />
                <Route
                  exact
                  path="/NetworksTeams/NetworkRecipes"
                  element={
                    <NetworkRecipes />
                  }
                />
                <Route
                  exact
                  path="/NetworksTeams/NetworkUsers"
                  element={
                    <NetworkUsers />
                  }
                />
                <Route
                  exact
                  path="/LearnIQ/Learncategories"
                  element={
                    <Learncategories />
                  }
                />
                <Route
                  exact
                  path="/LearnIQ/Learncategories/LoadLearniqPDF"
                  element={
                    <LoadLearniqPDF />
                  }
                />
                <Route
                  exact
                  path="/CraftiqLibrary"
                  element={
                    <CraftiqLibrary />
                  }
                />
                <Route
                  exact
                  path="/NetworksTeams"
                  element={
                    <NetworksTeams />
                  }
                />
                <Route
                  exact
                  path="/Tutorial"
                  element={
                    <Tutorial />
                  }
                />
                <Route
                  exact
                  path="/BackBar"
                  element={
                    <BackBar />
                  }
                />
                <Route
                  exact
                  path="/Setting"
                  element={
                    <Setting />
                  }
                />
                <Route
                  exact
                  path="/Setting/Profile"
                  element={
                    <Profile />
                  }
                />
                <Route
                  exact
                  path="/About"
                  element={
                    <About />
                  }
                />
                <Route
                  exact
                  path="/Recipe/ItemDetails"
                  element={
                    <ItemDetails />
                  }
                />
                <Route
                  exact
                  path="/Recipe/CostRecipe"
                  element={
                    <CostRecipe />
                  }
                />

                <Route
                  exact
                  path="/Recipe/PDFTemplate"
                  element={
                    <PDFTemplate />
                  }
                />
                <Route
                  exact
                  path="/Premium"
                  element={
                    <Premium />
                  }
                />
                <Route
                  exact
                  path="/NetworkSubscription"
                  element={
                    <NetworkSubscription />
                  }
                />

                {/* <Route exact path="*" element={<Notfound />} /> */}
              </Routes></> : <Routes>
              <Route exact path="/" element={<Login setAuth={setAuth} />} />
              <Route exact path="/Signup" element={<Signup setAuth={setAuth} />} />
              {/* <Route exact path="*" element={<Notfound />} /> */}
            </Routes>}
        </Router>
      </AlertProvider>
    </MuiThemeProvider>

  );
}

export default App;
