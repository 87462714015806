export default class Prepmanager {
    static myInstance = null;
    static dociID = ""
    static itemname = ""
    static type = "prep"
    static order = 0.0
    
    /**
     * @returns {Prepmanager}
     */
    static getInstance() {
        Prepmanager.myInstance = new Prepmanager();

        return this.myInstance;
    }

    getItems() {
        return {itemname: this.itemname, type:this.type,  order: this.order,dociID: this.dociID};
    }

    setItems(name, id, iorder) {
        this.dociID = id
        this.itemname = name
        this.type = "prep"
        this.order = iorder
        return this
    }
}