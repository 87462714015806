import React, { useEffect, useRef, useState } from "react";
import { db, auth, frbase } from '../../src/Database_fb';

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import Modal from 'react-modal';
import CraftDialog from "../Component/CraftDialog";
import { useAlert } from 'react-alert-with-buttons'
import TextField from "@mui/material/TextField";
import SelectDropdown from 'react-dropdown';

import '../css/tags.css'
import { Tag } from "@mui/icons-material";
Modal.setAppElement(document.getElementById('root'));


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#D28816",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
var selectedItem = { key: '' };



export default function MyCraft({ route }) {
  const navigate = useNavigate();
  const [mycraftArray, setmycraftArray] = useState([]);
  const [loading, setloading] = React.useState(true);
  const location = useLocation();
  const [modalVisible, setmodalVisible] = useState(false);
  const [orderModal, setorderModal] = useState(false);
  const [shareModal, setshareModal] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [recipeName, setrecipeName] = useState("");
  const [docid, setdocid] = useState("");
  const [selectedIndex, setselectedIndex] = useState(0);
  const [fromNetwork, setfromNetwork] = useState(location.state != null ? location.state.fromNetwork : false);
  const alert = useAlert()
  const [searchValue, setSearchValue] = useState("")
  const [myFilterArray, setmyFilterArray] = useState([])
  const [filteredlibraryArray, setfilteredlibraryArray] = useState([]);
  const [selectedSearch, setselectedSearch] = useState("Recipe Name")
  const [Tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [tagsFilters, settagsFilters] = useState([]);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    FetchCrafts()
  }, [])

  function FetchCrafts() {
    setloading(true)
    const user = auth.currentUser;
    const subscribe = db.collection("MyCrafts")
      .where("uid", "==", user.uid)
      .orderBy("timestamp", "asc")
      .get()
      .then((doc) => {
        const crafts = [];
        const library = [];
        const promises = [];
       
        doc.forEach((snap) => {
          library.push({ key: snap.id, ...snap.data() });
          promises.push(db.collection("MyCrafts").doc(snap.id).collection('items').get());
        });

        Promise.all(promises).then(values => {
          values.forEach((doc, index) => {
            let items = [];
            doc.docs.forEach((snappy, index) => {
              items.push({ key: snappy.id, ...snappy.data() });
            })
             let lib = library[index]
             crafts.push({...lib, items: items});
          })
         // let sorted = crafts.sort((a, b) => a.craftname.localeCompare(b.craftname))
          setmycraftArray(crafts);
          setfilteredlibraryArray(crafts)
          setloading(false)
        })

        // let sorted = crafts.sort((a, b) => a.craftname.localeCompare(b.craftname))
        // setmycraftArray(sorted);
        // setfilteredlibraryArray(sorted)
        // setloading(false)
      });
  }

  function deleteCraft() {
    const subscribe = db
      .collection("MyCrafts")
      .doc(selectedItem.key)
      .delete()
      .then(() => {
        mycraftArray.splice(selectedIndex, 1);
        setmycraftArray([...mycraftArray]);
      });
    return subscribe;
  }

  function addRecipeToNetwork() {
    db
      .collection("MyNetwork")
      .doc(location.state.networkid)
      .collection('recipes')
      .where("recipeID", "==", selectedItem.key)
      .get()
      .then((doc) => {

        if (doc.size == 0) {
          let data = {
            craftname: selectedItem.craftname,
            status: "active",
            uid: frbase.auth().currentUser.uid,
            recipeID: selectedItem.key,
            networkID: location.state.networkid,
            timestamp: frbase.firestore.FieldValue.serverTimestamp(),
          };


          db.collection("MyNetwork")
            .doc(location.state.networkid)
            .collection('recipes')
            .add(data)
            .then((ref) => {
              //console.log('ref' , ref.id);
              db.collection("MyNetwork")
                .doc(location.state.networkid)
                .update({
                  recipeIDs: frbase.firestore.FieldValue.arrayUnion(selectedItem.key)
                })
                .then(() => {
                  // console.log('rechaed last');
                  showAlert("Success! Recipe added in " + location.state.networkname)
                })
              setloading(false)

            })
        }
        else {
          setloading(false)
          showAlert("Exists! Recipe already exist in this network")

        }
      });
  }

  function showAlert(message) {
    alert.open({
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            alert.close()
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });

  }

  function openModal() {
    setmodalVisible(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#D28816';
  }

  function closeModal() {
    setmodalVisible(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function searchingItem(e) {
    let text = e.target.value
    setSearchValue(text)
    if (text.length == 0) {
      setfilteredlibraryArray(mycraftArray)
      return;
    }
    if (selectedSearch == 'Recipe Name') {
      setfilteredlibraryArray(mycraftArray.filter(name => name.craftname.match(new RegExp(text, "i"))))
    }
    else {
      if (Tags.length == 5) {
        showAlert("Sorry! You can not add more then 5 tags ", [
          {
            text: "Ok",
          },
        ]);
        return
      }
      //setfilteredlibraryArray(mycraftArray.filter(item => item.ingrediants.find(name => name.match(new RegExp(text, "i")))))
      let searched = (tagsFilters.length > 0 ? tagsFilters : mycraftArray).filter(item => item.items.find(name => {
        //name.match(new RegExp(text, "i"))
        return name.itemname.match(new RegExp(text, "i"))
        //|| Tags.find(tag => name.match(new RegExp(tag, "i")))
      }))
      setfilteredlibraryArray([...searched])
    }
    ///{libraryArray.filter(name => (name.ingrediants.filter(item => item.match(new RegExp(searchValue, "i"))))).map((prop, index) => {
  }

  const deleteTag = (index) => {
    let prevState = [...Tags]
    prevState = prevState.filter((tag, i) => i !== index)
    setTags(prevState)
    let reversed = prevState.reverse()
    let updatedArray = []
    reversed.forEach((text) => {
      updatedArray = (updatedArray.length > 0 ? updatedArray : mycraftArray).filter(item => item.items.find(name => {
        return (name.itemname.match(new RegExp(text, "i")))
      }))
    })
    if (updatedArray.length == 0) {
      setfilteredlibraryArray(mycraftArray)
      settagsFilters([])
      return;
    }
    settagsFilters([...updatedArray])
    setfilteredlibraryArray([...updatedArray])
  }



  const onKeyDown = (e) => {

    if (selectedSearch == 'Recipe Name') {
      return
    }

    const { key } = e;
    const trimmedInput = searchValue.trim();

    if (key === ',' && trimmedInput.length && !Tags.includes(trimmedInput)) {
      e.preventDefault();
      //let searched = mycraftArray.filter(item => item.ingrediants.find(name => name.match(new RegExp(trimmedInput, "i"))))
      setTags(prevState => [...prevState, trimmedInput]);
      settagsFilters(filteredlibraryArray)
      //setfilteredlibraryArray(prevState => [...prevState, ...searched].filter((val,id,array) => array.indexOf(val) == id));
      setSearchValue('');
    }
    if (key === 'Enter' && trimmedInput.length && !Tags.includes(trimmedInput)) {
      e.preventDefault();
      //let searched = mycraftArray.filter(item => item.ingrediants.find(name => name.match(new RegExp(trimmedInput, "i"))))
      setTags(prevState => [...prevState, trimmedInput]);
      settagsFilters(filteredlibraryArray)
      //setfilteredlibraryArray(prevState => [...prevState, ...searched].filter((val,id,array) => array.indexOf(val) == id));
      setSearchValue('');
    }

    if (key === "Backspace" && !searchValue.length && Tags.length && isKeyReleased) {
      const tagsCopy = [...Tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setSearchValue(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }


  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>
            <p style={{ fontSize: 30, color: "#D28816" }}>MY CRAFT</p>
            <div
              style={{
                flexDirection: 'row',
                alignItems: "flex-end",
                marginHorizontal: 20,
                marginTop: 5,
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
                // position: 'absolute',
                //   right: 20,
                //   top: 50
              }}
            >
              <Button
                onClick={() => {
                  navigate("/Recipe", {
                    state: {
                      dociID: null,

                    }
                  });
                }}
                style={{
                  height: 60,
                  width: 60,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffac30",
                  borderRadius: 30,

                }}
              >
                <img
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  src={require("../images/addNote.png")}
                />
              </Button>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >

            <div
              style={{
                width: "80%",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >


              <TextField
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                    color: '#f7a828',
                  }
                }}
                inputProps={{
                  style: {
                    height: "5px",
                  },
                }}
                placeholder= {(selectedSearch == 'Recipe Name') ? 'Search by name...' : 'Press enter or comma to add new search tag...'}
                fullWidth
                style={{ backgroundColor: "#fff", borderRadius: 10, }}
                required
                id="search"
                name="search"
                hiddenLabel={true}
                value={searchValue}
                onChange={e => searchingItem(e)}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
              />



              <div style={{ backgroundColor: 'red', marginLeft: -5, width: '30%' }}>
                <SelectDropdown
                  value={selectedSearch}
                  options={['Recipe Name', 'Ingredient']}
                  onChange={(value, index) => {
                    setfilteredlibraryArray(mycraftArray)
                    setSearchValue('')
                    setselectedSearch(value.value)
                  }}
                />
              </div>
            </div>

          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {Tags.map((tag, index) => (
              <div className="tag">
                {tag}
                <button onClick={() => deleteTag(index)}>x</button>
              </div>
            ))}
          </div>
          <div style={{ height: 20 }} />

          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {/* {mycraftArray.filter(name => name.craftname.match(new RegExp(searchValue, "i"))).map((prop, index) => { */}
            {filteredlibraryArray.map((prop, index) => {

              return (
                <Grid item xs={6} >

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 5,
                      height: "60px",
                      cursor: 'pointer',
                      //width: "100%",
                      //backgroundColor: "#fff",
                      // marginBottom: 10,
                      // marginLeft: (index % 2) ? 10 : 0
                      backgroundImage: `url(${list_item_bg})`,
                      backgroundRepeat: "repeat",

                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => {

                      setselectedIndex(index);

                      selectedItem = prop
                      if (fromNetwork) {
                        setloading(true)
                        addRecipeToNetwork()
                      }
                      else {
                        console.log('clicke');
                        openModal()
                      }
                    }}
                  >
                    <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                    <div style={{ width: '5%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                      <p
                        // className="App"
                        style={{
                          fontSize: 12,
                          //height: 40,
                          color: "#000",
                          textAlign: "start",
                          //marginLeft: '35%',
                          //marginTop: '32%'

                        }}
                      >
                        #{index + 1}
                        {/* <br/>{craf.craftname} */}
                      </p>
                    </div>
                    <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.craftname}
                      </p>
                    </div>

                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      />
                    </div>

                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>





        <Modal
          isOpen={modalVisible}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel=""
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              display: 'flex'
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "70%",
                alignItems: "center",
                // display: 'flex',
                borderRadius: 15,
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: 'flex',
                  width: "100%",
                  //  backgroundColor:"blue",
                  height: 60,
                  borderBottomWidth: 1,
                  borderBottomColor: "grey",
                }}
              >
                <h4
                  style={{
                    fontSize: 15,
                    fontWeight: "300",
                    color: "grey",
                  }}
                >
                  Please Select type
                </h4>
              </div>
              <Button
                onClick={() => {
                  setmodalVisible(false);
                  navigate("/Recipe", {
                    state: {
                      dociID: selectedItem.key,
                      recipeName: selectedItem.craftname,
                      viewOnly: false,
                    }
                  });
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Edit
                </p>
              </Button>
              <Button
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setmodalVisible(false);
                  // console.log('selectedItem.key',selectedItem.key);
                  // console.log('selectedItem.craftname',selectedItem.craftname);
                  navigate("/Recipe", {
                    state: {
                      dociID: selectedItem.key,
                      recipeName: selectedItem.craftname,
                      viewOnly: true,
                    }
                  });
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  View
                </p>
              </Button>
              <Button
                onClick={() => {
                  setmodalVisible(false);
                  setTimeout(() => {
                    setshareModal(true)
                  }, 200);


                }}
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Share Recipe
                </p>
              </Button>

              <Button
                onClick={() => {
                  setmodalVisible(false);
                  setshowDelete(true)

                }}
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Delete
                </p>
              </Button>
              <Button
                onClick={() => setmodalVisible(false)}
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "600",
                    color: "#1c68ff",
                  }}
                >
                  Cancel
                </p>
              </Button>
            </div>
          </div>

        </Modal>


        <Modal

          isOpen={shareModal}

          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel=""
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              flex: 1,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "70%",
                alignItems: "center",
                // display: 'flex',
                borderRadius: 15,
              }}
            >

              <Button
                onClick={() => {
                  setshareModal(false);
                  navigate("/ShareRecipe", {
                    state: {
                      RecipeName: selectedItem.craftname,
                      dociID: selectedItem.key,
                      item: selectedItem
                    }
                  });
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Share with User
                </p>
              </Button>
              {/* <Button
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setshareModal(false);
                  navigate("/Recipe", {
                    state: {
                      dociID: selectedItem.key,
                      recipeName: selectedItem.craftname,
                      viewOnly: true,
                      shareOnline: true
                    }
                  });
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Share Online
                </p>
              </Button> */}
              <Button
                onClick={() => {
                  setshareModal(false);
                  navigate("/NetworksTeams", {
                    state: {
                      RecipeName: selectedItem.craftname,
                      dociID: selectedItem.key,
                      shareRecipe: true
                    }
                  });
                }}
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Share with Network
                </p>
              </Button>

              <Button
                onClick={() => setshareModal(false)}
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "600",
                    color: "#1c68ff",
                  }}
                >
                  Cancel
                </p>
              </Button>
            </div>
          </div>
        </Modal>

      </Box>

      <CraftDialog
        show={showDelete}
        title={'Delete Recipe'}
        body={'Are you sure you want to delete this?'}
        handleToClose={() => {
          setshowDelete(false)
        }}
        handleToOk={() => {
          setshowDelete(false)
          deleteCraft();
        }}
      />
    </>
  );
}

