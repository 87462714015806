// Scripts for firebase and firebase messaging

import { db, auth, frbase, messaging } from './Database_fb';
import axios from 'axios';
import backbarimg from './images/backbar.png';


export default class Utility {
    static FCMToken = ""
    static currentuser = null
    static sub_decided = false
    static selected_sub = 0
    static itemsArray = []; //Utility.Items()
    static FIREBASE_API_KEY = "AAAA-4Dtq3U:APA91bGwd_e4yN9cmxit9xicVCtH3E9Ak8TfudO8r0pjDF_KYLuOkEcOBDpgD8B-jNtnbtcgadrbw52Wu81gMlxfCv-6BAoVeGVIN6rdirqq20L5pWuu0InT07fgJt5b13A5rvSq6YFN"


    static async loadUser() {
        const user = frbase.auth().currentUser;
        if (user != null) {
            const users = db
                .collection("users")
                .doc(user.uid)
            users.get().then(async (documentSnapshot) => {
                if (documentSnapshot.exists) {
                    let data = documentSnapshot.data()
                    Utility.currentuser = data
                    if (data.isPremium != null && data.isPremium) {
                        Utility.selected_sub = 5
                        Utility.sub_decided = true
                        Utility.currentuser.subscribed = true
                        return
                    }
                    // Utility.loadBackbar()
                    Utility.selected_sub = data.selectedPlan
                    if (data.subscribed != null && data.subscribed == true && data.membership != 'free') {
                        Utility.sub_decided = true

                        if (data.network_subscribed_from != null && data.network_subscribed_from === 'web') {
                            var reqBody = "sub_id=" + data.network_subscription.id;
                            axios({
                                method: "post",
                                headers: { 'Content-type': 'application/x-www-form-urlencoded' },
                                url: "https://us-central1-craftiqapp.cloudfunctions.net/checkWebSubscriptionStatus",
                                data: reqBody
                            }).then((response) => {
                                if (response.data.status != null && response.data.status != 'active') {
                                    db.collection('users').doc(user.uid).update({
                                        subscribed: false,
                                    })
                                    Utility.currentuser.subscribed = false
                                }
                            })
                                .catch((e) => {
                                    // console.log(e);
                                });
                        }
                        else {
                            var bearerKey = ''
                            if (data.deviceType == 'iOS') {
                                bearerKey = "Bearer appl_swJtVcREbjGibuQSHQXQADxSDuz"
                            }
                            else {
                                bearerKey = "Bearer goog_BFhDtVUhqRJjYUkmyvFbnvcimTJ"
                            }
                            let headers = new Headers({
                                "Content-Type": "application/json",
                                Authorization: bearerKey,
                            })
                            //data.revenuecat_user_id
                            let response = await fetch("https://api.revenuecat.com/v1/subscribers/" + data.revenuecat_user_id, {
                                method: "GET",
                                headers,
                                //body: JSON.stringify(data),
                            })
                            response = await response.json()
                            // console.log('response networking', response);
                            if (response.subscriber.subscriptions.Networking != null) {
                                Utility.currentuser.subscribed = true
                            }
                            else {
                                Utility.currentuser.subscribed = false
                                db.collection('users').doc(user.uid).update({
                                    subscribed: false,
                                })
                            }
                        }
                    }
                    else {
                        Utility.currentuser.subscribed = false
                    }

                    //   try {
                    //     const customerInfo = await Purchases.getCustomerInfo();
                    //     if (typeof customerInfo.entitlements.active.Networking !== "undefined") {
                    //       Utility.currentuser.subscribed = true
                    //     }
                    //     else {
                    //         db
                    //         .collection("users")
                    //         .doc(user.uid)
                    //         .update({
                    //           subscribed: true,
                    //           max_member: 5,
                    //           membership: "Free",
                    //           selectedPlan: 0,
                    //           membership_name: "Friends and Family",
                    //           updatedAt: firebase.firestore.FieldValue.serverTimestamp()
                    //         }).then(() => {
                    //           Utility.currentuser.subscribed = true
                    //           Utility.currentuser.max_member = 5
                    //           Utility.currentuser.membership = "Free"
                    //           Utility.currentuser.membership_name = "Friends and Family"
                    //           Utility.sub_decided = true
                    //         })
                    //     }
                    //   } catch (e) {
                    //     // Error fetching purchaser info
                    //   }


                }
            });
        }
    }

    static async loadPremiumUser() {
        const user = frbase.auth().currentUser;
        if (user != null) {
            const users = db
                .collection("users")
                .doc(user.uid)
            users.get().then(async (documentSnapshot) => {
                if (documentSnapshot.exists) {
                    let data = documentSnapshot.data()
                    Utility.currentuser = data
                    if (data.isPremium != null && data.isPremium) {
                        Utility.currentuser.premium_subscribed = true
                        return
                    }

                    if (data.premium_subscribed != null && data.premium_subscribed == true) {

                        if (data.premium_subscribed_from != null && data.premium_subscribed_from === 'web') {
                            var reqBody = "sub_id=" + data.subscription.id;
                            axios({
                                method: "post",
                                headers: { 'Content-type': 'application/x-www-form-urlencoded' },
                                url: "https://us-central1-craftiqapp.cloudfunctions.net/checkWebSubscriptionStatus",
                                data: reqBody
                            }).then((response) => {

                                if (response.data.status != null && response.data.status != 'active') {
                                    db.collection('users').doc(user.uid).update({
                                        premium_subscribed: false,
                                    })
                                    Utility.currentuser.premium_subscribed = false
                                }
                            })
                                .catch((e) => {
                                    // console.log(e);
                                });
                        }
                        else {
                            var bearerKey = ''
                            if (data.deviceType == 'iOS') {
                                bearerKey = "Bearer appl_swJtVcREbjGibuQSHQXQADxSDuz"
                            }
                            else {
                                bearerKey = "Bearer goog_BFhDtVUhqRJjYUkmyvFbnvcimTJ"
                            }
                            let headers = new Headers({
                                "Content-Type": "application/json",
                                Authorization: bearerKey,
                            })
                            //data.revenuecat_user_id
                            let response = await fetch("https://api.revenuecat.com/v1/subscribers/" + data.revenuecat_user_id, {
                                method: "GET",
                                headers,
                                //body: JSON.stringify(data),
                            })
                            response = await response.json()
                            // console.log('response entitlements', response.subscriber.entitlements);
                            // console.log('response subscriptions', response.subscriber.subscriptions);

                            if (response.subscriber.subscriptions.premium != null) {
                                Utility.currentuser.premium_subscribed = true
                            }
                            else {
                                Utility.currentuser.premium_subscribed = false
                                db.collection('users').doc(user.uid).update({
                                    premium_subscribed: false,
                                })
                            }
                        }
                    }


                    //   try {
                    //     const customerInfo = await Purchases.getCustomerInfo();
                    //     if (typeof customerInfo.entitlements.active.premium !== "undefined") {
                    //       Utility.currentuser.premium_subscribed = true
                    //     }
                    //     else {
                    //       Utility.currentuser.premium_subscribed = false
                    //     }
                    //   } catch (e) {
                    //     // Error fetching purchaser info
                    //   }
                }
            });
        }
    }


    static async loadBackbar() {
        // console.log('Utility.itemsArray',Utility.itemsArray);
        var items = [...this.popularItems]
        db.collection("backbar").onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
            snapshot.docChanges().forEach((change, index) => {
                if (change.type === "added") {
                    let data = change.doc.data()
                    let contains = items.some(e => e.label === data.name)
                    if (!contains) {
                        items.push({ label: data.name, value: data.name, popular: false })
                    }
                }
            });
            Utility.itemsArray = items
                .filter(e => e.popular === true)
                .concat(
                    items
                        .filter(e => e.popular !== true)
                        .sort((a, b) => a.label.localeCompare(b.label))
                );
            // Utility.itemsArray = items.sort((a, b) => {
            //     if (!a.popular) {
            //         return a.label.localeCompare(b.label)
            //     }
            // })
            // Utility.itemsArray = items;
        });

        const user = frbase.auth().currentUser;
        db.collection("users").where("uid", "==", user.uid).onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
            snapshot.docChanges().forEach((change, index) => {
                if (change.type === "added" || change.type === "modified") {
                    let data = change.doc.data()
                    data.backbar.forEach((item) => {
                        let contains = Utility.itemsArray.some(e => e.label === item)
                        if (!contains) {
                            items.push({ label: item, value: item, popular: false })
                        }
                    });
                }
            });

            // doc.forEach((snap) => {
            //     let data = snap.data()
            //     data.backbar.forEach((item) => {
            //         let contains = Utility.itemsArray.some(e => e.label === item)
            //         if (!contains) {
            //             items.push({ label: item, value: item, popular: false })
            //         }
            //     });
            //   });

            Utility.itemsArray = items
                .filter(e => e.popular === true)
                .concat(
                    items
                        .filter(e => e.popular !== true)
                        .sort((a, b) => a.label.localeCompare(b.label))
                );

            // Utility.itemsArray = items.sort((a, b) => {
            //     if (!a.popular) {
            //         return a.label.localeCompare(b.label)
            //     }
            // })
            //Utility.itemsArray = items;
        });
    }

    static Items() {
        localStorage.getItem('Items_key', (err, value) => {
            if (value != null) {
                let array = JSON.parse(value)
                Utility.itemsArray.push(...array)
                // Utility.itemsArray = Utility.itemsArray.sort((a, b) => {
                //     if (!a.popular) {
                //         return a.label.localeCompare(b.label)
                //     }
                // })

                Utility.itemsArray = Utility.itemsArray
                    .filter(e => e.popular === true)
                    .concat(
                        Utility.itemsArray
                            .filter(e => e.popular !== true)
                            .sort((a, b) => a.label.localeCompare(b.label))
                    );
            }
            return []
        });
    }


    static AddItem(item) {
        localStorage.getItem('Items_key', (err, value) => {
            if (value != null) {
                let array = JSON.parse(value)
                array.push({ label: item, value: item, popular: false })
                localStorage.setItem('Items_key', JSON.stringify(array))

            }
            else {
                localStorage.setItem('Items_key', JSON.stringify([{ label: item, value: item, popular: false }]))

            }
        });
        const user = frbase.auth().currentUser;
        db.collection("users")
            .doc(user.uid)
            .update({
                backbar: frbase.firestore.FieldValue.arrayUnion(item)
            })
        //console.log(item);
        Utility.itemsArray.push({ label: item, value: item, popular: false })
        Utility.itemsArray = Utility.itemsArray.sort((a, b) => {
            if (!a.popular) {
                return a.label.localeCompare(b.label)
            }
        })

    }

    static sendPushNotification = async (tokens, question) => {
        const FIREBASE_API_KEY = "AAAAGDx3iu4:APA91bEEwhNcBGFWfjQztulSEaEzEbjRQWw34lcL30YxAn5e0GFjyq37VbvYGIYuXSyouAFj_TO4iunaiqNGyqAcFhHU79k4EQ7gTd5JoOtBlrMBqYgH1JlBc-E_iGVzO3CSzLQZibP0"
        const message = {
            //to:tokens[0],
            registration_ids: tokens,
            notification: {
                title: "Someone asked question",
                body: question,
                vibrate: 1,
                sound: 1,
                show_in_foreground: true,
                priority: "high",
                content_available: true,
            },
            // data: {
            //   title: "Someone asked question",
            //   body: "IND chose to bat",
            //   score: 50,
            //   wicket: 1,
            // },
        }

        let headers = new Headers({
            "Content-Type": "application/json",
            Authorization: "key=" + FIREBASE_API_KEY,
        })

        let response = await fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers,
            body: JSON.stringify(message),
        })
        response = await response.json()
        console.log(response)
    }

    static sendUpdatePushNotification(recipeid, recipename, uid) {
        var reqBody = "recipeid=" + recipeid + "&recipename=" + recipename + "&currentuser=" + uid;

        axios({
            method: "post",
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            url: "https://us-central1-craftiqapp.cloudfunctions.net/sendRecipeUpdatePushtoMembersWeb",
            data: reqBody
        }).then((response) => {
            //console.log(response);
        })
            .catch((e) => {
                // console.log(e);
            });
    }


    static numberofMembers(indentifier) {
        if (indentifier == "bar_program") {
            return 15
        }
        else if (indentifier == "restaurant_program") {
            return 50
        }
        else if (indentifier == "catering") {
            return 100
        }
        else if (indentifier == "corporate") {
            return 250
        }
        else if (indentifier == "corporate2") {
            return 250
        }
        else {
            return 5
        }
    }

    static membershipName(indentifier) {
        if (indentifier == "bar_program") {
            return "Bar Program"
        }
        else if (indentifier == "restaurant_program") {
            return "Restaurant Program"
        }
        else if (indentifier == "catering") {
            return "Catering"
        }
        else if (indentifier == "corporate") {
            return "Corporate Tier 1"
        }
        else if (indentifier == "corporate2") {
            return "Corporate Tier 2"
        }
        else {
            return "Friends and Family"
        }
    }

    static pdfContent(recipename, recipeimageurl, itemrender, dilutionrender, floatRender, garnishrender, glassware, glasswareArray, totalYields, totalBatchedOunces, totalOunces) {
        let imgurl = recipeimageurl.length > 0 ? recipeimageurl : 'https://firebasestorage.googleapis.com/v0/b/craftiqapp.appspot.com/o/backbar.png?alt=media&token=e76dfad4-5800-4a34-bfed-d937f098e516'

        let showItem = (itemrender != null && itemrender[0].itemname.length > 0)
        let showDilution = (dilutionrender != null && dilutionrender[0].itemname.length > 0)
        let showfloats = (floatRender != null && floatRender[0].itemname.length > 0)
        let showgarnish = (garnishrender != null && garnishrender[0].itemname.length > 0)

        let showPrep = ((glassware != null && glassware.length > 0) || glasswareArray[0].length > 0)
        let garray = glasswareArray.filter((text) => text.length != 0)

        return (
            `<html id = "craft">
    
          <head>
              <style>
                
                  header,
                  footer {
                      height: 50px;
                      background-color: #fff;
                      display: flex;
                      justify-content: center;
                      padding: 20px 20px;
                  }
          
                  table {
                      width: 100%;
                      border-collapse: collapse;
                  }
          
                  th,
                  td {
                      border: 1px solid #000;
                      padding: 5px;
                      text-align: right;
                      font-size: 10px;
                      
                  }
          
                  th {
                      background-color: #ccc;
                      font-size: 10px;
                  }
          
                  #td {
                      text-align: left;
                      
                  }

                  #smalltd {
                    text-align: center;
                    width: 70px;
                    overflow: hidden;
                }
          
                  #logo {
                      float: left;
                      margin-top: 8px;
                  }
          
                  #logo img {
                    float: center;
                      width:200px;
                      height:auto;
                      padding-top: 100px;
                  }
          
                  #invoice {
                      float: right;
                      text-align: right;
                  }
                  #image {
                    display: flex;
                    float: center;
                    height:auto;
                    width: auto;
                }
          
                  .container {
                      display: grid;
                      grid-template-columns: 70% 20%;
                  }
          
                  .tablecontainer {
                      padding: 20px;
                  }
              </style>
          </head>
          
          <body>
              <header>
                  <h3>${recipename}</h3>
              </header>
        
    
              <div class="container">
                  <div class="tablecontainer">
                     ${showItem ? `<h4>Items</h4>
                      <table>
                          <tr>
                              <th id="td">Item</th>
                              <th id="smalltd">Quantity</th>
                              <th id="smalltd">Unit</th>
                              <th id="smalltd">Total Ounce</th>
                          </tr>
                          ${itemrender
                    .map(
                        line => `
                          <tr>
                              <td id="td">${line.itemname}</td>
                              <td id="smalltd">${line.Quantity.toFixed(2)}</td>
                              <td id="smalltd">${line.unitName}</td>
                              <td id="smalltd">${line.totalOunces.toFixed(3)}</td>
                          </tr>
                          `,
                    )
                    .join('')}
                      </table>` : ``}
                     
          
                     ${showDilution ? `<h4>Dilution</h4>
                      <table>
                          <tr>
                              <td id="td">${dilutionrender[0].itemname}</td>
          
                              <td>${dilutionrender[0].totalOunces.toFixed(2)}</td>
                          </tr>
          
                      </table>` : ``}
          
          
                      ${showfloats ? `<h4>Float / Muddle / other</h4>
                      <table>
                          <tr>
                          <th id="td">Item</th>
                          <th id="smalltd">Quantity</th>
                          <th id="smalltd">Unit</th>
                          <th id="smalltd">Total Ounce</th>
                          </tr>
                          ${floatRender
                    .map(
                        line => `
                          <tr>
                          <td id="td">${line.itemname}</td>
                          <td id="smalltd">${line.Quantity.toFixed(2)}</td>
                          <td id="smalltd">${line.unitName}</td>
                          <td id="smalltd">${line.totalOunces.toFixed(3)}</td>
                          </tr>
                          `,
                    )
                    .join('')}
                      </table>`: ``}
          
          
                      ${showgarnish ? `<h4>Garnish</h4>
                      <table>
                          <tr>
                          <th id="td">Item</th>
                          <th id="smalltd">Quantity</th>
                          <th id="smalltd">Unit</th>
                          <th id="smalltd">Total Ounce</th>
                          </tr>
                          ${garnishrender
                    .map(
                        line => `
                          <tr>
                          <td id="td">${line.itemname}</td>
                          <td id="smalltd">${line.Quantity.toFixed(2)}</td>
                          <td id="smalltd">${line.unitName}</td>
                          <td id="smalltd">${line.totalOunces.toFixed(3)}</td>
                          </tr>
                          `,
                    )
                    .join('')}
                      </table>` : ``}
    
          
          
                      ${showPrep ? `<h4>Preparation</h4>
                      <table>
                          <tr>
                              <td id="td">Glassware</td>
          
                              <td>${glassware}</td>
                          </tr>
          
                       ${garray.length > 0 ? `${garray
                    .map(
                        line => `
                          <tr>
                              <td id="td">
                                  <p>
          
                                      ${line}
                                  </p>
                              </td>
                          </tr>
                          `,
                    )
                    .join('')}` : ``}
          
                      </table>` : ``}
                  </br>
              </br>
                      <table>
                          <tr>
                              <th> </th>
                              <th>Total yield</th>
                              <th>Batched Ounces</th>
                              <th>Total Ounces</th>
                          </tr>
                      
                          <tr>
                              <td> </td>
                              <td>${totalYields}</td>
                              <td>${totalBatchedOunces}</td>
                              <td>${totalOunces}</td>
                          </tr>
          
                      </table>
          
                  </div>
                  <div id="image">
                  <div id="logo">
                      <img src=${imgurl}
                          style="object-fit:scale-down;
                          margin-top: 20%;
                  ">
          </div>
                  </div>
              </div>
              <footer>
                  <p>Created by The CraftIQ!</p>
              </footer>
          </body>
          
          </html>`
        )
    }

    static popularItems = [{ label: 'Gin', value: 'Gin', popular: true },
    { label: 'Gin | London Dry', value: 'Gin | London Dry', popular: true },
    { label: 'Gin | Plymouth', value: 'Gin | Plymouth', popular: true },
    { label: 'Vodka', value: 'Vodka', popular: true },
    { label: 'Whisky | Bourbon', value: 'Whisky | Bourbon', popular: true },
    { label: 'Whisky | Rye', value: 'Whisky | Rye', popular: true },
    { label: 'Whisky | Tennesse', value: 'Whisky | Tennesse', popular: true },
    { label: 'Whisky | Canadian', value: 'Whisky | Canadian', popular: true },
    { label: 'Whiskey | Scotch', value: 'Whiskey | Scotch', popular: true },
    { label: 'Whiskey | Blended Scotch', value: 'Whiskey | Blended Scotch', popular: true },
    { label: 'Whisky | Irish', value: 'Whisky | Irish', popular: true },
    { label: 'Whisky | Japanese', value: 'Whisky | Japanese', popular: true },
    { label: 'Rum', value: 'Rum', popular: true },
    { label: 'Rum | 151', value: 'Rum | 151', popular: true },
    { label: 'Rum | Dark', value: 'Rum | Dark', popular: true },
    { label: 'Rum | Jamaican', value: 'Rum | Jamaican', popular: true },
    { label: 'Rum | Light', value: 'Rum | Light', popular: true },
    { label: 'Rum | Navy Strength', value: 'Rum | Navy Strength', popular: true },
    { label: 'Rum | Spiced', value: 'Rum | Spiced', popular: true },
    { label: 'Rum | White', value: 'Rum | White', popular: true },
    { label: 'Over Proof Rum', value: 'Over Proof Rum', popular: true },
    { label: 'Rhum Agricole', value: 'Rhum Agricole', popular: true },
    { label: 'Tequila', value: 'Tequila', popular: true },
    { label: 'Tequila | Añejo', value: 'Tequila | Añejo', popular: true },
    { label: 'Tequila | Blanco', value: 'Tequila | Blanco', popular: true },
    { label: 'Tequila | Cristalino', value: 'Tequila | Cristalino', popular: true },
    { label: 'Tequila | Extra Añejo', value: 'Tequila | Extra Añejo', popular: true },
    { label: 'Tequila | Reposado', value: 'Tequila | Reposado', popular: true },
    { label: 'Tequila | Silver', value: 'Tequila | Silver', popular: true },
    { label: 'Mezcal', value: 'Mezcal', popular: true },
    { label: 'Vermouth | Dry', value: 'Vermouth | Dry', popular: true },
    { label: 'Vermouth | Sweet', value: 'Vermouth | Sweet', popular: true },
    { label: 'Brandy', value: 'Brandy', popular: true },
    { label: 'Cognac', value: 'Cognac', popular: true },
    { label: 'Cognac | V.S.', value: 'Cognac | V.S.', popular: true },
    { label: 'Cognac | V.S.', value: 'Cognac | V.S.', popular: true },
    { label: 'Cognac | V.S.O.P', value: 'Cognac | V.S.O.P', popular: true },
    { label: 'Cognac | X.O.', value: 'Cognac | X.O.', popular: true },
    { label: 'Simple Syrup', value: 'Simple Syrup', popular: true },
    { label: 'Triple Sec', value: 'Triple Sec', popular: true },
    { label: 'Bitters', value: 'Bitters', popular: true },
    { label: 'Bitters | Orange', value: 'Bitters | Orange', popular: true },
    { label: 'Club Soda', value: 'Club Soda', popular: true },
    { label: 'Ginger Ale', value: 'Ginger Ale', popular: true },
    { label: 'Ginger Beer', value: 'Ginger Beer', popular: true },
    { label: 'Tonic Water', value: 'Tonic Water', popular: true },
    { label: 'Lemon', value: 'Lemon', popular: true },
    { label: 'Lemon Juice', value: 'Lemon Juice', popular: true },
    { label: 'Lime', value: 'Lime', popular: true },
    { label: 'Lime Juice', value: 'Lime Juice', popular: true },
    { label: 'Orange', value: 'Orange', popular: true },
    { label: 'Orange Juice', value: 'Orange Juice', popular: true },
    { label: 'Mint', value: 'Mint', popular: true },
    { label: 'Grapefruit', value: 'Grapefruit', popular: true },
    { label: 'Grapefruit Juice', value: 'Grapefruit Juice', popular: true }]
}