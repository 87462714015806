import React, { useEffect, useRef, useState } from "react";
import { db, auth } from '../../src/Database_fb';
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight } from "react-icons/ai";
import backgroundimg from '../images/backbar.png';
import { getAuth, signOut, deleteUser } from "firebase/auth";
import { useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert-with-buttons'
import TextField from "@mui/material/TextField";
import { isDesktop } from 'react-device-detect';

export default function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert()
  const [loading, setloading] = React.useState(true);
  const [profileImg, setprofileImg] = React.useState('');
  const [username, setusername] = React.useState('');
  const [useremail, setuseremail] = React.useState('');
  const [userphone, setuserphone] = React.useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    Fetchuser()
  }, [])

  function Fetchuser() {
    const user = auth.currentUser;
    const subscribe = db.collection("users")
      .doc(user.uid)
      .get().then((doc) => {
        const crafts = [];
        let data = doc.data()
        setprofileImg(data.imageUrl)
        setusername(data.name)
        setuseremail(data.email)
        setuserphone(data.phone)
        setloading(false)
      });
  }

  function updateUser() {
    if (username.length == 0) {
      alert("Please enter your name");
      return
     }
    
    setloading(true)
    const user = auth.currentUser;
    db.collection("users")
      .doc(user.uid)
      .update({
        email: useremail,
        name: username,
        phone: userphone,
      })
      .then(() => {
        setloading(false)
      });
  }

  return (
    <>
      <div
        style={{
          width: '20%',
          // alignItems: "flex-end",
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          //marginLeft: "10%",
          // marginRight: 20,
          marginTop: 10,
        }}
      >
        <Button
          onClick={() => {
            navigate(-1)
          }}>
          <AiOutlineArrowLeft
            style={{
              width: 20,
              height: 20,

              color: "#fff",
            }}
          /></Button>
      </div>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p style={{ fontSize: 30, color: "#D28816" }}>EDIT PROFILE</p>
        <Box sx={{ width: isDesktop ? "70%" : "90%" }}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{
              width: '100%', height: 180,
              backgroundImage: `url(${backgroundimg})`,
              backgroundRepeat: "repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              alignItems: 'center', display: 'flex', justifyContent: 'center'
            }} >
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <img style={{ width: 120, height: 120, borderRadius: 60, backgroundColor: 'grey' }} src={profileImg} />
              </div>
            </div>
          </div>

          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={6}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: 0,
                  height: "50px",
                  cursor: 'pointer',
                  backgroundColor: "#fff",
                }}
              >
                <TextField color='warning' id="standard-basic" defaultValue="1" label="" variant="standard" textAlign='center'
                  style={{ width: '100%', height: "40px", }}
                  value={username}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setusername(event.target.value);
                  }}
                  placeholder={'Name'}
                  inputProps={{ style: { fontSize: 18, textAlign: 'center', color: 'black', height: "40px" } }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: 0,
                  height: "50px",
                  cursor: 'pointer',
                  backgroundColor: "#fff",
                }}
              >
                <TextField disabled={true} color='warning' id="standard-basic" defaultValue="1" label="" variant="standard" textAlign='center'
                  style={{ width: '100%', height: "40px", }}
                  value={useremail}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setuseremail(event.target.value);
                  }}
                  placeholder={'Email'}
                  inputProps={{ style: { fontSize: 18, textAlign: 'center', color: 'black', height: "40px" } }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: 0,
                  height: "50px",
                  cursor: 'pointer',
                  backgroundColor: "#fff",
                }}
              >
                <TextField color='warning' id="standard-basic" defaultValue="1" label="" variant="standard" textAlign='center'
                  style={{ width: '100%', height: "40px", }}
                  value={userphone}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setuserphone(event.target.value);
                  }}
                  placeholder={'Phone'}
                  inputProps={{ style: { fontSize: 18, textAlign: 'center', color: 'black', height: "40px" } }}
                />
              </div>
            </Grid>

          </Grid>
          <div
            style={{
              alignItems: 'center', display: 'flex', justifyContent: 'center',
              width: '100%',
            }}
          >

            <Button
              onClick={() => {
                updateUser()
              }}
              style={{ backgroundColor: "#f7a828", width: '40%' }}
              // backgroundColor={"red"}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Update
            </Button>
          </div>

        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>


      </Box>
    </>
  );
}

