// import { height } from "@mui/system";
// import React from "react";
// import Dropdown from "react-dropdown";
// import "react-dropdown/style.css";

// function Test() {
//   const options = ["one", "two", "three"];
//   // const defaultOption = options[0];
//   return (
//     <div>
//       <p>hello</p>
//       <Dropdown style={{height:"55vh",}}

//       height={"50vh"}
//       max-height= '200px'
//       width={"50%"}
//         options={options}
//         // onChange={this._onSelect}
//         // value={defaultOption}
//         placeholder="Select an option"
//       />

//     </div>
//   );
// }

// export default Test;

import * as React from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#0063cc",
  borderColor: "#0063cc",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const ColorButton =
  styled(Button) 
  (({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
  }));

export default function Test() {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton variant="contained">Custom CSS</ColorButton>
      <BootstrapButton variant="contained" disableRipple>
        Bootstrap
      </BootstrapButton>
    </Stack>
  );
}
// https://openai.com/api/
// https://www.npmjs.com/package/chatgpt?activeTab=readme
// https://www.npmjs.com/package/openai