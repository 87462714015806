import React, { useEffect, useRef, useState } from "react";
import { db, auth, frbase } from '../../src/Database_fb';

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight, AiOutlineDelete } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import Modal from 'react-modal';
import CraftDialog from "../Component/CraftDialog";
import TextField from "@mui/material/TextField";
import ExistDialog from "../Component/CraftDialog";
import SuccessDialog from "../Component/CraftDialog";
import { useAlert } from 'react-alert-with-buttons'


export default function NetworksTeams() {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert()

  const [mynetworkArray, setmynetworkArray] = useState([]);
  const [loading, setloading] = React.useState(true);
  const [modalVisible, setmodalVisible] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [showExistDialog, setshowExistDialog] = useState(false);
  const [showSuccessDialog, setshowSuccessDialog] = useState(false);
  const [newNetworkModel, setnewNetworkModel] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [networkName, setnetworkName] = useState("");
  const [isShare, setisShare] = useState((location.state != null) ? location.state.shareRecipe : false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    //   window.addEventListener("scroll", () => {
    //     if (window.scrollY > 400) {
    //         //setShowTopBtn(true);
    //     } else {
    //         //setShowTopBtn(false);
    //     }
    // });

    setisShare((location.state != null) ? location.state.shareRecipe : false)
    FetchCrafts()
  }, [])

  function FetchCrafts() {
    const user = auth.currentUser;
    const subscribe = db.collection("MyNetwork")
      .where("userIDs", 'array-contains', user.uid)
      .onSnapshot((doc) => {
        const crafts = [];
        doc.forEach((snap) => {
          crafts.push({ key: snap.id, ...snap.data() });
        });
        let sorted = crafts.sort((a, b) => a.name.localeCompare(b.name))
        //console.log('sorted', sorted);
        setmynetworkArray(sorted);
        setloading(false)
      });
  }

  function addNewNetwork() {
    setloading(true)
    let data = {
      name: networkName,
      status: "active",
      uid: frbase.auth().currentUser.uid,
      editors: frbase.firestore.FieldValue.arrayUnion(frbase.auth().currentUser.uid),
      editAll: false,
      timestamp: frbase.firestore.FieldValue.serverTimestamp(),
    };
    db
      .collection("MyNetwork")
      .add(data)
      .then((ref) => {
        db
          .collection("MyNetwork")
          .doc(ref.id)
          .update({
            userIDs: frbase.firestore.FieldValue.arrayUnion(frbase.auth().currentUser.uid)
          })
        setloading(false)

        navigate('EditNetworks', {
          state: {
            NetworkName: networkName,
            dociID: ref.id,
            isOwner: true,
            isEditor: true,
            networkEditors: []
          }
        })

      })
  }

  function addRecipeToNetwork(item) {
    db
      .collection("MyNetwork")
      .doc(item.key)
      .collection('recipes')
      .where("recipeID", "==", item.key)
      .get()
      .then((doc) => {

        if (doc.empty) {
          let data = {
            craftname: location.state.RecipeName,
            status: "active",
            uid: frbase.auth().currentUser.uid,
            recipeID: location.state.dociID,
            networkID: item.key,
            timestamp: frbase.firestore.FieldValue.serverTimestamp(),
          };

          db
            .collection("MyNetwork")
            .doc(item.key)
            .collection('recipes')
            .add(data)
            .then((ref) => {
              //console.log('ref' , ref.id);
              db
                .collection("MyNetwork")
                .doc(item.key)
                .update({
                  recipeIDs: frbase.firestore.FieldValue.arrayUnion(location.state.dociID)
                })
                .then(() => {
                  // console.log('rechaed last');

                  setshowSuccessDialog(true)
                })
              setloading(false)
            })
        }
        else {
          setloading(false)
          setshowExistDialog(true)
        }
      });
  }


  const handleDelete = (prop) => {
    alert.open({
      message: (prop.uid == frbase.auth().currentUser.uid) ? "Delete Network? Are you sure ?" : "Remove from Network ?",
      buttons: [
        {
          label: (prop.uid == frbase.auth().currentUser.uid) ? "Yes, Delete" : "Yes Remove",
          onClick: () => {
            alert.close()
            if (prop.uid == frbase.auth().currentUser.uid) {
              db.collection("MyNetwork")
                .doc(prop.key)
                .delete()
                .then((doc) => {
                });
            }
            else {
              db.collection("MyNetwork")
                .doc(prop.key)
                .update({
                  userIDs: frbase.firestore.FieldValue.arrayRemove(frbase.auth().currentUser.uid),
                  editors: frbase.firestore.FieldValue.arrayRemove(frbase.auth().currentUser.uid)
                })
              db.collection("MyNetwork")
                .doc(prop.key)
                .collection('users')
                .where("userID", "==", frbase.auth().currentUser.uid)
                .get()
                .then((doc) => {
                  doc.forEach((snap) => {
                    snap.ref.delete()
                  });
                });
            }
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        },

        {
          label: "No",
          onClick: () => {
            alert.close()
          },
          style: {
            backgroundColor: "#fff",
            borderRadius: 15,
            color: "black",
          }
        }
      ],
    });
  };


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  return (
    <>

      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>
            <p style={{ fontSize: 30, color: "#D28816" }}>NETWORK & TEAMS</p>
            <div
              style={{
                flexDirection: 'row',
                alignItems: "flex-end",
                marginHorizontal: 20,
                marginTop: 5,
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setnewNetworkModel(true)
                }}
                style={{
                  height: 60,
                  width: 60,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffac30",
                  borderRadius: 30,
                  // position: 'absolute',
                  // right: 20,
                  // bottom: 20
                }}
              >
                <img
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  src={require("../images/addNote.png")}
                />
              </Button>
            </div>
          </div>
          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {mynetworkArray.map((prop, index) => {
              return (
                <Grid item xs={6} >

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 5,
                      height: "60px",
                      cursor: 'pointer',
                      //width: "100%",
                      //backgroundColor: "#fff",
                      // marginBottom: 10,
                      // marginLeft: (index % 2) ? 10 : 0
                      backgroundImage: `url(${list_item_bg})`,
                      backgroundRepeat: "repeat",

                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => {
                      console.log('isShare', isShare);
                      if (isShare) {
                        setloading(true)
                        addRecipeToNetwork(prop)
                        return
                      }
                      // if (prop.uid == frbase.auth().currentUser.uid) {
                      navigate('EditNetworks', {
                        state: {
                          NetworkName: prop.name,
                          dociID: prop.key,
                          isOwner: (prop.uid == auth.currentUser.uid),
                          isEditor: (prop.uid == auth.currentUser.uid) ? true : (prop.editAll) ? true : prop.editors.includes(auth.currentUser.uid),
                          networkEditors: prop.editors,
                          canEditAll: prop.editAll
                        }
                      })
                      // }
                      // else {
                      //   setselectedItem(prop)
                      //   setmodalVisible(true)
                      // }

                    }}
                  >
                    <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                    <div style={{ width: '5%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                      <p
                        // className="App"
                        style={{
                          fontSize: 12,
                          //height: 40,
                          color: "#000",
                          textAlign: "start",
                          //marginLeft: '35%',
                          //marginTop: '32%'

                        }}
                      >
                        #{index + 1}
                        {/* <br/>{craf.craftname} */}
                      </p>
                    </div>
                    <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.name}
                      </p>
                    </div>

                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      />
                    </div>

                  </div>

                  <div
                    style={{
                      // marginRight: "1%",
                      width: "1.5%",
                      // height: "20%",
                      // display: "flex",
                      // alignItems: 'center',
                      // justifyContent: 'center'
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleDelete(prop)
                      }}>
                      <AiOutlineDelete
                        style={{
                          width: 20,
                          height: 20,

                          color: "red",
                        }}
                      /></Button>
                  </div>

                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>




        <Modal

          isOpen={modalVisible}

          // onAfterOpen={afterOpenModal}
          onRequestClose={() => {
            setmodalVisible(false)
          }}
          style={customStyles}
          contentLabel=""
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              flex: 1,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "70%",
                alignItems: "center",
                // display: 'flex',
                borderRadius: 15,
              }}
            >

              <Button
                onClick={() => {
                  setmodalVisible(false);
                  navigate('EditNetworks', {
                    state: {
                      NetworkName: selectedItem.name,
                      dociID: selectedItem.key,
                      isOwner: (selectedItem.uid == auth.currentUser.uid),
                      isEditor: (selectedItem.uid == auth.currentUser.uid) ? true : (selectedItem.editAll) ? true : selectedItem.editors.includes(auth.currentUser.uid),
                      networkEditors: selectedItem.editors,
                      canEditAll: selectedItem.editAll
                    }
                  })
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  View Network
                </p>
              </Button>

              <Button
                onClick={() => {
                  setmodalVisible(false);
                  setshowDelete(true)
                }}
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Delete Network
                </p>
              </Button>

              <Button
                onClick={() => setmodalVisible(false)}
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: "600",
                    color: "#1c68ff",
                  }}
                >
                  Cancel
                </p>
              </Button>
            </div>
          </div>
        </Modal>


        <Modal
          isOpen={newNetworkModel}
          onRequestClose={() => {
            setnewNetworkModel(false)
          }}
          style={customStyles}
          contentLabel=""
        >
          <h2 >Add Network name</h2>
          <div
            style={{
              // width: "90%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'

            }}
          >
            <TextField
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  color: '#f7a828',
                }
              }}
              inputProps={{
                style: {
                  height: "5px",
                },
              }}
              style={{ backgroundColor: "#fff", borderRadius: 10, }}
              required
              id="recipe"
              name="recipe"
              autoFocus
              hiddenLabel={true}
              onChange={event => {
                const { value } = event.target;
                setnetworkName(value)
              }}
            />
          </div>

          <Button
            style={{ backgroundColor: "#f7a828" }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              setnewNetworkModel(false);
              addNewNetwork()
            }}
          >
            Submit
          </Button>

          <Button
            style={{ backgroundColor: "transparent", color: 'black' }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            // sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              setnewNetworkModel(false);
            }}
          >
            Cancel
          </Button>
        </Modal>

        <CraftDialog
          show={showDelete}
          title={'Success!'}
          body={'Recipe shared with'}
          handleToClose={() => {
            setshowDelete(false)
          }}
          handleToOk={() => {
            setshowDelete(false)
            // deleteCraft();
          }}
        />

        <ExistDialog
          show={showExistDialog}
          title={'Exists!'}
          body={'Recipe already exist in this network'}
          handleToClose={() => {
            setshowExistDialog(false)
          }}
          handleToOk={() => {
            setshowExistDialog(false)
            navigate(-1)
          }}
          showCancel={false}
        />

        <SuccessDialog
          show={showSuccessDialog}
          title={'Success!'}
          body={'Recipe shared with network'}
          handleToClose={() => {
            setshowSuccessDialog(false)
          }}
          handleToOk={() => {
            setshowSuccessDialog(false)
            navigate(-1)
          }}
          showCancel={false}
        />


        <div style={{ position: 'fixed', right: 20, bottom: 10, backgroundColor: 'rgba(0, 0, 0, 0.7)' ,justifyContent: "center",
                  alignItems: "center", display:'flex'}}>
        <Button
                onClick={() => {
                 navigate('/NetworkSubscription')
                }}
                style={{
                  height: 100,
                  width: 100,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 30,
                  // position: 'absolute',
                  // right: 20,
                  // bottom: 20
                }}
              >
                <img
                  style={{
                    height: 94,
                    width: 94,
                  }}
                  src={require("../images/Subscribe.png")}
                />
              </Button>
        </div>


      </Box>
    </>
  );
}

