import React, { useEffect, useRef, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft, AiOutlineRight, AiFillPrinter } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { isMobile } from 'react-device-detect';
import { useReactToPrint } from 'react-to-print';
import ReactPlayer from 'react-player';


const LoadLearniqPDF = () => {
    const navigate = useNavigate();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const location = useLocation();
    const pdfRef = useRef(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        //console.log(location);

    }, [])

    const handlePrint = useReactToPrint({
        content: () => pdfRef.current,
    });

    const openLinkInNewTab = ( ) => {
        const newTab = window.open(location.state.weburl, '_blank', 'noopener,noreferrer');
        if ( newTab ) newTab.opener = null;
      }

    return (
        <div

            className="App"
            style={{
                flex: 1,
                width: "100%",
                height: '85vh',
                alignItems: "center",
            }}
        >
            <div
                style={{
                    width: '20%',
                    // alignItems: "flex-end",
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    //marginLeft: "10%",
                    // marginRight: 20,
                    marginTop: 10,
                }}
            >
                <Button
                    onClick={() => {
                        navigate(-1)
                    }}>
                    <AiOutlineArrowLeft
                        style={{
                            width: 20,
                            height: 20,

                            color: "#fff",
                        }}
                    /></Button>

                {/* <Button
                    onClick={() => {
                        handlePrint()
                    }}>
                    <AiFillPrinter
                        style={{
                            width: 20,
                            height: 20,

                            color: "#fff",
                        }}
                    /></Button> */}
            </div>


            {/* <object
                data={`${location.state.pdfurl}#view=fitH`}
                type="application/pdf"
                width="100%"
                height="100%"
                aria-labelledby="PDF document"
            >
                <p>
                    No Data found
                    <a href="document.pdf">Download the PDF</a>
                </p>
            </object> */}
            {/* {location.state.pdfurl ? <div style={{width: '100%', height: '30%', alignItems:'center', justifyContent:'center', display:'flex'}} ><CircularProgress color="inherit" /></div> : null } */}
            {location.state.pdfurl &&
                // <iframe frameBorder="0" src={`${location.state.pdfurl}#view=fitH`} title="Learn IQ" height="100%" width="100%" /> 
                <embed src={`${location.state.pdfurl}`} width="100%" height="100%" type='application/pdf' />}

            
            {location.state.videourl &&
                <ReactPlayer
                url={location.state.videourl}
                width="100%"
                height="100%"
                controls
                playing
                loop
              />}

            


            

            {location.state.weburl && <div

                style={{
                    flex: 1,
                    backgroundColor: "#fff",
                    height: '50px'
                }}
            >
                <div
                    style={{
                        justifyContent: "center",
                        marginLeft: "10%",
                        marginRight: "10%"
                    }}
                >
                   <Button onClick={ () => openLinkInNewTab('your.url')}>
                        <p style={{ color: "#f7a828" }}>Visit Website</p>
                    </Button>
                </div>
            </div>}
        </div>
    );
};

export default LoadLearniqPDF;
