import React, { useEffect, useRef, useState } from "react";
import { db, auth, frbase } from '../../src/Database_fb';

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight, AiOutlineSetting } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import Itemmanager from "../context/Itemmanager";
import Floatmanager from "../context/Floatmanager";
import Garnishmanager from "../context/Garnishmanager";
import Modal from 'react-modal';
import TextField from "@mui/material/TextField";
import axios from "axios";
import CraftDialog from "../Component/CraftDialog";
import { useAlert } from 'react-alert-with-buttons'
export default function NetworkUsers() {
  const alert = useAlert()

  const navigate = useNavigate();
  const location = useLocation();
  const [modalVisible, setmodalVisible] = useState(false);
  const [loading, setloading] = useState(true);
  const [addModel, setaddModel] = useState(false);
  const [recipeName, setrecipeName] = useState("");
  const [myCraft, setmyCraft] = useState([]);
  const [showDelete, setshowDelete] = useState(false);

  const [docid, setdocid] = useState("");
  const [SearchData, setSearchData] = useState([]);
  const [isSearching, setisSearching] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(0);
  const [selectedItem, setselectedItem] = useState(null);
  const [batchCraft, setbatchCraft] = useState(null);
  const [itemrender, setitemrender] = useState([]);
  const [floatRender, setfloatRender] = useState([]);
  const [garnishrender, setgarnishrender] = useState([]);
  const [dilutionrender, setdilutionrender] = useState([]);

  const [membername, setmembername] = useState("")
  const [memberemail, setmemberemail] = useState("")
  const [currentUserName, setcurrentUserName] = useState("")
  const [currentUserEmail, setcurrentUserEmail] = useState("")
  const [isSelectedEditor, setSelectionEditor] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const user = frbase.auth().currentUser;
    db
      .collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        setcurrentUserName(doc.data().name);
        setcurrentUserEmail(doc.data().email)
      });
    const subscribe = db
      .collection("MyNetwork")
      .doc(location.state.networkid)
      .collection('users')
      .onSnapshot((doc) => {
        const crafts = [];
        doc.forEach((snap) => {
          crafts.push({ key: snap.id, ...snap.data() });
        });
        setmyCraft(crafts);
        setloading(false)
      });

    return subscribe;
  }, []);



  function deleteCraft() {
    const subscribe = db
      .collection("MyNetwork")
      .doc(location.state.networkid)
      .collection("users")
      .doc(selectedItem.key)
      .delete()
      .then(() => {
        myCraft.splice(selectedIndex, 1);
        setmyCraft([...myCraft]);
      });
    return subscribe;
  }


  function searchMember() {
    if (memberemail != "") {
      if (membername != "") {
        setloading(true)
        db
          .collection("users").where("email", "==", memberemail.toLowerCase())
          .get().then((documentSnapshot) => {
            if (documentSnapshot.size > 0) {
              documentSnapshot.forEach((snap) => {
                let data = snap.data()
                console.log('user data', data);
                if (currentUserEmail == data.email) {
                  setloading(false)
                  showAlert("You cannot add yourself")
                }
                else {
                  addUserToNetwork(data)
                }
              });
            }
            else {
              setloading(false)
              showAlert("We have sent invitation to this user")
              sendEmailtoUser(memberemail, membername)
            }
          })
      } else {
        showAlert("Enter enter member name")
      }
    } else {
      showAlert("Enter member email")
    }
  }

  function showAlert(message) {
    alert.open({
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            alert.close() 
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });
    
  }


  function addUserToNetwork(userdata) {
    db
      .collection("MyNetwork").doc(location.state.networkid).collection('users').where("email", "==", memberemail)
      .get().then((documentSnapshot) => {
        if (documentSnapshot.size == 0) {
          let data = {
            username: userdata.name,
            userID: userdata.uid,
            email: userdata.email,
            status: "active",
            addedby: frbase.auth().currentUser.uid,
            networkID: location.state.networkid,
            timestamp: frbase.firestore.FieldValue.serverTimestamp()
          }

          console.log('data', data);
          db
            .collection("MyNetwork")
            .doc(location.state.networkid)
            .collection('users')
            .add(data)
            .then((ref) => {
              db
                .collection("MyNetwork")
                .doc(location.state.networkid)
                .update({
                  userIDs: frbase.firestore.FieldValue.arrayUnion(userdata.uid)
                })
                .then((reference) => {
                  setloading(false)
                  location.state.networkEditors.push(userdata.uid)
                  reference.update({
                    editors: frbase.firestore.FieldValue.arrayUnion(userdata.uid)
                  })
                  if (userdata.tokens.length > 0) {
                    let myname = currentUserName
                    if (currentUserName.length == 0) {
                      myname = "Someone"
                    }
                    sendPushnotification(userdata.tokens, myname + " added you in a network")
                  }

                  showAlert("Success!", "User added in " + location.state.networkname)
                 
                })

            })
        }
        else {
          showAlert("User already exist in this network")
          setloading(false)
        }
      })
  }

  function sendPushnotification(tokens, Message) {
    let serverKey = "AAAAGDx3iu4:APA91bEEwhNcBGFWfjQztulSEaEzEbjRQWw34lcL30YxAn5e0GFjyq37VbvYGIYuXSyouAFj_TO4iunaiqNGyqAcFhHU79k4EQ7gTd5JoOtBlrMBqYgH1JlBc-E_iGVzO3CSzLQZibP0"
    let postParams = {
      //to: token,
      registration_ids: tokens,
      notification: {
        body: Message,
        title: "CraftIQ",
        sound: true, // or specify audio name to play
        click_action: "🚀", // action when user click notification (categoryIdentifier)
      }
    }
    let key = "key=" + serverKey
    axios({
      method: 'post',
      url: 'https://fcm.googleapis.com/fcm/send',
      data: postParams,
      headers: { 'Authorization': key, 'Content-Type': 'application/json; charset=utf-8' }
    }).then(function (response) {

    });;
  }

  function sendEmailtoUser(email, name) {
   
    let variables = { username: name, email: email, network: location.state.networkname }
    var reqBody = "variables=" + variables + "&from=" + "The Craft IQ <shaun.prevatt@thecraftiq.co>" + "&to=" + email + "&template=invitation" + "&reply=shaun.prevatt@thecraftiq.co"  + "&subject=You have been invited by " + name;

    // fetch('https://us-central1-craftiqapp.cloudfunctions.net/customPackageWeb', {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'Content-type',
    //     'application/x-www-form-urlencoded'
    //   },
    //   data: reqBody,
    //   // body: JSON.stringify({
    //   //   variables: variables,
    //   //   from: "The Craft IQ <shaun.prevatt@thecraftiq.co>",
    //   //   to: email,
    //   //   template: 'invitation',
    //   //   reply: 'shaun.prevatt@thecraftiq.co',
    //   //   subject: "You have been invited by " + name,
    //   // }),
    // }, 5000) // throw after max 5 seconds timeout error
    axios({
      method: "post",
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      url: "https://us-central1-craftiqapp.cloudfunctions.net/customPackageWeb",
      data: reqBody
    }).then((response) => {
        //console.log(response);
      })
      .catch((e) => {
        // console.log(e);
      });



    // const data = {
    //     from: "The Craft IQ <shaun.prevatt@thecraftiq.co>",
    //     to: email,
    //     subject: "You have been invited by " + name,
    //     template: 'invitation',
    //     'h:X-Mailgun-Variables': JSON.stringify(variables),
    //     'h:Reply-To': 'shaun.prevatt@thecraftiq.co'
    // };

    // mg.messages().send(data, (error, body) => {
    // setloading(false)
    // Alert.alert("Invitation!", "We have sent invitation to this user", [
    //   {
    //     text: "Ok",
    //   },
    // ]);
    // });
  }

  function changePermission() {

    alert.open({
      message: (location.state.networkEditors.includes(selectedItem.userID)) ? "Remove editor permission" : "Allow this user to edit network",
      buttons: [
        {
          label: "Yes, Sure",
          onClick: () => {
            let refer = db
            .collection("MyNetwork")
            .doc(location.state.networkid)
          if ((location.state.networkEditors.includes(selectedItem.userID))) {
            refer.update({
              editors: frbase.firestore.FieldValue.arrayRemove(selectedItem.userID)
            })
            let index = location.state.networkEditors.findIndex(obj => obj === selectedItem.userID);
            if (index > -1) {
              location.state.networkEditors.splice(index, 1);
            }
          }
          else {
            refer.update({
              editors: frbase.firestore.FieldValue.arrayRemove(selectedItem.userID)
            })
          }
            alert.close() 
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        },
        {
          label: "No",
          onClick: () => {
            alert.close() 
          },
          style: {
            backgroundColor: "#fff",
            borderRadius: 15,
            color: "black",
          }
        }
      ],
    });
   
  }


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  return (
    <>
     
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
          onClick={() => {
            navigate(-1)
          }}>
          <AiOutlineArrowLeft
            style={{
              width: 20,
              height: 20,

              color: "#fff",
            }}
          /></Button>
            <p style={{ fontSize: 30, color: "#D28816" }}>{location.state.networkname} Users</p>
            <div
              style={{
                flexDirection: 'row',
                alignItems: "flex-end",
                marginHorizontal: 20,
                marginTop: 5,
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setaddModel(true)
                }}
                style={{
                  height: 60,
                  width: 60,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffac30",
                  borderRadius: 30,

                }}
              >
                <img
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  src={require("../images/addNote.png")}
                />
              </Button>
            </div>
          </div>
          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {myCraft.map((prop, index) => {
              return (
                <Grid item xs={6} >

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 5,
                      height: "60px",
                      cursor: 'pointer',
                      //width: "100%",
                      //backgroundColor: "#fff",
                      // marginBottom: 10,
                      // marginLeft: (index % 2) ? 10 : 0
                      backgroundImage: `url(${list_item_bg})`,
                      backgroundRepeat: "repeat",

                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => {
                      setselectedIndex(index);
                      setmodalVisible(true);
                      setrecipeName(prop.craftname);
                      setdocid(prop.recipeID);
                      setselectedItem(prop)
                    }}
                  >
                    <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                    <div style={{ width: '5%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                      <p
                        // className="App"
                        style={{
                          fontSize: 12,
                          //height: 40,
                          color: "#000",
                          textAlign: "start",
                          //marginLeft: '35%',
                          //marginTop: '32%'

                        }}
                      >
                        #{index + 1}
                        {/* <br/>{craf.craftname} */}
                      </p>
                    </div>
                    <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.username}
                      </p>
                    </div>

                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      />
                    </div>

                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>





        <Modal
          isOpen={modalVisible}
          onRequestClose={() => {
            setmodalVisible(false)
          }}
          style={customStyles}
          contentLabel=""
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              display: 'flex'
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "70%",
                alignItems: "center",
                // display: 'flex',
                borderRadius: 15,
              }}
            >

              <Button
                onClick={() => {
                  setmodalVisible(false);
                  changePermission()
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Change Permission
                </p>
              </Button>
              <Button
                style={{
                  width: "100%",
                  height: 40,
                  borderBottomColor: "grey",
                  borderBottomWidth: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {

                  setmodalVisible(false);
                  setshowDelete(true)

                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#1c68ff",
                  }}
                >
                  Delete User
                </p>
              </Button>



              <Button
                onClick={() => setmodalVisible(false)}
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "600",
                    color: "#1c68ff",
                  }}
                >
                  Cancel
                </p>
              </Button>
            </div>
          </div>

        </Modal>


        <Modal
          isOpen={addModel}
          onRequestClose={() => {
            setaddModel(false)
          }}
          style={customStyles}
          contentLabel=""
        >
          <h2 >Add user</h2>
          <div
            style={{
              // width: "90%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'

            }}
          >
            <TextField
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  color: '#f7a828',
                }
              }}
              inputProps={{
                style: {
                  height: "5px",
                },
              }}
              placeholder='Member name'
              style={{ backgroundColor: "#fff", borderRadius: 10, }}
              required
              id="member"
              name="member"
              autoFocus
              hiddenLabel={true}
              onChange={event => {
                const { value } = event.target;
                setmembername(value)
              }}
            />
          </div>

          <div
            style={{
             height:'20px'
            }}
          />

          <div
            style={{
              // width: "90%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',

            }}
          >

            <TextField
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  color: '#f7a828',
                }
              }}
              inputProps={{
                style: {
                  height: "5px",
                },
              }}
              placeholder='Member email'
              style={{ backgroundColor: "#fff", borderRadius: 10, }}
              required
              id="memberemail"
              name="memberemail"
              //autoFocus
              hiddenLabel={true}
              onChange={event => {
                const { value } = event.target;
                setmemberemail(value)
              }}
            />
          </div>
          <div
            style={{
             height:'20px'
            }}
          />

          <div style={{ display: 'flex', alignItems: 'center', height: 35, width: '100%' , justifyContent:'space-between'}}>
            <p
              style={{
                fontSize: 15,
                fontWeight: "400",
                marginTop: 3,
                color: 'black'
              }}
            >
              Editor
            </p>
            <Button
              onClick={() => {

                setaddModel(true)
                setSelectionEditor(!isSelectedEditor)

              }}
              style={{
               // height: 20,
                //width: 20,
                justifyContent: 'flex-end',
                alignItems: "center",
               // borderRadius: 32,
               // position: 'absolute',
                right: 8,
                bottom: 8,
                //backgroundColor:'red'
              }}
              
            >
              <img
                style={{
                  height: 20,
                  width: 20,
                }}
                src={isSelectedEditor ? require("../images/checked_checkbox.png") : require("../images/unchecked_checkbox.png")}
              />
            </Button>

          </div>

          <Button
            style={{ backgroundColor: "#f7a828" }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              setaddModel(false);
              searchMember()
            }}
          >
            Submit
          </Button>

          <Button
            style={{ backgroundColor: "transparent", color: 'black' }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            // sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              setaddModel(false);
            }}
          >
            Cancel
          </Button>
        </Modal>

        {/* old one */}

      </Box>



      <CraftDialog
        show={showDelete}
        title={'Delete User'}
        body={'Are you sure you want to delete this?'}
        handleToClose={() => {
          setshowDelete(false)
        }}
        handleToOk={() => {
          setshowDelete(false)
          deleteCraft();
        }}
      />
    </>
  );
}

