import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
//const stripePromise = loadStripe("pk_test_51MnnbJFC7Opl3HqEOI3dg42uSE1AlBMMW0tZOYoaiiDFyFPeNWNVqBiGyURGEXNaRt3gxPGwBkFonzJxnUSAV5PH00qOdUJvzr"); // starts with pk_
const stripePromise = loadStripe("pk_live_51MnnbJFC7Opl3HqE5BHzAKMGX8f7fdfPEzra77RmAllMfOCNW214VUpFAncGs1Q33SXqHyXCwN7xZd5e1YRUfeT500DCOv0Wwl"); // starts with pk_

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Elements stripe={stripePromise}>
    <App />
    </Elements>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
