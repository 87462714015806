import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@mui/material/Button";

export default function CraftDialog({show, title, body, handleToClose,handleToOk, showCancel = true}) {
  
  return (
    <Dialog open={show} onClose={handleToClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         {showCancel && <Button onClick={handleToClose} 
                  color="secondary" autoFocus>
            Cancel
          </Button>}

          <Button onClick={handleToOk} 
                  color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
  )
  
 }