import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { AiOutlineRight } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import Select from 'react-select';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { sizeDropNew, sizeDrop } from "../context/craftUtil";
import Utility from "../utility";
import { AiOutlineArrowLeft, AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import FocusLock from "react-focus-lock";
import { useKey, useKeyPressEvent } from "react-use";
import { db, auth, frbase, messaging } from '../Database_fb';

Modal.setAppElement(document.getElementById('root'));



const BackBar = () => {
  const [itemname, setitemname] = useState("")
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  let subtitle;
  const [value, setValue] = useState(null);
  const [newItem, setnewItem] = useState('');
  const [searchValue, setSearchValue] = useState("")
  const [isSeraching, setisSeraching] = useState(false);
  const [myFilterArray, setmyFilterArray] = useState([])

  const [render, setrender] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    // getData();
    FetchBackBar();
  }, []);

  // useKey("Tab", addList);
  // useKeyPressEvent("Tab", () => {
  //   addList()
  // }, null);

  function FetchBackBar() {
    const user = auth.currentUser;
    
    const subscribe = db.collection("users")
      .doc(user.uid)
      .collection('backbaritems')
      .orderBy('order', 'asc')
      .onSnapshot((doc) => {
        var crafts = [];
        doc.forEach((snap) => {
          crafts.push({ key: snap.id, ...snap.data() });
        });
        // doc.docChanges().forEach(change => {
        //   if (change.type === 'added') {
        //     let snap = change.doc
        //     crafts.push({ key: snap.id, ...snap.data() });
        //   }
        //   if (change.type === 'modified') {
        //     let snap = change.doc
        //     let index = crafts.findIndex( item => item.key === snap.id);
        //     crafts[index] = { key: snap.id, ...snap.data() }
        //   }
        //   if (change.type === 'removed') {
        //     let snap = change.doc
        //     //BBitems = BBitems.filter((item, i) => item.key !== snap.id)
        //     let index = crafts.findIndex( item => item.key === snap.id);
        //     crafts.splice(0, index);
        //   }
        // });
        setrender(crafts)
      });
  }

  function addList() {
    // console.log("id:", Math.random().toString());

    let data = {
      id: Math.random().toString(),
      itemName: "",
      cost: "",
      sizeName: "",
      sizeValue: "",
      totalUnit: "",
      order: render.length
    }
    // setrender([
    //   ...render,
    //   data,
    // ]);

    const user = frbase.auth().currentUser;
    db.collection("users")
      .doc(user.uid)
      .collection('backbaritems')
      .add(data)
    //storeData(render);
  }

  function updateItem(key, item) {
    const user = frbase.auth().currentUser;
    db.collection("users")
      .doc(user.uid)
      .collection('backbaritems')
      .doc(key)
      .update(item)
  }

  function deleteItem(key) {
    const user = frbase.auth().currentUser;
    db.collection("users")
      .doc(user.uid)
      .collection('backbaritems')
      .doc(key)
      .delete()
      .then(() => {
       
      });
  }

  const storeData = async (value) => {
    try {
      const jsonValue = JSON.stringify(value);
      await localStorage.setItem("barRow", jsonValue);
    } catch (e) {
      // saving error
    }
  };
  const getData = async () => {
    try {
      const jsonValue = await localStorage.getItem("barRow");
      const value = jsonValue != null ? JSON.parse(jsonValue) : null;
      // console.log("value", value);
      if (value != null) {
        setrender(value);
      }
    } catch (e) {
      // error reading value
    }
  };

  // const handleChange = (DDitem) => {
  //   // if (isvolume) {
  //   //   setvolumeSelect1(DDitem)
  //   //   convertVolumeValues(DDitem.value, volumeSelect2.value, VolumeValue)
  //   // }
  //   // else {
  //   //   setbarSelect1(DDitem)
  //   //   convertBarValues(DDitem.value, barSelect2.value, BarValue)
  //   // }

  //    // console.log("itemName", value);
  //    render[index].itemName = DDitem.value;
  //    if (render[index].cost == 0 && render[index].sizeValue == 0) {
  //      render[index].cost = "1"
  //      render[index].sizeName = "750ml"
  //      render[index].sizeValue = 25.361
  //      // self.costTextField.text = "1"
  //    }
  //    totalUnit(render[index].cost, render[index].sizeValue);
  //    // render[index].totalUnit=(render[index].cost / sizeDrop[key].value).toFixed(3)
  //    // setsize(sizeDrop[index].value);
  //    setrender((counter) => [...counter]);
  //    storeData(render);
  // };

  // function searchItemList(text) {
  //   const new_item = render.filter((item) => {
  //     const itemData = item.toUpperCase();
  //     const textData = text.toUpperCase();
  //     return itemData.indexOf(textData) > -1;
  //   });
  //   if (text.length != 0) {
  //     setmyFilterArray(new_item);
  //   } else {
  //     setisSeraching(false);
  //   }
  // }

  // function serachSizeList(text) {
  //   const new_item = sizeDrop()
  //     .map((i) => i.key)
  //     .filter((item) => {
  //       const itemData = item.toUpperCase();
  //       const textData = text.toUpperCase();
  //       return itemData.indexOf(textData) > -1;
  //     });
  //   if (text.length != 0) {
  //     setserachSizeData(new_item);
  //   } else {
  //     setisSeraching(false);
  //   }
  // }


  // const options = [
  //   { value: 'chocolate', label: 'Chocolate' },
  //   { value: 'strawberry', label: 'Strawberry' },
  //   { value: 'vanilla', label: 'Vanilla' },
  // ];

  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 0,
    }
  });


  function renderRow(item, index) {
    function totalUnit(cost, size) {
      render[index].totalUnit = (cost / size).toFixed(2);
    }
    return (
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            //height: "45px",
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: "40%",
              padding: 5,
            }}
          >
            <Select
              // inputValue="hello"
              //theme={theme}
              //styles={{ height: "45px", }}
              placeholder={item.itemName.length > 0 ? item.itemName : ""}
              value={item.itemName}
              onChange={DDitem => {
                render[index].itemName = DDitem.value;
                if (render[index].cost == 0 && render[index].sizeValue == 0) {
                  render[index].cost = "1"
                  render[index].sizeName = "750ml"
                  render[index].sizeValue = 25.361
                  // self.costTextField.text = "1"
                }
                totalUnit(render[index].cost, render[index].sizeValue);
                // render[index].totalUnit=(render[index].cost / sizeDrop[key].value).toFixed(3)
                // setsize(sizeDrop[index].value);
                setrender((counter) => [...counter]);

                updateItem(item.key, render[index])
                //storeData(render);
              }}
              options={Utility.itemsArray}
            />
          </div>
          <div
            style={{
              width: "10%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'

            }}
          >
            <TextField
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  color: '#f7a828',
                }
              }}
              inputProps={{
                style: {
                  height: "5px",
                },
              }}
              value={item.cost}
              style={{ backgroundColor: "#fff", borderRadius: 10, }}
              required
              id="cost"
              name="cost"
              //autoComplete="email"
              //autoFocus
              hiddenLabel={true}
              onChange={event => {
                const { value } = event.target;
                render[index].cost = value;
                totalUnit(value, render[index].sizeValue);
                setrender((counter) => [...counter]);
                updateItem(item.key, render[index])
                //(render);
              }}
            />
          </div>
          <div
            style={{
              width: "35%",
              padding: 5,
            }}
          >
            <Select
              placeholder={item.sizeName.length > 0 ? item.sizeName : ""}
              //placeholder={'chocolate'}
              value={item.sizeName}
              onChange={DDitem => {
                let sizeindex = sizeDrop().findIndex(x => x.key === DDitem.label);
                render[index].sizeValue = sizeDrop()[sizeindex].value;
                render[index].sizeName = DDitem.label;
                totalUnit(render[index].cost, sizeDrop()[sizeindex].value);
                setrender((counter) => [...counter]);
                updateItem(item.key, render[index])
                //storeData(render);
              }}
              options={sizeDropNew()}
            />
          </div>

          <div
            style={{
              // marginRight: "1%",
              width: "10%",
              // height: "20%",
              padding: 5,
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p style={{ color: "#000" }}>{item.totalUnit == "Infinity" || item.totalUnit == "NaN"
              ? ""
              : item.totalUnit}</p>
          </div>

          <div
            style={{
              // marginRight: "1%",
              width: "5%",
              // height: "20%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => {
                //navigate(-1)
                render.splice(index, 1);
                setrender([...render]);
                deleteItem(item.key)
                //storeData(render);
              }}>
              <AiOutlineDelete
                style={{
                  width: 20,
                  height: 20,

                  color: "red",
                }}
              /></Button>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: '1px',
            backgroundColor: 'blue'
          }}
        />

      </div>
    )
  }

  function openModal() {
    let premium_subscribed = (Utility.currentuser != null && Utility.currentuser.premium_subscribed)
    console.log(Utility.currentuser.premium_subscribed);
    if (premium_subscribed == false) {
      navigate("/Premium")
      return
    }
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#D28816';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <>
      <div style={{ flex: 1, backgroundColor: "#fff" }}>

        {/* Header row Start */}
        <div
          style={{
            width: "100%",
            // alignItems: "flex-end",
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            //marginLeft: "10%",
            // marginRight: 20,
            marginTop: 10,
          }}
        >
          <Button
            onClick={() => {
              navigate(-1)
            }}>
            <AiOutlineArrowLeft
              style={{
                width: 20,
                height: 20,

                color: "#000",
              }}
            /></Button>

          <p style={{ fontSize: 20, color: "#D28816" }}>BACK BAR</p>

          <div style={{ marginRight: "3%" }}>
            <Button
              onClick={openModal}>

              <p style={{ color: 'black' }}>Create New Item</p>

            </Button>
          </div>
        </div>


        {/* Header row End */}

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >

          <div
            style={{
              width: "90%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextField
              disabled={false}
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  color: '#f7a828',
                }
              }}
              inputProps={{
                style: {
                  height: "5px",
                },
              }}
              placeholder='Search...'
              fullWidth
              style={{ backgroundColor: "#fff", borderRadius: 10, }}
              required
              id="search"
              name="search"
              hiddenLabel={true}
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value)
              }}
            />
          </div>
        </div>

        {/* title row start */}

        <div
          style={{
            width: "100%",
            display: "flex",
            backgroundColor: "#fff",
            justifyContent: "space-between",
            height: '30px'
          }}
        >
          <div
            style={{
              width: "40%",
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p
              style={{
                fontSize: 16,
                color: "#000",
              }}
            >
              item
            </p>
          </div>
          <div style={{
            width: "10%", paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <p
              style={{
                fontSize: 16,
                color: "#000",
              }}
            >
              Cost($)
            </p>
          </div>
          <div style={{
            width: "35%", paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <p
              style={{
                fontSize: 16,
                color: "#000",
              }}
            >
              Size
            </p>
          </div>
          <div
            style={{
              width: "10%",
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p
              style={{
                fontSize: 16,
                color: "#000",
              }}
            >
              Cost/unit
            </p>
          </div>

          <div
            style={{
              width: "5%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >

          </div>

        </div>
        {/* title row end */}


        {/* Item row start */}
        {/* {isSeraching ? myFilterArray.map((item, index) => (
          renderRow(item, index)
        )) : render.map((item, index) => (
          renderRow(item, index)
        ))} */}

        {render.filter(name => name.itemName.match(new RegExp(searchValue, "i")))
          .map((item, index) => (
            renderRow(item, index)
          ))}

        {/* Item row end */}

        {/* <FocusLock > */}
        <div style={{
          width: "100%",
          padding: 10,
          display: "flex",
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <Button
            onClick={() => {
              addList()
            }}>

            <BsPlusCircle style={{ Size: "20px", color: "blue" }} />
          </Button>
        </div>
        {/* </FocusLock> */}

        {/* <div style={{ backgroundColor: "black" }}>
          <div style={{ marginLeft: "2%" }}>
            <p style={{ color: "#fff", fontSize: 20, textAlign: "start" }}>
              Dilution
            </p>
          </div>
        </div> */}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel=""
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Add Item</h2>
        <div
          style={{
            // width: "90%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'

          }}
        >
          <TextField
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: '#f7a828',
              }
            }}
            inputProps={{
              style: {
                height: "5px",
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: 10, }}
            required
            id="item"
            name="item"
            autoFocus
            hiddenLabel={true}
            onChange={event => {
              const { value } = event.target;
              setnewItem(value)
            }}
          />
        </div>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setIsOpen(false)
            Utility.AddItem(newItem)
          }}
        >
          Add
        </Button>

        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setIsOpen(false)
          }}
        >
          Cancel
        </Button>
      </Modal>

    </>
  );
}

export default BackBar