import React from 'react';
import {
    Nav,
    NavLink,
    NavMenu,
    NavBtn,
    Bars,
    NavBtnLink,
} from './NavbarElements';
import { AiOutlineSetting } from "react-icons/ai";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';

const Navbar = ({setAuth}) => {
    const navigate = useNavigate();

    return (
        <>
            <Nav>
                <Bars>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "90%",
                        }}
                    >
                        <Button
                            onClick={() => {
                                navigate('Setting')
                            }}>
                            <AiOutlineSetting
                                style={{
                                    width: 20,
                                    height: 20,

                                    color: "#fff",
                                }}
                            /></Button>
                    </div>
                </Bars>
                <NavMenu>
                    <NavLink to='/' activeStyle>
                        Home
                    </NavLink>
                    {/* Second Nav */}
                    {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
                </NavMenu>
                <NavBtn>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "90%",
                        }}
                    >
                        <Button
                            onClick={() => {
                                navigate('Setting',{state:{setauth: setAuth}})
                            }}>
                            <AiOutlineSetting
                                style={{
                                    width: 20,
                                    height: 20,

                                    color: "#fff",
                                }}
                            /></Button>
                    </div>
                </NavBtn>
            </Nav>
        </>
    );
};

export default Navbar;