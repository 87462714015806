import React, { useRef, useState, useEffect, } from "react";
import { Link } from "react-router-dom";
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AiOutlineArrowLeft, AiOutlinePrinter, AiOutlineDownload } from "react-icons/ai";
import Button from "@mui/material/Button";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Utility from "../../utility";
import { html2pdf } from "js-html2pdf";
import html2canvas from "html2canvas";
import { useScreenshot, createFileName } from "use-react-screenshot";

const PDFTemplate = ({ tickets }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    const pdfRef = useRef(null);
    const downloadRef = useRef(null);
    const [render, setrender] = useState([{ id: 1, item: 'item 1', qty: '2', unit: 'ounce', total: '100' }]);
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);
    function createData(number, item, qty, price) {
        return { number, item, qty, price };
    }
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    const downloadScreenshot = () => takeScreenShot(downloadRef.current).then(download);

    const rawHTML = Utility.pdfContent(location.state.recipename, location.state.recipeimageurl, location.state.itemrender, location.state.dilutionrender, location.state.floatRender, location.state.garnishrender, location.state.glassware, location.state.glasswareArray, location.state.totalYields.toFixed(3), location.state.totalBatchedOunces.toFixed(3), location.state.totalOunces.toFixed(3))

    const download = (image, { name = location.state.recipename, extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    useEffect(() => {
        // if (isPrinting && promiseResolveRef.current) {
        //     // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
        //     promiseResolveRef.current();
        // }

        // totalOunces: TotalOunces,
        //           totalYields: TotalYield,
        //           totalBatchedOunces: TotalBatchedOunces,
        //           recipename: recipeName,
        //           recipeimageurl: recipeimageurl,
        //           itemrender: itemrender,
        //           dilutionrender: dilutionrender,
        //           garnishrender: garnishrender,
        //           floatRender: floatRender,
        //           glassware: selectedGlassware,
        //           glasswareArray: glasswareArrayStrings()
        // console.log('location.state.glasswareArray', location.state.glasswareArray);
        //handlePrint()
    }, []);



    const handleDownload = useReactToPrint({
        onPrintError: (error) => console.log('err', error),
        content: () => pdfRef.current,
        documentTitle: location.state.recipename + ' - The CraftIQ',
        removeAfterPrint: true,
        print: async (printIframe) => {
            const content = printIframe.contentDocument;
            if (content) {
                const html = content.getElementsByTagName("html");
                // html2canvas(document).then(canvas => 
                //     canvas.toDataURL()
                //   ) 

                html2canvas(html, { scale: 1 }).then(canvas => {
                    const a = document.createElement("a");
                    a.href = canvas.toDataURL();
                    a.download = location.state.recipename + '.jpg';
                    a.click();
                });
                // const exporter = new html2pdf(html);
                // await exporter.getPdf(true);
            }
        },
    });

    const handlePrint = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: location.state.recipename + ' - The CraftIQ',
        onBeforeGetContent: () => {
            // return new Promise((resolve) => {
            //     promiseResolveRef.current = resolve;
            //     setIsPrinting(true);
            // });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            //  promiseResolveRef.current = null;
            //setIsPrinting(false);
            setTimeout(() => {
                navigate(-1)
            }, 500);

        }
    });

    const StyledTableRow = withStyles((theme) => ({
        root: {
            height: 30
        }
    }))(TableRow);

    const StyledTableCell = withStyles((theme) => ({
        root: {
            padding: "0px 16px"
        }
    }))(TableCell);

    // return (
    //     <>
    // <div
    //     style={{
    //         flexDirection: "row",
    //         display: 'flex',
    //         //marginHorizontal: 20,
    //         // marginTop: 40,
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //         width: '98%'
    //     }}
    // >
    //     <Button
    //         onClick={() => {
    //             navigate(-1)
    //         }}>
    //         <AiOutlineArrowLeft
    //             style={{
    //                 width: 20,
    //                 height: 20,

    //                 color: "#fff",
    //             }}
    //         /></Button>




    //     <p
    //         style={{
    //             fontSize: 20,
    //             color: "white",
    //             fontWeight: "400",
    //             //backgroundColor:'red',
    //             //width: 200, 
    //             left: 10,
    //             textAlign: 'center'
    //         }}
    //     >
    //         Print Recipe
    //     </p>

    //     <div style={{ marginRight: "3%" }}>
    //     </div>
    // </div>
    //         <div ref={pdfRef} style={{ flex: 1, backgroundColor: 'white' }}>
    //             <p
    //                 style={{
    //                     fontSize: 30,
    //                     color: "black",
    //                     fontWeight: "400",
    //                     //width: 200, 
    //                     left: 10,
    //                     textAlign: 'center'
    //                 }}
    //             >
    //                 {location.state.recipename}
    //             </p>
    //             <div style={{
    //                 width: "100%",
    //                 display: "flex",
    //                 justifyContent: 'flex-start',
    //                 alignItems: 'center',
    //             }}>

    //                {location.state.itemrender[0].itemname.length > 0 && <div style={{ width: '70%', alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: '10px' }}>
    //                     <TableContainer component={Paper} >

    //                         <Table aria-label="simple table">
    //                             <TableHead >
    //                                 <TableRow  className={classes.tableRow}>
    //                                     <TableCell className={classes.tableCell} align="left">Item</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">Quantity</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">Unit</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">Total Ounce</TableCell>
    //                                 </TableRow>
    //                             </TableHead>
    //                             <TableBody>
    //                                 {location.state.itemrender.map((row) => (
    //                                     <StyledTableRow key={row.dociID}>

    //                                         <StyledTableCell align="left">{row.itemname}</StyledTableCell>
    //                                         <StyledTableCell align="center">{row.Quantity}</StyledTableCell>
    //                                         <StyledTableCell align="center">{row.unitName}</StyledTableCell>
    //                                         <StyledTableCell align="center">{row.totalOunces}</StyledTableCell>
    //                                     </StyledTableRow>
    //                                 ))}
    //                             </TableBody>
    //                         </Table>
    //                     </TableContainer>
    //                 </div>}

    //                 <div style={{ width: '30%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

    //                     <img
    //                         style={{
    //                             height: '50%',
    //                             width: '50%',
    //                         }}
    //                         src={(location.state.recipeimageurl.length != 0) ? location.state.recipeimageurl : require("../../images/backbar.png")}
    //                     />
    //                 </div>


    //             </div>

    //             {/* Dilution Section  Start*/}

    //             {location.state.dilutionrender[0].itemname.length > 0 && <div style={{ width: '70%', alignItems: 'center', justifyContent: 'center', paddingLeft: '10px' }}>
    //                 <div style={{ backgroundColor: 'black', height: '20px', alignItems: 'center', justifyContent: 'center' }}>
    //                     <p
    //                         style={{
    //                             fontSize: 15,
    //                             color: "white",
    //                             fontWeight: "400",
    //                             //width: 200, 
    //                             paddingLeft: 20,
    //                             paddingTop: 0,
    //                             textAlign: 'left',
    //                         }}
    //                     >
    //                         Dilution
    //                     </p>
    //                 </div>
    //                 <TableContainer component={Paper} >

    //                     <Table aria-label="simple table">

    //                         <TableBody>
    //                             {/* {location.state.dilutionrender.filter((item) => item.totalOunces !== 2.75).map((row) => (
    //                                 <TableRow className={classes.tableRow} key={row.dociID}>
    //                                     <TableCell className={classes.tableCell} component="th" scope="row">
    //                                         {row.itemname}
    //                                     </TableCell>
    //                                     <TableCell className={classes.tableCell} align="right">{row.totalOunces}</TableCell>
    //                                 </TableRow>
    //                             ))} */}

    //                              <TableRow className={classes.tableRow} key={0}>
    //                                     <TableCell className={classes.tableCell} component="th" scope="row">
    //                                         {location.state.dilutionrender[0].itemname}
    //                                     </TableCell>
    //                                     <TableCell className={classes.tableCell} align="right">{location.state.dilutionrender[0].totalOunces}</TableCell>
    //                                 </TableRow>
    //                         </TableBody>
    //                     </Table>
    //                 </TableContainer>
    //             </div>}

    //             {/* Dilution Section  Start*/}


    //             {/* Float / Muddle / other  Section  Start*/}

    //             {location.state.floatRender[0].itemname.length > 0 && <div style={{ width: '70%', alignItems: 'center', justifyContent: 'center', paddingLeft: '10px' }}>
    //                 <div style={{ backgroundColor: 'black', height: '20px', alignItems: 'center', justifyContent: 'center' }}>
    //                     <p
    //                         style={{
    //                             fontSize: 15,
    //                             color: "white",
    //                             fontWeight: "400",
    //                             //width: 200, 
    //                             paddingLeft: 20,
    //                             //paddingTop: 10,
    //                             textAlign: 'left',
    //                         }}
    //                     >
    //                         Float / Muddle / other
    //                     </p>
    //                 </div>
    //                 <TableContainer component={Paper} >

    //                     <Table aria-label="simple table">
    //                         <TableHead>
    //                             <TableRow className={classes.tableRow}>
    //                                 <TableCell className={classes.tableCell} align="left">Item</TableCell>
    //                                 <TableCell className={classes.tableCell} align="center">Quantity</TableCell>
    //                                 <TableCell className={classes.tableCell} align="center">Unit</TableCell>
    //                                 <TableCell className={classes.tableCell} align="center">Total Ounce</TableCell>
    //                             </TableRow>
    //                         </TableHead>
    //                         <TableBody>
    //                             {location.state.floatRender.map((row) => (
    //                                 <TableRow className={classes.tableRow} key={row.dociID}>

    //                                     <TableCell className={classes.tableCell} align="left">{row.itemname}</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">{row.Quantity}</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">{row.unitName}</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">{row.totalOunces}</TableCell>
    //                                 </TableRow>
    //                             ))}
    //                         </TableBody>
    //                     </Table>
    //                 </TableContainer>
    //             </div>}

    //             {/* Float / Muddle / other Section  Start*/}


    //             {/* Garnish  Section  Start*/}

    //             {location.state.garnishrender[0].itemname.length > 0 && <div style={{ width: '70%', alignItems: 'center', justifyContent: 'center', paddingLeft: '10px' }}>
    //                 <div style={{ backgroundColor: 'black', height: '20px', alignItems: 'center', justifyContent: 'center' }}>
    //                     <p
    //                         style={{
    //                             fontSize: 15,
    //                             color: "white",
    //                             fontWeight: "400",
    //                             //width: 200, 
    //                             paddingLeft: 20,
    //                             //paddingTop: 10,
    //                             textAlign: 'left',
    //                         }}
    //                     >
    //                         Garnish
    //                     </p>
    //                 </div>
    //                 <TableContainer component={Paper} >

    //                     <Table aria-label="simple table">
    //                         <TableHead>
    //                             <TableRow className={classes.tableRow}>
    //                                 <TableCell className={classes.tableCell} align="left">Item</TableCell>
    //                                 <TableCell className={classes.tableCell} align="center">Quantity</TableCell>
    //                                 <TableCell className={classes.tableCell} align="center">Unit</TableCell>
    //                                 <TableCell className={classes.tableCell} align="center">Total Ounce</TableCell>
    //                             </TableRow>
    //                         </TableHead>
    //                         <TableBody>
    //                             {location.state.garnishrender.map((row) => (
    //                                 <TableRow className={classes.tableRow} key={row.dociID}>

    //                                     <TableCell className={classes.tableCell} align="left">{row.itemname}</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">{row.Quantity}</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">{row.unitName}</TableCell>
    //                                     <TableCell className={classes.tableCell} align="center">{row.totalOunces}</TableCell>
    //                                 </TableRow>
    //                             ))}
    //                         </TableBody>
    //                     </Table>
    //                 </TableContainer>
    //             </div>}

    //             {/* Garnishr Section  Start*/}


    //             {/* Preparation  Section  Start*/}

    //             {(location.state.glassware.length > 0 || location.state.glasswareArray[0].length > 0) && <div style={{ width: '70%', alignItems: 'center', justifyContent: 'center', paddingLeft: '10px' }}>
    //                 <div style={{ backgroundColor: 'black', height: '20px', alignItems: 'center', justifyContent: 'center' }}>
    //                     <p
    //                         style={{
    //                             fontSize: 15,
    //                             color: "white",
    //                             fontWeight: "400",
    //                             //width: 200, 
    //                             paddingLeft: 20,
    //                             //paddingTop: 10,
    //                             textAlign: 'left',
    //                         }}
    //                     >
    //                         Preparation
    //                     </p>
    //                 </div>
    //                 <TableContainer component={Paper} >

    //                     <Table aria-label="simple table">
    //                         {/* <TableHead>
    //                         <TableRow>
    //                             <TableCell>S.No</TableCell>
    //                             <TableCell align="right">Item</TableCell>
    //                             <TableCell align="right">Quantity&nbsp;(kg)</TableCell>
    //                             <TableCell align="right">Price&nbsp;($)</TableCell>
    //                         </TableRow>
    //                     </TableHead> */}
    //                         <TableBody>
    //                             {location.state.glassware != null && <TableRow className={classes.tableRow} key={1}>

    //                                 <TableCell className={classes.tableCell} component="th" scope="row">
    //                                     Glassware
    //                                 </TableCell>
    //                                 <TableCell className={classes.tableCell} align="right">{location.state.glassware}</TableCell>

    //                             </TableRow>}
    //                             {location.state.glasswareArray[0].length > 0 && <>
    //                             {location.state.glasswareArray.map((row, index) => (
    //                                 <TableRow className={classes.tableRow} key={index + 1}>
    //                                     <TableCell className={classes.tableCell} align="center">
    //                                         <p
    //                                             style={{
    //                                                 fontSize: 16,
    //                                                 color: "black",
    //                                                 fontWeight: "400",
    //                                                 //width: 200, 
    //                                                 paddingLeft: 20,
    //                                                 paddingTop: 10,
    //                                                 textAlign: 'left',
    //                                             }}
    //                                         >
    //                                             {row}
    //                                         </p>
    //                                     </TableCell>
    //                                 </TableRow>
    //                             ))}</> }
    //                         </TableBody>
    //                     </Table>
    //                 </TableContainer>
    //             </div>}


    //             {/* Preparation Section  Start*/}


    //             {/* Total  Section  Start*/}

    //             <div style={{ width: '70%', alignItems: 'center', justifyContent: 'center', paddingLeft: '10px' }}>
    //                 <TableContainer component={Paper} >

    //                     <Table aria-label="simple table">
    //                         <TableHead>
    //                             <TableRow className={classes.tableRow}>
    //                                 <TableCell className={classes.tableCell}></TableCell>
    //                                 <TableCell className={classes.tableCell}></TableCell>
    //                                 <TableCell className={classes.tableCell} align="right">Total yield</TableCell>
    //                                 <TableCell className={classes.tableCell} align="right">Batched ounces</TableCell>
    //                                 <TableCell className={classes.tableCell} align="right">Total ounces</TableCell>
    //                             </TableRow>
    //                         </TableHead>
    //                         <TableBody>
    //                             <TableRow className={classes.tableRow} key={1}>

    //                                 <TableCell className={classes.tableCell} scope="row" />
    //                                 <TableCell className={classes.tableCell} scope="row" />
    //                                 <TableCell className={classes.tableCell} align="right">{location.state.totalYields.toFixed(3)}</TableCell>
    //                                 <TableCell className={classes.tableCell} align="right">{location.state.totalBatchedOunces.toFixed(3)}</TableCell>
    //                                 <TableCell className={classes.tableCell} align="right">{location.state.totalOunces.toFixed(3)}</TableCell>
    //                             </TableRow>

    //                         </TableBody>
    //                     </Table>
    //                 </TableContainer>
    //             </div>


    //             {/* Total Section  Start*/}
    //         </div>

    //     </>
    // );


    return (
        <div ref={downloadRef}>
            <div
                style={{
                    flexDirection: "row",
                    display: 'flex',
                    //marginHorizontal: 20,
                    // marginTop: 40,
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: '98%'
                }}
            >
                <Button
                    onClick={() => {
                        navigate(-1)
                    }}>
                    <AiOutlineArrowLeft
                        style={{
                            width: 20,
                            height: 20,

                            color: "#fff",
                        }}
                    /></Button>




                <p
                    style={{
                        fontSize: 20,
                        color: "white",
                        fontWeight: "400",
                        //backgroundColor:'red',
                        //width: 200, 
                        left: 10,
                        textAlign: 'center'
                    }}
                >
                    Print Recipe
                </p>

                <div style={{ marginRight: "3%" }}>
                    <Button
                        onClick={() => {
                            handlePrint()
                        }}>
                        <AiOutlinePrinter
                            style={{
                                width: 20,
                                height: 20,

                                color: "#fff",
                            }}
                        /></Button>

                    {/* <Button
                        onClick={() => {
                            downloadScreenshot()
                        }}>
                        <AiOutlineDownload
                            style={{
                                width: 20,
                                height: 20,

                                color: "#fff",
                            }}
                        /></Button> */}

                </div>
            </div>
            <div ref={pdfRef} style={{ flex: 1, backgroundColor: 'white' }}>
                <div  dangerouslySetInnerHTML={{ __html: rawHTML }} />
            </div>
        </div>
    );
};

export default PDFTemplate;

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650
    },
    tableRow: {
        height: 30
    },
    tableCell: {
        padding: "0px 16px"
    }
}));