import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { BsPlusCircle } from "react-icons/bs";

import TextField from "@mui/material/TextField";
import Select from 'react-select';
import SelectDropdown from 'react-dropdown';

import Button from "@mui/material/Button";
import { AiOutlineArrowLeft, AiOutlineRight } from "react-icons/ai";
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import {
  ShoppingBottleSizeItems,
  ShoppingFloatSizeItems,

} from "../context/craftUtil";
import { ListOfAmount, convertCostingValues, convertCostingFloatGarnishValues, floatBottleSize, garnishBottleSize } from "../context/conversionUtil";
import { db, auth, frbase } from './../Database_fb';
import FlatList from 'flatlist-react';
import ActionSheet from "actionsheet-react";
import { display } from "@mui/system";
import { NumericFormat } from 'react-number-format';


Modal.setAppElement(document.getElementById('root'));

let Totalcost = 0
const CostRecipe = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const finishRef = useRef();
  const [orderModal, setorderModal] = useState(false);
  const [render, setrender] = useState(["1"]);
  const [loading, setloading] = useState(false);
  const [itemrender, setitemrender] = useState([]);
  const [floatRender, setfloatRender] = useState([]);
  const [garnishrender, setgarnishrender] = useState([]);
  const [dilutionrender, setdilutionrender] = useState([...location.state.dilutionArray]);
  const [viewOnly, setviewOnly] = useState(location.state.viewOnly);

  const [recipeModal, setrecipeModal] = useState(false);
  const [recipeName, setrecipeName] = useState(location.state.craftName);
  const [dociID, setdociID] = useState(location.state.dociID)

  const [totalAmount, settotalAmount] = useState(0)
  const [TotalOunces, setTotalOunces] = useState(location.state.TotalOunces)
  const [TotalYield, setTotalYield] = useState(location.state.TotalYield)
  const [TotalYieldWithDilution, setTotalYieldWithDilution] = useState(location.state.TotalYield)
  const [selectedBatchType, setselectedBatchType] = useState(location.state.selectedBatchType)
  const [TotalOunceWithDilution, setTotalOunceWithDilution] = useState(location.state.TotalOunceWithDilution)
  const [selectedBottleType, setselectedBottleType] = useState(location.state.selectedBottleType)
  const [CostPercentageText, setCostPercentageText] = useState(0)
  const [MenuPriceText, setMenuPriceText] = useState(0)
  const [selectedIngredientVal, setselectedIngredientVal] = useState(location.state.selectedIngredientVal)
  const [batchQuantity, setbatchQuantity] = useState(location.state.QTYValue)
  const [AssumedBatchValue, setAssumedBatchValue] = useState(location.state.AssumedBatchValue)
  const [ingrediantArray, setingrediantArray] = useState([...location.state.ingrediantArray])
  const [isShopping, setisShopping] = useState(location.state.isShopping)
  const [finishWithOrder, setfinishWithOrder] = useState(false)
  const [serachSizeData, setserachSizeData] = useState([]);
  const [isSeraching, setisSeraching] = useState(false);
  const [totalCostText, settotalCostText] = useState(0);


  const [PercentageMenuPriceText, setPercentageMenuPriceText] = useState(0);
  const [MenuPricePercentageText, setMenuPricePercentageText] = useState(0);



  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
    Totalcost = 0
    const getCostPercentage = async () => {
      const jsonValue = await localStorage.getItem("percentage_" + location.state.dociID);
      const value = jsonValue != null ? JSON.parse(jsonValue) : null;
      //console.log('value CostPercentageText',value);
      if (value != null) {
        setCostPercentageText(value)
      }
      else {
        setCostPercentageText(0.00)
      }
      // console.log('CostPercentageText', CostPercentageText);
    }

    const getMenuPrice = async () => {
      const jsonValue = await localStorage.getItem("currency_" + location.state.dociID);
      const value = jsonValue != null ? JSON.parse(jsonValue) : null;
      if (value != null) {
        setMenuPriceText(value)
      }
      else {
        setMenuPriceText(0.00)
      }
      //console.log('MenuPriceText', MenuPriceText);
    }

    const updateCostinsideArray = async () => {

      const user = auth.currentUser;
    
    const subscribe = db.collection("users")
      .doc(user.uid)
      .collection('backbaritems')
      .orderBy('order', 'asc')
      .onSnapshot((doc) => {
        var value = [];
        doc.forEach((snap) => {
          value.push({ key: snap.id, ...snap.data() });
        });
        var backBarArry = []
        if (value != null) {
          backBarArry = value
        }
        else {
          backBarArry = [{
            id: Math.random().toString(),
            itemName: "",
            cost: 0.0,
            sizeName: "",
            sizeValue: "",
            totalUnit: 0.0,
          }]
        }
  
        let itemsArry = [...location.state.myItemArray]
        itemsArry.forEach((item) => {
          let elementfound = backBarArry.find((element) => {
            return element.itemName === item.itemname;
          })
          if (elementfound != null) {
            if (elementfound.totalUnit > 0) {
              item.cost = elementfound.totalUnit
            }
            else {
              item.cost = 0.0
            }
          }
          else {
            item.cost = 0.0
          }
          //console.log('item.cost',item.cost);
          //console.log('item.totalOunces',item.totalOunces);
          Totalcost = Totalcost + (item.totalOunces * item.cost)
        })
        setitemrender(itemsArry)
  
        let floatArry = [...location.state.floatArray]
        floatArry.forEach((item) => {
          let elementfound = backBarArry.find((element) => {
            return element.itemName === item.itemname;
          })
          if (elementfound != null) {
            if (elementfound.totalUnit > 0) {
              item.cost = elementfound.totalUnit
            }
            else {
              item.cost = 0.0
            }
          }
          else {
            item.cost = 0.0
          }
          Totalcost = Totalcost + (item.totalOunces * item.cost)
        })
        setfloatRender(floatArry)
  
        let garnishArry = [...location.state.garnishArray]
        garnishArry.forEach((item) => {
          let elementfound = backBarArry.find((element) => {
            return element.itemName === item.itemname;
          })
          if (elementfound != null) {
            if (elementfound.totalUnit > 0) {
              item.cost = elementfound.totalUnit
            }
            else {
              item.cost = 0.0
            }
          }
          else {
            item.cost = 0.0
          }
          Totalcost = Totalcost + (item.totalOunces * item.cost)
        })
  
        setgarnishrender(garnishArry)
        settotalCostText(roundUsing(Math.ceil, Totalcost, 2))
      });

      // const jsonValue = await localStorage.getItem("barRow");
      // const value = jsonValue != null ? JSON.parse(jsonValue) : null;
      // console.log("value", value);
     
    };

    getCostPercentage()
    getMenuPrice()
    updateCostinsideArray()



    if (CostPercentageText > 0) {
      setPercentageMenuPriceText((Totalcost / CostPercentageText))
    }
    else {
      setPercentageMenuPriceText(0.00)
    }
    if (MenuPriceText > 0) {
      setMenuPricePercentageText((Totalcost / MenuPriceText))
    }
    else {
      setMenuPricePercentageText(0.00)
    }
  }, []);

  function roundUsing(func, number, prec) {
    var tempnumber = number * Math.pow(10, prec);
    tempnumber = func(tempnumber);
    return tempnumber / Math.pow(10, prec);
  }

  function totalOunceYieldValue(itemsArry, diluArry) {
    var amt = 0
    var yields = 0
    var ounces = 0
    itemsArry.forEach((item) => {
      amt = amt + item.amount
      ounces = ounces + item.totalOunces
      yields = yields + item.yields
    })
    //console.log('total amt', amt);
    let dilu = diluArry[0]
    if (dilu.itemname.length > 0) {
      amt = amt + dilu.amount
      ounces = ounces + dilu.totalOunces
      yields = yields + dilu.yields
    }
    setTotalOunces(ounces)
    settotalAmount(amt)
    setTotalYield(yields)
    setTotalYieldWithDilution(Math.round(yields / ounces))

    return Math.round(yields / ounces)

  }



  function renderFlatItem() {
    return (
      <div
        style={{
          backgroundColor: "white",
          flex: 1,
        }}
      >
        <FlatList
          list={itemrender}
          renderItem={renderItem}
        ></FlatList>
        <div
          style={{
            backgroundColor: "black",
            height: 30,
            alignItems: "center",
            display: 'flex',
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 13,
              fontWeight: "300",
              marginLeft: 10,
            }}
          >
            Dilution
          </p>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: 'flex',
            height: 40,
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              flexDirection: "row",
              width: '40%',
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "600",
                color: "black"
              }}
            >
              {" "}
              {dilutionrender[0].itemname}
            </p>

          </div>

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: '40%',
              //backgroundColor:'red'
              // display:'flex',
            }}
          >
            {/* <SelectDropdown
              disabled={viewOnly || (dilutionrender[0].itemname.length == 0)}
              placeholder=''
              value={(dilutionrender[0].itemname.length == 0) ? "" : dilutionrender[0].bottleSize}
              options={Object.keys(ShoppingBottleSizeItems())}
              onChange={(value, index) => {
                let unit = ShoppingBottleSizeItems()[value.value]
                let backup = [...dilutionrender]
                backup[0].bottleSize = value.value
                backup[0].bottleSizeValue = unit
                backup[0].amount = ListOfAmount((isShopping) ? "shopping" : "", value.value, unit, dilutionrender[0].yields)
                setdilutionrender(backup, () => {
                  totalOunceYieldValue(itemrender, dilutionrender)
                });
              }}

            /> */}

          </div>


          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: 'flex',
              width: '20%',
            }}
          >
            <p

              style={{
                textAlignVertical: 'center',
                fontSize: 15,
                fontWeight: "600",
                // fontSize: RFValue(12),
                // fontWeight: "300",
                color: "black"
              }}
            >
              {(dilutionrender.length > 0) ? dilutionrender[0].totalOunces.toFixed(2) : ''}
            </p>

          </div>
        </div>


        <div
          style={{
            backgroundColor: "black",
            height: 30,
            alignItems: "center",
            display: 'flex',
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 13,
              fontWeight: "300",
              marginLeft: 10,
            }}
          >
            Float / Muddle / other
          </p>
        </div>
        <FlatList
          list={floatRender}
          renderItem={renderItem}
        ></FlatList>

        <div
          style={{
            backgroundColor: "black",
            height: 30,
            alignItems: "center",
            display: 'flex',
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 13,
              fontWeight: "300",
              marginLeft: 10,
            }}
          >
            Garnish
          </p>
        </div>
        <FlatList
          list={garnishrender}
          renderItem={renderItem}
        ></FlatList>

        <div
          style={{
            height: 120,

          }}
        >

        </div>
      </div >

    );
  }


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function renderItem(item, index) {
    //console.log("item",item);
    return (
      <>
        <div
          key={index.toString()}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            display: 'flex',
            borderBottomWidth: 1,
            borderBottomColor: "#80b3ff"
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: '40%',
              display: 'flex',
              //height: 40,
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "600",
                textAlign: "center",
                color: "black"
              }}
            >
              {item.itemname}
            </p>
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: '20%',
              display: 'flex',
              height: 40,
            }}
          >
            <p
              style={{
                textAlignVertical: 'center',
                fontSize: 15,
                fontWeight: "600",
                textAlign: "center",
                color: "black"
              }}
            >
              $ {item.cost}
            </p>
          </div>



          <div
            style={{
              width: '20%',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "600",
                color: "black",
                textAlignVertical: 'center'
              }}
            >
              {(item.itemname.length > 0) ? item.totalOunces.toFixed(3) : ""}
            </p>
          </div>
          <div
            style={{
              width: '20%',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              marginRight: 10,
            }}
          >
            <p

              style={{
                fontSize: 15,
                fontWeight: "600",
                color: "black",
                textAlignVertical: 'center',
              }}
            >
              {(item.type == "item") ? convertCostingValues(item.itemname, item.unitName, item.unitValue, item.Quantity, item.cost, item.totalOunces).toFixed(2) : convertCostingFloatGarnishValues(item.itemname, item.unitName, item.unitValue, item.Quantity, item.cost, item.totalOunces).toFixed(3)}
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "blue",
            opacity: 0.5,
            width: '100%',
            height: 1
          }}
        />
      </>
    );
  }

  return (
    <>
      <div
        style={{
          flexDirection: "row",
          display: 'flex',
          alignItems: "center",
          //justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => {
            navigate(-1)
          }}>
          <AiOutlineArrowLeft
            style={{
              width: 20,
              height: 20,
              color: "#fff",
            }}
          /></Button>
        <p
          style={{
            fontSize: 20,
            color: "white",
            fontWeight: "400",
            width: '90%',
            textAlign: 'center',
            //backgroundColor:'red'
          }}
        >
          {location.state.headername}
        </p>

      </div>
      <div
        style={{
          flex: 1,
          backgroundColor: "white",
          //top: 10,
        }}
      >
        <div style={{
          height: 145,
        }}>
          <div
            style={{
              flexDirection: "row",
              display: 'flex',
            }}
          >
            <div
              style={{
                backgroundColor: "#434343",
                justifyContent: "center",
                alignItems: "center",
                width: '20%',
                borderWidth: 1,
                borderColor: "#8e9299",
                height: 70,
                display: 'flex'
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  color: "white",
                  fontWeight: "700",
                }}
              >
                Total Cost
              </p>
            </div>
            <div style={{
              width: '80%',
              height: 70,
            }}>
              <div style={{
                width: '100%',
                display: 'flex',
                backgroundColor: 'white',
                alignItems: "center",
                justifyContent: 'space-between',
              }}>
                <div
                  style={{
                    backgroundColor: "#3C3A4E",
                    alignItems: "center",
                    justifyContent: 'center',
                    width: '33.3%',
                    height: 35,
                    display: 'flex'
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: "white",
                      fontWeight: "300",
                    }}
                  >
                    Price based on
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#3C3A4E",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '33.3%',
                    display: 'flex',
                    height: 35,
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: "white",
                    }}
                  >
                    Cost Percentage
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#3C3A4E",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '33.3%',
                    height: 35,
                    display: 'flex'

                  }}
                >
                  <p

                    style={{
                      fontSize: 14,
                      color: "white",
                      fontWeight: "400",
                    }}
                  >
                    Menu Price
                  </p>
                </div>
              </div>

              <div style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                width: '100%',
              }}>
                <div
                  style={{
                    // backgroundColor:"#61656d",
                    justifyContent: "center",
                    alignItems: "center",
                    display: 'flex',
                    width: '33.3%',
                    borderWidth: 1,
                    borderColor: "#8e9299",
                    height: 35,

                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: "black",
                      fontWeight: "300",
                    }}
                  >
                    percentage
                  </p>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: 'flex',
                    width: '33.3%',
                    height: 35,
                  }}
                >

                  <NumericFormat
                    value={CostPercentageText}
                    placeholder="0.00%"
                    suffix="%"
                    
                    onValueChange={(values, sourceInfo) => {
                      var percentage = values.floatValue;
                      if (percentage == null) {
                        setPercentageMenuPriceText(0.0)
                        localStorage.setItem('percentage_' + dociID, JSON.stringify(0))
                      }
                      else {
                        setPercentageMenuPriceText(((Totalcost / percentage) * 100))
                        localStorage.setItem('percentage_' + dociID, JSON.stringify(percentage))
                      }
                    }}
                  />


                </div>
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: 'flex',
                    width: '33.3%',
                    height: 35,
                  }}
                >

                  <NumericFormat
                    disabled={true}
                    placeholder="$0.00"
                    value={PercentageMenuPriceText.toFixed(2)}
                    prefix="$"
                  // onValueChange={(values, sourceInfo) => {
                  //   console.log(values, sourceInfo);
                  // }}
                  />


                </div>
              </div>

            </div>


          </div>
          <div
            style={{
              display: 'flex',
              //justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: 'flex',
                width: '20%',
                borderWidth: 1,
                borderColor: "#8e9299",
                height: 70,
              }}
            >
              <p
                style={{
                  fontSize: 24,
                  color: "black",
                  fontWeight: "400",
                }}
              >
                ${totalCostText}
              </p>
              {/* <NumericFormat
                    placeholder="$0.00"
                    value={totalCostText}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      console.log(values, sourceInfo);
                    }}
                  /> */}

            </div>

            <div style={{
              height: 70,
              width: '80%',
              //backgroundColor:'red'
            }}>
              <div style={{
                justifyContent: 'space-between',
                alignItems: "center",
                display: 'flex',
                width: '100%',
                backgroundColor: 'white'
              }}>
                <div
                  style={{
                    backgroundColor: "#3C3A4E",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '33.3%',
                    display: 'flex',
                    height: 35,
                    //left: 1
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: "white",
                      fontWeight: "300",
                    }}
                  >
                    Cost based on
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#3C3A4E",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '33.3%',
                    display: 'flex',
                    height: 35,
                    // left: 2
                  }}
                >
                  <p
                    style={{
                      fontSize: 13,
                      color: "white",
                    }}
                  >
                    Menu Price
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#3C3A4E",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '33.3%',
                    display: 'flex',
                    height: 35,
                  }}
                >
                  <p
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={{
                      fontSize: 15,
                      color: "white",
                      fontWeight: "400",
                      //right: 5,
                      textAlign: 'center'
                    }}
                  >
                    Cost Percentage
                  </p>
                </div>
              </div>

              <div style={{
                ustifyContent: 'space-between',
                alignItems: "center",
                display: 'flex',
                width: '100%',
                backgroundColor: 'white'
              }}>
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: '33.3%',
                    display: 'flex',
                    borderWidth: 1,
                    borderColor: "#8e9299",
                    height: 35,
                    //left: 1
                  }}
                >
                  <p
                    style={{
                      fontSize: 13,
                      color: "black",
                      fontWeight: "300",
                    }}
                  >
                    Menu Price
                  </p>
                </div>
                <div
                  style={{
                    // backgroundColor:"#61656d",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '33.3%',
                    display: 'flex',
                    borderWidth: 1,
                    borderColor: "#8e9299",
                    // right: 10,
                    height: 35,
                    // left: 2
                  }}
                >

                  <NumericFormat
                    placeholder="$0.00"
                    value={totalCostText.toFixed(3)}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      var currency = values.floatValue;
                      
                      if (currency == null) {
                        console.log('if ', currency);
                        setMenuPricePercentageText(0)
                        localStorage.setItem('currency_' + dociID, JSON.stringify(0))
                      }
                      else {
                        console.log('else ', currency);
                        setMenuPricePercentageText(((Totalcost / currency) * 100))
                        localStorage.setItem('currency_' + dociID, JSON.stringify(currency))
                      }
                    }}
                  />


                </div>
                <div
                  style={{
                     backgroundColor:"#61656d",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '33.3%',
                    display: 'flex',
                    height: 35,
                    borderWidth: 1,
                    borderColor: "#8e9299",
                    //left: 3
                    // right: 10,
                  }}
                >

                  <NumericFormat
                    disabled={true}
                    placeholder="0.00%"
                    value={MenuPricePercentageText.toFixed(2)}
                    suffix="%"
                  // onValueChange={(values, sourceInfo) => {
                  //   console.log(values, sourceInfo);
                  // }}
                  />



                </div>
              </div>

            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "red",
            opacity: 0.5,
            width: '100%',
            height: 1
          }}
        />
        <div
          style={{
            flexDirection: "row",
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor:"grey",
            height: 40,


          }}
        >
          <div
            style={{
              width: '40%',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              // height: 30,
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "400",
                color: "black",
              }}
            >
              Item
            </p>
          </div>
          <div
            style={{
              width: '20%',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              //height: 40,
            }}
          >
            <p
              style={{
                fontSize: 13,
                fontWeight: "400",
                color: "black",
                textAlign: 'center'
                // left: 18,
              }}
            >
              Cost/Ounces ($)
            </p>
          </div>
          <div
            style={{
              width: '20%',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              //height: 40,
            }}
          >
            <p
              style={{
                fontSize: 13,
                fontWeight: "400",
                color: "black",
              }}
            >
              Total Ounces
            </p>
          </div>

          <div
            style={{
              width: '20%',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              //height: 40,
              // marginRight: 10,
            }}
          >
            <p
              style={{
                fontSize: 13,
                fontWeight: "400",
                color: "black",
                // width:80,
                // height:80
              }}
            >
              Total {'\n'}Cost
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "blue",
            opacity: 0.5,
            width: '100%',
            height: 1
          }}
        />
        <div
          style={{
            backgroundColor: "white",
            flex: 1,
          }}
        >
          <FlatList
            list={render}
            renderItem={renderFlatItem}
          ></FlatList>
        </div>
      </div>


      <Modal
        isOpen={recipeModal}
        onRequestClose={() => {
          setrecipeModal(false)
        }}
        style={customStyles}
        contentLabel=""
      >
        <h2 >Add Recipe name</h2>
        <div
          style={{
            // width: "90%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'

          }}
        >
          <TextField
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: '#f7a828',
              }
            }}
            inputProps={{
              style: {
                height: "5px",
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: 10, }}
            required
            value={recipeName}
            id="recipe"
            name="recipe"
            autoFocus
            hiddenLabel={true}
            onChange={event => {
              const { value } = event.target;
              setrecipeName(value);
            }}
          />
        </div>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setrecipeModal(false);
            // uploadDataToServer(recipeName)
          }}
        >
          Submit
        </Button>

        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {


          }}
        >
          Cancel
        </Button>
      </Modal>

      <ActionSheet ref={finishRef}>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            finishRef.current.close();
            setfinishWithOrder(false)
            setTimeout(() => {
              setrecipeModal(true);
            }, 200);
          }}
        >
          Finish Recipe
        </Button>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            finishRef.current.close();
            setfinishWithOrder(true)
            setTimeout(() => {
              setrecipeModal(true);
            }, 200);
          }}
        >
          Finish & Order Recipe
        </Button>

        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            finishRef.current.close();
          }}
        >
          Cancel
        </Button>
      </ActionSheet>

    </>
  )
}

export default CostRecipe;
