import React from "react";

import { AiOutlineClose,AiOutlineArrowLeft } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();

  return (
    <>
     <div
        style={{
          width:'20%',
          // alignItems: "flex-end",
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
         //marginLeft: "10%",
          // marginRight: 20,
           marginTop: 10,
        }}
      >
        <Button
          onClick={() => {
            navigate(-1)
          }}>
          <AiOutlineArrowLeft
            style={{
              width: 20,
              height: 20,

              color: "#fff",
            }}
          /></Button>
      </div>
    <div
      className="App"
      style={{
        flex: 1,
        backgroundColor: "#473929",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1 / 11,
          backgroundColor: "#fff",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "30px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div style={{}}>
            <p style={{ fontSize: 15 }}>About</p>
          </div>
        </div>
        <div
          className="App"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <br />
        </div>
      </div>
      <div
        style={{
          flex: 5 / 11,
        }}
      >
        <div
          style={{
            justifyContent: "center",
            backgroundColor: "#473929",
          }}
        >
          <p style={{ fontSize: 20, color: "#fff" }}>CraftIQ</p>
          <p style={{ fontSize: 20, color: "#ddda" }}>Version 1.0.2(5.0)</p>
          <p style={{ fontSize: 20, color: "#dddd",marginTop:"-1%" }}>
            @ 2022 The Craft IQ LLC
          </p>
          
        </div>
      </div>
      <div
        style={{
          flex: 5 / 11,
          backgroundColor: "#ffd",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            marginLeft:"10%",
            marginRight:"10%"
          }}
        >
          <p style={{ fontSize: 20, color: "#383837" }}>
            Feel like you're always missing an ingredient searching for your
            next cocktail? There are so many ways to find our perfect cocktail
            using our app!. Don't miss out on an opportunity to create an amazing
            cocktail experience for friends and family.
          </p>
          <br />
        </div>
      </div>
    </div>
    </>
  );
};

export default About;
