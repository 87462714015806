
export function VolumeFromArray() {
  return [
    { label: "ml", value: "ml" },
    { label: "187 ml", value: "187 ml" },
    { label: "375 ml", value: "375 ml" },
    { label: "750 ml", value: "750 ml" },
    { label: "Liter", value: "Liter" },
    { label: "1.5 Liter", value: "1.5 Liter" },
    { label: "1.75 Liter", value: "1.75 Liter" },
    { label: "Ounce", value: "Ounce" },
    { label: "Cup", value: "Cup" },
    { label: "Pint", value: "Pint" },
    { label: "Quart", value: "Quart" },
    { label: "Gallon", value: "Gallon" },
    { label: "Tea Spoon", value: "Tea Spoon" },
    { label: "Table Spoon", value: "Table Spoon" },
    { label: "Grams", value: "Grams" },

  ]
}

export function BarFromArray() {
  return[
    { label: "Lemon", value: "Lemon" },
    { label: "Grapefruit", value: "Grapefruit" },
    { label: "Orange", value: "Orange" },
    { label: "Lime", value: "Lime" },
    { label: "Pineapple", value: "Pineapple" },
    { label: "Strawberries", value: "Strawberries" },
    { label: "Float", value: "Float" },
    { label: "Dash", value: "Dash" },
    { label: "Splash", value: "Splash" },
    { label: "Rinse", value: "Rinse" },
    { label: "egg white", value: "egg white" },
    { label: "egg yolk", value: "egg yolk" },
    { label: "Whole Egg", value: "Whole Egg" },
    { label: "Bar Spoon", value: "Bar Spoon" },
    { label: "Spritz", value: "Spritz" },
    { label: "Lime juice", value: "Lime juice" },
    { label: "Lemon juice", value: "Lemon juice" },
    { label: "Orange juice", value: "Orange juice" },
    { label: "Grapefruit juice", value: "Grapefruit juice" },
  ]
}

export function VolumeToArray() {

  return[
    { label: "ml", value: "ml" },
    { label: "187 ml", value: "187 ml" },
    { label: "375 ml", value: "375 ml" },
    { label: "750 ml", value: "750 ml" },
    { label: "Liter", value: "Liter" },
    { label: "1.5 Liter", value: "1.5 Liter" },
    { label: "1.75 Liter", value: "1.75 Liter" },
    { label: "Grams", value: "Grams" },
    { label: "Ounce", value: "Ounce" },
    { label: "Cup", value: "Cup" },
    { label: "Pint", value: "Pint" },
    { label: "Quart", value: "Quart" },
    { label: "Gallon", value: "Gallon" },
    { label: "Tea Spoon", value: "Tea Spoon" },
    { label: "Table Spoon", value: "Table Spoon" },
  ]
}

export function BarToArray() {
  return[
    { label: "wedge", value: "wedge" },
    { label: "half", value: "half" },
    { label: "ea", value: "ea" },
    { label: "quarter", value: "quarter" },
    { label: "wheel", value: "wheel" },
    { label: "Half Moon", value: "Half Moon" },
    { label: "Slice", value: "Slice" },
    { label: "Peel", value: "Peel" },
    { label: "Zest", value: "Zest" },
    { label: "Twist", value: "Twist" },
    { label: "Ounce", value: "Ounce" },
    { label: "ml", value: "ml" },
    { label: "Spiral", value: "Spiral" },
  ]
}

export function convertBarValues(from, To, value) {
    if (from == "Lemon" && To == "wedge") {
      return value * 6;
    }
    if (from == "Grapefruit" && To == "half") {
      return value * 2;
    }
    if (from == "Lemon" && To == "ea") {
      return value * 1;
    }
    if (from == "Grapefruit" && To == "ea") {
      return value * 1;
    }
    if (from == "Orange" && To == "ea") {
      return value * 1;
    }
    if (from == "Grapefruit" && To == "Quarter") {
      return value * 4;
    }
    if (from == "Orange" && To == "half") {
      return value * 2;
    }
    if (from == "Orange" && To == "quarter") {
      return value * 4;
    }
    if (from == "Lemon" && To == "half") {
      return value * 2;
    }
    if (from == "Lemon" && To == "Quarter") {
      return value * 4;
    }
    if (from == "Lemon" && To == "half") {
      return value * 2;
    }
    if (from == "Lemon" && To == "Quarter") {
      return value * 4;
    }
    if (from == "Orange" && To == "Quarter") {
      return value * 4;
    }
    if (from == "Orange" && To == "Half") {
      return value * 2;
    }
    if (from == "Lime" && To == "wedge") {
      return value * 6;
    }
    if (from == "Lime" && To == "ea") {
      return value * 1;
    }
    if (from == "Orange" && To == "wedge") {
      return value * 8;
    }
    if (from == "Grapefruit" && To == "wedge") {
      return value * 8;
    }
    if (from == "Pineapple" && To == "wedge") {
      return value * 36;
    }
    if (from == "Lemon" && To == "wheel") {
      return value * 5;
    }
    if (from == "Lime" && To == "wheel") {
      return value * 4;
    }
    if (from == "Orange" && To == "wheel") {
      return value * 6;
    }
    if (from == "Grapefruit" && To == "wheel") {
      return value * 6;
    }
    if (from == "Pineapple" && To == "wheel") {
      return value * 6;
    }
    if (from == "Strawberries" && To == "wheel") {
      return value * 4;
    }
    if (from == "Strawberries" && To == "ea") {
      return value * 1;
    }
    if (from == "Lemon" && To == "Half Moon") {
      return value * 10;
    }
    if (from == "Lime" && To == "Half Moon") {
      return value * 8;
    }
    if (from == "Orange" && To == "Half Moon") {
      return value * 8;
    }
    if (from == "Grapefruit" && To == "Half Moon") {
      return value * 12;
    }
    if (from == "Pineapple" && To == "Half Moon") {
      return value * 12;
    }
    if (from == "Lemon" && To == "Slice") {
      return value * 10;
    }
    if (from == "Strawberries" && To == "Slice") {
      return value * 4;
    }
    if (from == "Lime" && To == "Slice") {
      return value * 8;
    }
    if (from == "Orange" && To == "Slice") {
      return value * 8;
    }
    if (from == "Grapefruit" && To == "Slice") {
      return value * 12;
    }
    if (from == "Pineapple" && To == "Slice") {
      return value * 12;
    }
    if (from == "Lemon" && To == "Peel") {
      return value * 4;
    }
    if (from == "Lime" && To == "Peel") {
      return value * 3;
    }
    if (from == "Orange" && To == "Peel") {
      return value * 6;
    }
    if (from == "Grapefruit" && To == "Peel") {
      return value * 6;
    }
    if (from == "Lemon" && To == "Zest") {
      return value * 4;
    }
    if (from == "Lime" && To == "Zest") {
      return value * 3;
    }
    if (from == "Orange" && To == "Zest") {
      return value * 6;
    }
    if (from == "Grapefruit" && To == "Zest") {
      return value * 6;
    }
    if (from == "Lemon" && To == "Twist") {
      return value * 4;
    }
    if (from == "Lime" && To == "Twist") {
      return value * 3;
    }
    if (from == "Orange" && To == "Twist") {
      return value * 8;
    }
    if (from == "Grapefruit" && To == "Twist") {
      return value * 8;
    }
    if (from == "Float" && To == "Ounce") {
      return value * 0.5;
    }
    if (from == "Dash" && To == "Ounce") {
      return value * 0.031;
    }
    if (from == "Dash" && To == "ml") {
      return value * 0.92;
    }
    if (from == "Splash" && To == "ml") {
      return value * 5.91;
    }
    if (from == "Splash" && To == "Ounce") {
      return value * 0.2;
    }
    if (from == "Rinse" && To == "ml") {
      return value * 0.37;
    }
    if (from == "Rinse" && To == "Ounce") {
      return value * 0.125;
    }
    if (from == "egg white" && To == "ml") {
      return value * 29.75;
    }
    if (from == "egg white" && To == "Ounce") {
      return value * 1;
    }
    if (from == "egg yolk" && To == "ml") {
      return value * 17.742;
    }
    if (from == "egg yolk" && To == "Ounce") {
      return value * 0.6;
    }
    if (from == "Whole Egg" && To == "ml") {
      return value * 44.355;
    }
    if (from == "Whole Egg" && To == "Ounce") {
      return value * 1.5;
    }
    if (from == "Bar Spoon" && To == "Ounce") {
      return value * 0.125;
    }
    if (from == "Bar Spoon" && To == "ml") {
      return value * 0.37;
    }
    if (from == "Float" && To == "ml") {
      return value * 0.37 * 0.5;
    }
    if (from == "Spritz" && To == "Ounce") {
      return value * 0.003;
    }
    if (from == "Spritz" && To == "ml") {
      return value * 0.1;
    }
    if (from == "Lime" && To == "Spiral") {
      return value * 1;
    }
    if (from == "Lemon" && To == "Spiral") {
      return value * 1;
    }
    if (from == "Orange" && To == "Spiral") {
      return value * 2;
    }
    if (from == "Grapefruit" && To == "Spiral") {
      return value * 3;
    }
    if (from == "Lime" && To == "Ounce") {
      return value * 1;
    }
    if (from == "Lemon" && To == "Ounce") {
      return value * 2.25;
    }
    if (from == "Orange" && To == "Ounce") {
      return value * 2.5;
    }
    if (from == "Grapefruit" && To == "Ounce") {
      return value * 6;
    }
    if (from == "Lime" && To == "ml") {
      return value * 29.5735;
    }
    if (from == "Lemon" && To == "ml") {
      return value * 66.5404;
    }
    if (from == "Orange" && To == "ml") {
      return value * 73.9338;
    }
    if (from == "Grapefruit juice" && To == "ml") {
      return value * 177.441;
    }
    if (from == "Lime juice" && To == "Ounce") {
      return value * 1;
    }
    if (from == "Lemon juice" && To == "Ounce") {
      return value * 2.25;
    }
    if (from == "Orange juice" && To == "Ounce") {
      return value * 2.5;
    }
    if (from == "Grapefruit Juice" && To == "Ounce") {
      return value * 6;
    }
    if (from == "Lime juice" && To == "ml") {
      return value * 29.5735;
    }
    if (from == "Lemon juice" && To == "ml") {
      return value * 66.5404;
    }
    if (from == "Orange juice" && To == "ml") {
      return value * 73.9338;
    }
    if (from == "Grapefruit juice" && To == "ml") {
      return value * 177.441;
    } else {
      return 0.0;
    }
  }

 export function convertVolumeValues(from, To, value) {
  console.log(from + To + value);
    if (from == "ml" && To == "ml") {
      return 1 * value;
    }
    if (from == "ml" && To == "187 ml") {
      return value / 187;
    }
    if (from == "ml" && To == "375 ml") {
      return value / 375;
    }
    if (from == "ml" && To == "750 ml") {
      return value / 750;
    }
    if (from == "ml" && To == "Liter") {
      return value / 1000;
    }
    if (from == "ml" && To == "1.5 Liter") {
      return value / 1500;
    }
    if (from == "ml" && To == "1.75 Liter") {
      return value / 1750;
    }
    if (from == "ml" && To == "Grams") {
      return 1 * value;
    }
    if (from == "ml" && To == "Ounce") {
      return value * 0.033814;
    }
    if (from == "ml" && To == "Cup") {
      return (value * 0.033814) / 8;
    }
    if (from == "ml" && To == "Pint") {
      return (value * 0.033814) / 16;
    }
    if (from == "ml" && To == "Quart") {
      return (value * 0.033814) / 32;
    }
    if (from == "ml" && To == "Gallon") {
      return (value * 0.033814) / 128;
    }
    if (from == "ml" && To == "Tea Spoon") {
      return value * 0.2028;
    }
    if (from == "ml" && To == "Table Spoon") {
      return value * 0.0676;
    }
    if (from == "187 ml" && To == "ml") {
      return value * 187;
    }
    if (from == "187 ml" && To == "187 ml") {
      return value * 1;
    }
    if (from == "187 ml" && To == "375 ml") {
      return (187 / 375) * value;
    }
    if (from == "187 ml" && To == "750 ml") {
      return (187 / 750) * value;
    }
    if (from == "187 ml" && To == "Liter") {
      return (187 / 1000) * value;
    }
    if (from == "187 ml" && To == "1.5 Liter") {
      return (187 / 1500) * value;
    }
    if (from == "187 ml" && To == "1.75 Liter") {
      return (187 / 1750) * value;
    }
    if (from == "187 ml" && To == "Grams") {
      return 187 * value;
    }
    if (from == "187 ml" && To == "Ounce") {
      return value * 6.32322;
    }
    if (from == "187 ml" && To == "Cup") {
      return (value * 6.32322) / 8;
    }
    if (from == "187 ml" && To == "Pint") {
      return (value * 6.32322) / 16;
    }
    if (from == "187 ml" && To == "Quart") {
      return (value * 6.32322) / 32;
    }
    if (from == "187 ml" && To == "Gallon") {
      return (value * 6.32322) / 128;
    }
    if (from == "187 ml" && To == "Tea Spoon") {
      return value * 37.9393;
    }
    if (from == "187 ml" && To == "Table Spoon") {
      return value * 12.6464;
    }
    if (from == "375 ml" && To == "ml") {
      return value * 375;
    }
    if (from == "375 ml" && To == "187 ml") {
      return (375 / 187) * value;
    }
    if (from == "375 ml" && To == "375 ml") {
      return (375 / 375) * value;
    }
    if (from == "375 ml" && To == "750 ml") {
      return (375 / 750) * value;
    }
    if (from == "375 ml" && To == "Liter") {
      return (375 / 1000) * value;
    }
    if (from == "375 ml" && To == "1.5 Liter") {
      return (375 / 1500) * value;
    }
    if (from == "375 ml" && To == "1.75 Liter") {
      return (375 / 1750) * value;
    }
    if (from == "375 ml" && To == "Grams") {
      return 375 * value;
    }
    if (from == "375 ml" && To == "Ounce") {
      return value * 12.6803;
    }
    if (from == "375 ml" && To == "Cup") {
      return (value * 12.6803) / 8;
    }
    if (from == "375 ml" && To == "Pint") {
      return (value * 12.6803) / 16;
    }
    if (from == "375 ml" && To == "Quart") {
      return (value * 12.6803) / 32;
    }
    if (from == "375 ml" && To == "Gallon") {
      return (value * 12.6803) / 128;
    }
    if (from == "375 ml" && To == "Tea Spoon") {
      return value * 76.0816;
    }
    if (from == "375 ml" && To == "Table Spoon") {
      return value * 25.3605;
    }
    if (from == "750 ml" && To == "ml") {
      return value * 750;
    }
    if (from == "750 ml" && To == "187 ml") {
      return (750 / 187) * value;
    }
    if (from == "750 ml" && To == "375 ml") {
      return (750 / 375) * value;
    }
    if (from == "750 ml" && To == "750 ml") {
      return (750 / 750) * value;
    }
    if (from == "750 ml" && To == "Liter") {
      return (750 / 1000) * value;
    }
    if (from == "750 ml" && To == "1.5 Liter") {
      return (750 / 1500) * value;
    }
    if (from == "750 ml" && To == "1.75 Liter") {
      return (750 / 1750) * value;
    }
    if (from == "750 ml" && To == "Grams") {
      return 750 * value;
    }
    if (from == "750 ml" && To == "Ounce") {
      return value * 25.3605;
    }
    if (from == "750 ml" && To == "Cup") {
      return (value * 25.3605) / 8;
    }
    if (from == "750 ml" && To == "Pint") {
      return (value * 25.3605) / 16;
    }
    if (from == "750 ml" && To == "Quart") {
      return (value * 25.3605) / 32;
    }
    if (from == "750 ml" && To == "Gallon") {
      return (value * 25.3605) / 128;
    }
    if (from == "750 ml" && To == "Tea Spoon") {
      return value * 152.163;
    }
    if (from == "750 ml" && To == "Table Spoon") {
      return value * 50.721;
    }
    if (from == "Liter" && To == "ml") {
      return value * 1000;
    }
    if (from == "Liter" && To == "187 ml") {
      return (1000 / 187) * value;
    }
    if (from == "Liter" && To == "375 ml") {
      return (1000 / 375) * value;
    }
    if (from == "Liter" && To == "750 ml") {
      return (1000 / 750) * value;
    }
    if (from == "Liter" && To == "Liter") {
      return value * 1;
    }
    if (from == "Liter" && To == "1.5 Liter") {
      return (1000 / 1500) * value;
    }
    if (from == "Liter" && To == "1.75 Liter") {
      return (1000 / 1750) * value;
    }
    if (from == "Liter" && To == "Grams") {
      return 1000 * value;
    }
    if (from == "Liter" && To == "Ounce") {
      return value * 33.814;
    }
    if (from == "Liter" && To == "Cup") {
      return (value * 33.814) / 8;
    }
    if (from == "Liter" && To == "Pint") {
      return (value * 33.814) / 16;
    }
    if (from == "Liter" && To == "Quart") {
      return (value * 33.814) / 32;
    }
    if (from == "Liter" && To == "Gallon") {
      return (value * 33.814) / 128;
    }
    if (from == "Liter" && To == "Tea Spoon") {
      return value * 202.884;
    }
    if (from == "Liter" && To == "Table Spoon") {
      return value * 67.628;
    }
    if (from == "1.5 Liter" && To == "ml") {
      return value * 1500;
    }
    if (from == "1.5 Liter" && To == "187 ml") {
      return (1500 / 187) * value;
    }
    if (from == "1.5 Liter" && To == "375 ml") {
      return (1500 / 375) * value;
    }
    if (from == "1.5 Liter" && To == "750 ml") {
      return (1500 / 750) * value;
    }
    if (from == "1.5 Liter" && To == "Liter") {
      return (1500 / 1000) * value;
    }
    if (from == "1.5 Liter" && To == "1.5 Liter") {
      return 1 * value;
    }
    if (from == "1.5 Liter" && To == "1.75 Liter") {
      return (1500 / 1750) * value;
    }
    if (from == "1.5 Liter" && To == "Grams") {
      return 1500 * value;
    }
    if (from == "1.5 Liter" && To == "Ounce") {
      return value * 50.721;
    }
    if (from == "1.5 Liter" && To == "Cup") {
      return (value * 50.721) / 8;
    }
    if (from == "1.5 Liter" && To == "Pint") {
      return (value * 50.721) / 16;
    }
    if (from == "1.5 Liter" && To == "Quart") {
      return (value * 50.721) / 32;
    }
    if (from == "1.5 Liter" && To == "Gallon") {
      return (value * 50.721) / 128;
    }
    if (from == "1.5 Liter" && To == "Tea Spoon") {
      return value * 304.326;
    }
    if (from == "1.5 Liter" && To == "Table Spoon") {
      return value * 101.442;
    }
    if (from == "1.75 Liter" && To == "ml") {
      return value * 1750;
    }
    if (from == "1.75 Liter" && To == "187 ml") {
      return (1750 / 187) * value;
    }
    if (from == "1.75 Liter" && To == "375 ml") {
      return (1750 / 375) * value;
    }
    if (from == "1.75 Liter" && To == "750 ml") {
      return (1750 / 750) * value;
    }
    if (from == "1.75 Liter" && To == "Liter") {
      return (1750 / 1000) * value;
    }
    if (from == "1.75 Liter" && To == "1.5 Liter") {
      return (1750 / 1500) * value;
    }
    if (from == "1.75 Liter" && To == "1.75 Liter") {
      return value * 1;
    }
    if (from == "1.75 Liter" && To == "Grams") {
      return 1750 * value;
    }
    if (from == "1.75 Liter" && To == "Ounce") {
      return value * 59.17454;
    }
    if (from == "1.75 Liter" && To == "Cup") {
      return (value * 59.17454) / 8;
    }
    if (from == "1.75 Liter" && To == "Pint") {
      return (value * 59.17454) / 16;
    }
    if (from == "1.75 Liter" && To == "Quart") {
      return (value * 59.17454) / 32;
    }
    if (from == "1.75 Liter" && To == "Gallon") {
      return (value * 59.17454) / 128;
    }
    if (from == "1.75 Liter" && To == "Tea Spoon") {
      return value * 355.0473;
    }
    if (from == "1.75 Liter" && To == "Table Spoon") {
      return value * 118.349;
    }
    if (from == "Ounce" && To == "ml") {
      return value * 29.5735;
    }
    if (from == "Ounce" && To == "187 ml") {
      return (29.5735 / 187) * value;
    }
    if (from == "Ounce" && To == "375 ml") {
      return (29.5735 / 375) * value;
    }
    if (from == "Ounce" && To == "750 ml") {
      return (29.5735 / 750) * value;
    }
    if (from == "Ounce" && To == "Liter") {
      return (29.5735 / 1000) * value;
    }
    if (from == "Ounce" && To == "1.5 Liter") {
      return (29.5735 / 1500) * value;
    }
    if (from == "Ounce" && To == "1.75 Liter") {
      return (29.5735 / 1750) * value;
    }
    if (from == "Ounce" && To == "Grams") {
      return 29.5735 * value;
    }
    if (from == "Ounce" && To == "Ounce") {
      return value * 1;
    }
    if (from == "Ounce" && To == "Cup") {
      return value / 8;
    }
    if (from == "Ounce" && To == "Pint") {
      return value / 16;
    }
    if (from == "Ounce" && To == "Quart") {
      return value / 32;
    }
    if (from == "Ounce" && To == "Gallon") {
      return value / 128;
    }
    if (from == "Ounce" && To == "Tea Spoon") {
      return value * 6;
    }
    if (from == "Ounce" && To == "Table Spoon") {
      return value * 2;
    }
    if (from == "Cup" && To == "ml") {
      return value * 240;
    }
    if (from == "Cup" && To == "187 ml") {
      return (value * 240) / 187;
    }
    if (from == "Cup" && To == "375 ml") {
      return (value * 240) / 375;
    }
    if (from == "Cup" && To == "750 ml") {
      return (value * 240) / 750;
    }
    if (from == "Cup" && To == "Liter") {
      return (value * 240) / 1000;
    }
    if (from == "Cup" && To == "1.5 Liter") {
      return (value * 240) / 1500;
    }
    if (from == "Cup" && To == "1.75 Liter") {
      return (value * 240) / 1750;
    }
    if (from == "Cup" && To == "Grams") {
      return 240 * value;
    }
    if (from == "Cup" && To == "Ounce") {
      return value * 8;
    }
    if (from == "Cup" && To == "Cup") {
      return value * 1;
    }
    if (from == "Cup" && To == "Pint") {
      return value * 0.50721;
    }
    if (from == "Cup" && To == "Quart") {
      return value * 0.2536;
    }
    if (from == "Cup" && To == "Gallon") {
      return value * 0.0634;
    }
    if (from == "Cup" && To == "Tea Spoon") {
      return value * 48.692;
    }
    if (from == "Cup" && To == "Table Spoon") {
      return value * 16.23;
    }
    if (from == "Pint" && To == "ml") {
      return value * 473.176;
    }
    if (from == "Pint" && To == "187 ml") {
      return (value * 473.176) / 187;
    }
    if (from == "Pint" && To == "375 ml") {
      return (value * 473.176) / 375;
    }
    if (from == "Pint" && To == "750 ml") {
      return (value * 473.176) / 750;
    }
    if (from == "Pint" && To == "Liter") {
      return (value * 473.176) / 1000;
    }
    if (from == "Pint" && To == "1.5 Liter") {
      return (value * 473.176) / 1500;
    }
    if (from == "Pint" && To == "1.75 Liter") {
      return (value * 473.176) / 1750;
    }
    if (from == "Pint" && To == "Grams") {
      return 473.176 * value;
    }
    if (from == "Pint" && To == "Ounce") {
      return value * 16;
    }
    if (from == "Pint" && To == "Cup") {
      return value * 2;
    }
    if (from == "Pint" && To == "Pint") {
      return value * 1;
    }
    if (from == "Pint" && To == "Quart") {
      return value * 0.5;
    }
    if (from == "Pint" && To == "Gallon") {
      return value * 0.125;
    }
    if (from == "Pint" && To == "Tea Spoon") {
      return value * 96;
    }
    if (from == "Pint" && To == "Table Spoon") {
      return value * 32;
    }
    if (from == "Quart" && To == "ml") {
      return value * 946.353;
    }
    if (from == "Quart" && To == "187 ml") {
      return (value * 946.353) / 187;
    }
    if (from == "Quart" && To == "375 ml") {
      return (value * 946.353) / 375;
    }
    if (from == "Quart" && To == "750 ml") {
      return (value * 946.353) / 750;
    }
    if (from == "Quart" && To == "Liter") {
      return (value * 946.353) / 1000;
    }
    if (from == "Quart" && To == "1.5 Liter") {
      return (value * 946.353) / 1500;
    }
    if (from == "Quart" && To == "1.75 Liter") {
      return (value * 946.353) / 1750;
    }
    if (from == "Quart" && To == "Grams") {
      return 946.353 * value;
    }
    if (from == "Quart" && To == "Ounce") {
      return value * 32;
    }
    if (from == "Quart" && To == "Cup") {
      return value * 3.94314;
    }
    if (from == "Quart" && To == "Pint") {
      return value * 2;
    }
    if (from == "Quart" && To == "Quart") {
      return value * 1;
    }
    if (from == "Quart" && To == "Gallon") {
      return value * 0.25;
    }
    if (from == "Quart" && To == "Tea Spoon") {
      return value * 192;
    }
    if (from == "Quart" && To == "Table Spoon") {
      return value * 64;
    }
    if (from == "Gallon" && To == "ml") {
      return value * 3785.41;
    }
    if (from == "Gallon" && To == "187 ml") {
      return (value * 3785.41) / 187;
    }
    if (from == "Gallon" && To == "375 ml") {
      return (value * 3785.41) / 375;
    }
    if (from == "Gallon" && To == "750 ml") {
      return (value * 3785.41) / 750;
    }
    if (from == "Gallon" && To == "Liter") {
      return (value * 3785.41) / 1000;
    }
    if (from == "Gallon" && To == "1.5 Liter") {
      return (value * 3785.41) / 1500;
    }
    if (from == "Gallon" && To == "1.75 Liter") {
      return (value * 3785.41) / 1750;
    }
    if (from == "Gallon" && To == "Grams") {
      return 3785.41 * value;
    }
    if (from == "Gallon" && To == "Ounce") {
      return value * 128;
    }
    if (from == "Gallon" && To == "Cup") {
      return value * 15.7725;
    }
    if (from == "Gallon" && To == "Pint") {
      return value * 8;
    }
    if (from == "Gallon" && To == "Quart") {
      return value * 4;
    }
    if (from == "Gallon" && To == "Gallon") {
      return value * 1;
    }
    if (from == "Gallon" && To == "Tea Spoon") {
      return value * 768;
    }
    if (from == "Gallon" && To == "Table Spoon") {
      return value * 256;
    }
    if (from == "Tea Spoon" && To == "ml") {
      return value * 4.92892;
    }
    if (from == "Tea Spoon" && To == "187 ml") {
      return (value * 4.92892) / 187;
    }
    if (from == "Tea Spoon" && To == "375 ml") {
      return (value * 4.92892) / 375;
    }
    if (from == "Tea Spoon" && To == "750 ml") {
      return (value * 4.92892) / 750;
    }
    if (from == "Tea Spoon" && To == "Liter") {
      return (value * 4.92892) / 1000;
    }
    if (from == "Tea Spoon" && To == "1.5 Liter") {
      return (value * 4.92892) / 1500;
    }
    if (from == "Tea Spoon" && To == "1.75 Liter") {
      return (value * 4.92892) / 1750;
    }
    if (from == "Tea Spoon" && To == "Grams") {
      return 4.92892 * value;
    }
    if (from == "Tea Spoon" && To == "Ounce") {
      return value * 0.1666;
    }
    if (from == "Tea Spoon" && To == "Cup") {
      return (value * 0.1666) / 8;
    }
    if (from == "Tea Spoon" && To == "Pint") {
      return (value * 0.1666) / 16;
    }
    if (from == "Tea Spoon" && To == "Quart") {
      return (value * 0.1666) / 32;
    }
    if (from == "Tea Spoon" && To == "Gallon") {
      return (value * 0.1666) / 128;
    }
    if (from == "Tea Spoon" && To == "Tea Spoon") {
      return value * 1;
    }
    if (from == "Tea Spoon" && To == "Table Spoon") {
      return value * 0.3333;
    }
    if (from == "Table Spoon" && To == "ml") {
      return value * 14.7867;
    }
    if (from == "Table Spoon" && To == "187 ml") {
      return (value * 14.7867) / 187;
    }
    if (from == "Table Spoon" && To == "375 ml") {
      return (value * 14.7867) / 375;
    }
    if (from == "Table Spoon" && To == "750 ml") {
      return (value * 14.7867) / 750;
    }
    if (from == "Table Spoon" && To == "Liter") {
      return (value * 14.7867) / 1000;
    }
    if (from == "Table Spoon" && To == "1.5 Liter") {
      return (value * 14.7867) / 1500;
    }
    if (from == "Table Spoon" && To == "1.75 Liter") {
      return (value * 14.7867) / 1750;
    }
    if (from == "Table Spoon" && To == "Grams") {
      return 14.7867 * value;
    }
    if (from == "Table Spoon" && To == "Ounce") {
      return value * 0.5;
    }
    if (from == "Table Spoon" && To == "Cup") {
      return value * 0.0616;
    }
    if (from == "Table Spoon" && To == "Pint") {
      return value * 0.0312;
    }
    if (from == "Table Spoon" && To == "Quart") {
      return value * 0.0156;
    }
    if (from == "Table Spoon" && To == "Gallon") {
      return value * 0.0039;
    }
    if (from == "Table Spoon" && To == "Tea Spoon") {
      return value * 3;
    }
    if (from == "Table Spoon" && To == "Table Spoon") {
      return value * 1;
    }
    if (from == "Grams" && To == "ml") {
      return 1 * value;
    }
    if (from == "Grams" && To == "187 ml") {
      return value / 187;
    }
    if (from == "Grams" && To == "375 ml") {
      return value / 375;
    }
    if (from == "Grams" && To == "750 ml") {
      return value / 750;
    }
    if (from == "Grams" && To == "Liter") {
      return value / 1000;
    }
    if (from == "Grams" && To == "1.5 Liter") {
      return value / 1500;
    }
    if (from == "Grams" && To == "1.75 Liter") {
      return value / 1750;
    }
    if (from == "Grams" && To == "Grams") {
      return 1 * value;
    }
    if (from == "Grams" && To == "Ounce") {
      return value * 0.033814;
    }
    if (from == "Grams" && To == "Cup") {
      return (value * 0.033814) / 8;
    }
    if (from == "Grams" && To == "Pint") {
      return (value * 0.033814) / 16;
    }
    if (from == "Grams" && To == "Quart") {
      return (value * 0.033814) / 32;
    }
    if (from == "Grams" && To == "Gallon") {
      return (value * 0.033814) / 128;
    }
    if (from == "Grams" && To == "Tea Spoon") {
      return value * 0.2028;
    }
    if (from == "Grams" && To == "Table Spoon") {
      return value * 0.0676;
    } else {
      return 0;
    }
  }

export function genrateAssumedValue(batchType, UniteName, value, totalOunces, quantity) {

    if (batchType == 'Batch Size') {
        return value / totalOunces
    }
    if (batchType == 'By main item') {
        return quantity * value
    }
    return 0
}

export function convertItemValues(item, unitName, unit, value,totalDilution) {
    if (item == "Strawberries" && unitName == "Cup") {
        return value * 8
    }
    else if (item == "Lime" && unitName == "ea") {
        return value * 1
    }
    else if (item == "Lime" && unitName == "half") {
        return value * 0.5
    }
    else if (item == "Lime" && unitName == "Quarter") {
        return value * 4
    }
    else if (item == "Grapefruit" && unitName == "ea") {
        return value * 6
    }
    else if (item == "Grapefruit" && unitName == "half") {
        return value * 3
    }
    else if (item == "Grapefruit" && unitName == "Quarter") {
        return (value * 6) / 4
    }
    else if (item == "Lemon" && unitName == "ea") {
        return (value * 2.25)
    }
    else if (item == "Lemon" && unitName == "half") {
        return (value * 2.25) / 2
    }
    else if (item == "Lemon" && unitName == "Quarter") {
        return (value * 2.25) / 4
    }

    else if (item == "Orange" && unitName == "ea") {
        return (value * 2.5)
    }
    else if (item == "Orange" && unitName == "half") {
        return (value * 2.5) / 2
    }
    else if (item == "Orange" && unitName == "Quarter") {
        return (value * 2.5) / 4
    }
    else {
        return value * unit
    }
}


export function ListOfAmount(type, UniteName, value, totalYeild) {

    if (type == "shopping") {
        if (UniteName == "Table Spoon") {
            return Math.ceil(totalYeild * value)
        }
        else if (UniteName == "Cube") {
            return Math.ceil(totalYeild * value)
        }
        else if (UniteName == "Ounce") {
            return Math.ceil(totalYeild * value)
        }
        else if (UniteName == "ea") {
            return Math.ceil(totalYeild)
        }
        else {
            return Math.ceil(totalYeild / value)
        }
    }
    else {
        if (UniteName == "Table Spoon") {
            return totalYeild * value
        }
        else if (UniteName == "Cube") {
            return totalYeild * value
        }
        else if (UniteName == "Ounce") {
            return totalYeild * value
        }
        else if (UniteName == "ea") {
            return totalYeild
        }
        else {
            return totalYeild / value
        }
    }

}

export function dilutionBottleSize(amount, yields) {
    if (yields == 128) {
        return "Gallon"
    }
    else if (yields > 128) {
        return "Gallons"
    }
    else if (yields == 32) {
        return "Quart"
    }
    else if (yields > 32) {
        return "Quarts"
    }
    else if (yields == 16) {
        return "Pint"
    }
    else if (yields > 16) {
        return "Pints"
    }
    else if (yields <= 1) {
        return "Ounce"
    }
    else if (yields < 8) {
        return "Ounces"
    }
    else if (yields > 8) {
        return "Cups"
    }
    else if (yields == 8) {
        return "cup"
    }
    return "Ounce"
}

export function floatBottleSize(amount, yields, bottleSize, unitName) {
    
    if (bottleSize == "750ml" && yields <= 25.36) {
        return "Bottle"
    }
    else if (unitName == "Leaves" && yields > 1) {
        return "Leaves"
    }
    else if (bottleSize == "12oz") {
        return "12oz"
    }
    else if (amount <= 1 && bottleSize == "ea") {
        return "Bottles"
    }
    else if (amount > 1 && bottleSize == "ea") {
        return "Bottles"
    }
    else if (amount == 1 && bottleSize == "Ounce") {
        return "Bottles"
    }
    else if (amount <= 16 && bottleSize == "Ounce") {
        return "Bottles"
    }
    return "Ounce"
}

export function garnishBottleSize(itemName, amount, yields, bottleSize, unitName) {
    if (unitName == "Peel" && yields <= 6 && itemName == "Grapefruit") {
        return "Grapefruit"
    }
    else if (unitName == "Peel" && yields > 6 && itemName == "Grapefruit") {
        return "Grapefruits"
    }
    else if (unitName == "Peel" && yields <= 3 && itemName == "Lime") {
        return "Lime"
    }
    else if (unitName == "Peel" && yields > 3 && itemName == "Lime") {
        return "Limes"
    }
    else if (unitName == "Peel" && yields <= 6 && itemName == "Orange") {
        return "Orange"
    }

    else if (unitName == "Peel" && yields > 6 && itemName == "Orange") {
        return "Oranges"
    }

    else if (unitName == "Peel" && yields <= 4 && itemName == "Lemon") {
        return "Lemon"
    }

    else if (unitName == "Peel" && yields > 4 && itemName == "Lemon") {
        return "Lemons"
    }

    else if (unitName == "Zest" && yields <= 6 && itemName == "Grapefruit") {
        return "Grapefruit"
    }
    else if (unitName == "Zest" && yields > 6 && itemName == "Grapefruit") {
        return "Grapefruits"
    }
    else if (unitName == "Zest" && yields <= 3 && itemName == "Lime") {
        return "Lime"
    }
    else if (unitName == "Zest" && yields > 3 && itemName == "Lime") {
        return "Limes"
    }
    else if (unitName == "Zest" && yields <= 6 && itemName == "Orange") {
        return "Orange"
    }

    else if (unitName == "Zest" && yields > 6 && itemName == "Orange") {
        return "Oranges"
    }

    else if (unitName == "Zest" && yields <= 4 && itemName == "Lemon") {
        return "Lemon"
    }

    else if (unitName == "Zest" && yields > 4 && itemName == "Lemon") {
        return "Lemons"
    }


    else if (unitName == "Half Moon" && yields <= 12 && itemName == "Grapefruit") {
        return "Grapefruit"
    }
    else if (unitName == "Half Moon" && yields > 12 && itemName == "Grapefruit") {
        return "Grapefruits"
    }
    else if (unitName == "Half Moon" && yields <= 8 && itemName == "Lime") {
        return "Lime"
    }
    else if (unitName == "Half Moon" && yields > 8 && itemName == "Lime") {
        return "Limes"
    }
    else if (unitName == "Half Moon" && yields <= 12 && itemName == "Orange") {
        return "Orange"
    }
    else if (unitName == "Half Moon" && yields > 10 && itemName == "Orange") {
        return "Oranges"
    }

    else if (unitName == "Half Moon" && yields <= 10 && itemName == "Lemon") {
        return "Lemon"
    }

    else if (unitName == "Half Moon" && yields > 10 && itemName == "Lemon") {
        return "Lemons"
    }

    else if (unitName == "wheel" && yields <= 6 && itemName == "Grapefruit") {
        return "Grapefruit"
    }
    else if (unitName == "wheel" && yields > 6 && itemName == "Grapefruit") {
        return "Grapefruits"
    }
    else if (unitName == "wheel" && yields <= 4 && itemName == "Lime") {
        return "Lime"
    }
    else if (unitName == "wheel" && yields > 4 && itemName == "Lime") {
        return "Limes"
    }
    else if (unitName == "wheel" && yields <= 6 && itemName == "Orange") {
        return "Orange"
    }
    else if (unitName == "wheel" && yields > 6 && itemName == "Orange") {
        return "Oranges"
    }

    else if (unitName == "wheel" && yields <= 5 && itemName == "Lemon") {
        return "Lemon"
    }
    else if (unitName == "wheel" && yields > 5 && itemName == "Lemon") {
        return "Lemons"
    }

    else if (unitName == "wedge" && yields <= 8 && itemName == "Grapefruit") {
        return "Grapefruit"
    }
    else if (unitName == "wedge" && yields > 8 && itemName == "Grapefruit") {
        return "Grapefruits"
    }
    else if (unitName == "wedge" && yields <= 6 && itemName == "Lime") {
        return "Lime"
    }
    else if (unitName == "wedge" && yields > 6 && itemName == "Lime") {
        return "Limes"
    }
    else if (unitName == "wedge" && yields <= 8 && itemName == "Orange") {
        return "Orange"
    }
    else if (unitName == "wedge" && yields > 8 && itemName == "Orange") {
        return "Oranges"
    }

    else if (unitName == "wedge" && yields <= 6 && itemName == "Lemon") {
        return "Lemon"
    }
    else if (unitName == "wedge" && yields > 6 && itemName == "Lemon") {
        return "Lemons"
    }

    else if (unitName == "Twist" && yields <= 8 && itemName == "Grapefruit") {
        return "Grapefruit"
    }
    else if (unitName == "Twist" && yields > 8 && itemName == "Grapefruit") {
        return "Grapefruits"
    }
    else if (unitName == "Twist" && yields <= 3 && itemName == "Lime") {
        return "Lime"
    }
    else if (unitName == "Twist" && yields > 3 && itemName == "Lime") {
        return "Limes"
    }
    else if (unitName == "Twist" && yields <= 8 && itemName == "Orange") {
        return "Orange"
    }
    else if (unitName == "Twist" && yields > 8 && itemName == "Orange") {
        return "Oranges"
    }

    else if (unitName == "Twist" && yields <= 4 && itemName == "Lemon") {
        return "Lemon"
    }
    else if (unitName == "Twist" && yields > 4 && itemName == "Lemon") {
        return "Lemons"
    }

    else if (bottleSize == "ea" && itemName == "Cucumber") {
        return "Cucumber"
    }
    else if (bottleSize == "16oz" && itemName == "Salt") {
        return "Salt"
    }
    else if (bottleSize == "Gallon" && itemName == "Maraschino Cherry") {
        return "Gallon"
    }
    else if (bottleSize == "750ml") {
        return "Bottles"
    }
    else if (bottleSize == "ea" && amount <= 1) {
        return "Ounce"
    }
    else if (bottleSize == "ea" && amount > 1) {
        return "Ounces"
    }
    else if (bottleSize == "Ounce" && amount == 1) {
        return "Ounce"
    }
    else if (bottleSize == "Ounce" && yields >= 16) {
        return "Ounces"
    }
    else if (bottleSize == "Ounce" && yields <= 16) {
        return "Ounce"
    }
    else if (bottleSize == "16oz" && yields > 16) {
        return "Bottles"
    }
    else if (bottleSize == "16oz" && yields <= 16) {
        return "Bottle"
    }
    else if (bottleSize == "12oz" && yields > 12) {
        return "Bottles"
    }
    else if (bottleSize == "12oz" && yields <= 12) {
        return "Bottle"
    }
    else if (bottleSize == "10oz" && yields > 10) {
        return "Bottles"
    }
    else if (bottleSize == "10oz" && yields <= 10) {
        return "Bottle"
    }
    else if (bottleSize == "9oz" && yields > 9) {
        return "Bottles"
    }
    else if (bottleSize == "9oz" && yields <= 9) {
        return "Bottle"
    }
    else if (bottleSize == "6oz" && yields > 6) {
        return "Bottles"
    }
    else if (bottleSize == "6oz" && yields <= 6) {
        return "Bottle"
    }
    else if (bottleSize == "5oz" && yields > 5) {
        return "Bottles"
    }
    else if (bottleSize == "5oz" && yields <= 5) {
        return "Bottle"
    }
    else if (bottleSize == "3oz" && yields > 3) {
        return "Bottles"
    }
    else if (bottleSize == "3oz" && yields <= 3) {
        return "Bottle"
    }
    else if (bottleSize == "2oz" && yields > 2) {
        return "Bottles"
    }
    else if (bottleSize == "2oz" && yields <= 2) {
        return "Bottle"
    }
    return "Ounce"
}

export function ListOfFloatGarnishAmount(type, itemName, UniteName, bottleSize, value, yields, ounce, totalYields) {
    if (type == "shopping") {

        if (itemName == "Mint" && bottleSize == "ounce") { return Math.ceil(yields / 24) }
        else if (UniteName == "Rinse" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "Splash" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "Bar Spoon" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "Tea Spoon" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "11.2oz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "12oz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "3oz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "10oz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "9oz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "6oz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "5oz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "2oz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "Drop" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "Float" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "Dash" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "Spritz" && bottleSize == "12oz") { return Math.ceil((ounce * totalYields) / 12) }
        else if (UniteName == "Rinse" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Splash" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Bar Spoon" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Tea Spoon" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "11.2oz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "12oz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "3oz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "10oz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "9oz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "6oz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "5oz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "2oz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Drop" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Float" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Dash" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Spritz" && bottleSize == "16oz") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Rinse" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Splash" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Bar Spoon" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Tea Spoon" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "11.2oz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "12oz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "3oz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "10oz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "9oz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "6oz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "5oz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "2oz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Drop" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Float" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Dash" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Spritz" && bottleSize == "8oz") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Rinse" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }

        else if (UniteName == "Splash" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "Bar Spoon" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "Tea Spoon" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "11.2oz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "12oz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "3oz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "10oz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "9oz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "6oz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "5oz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "2oz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "Drop" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "Float" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "Dash" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "Spritz" && bottleSize == "5oz") { return Math.ceil((ounce * totalYields) / 5) }
        else if (UniteName == "Rinse" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "Splash" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "Bar Spoon" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "Tea Spoon" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "12oz" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "3oz" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "10oz" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "9oz" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "6oz" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "5oz" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "2oz" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "Drop" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "Float" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "Dash" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "Spritz" && bottleSize == "Gallon") { return Math.ceil((ounce * totalYields) / 64) }
        else if (UniteName == "Rinse" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "Splash" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "Bar Spoon" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "Tea Spoon" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "11.2oz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "12oz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "3oz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "10oz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "9oz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "6oz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "5oz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "2oz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "Drop" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "Float" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "Dash" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "Spritz" && bottleSize == "Quart") { return Math.ceil((ounce * totalYields) / 32) }
        else if (UniteName == "Rinse" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Splash" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Bar Spoon" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Tea Spoon" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "11.2oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "12oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "3oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "10oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "9oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "6oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "5oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "2oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Drop" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Float" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Dash" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Spritz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Rinse" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Splash" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Bar Spoon" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Tea Spoon" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "11.2oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "12oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "3oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "10oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "9oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "6oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "5oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "2oz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Drop" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Float" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Dash" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Spritz" && bottleSize == "Pint") { return Math.ceil((ounce * totalYields) / 16) }
        else if (UniteName == "Rinse" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Splash" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Bar Spoon" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Tea Spoon" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "11.2oz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "12oz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "3oz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "10oz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "9oz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "6oz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "5oz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "2oz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Drop" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Float" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Dash" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Spritz" && bottleSize == "Cups") { return Math.ceil((ounce * totalYields) / 8) }
        else if (UniteName == "Rinse" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "Splash" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "Bar Spoon" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "Tea Spoon" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "11.2oz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "12oz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "3oz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "10oz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "9oz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "6oz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "5oz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "2oz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "Drop" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "Float" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "Dash" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "Spritz" && bottleSize == "750ml") { return Math.ceil((ounce * totalYields) / 25.36) }
        else if (UniteName == "Rinse" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "Splash" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "Bar Spoon" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "Tea Spoon" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "11.2oz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "12oz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "3oz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "10oz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "9oz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "6oz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "5oz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "2oz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "Drop" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "Float" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "Dash" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "Spritz" && bottleSize == "187ml") { return Math.ceil((ounce * totalYields) / 6.3232) }
        else if (UniteName == "Rinse" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "Splash" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "Bar Spoon" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "Tea Spoon" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "11.2oz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "12oz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "3oz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "10oz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "9oz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "6oz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "5oz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "2oz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "Drop" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "Float" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "Dash" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "Spritz" && bottleSize == "375ml") { return Math.ceil((ounce * totalYields) / 12.6803) }
        else if (UniteName == "Rinse" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "Splash" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "Bar Spoon" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "Tea Spoon" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "11.2oz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "12oz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "3oz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "10oz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "9oz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "6oz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "5oz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "2oz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "Drop" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "Float" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "Dash" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "Spritz" && bottleSize == "Liter") { return Math.ceil((ounce * totalYields) / 33.814) }
        else if (UniteName == "Rinse" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "Splash" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "Bar Spoon" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "Tea Spoon" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "11.2oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "12oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "3oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "10oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "9oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "6oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "5oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "2oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "Drop" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "Float" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "Dash" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "Spritz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 50.721) }
        else if (UniteName == "Rinse" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "Splash" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "Bar Spoon" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "Tea Spoon" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "11.2oz" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "12oz" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "3oz" && bottleSize == "1.5 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "10oz" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "9oz" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "6oz" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "5oz" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "2oz" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "Drop" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "Float" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "Dash" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (UniteName == "Spritz" && bottleSize == "1.75 Liter") { return Math.ceil((ounce * totalYields) / 59.1745) }
        else if (itemName == "Lemon" && UniteName == "Twist") { return Math.ceil(yields / 4) }
        else if (itemName == "Rosemary" && bottleSize == "ounce") { return Math.ceil(yields / 22) }
        else if (bottleSize == "750ml") { return Math.ceil(yields / 25.5) }
        else if (bottleSize == "Cups") { return Math.ceil(yields / 8) }
        else if (bottleSize == "375ml") { return Math.ceil(yields / 12.68) }
        else if (bottleSize == "Liter") { return Math.ceil(yields / 33.85) }
        else if (bottleSize == "1.75 Liter") { return Math.ceil(yields / 59.1745) }
        else if (bottleSize == "1.5 Liter") { return Math.ceil(yields / 50.721) }
        else if (bottleSize == "16oz") { return Math.ceil(yields / 16) }
        else if (bottleSize == "8oz") { return Math.ceil(yields / 8) }
        else if (bottleSize == "12oz") { return Math.ceil(yields / 12) }
        else if (bottleSize == "Quart") { return Math.ceil(yields / 32) }
        else if (bottleSize == "187ml") { return Math.ceil(yields / 6.3232) }
        else if (bottleSize == "Gallon") { return Math.ceil(yields / 64) }
        else if (bottleSize == "Pint") { return Math.ceil(yields / 16) }
        else if (bottleSize == "6oz") { return Math.ceil(yields / 6) }
        else if (bottleSize == "5oz") { return Math.ceil(yields / 5) }
        else if (bottleSize == "3oz") { return Math.ceil(yields / 3) }
        else if (bottleSize == "2oz") { return Math.ceil(yields / 2) }
        else if (bottleSize == "2oz") { return Math.ceil(yields / 2) }
        else if (bottleSize == "2oz") { return Math.ceil(yields / 2) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "Pineapple" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Quarter") { return Math.ceil(yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Twist") { return Math.ceil(yields / 8) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Twist") { return Math.ceil(yields / 8) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Twist") { return Math.ceil(yields / 3) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Twist") { return Math.ceil(yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Half") { return Math.ceil(yields / 2) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Half") { return Math.ceil(yields / 2) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Half") { return Math.ceil(yields / 2) }
        else if (itemName == "Pineapple" && bottleSize == "ea" && UniteName == "Half") { return Math.ceil(yields / 2) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Half") { return Math.ceil(yields / 2) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Wedge") { return Math.ceil(yields / 8) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "wedge") { return Math.ceil(yields / 8) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "wedge") { return Math.ceil(yields / 6) }
        else if (itemName == "Pineapple" && bottleSize == "ea" && UniteName == "wedge") { return Math.ceil(yields / 36) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "wedge") { return Math.ceil(yields / 6) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Wheel") { return Math.ceil(yields / 6) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Wheel") { return Math.ceil(yields / 6) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Wheel") { return Math.ceil(yields / 4) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Wheel") { return Math.ceil(yields / 5) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Half Moon") { return Math.ceil(yields / 12) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Half Moon") { return Math.ceil(yields / 12) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Half Moon") { return Math.ceil(yields / 8) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Half Moon") { return Math.ceil(yields / 10) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Peel") { return Math.ceil(yields / 6) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Peel") { return Math.ceil(yields / 6) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Peel") { return Math.ceil(yields / 3) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Peel") { return Math.ceil(yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Zest") { return Math.ceil(yields / 6) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Zest") { return Math.ceil(yields / 6) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Zest") { return Math.ceil(yields / 3) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Zest") { return Math.ceil(yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Slice") { return Math.ceil(yields / 12) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Slice") { return Math.ceil(yields / 12) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Slice") { return Math.ceil(yields / 8) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Slice") { return Math.ceil(yields / 10) }
        else if (itemName == "Pineapple" && bottleSize == "ea" && UniteName == "Slice") { return Math.ceil(yields / 12) }
        else if (itemName == "Cucumber" && bottleSize == "ea" && UniteName == "Slice") { return Math.ceil(yields) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Spiral") { return Math.ceil(yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Spiral") { return Math.ceil(yields / 6) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Spiral") { return Math.ceil(yields / 3) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Spiral") { return Math.ceil(yields / 6) }
        else if (bottleSize == "ea") { return Math.ceil(yields) }
        else if (bottleSize == "Sprig") { return Math.ceil(yields) }
        else if (bottleSize == "Dozen") { return Math.ceil(yields / 12) }
        else if (bottleSize == "Rim") { return Math.ceil(yields) }
        else {
            return Math.ceil(yields)
        }
    }
    else {


        if (itemName == "Mint" && bottleSize == "ounce") { return (yields / 24) }
        else if (UniteName == "Rinse" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "Splash" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "Bar Spoon" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "Tea Spoon" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "11.2oz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "12oz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "3oz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "10oz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "9oz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "6oz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "5oz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "2oz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "Drop" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "Float" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "Dash" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "Spritz" && bottleSize == "12oz") { return (ounce * totalYields) / 12 }
        else if (UniteName == "Rinse" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Splash" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Bar Spoon" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Tea Spoon" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "11.2oz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "12oz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "3oz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "10oz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "9oz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "6oz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "5oz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "2oz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Drop" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Float" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Dash" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Spritz" && bottleSize == "16oz") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Rinse" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Splash" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Bar Spoon" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Tea Spoon" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "11.2oz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "12oz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "3oz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "10oz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "9oz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "6oz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "5oz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "2oz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Drop" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Float" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Dash" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Spritz" && bottleSize == "8oz") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Rinse" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }

        else if (UniteName == "Splash" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "Bar Spoon" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "Tea Spoon" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "11.2oz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "12oz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "3oz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "10oz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "9oz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "6oz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "5oz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "2oz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "Drop" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "Float" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "Dash" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "Spritz" && bottleSize == "5oz") { return (ounce * totalYields) / 5 }
        else if (UniteName == "Rinse" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "Splash" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "Bar Spoon" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "Tea Spoon" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "12oz" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "3oz" && bottleSize == "Gallon") { return (ounce * totalYields) / 32 }
        else if (UniteName == "10oz" && bottleSize == "Gallon") { return (ounce * totalYields) / 32 }
        else if (UniteName == "9oz" && bottleSize == "Gallon") { return (ounce * totalYields) / 32 }
        else if (UniteName == "6oz" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "5oz" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "2oz" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "Drop" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "Float" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "Dash" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "Spritz" && bottleSize == "Gallon") { return (ounce * totalYields) / 64 }
        else if (UniteName == "Rinse" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "Splash" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "Bar Spoon" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "Tea Spoon" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "11.2oz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "12oz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "3oz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "10oz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "9oz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "6oz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "5oz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "2oz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "Drop" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "Float" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "Dash" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "Spritz" && bottleSize == "Quart") { return (ounce * totalYields) / 32 }
        else if (UniteName == "Rinse" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Splash" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Bar Spoon" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Tea Spoon" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "11.2oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "12oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "3oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "10oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "9oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "6oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "5oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "2oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Drop" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Float" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Dash" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Spritz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Rinse" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Splash" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Bar Spoon" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Tea Spoon" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "11.2oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "12oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "3oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "10oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "9oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "6oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "5oz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "2oz" && bottleSize == "Pint") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Drop" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Float" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Dash" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Spritz" && bottleSize == "Pint") { return (ounce * totalYields) / 16 }
        else if (UniteName == "Rinse" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Splash" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Bar Spoon" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Tea Spoon" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "11.2oz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "12oz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "3oz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "10oz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "9oz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "6oz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "5oz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "2oz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Drop" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Float" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Dash" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Spritz" && bottleSize == "Cups") { return (ounce * totalYields) / 8 }
        else if (UniteName == "Rinse" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "Splash" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "Bar Spoon" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "Tea Spoon" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "11.2oz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "12oz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "3oz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "10oz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "9oz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "6oz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "5oz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "2oz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "Drop" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "Float" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "Dash" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "Spritz" && bottleSize == "750ml") { return (ounce * totalYields) / 25.36 }
        else if (UniteName == "Rinse" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "Splash" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "Bar Spoon" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "Tea Spoon" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "11.2oz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "12oz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "3oz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "10oz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "9oz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "6oz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "5oz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "2oz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "Drop" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "Float" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "Dash" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "Spritz" && bottleSize == "187ml") { return (ounce * totalYields) / 6.3232 }
        else if (UniteName == "Rinse" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "Splash" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "Bar Spoon" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "Tea Spoon" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "11.2oz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "12oz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "3oz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "10oz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "9oz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "6oz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "5oz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "2oz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "Drop" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "Float" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "Dash" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "Spritz" && bottleSize == "375ml") { return (ounce * totalYields) / 12.6803 }
        else if (UniteName == "Rinse" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "Splash" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "Bar Spoon" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "Tea Spoon" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "11.2oz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "12oz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "3oz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "10oz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "9oz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "6oz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "5oz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "2oz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "Drop" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "Float" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "Dash" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "Spritz" && bottleSize == "Liter") { return (ounce * totalYields) / 33.814 }
        else if (UniteName == "Rinse" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "Splash" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "Bar Spoon" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "Tea Spoon" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "11.2oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "12oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "3oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "10oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "9oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "6oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "5oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "2oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "Drop" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "Float" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "Dash" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "Spritz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 50.721 }
        else if (UniteName == "Rinse" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "Splash" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "Bar Spoon" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "Tea Spoon" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "11.2oz" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "12oz" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "3oz" && bottleSize == "1.5 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "10oz" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "9oz" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "6oz" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "5oz" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "2oz" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "Drop" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "Float" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "Dash" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (UniteName == "Spritz" && bottleSize == "1.75 Liter") { return (ounce * totalYields) / 59.1745 }
        else if (itemName == "Lemon" && UniteName == "Twist") { return (yields / 4) }
        else if (itemName == "Rosemary" && bottleSize == "ounce") { return (yields / 22) }
        else if (bottleSize == "750ml") { return (yields / 25.5) }
        else if (bottleSize == "Cups") { return (yields / 8) }
        else if (bottleSize == "375ml") { return (yields / 12.68) }
        else if (bottleSize == "Liter") { return (yields / 33.85) }
        else if (bottleSize == "1.75 Liter") { return (yields / 59.1745) }
        else if (bottleSize == "1.5 Liter") { return (yields / 50.721) }
        else if (bottleSize == "16oz") { return (yields / 16) }
        else if (bottleSize == "8oz") { return (yields / 8) }
        else if (bottleSize == "12oz") { return (yields / 12) }
        else if (bottleSize == "Quart") { return (yields / 32) }
        else if (bottleSize == "187ml") { return (yields / 6.3232) }
        else if (bottleSize == "Gallon") { return (yields / 64) }
        else if (bottleSize == "Pint") { return (yields / 16) }
        else if (bottleSize == "6oz") { return (yields / 6) }
        else if (bottleSize == "5oz") { return (yields / 5) }
        else if (bottleSize == "3oz") { return (yields / 3) }
        else if (bottleSize == "2oz") { return (yields / 2) }
        else if (bottleSize == "2oz") { return (yields / 2) }
        else if (bottleSize == "2oz") { return (yields / 2) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "Pineapple" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Quarter") { return (yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Twist") { return (yields / 8) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Twist") { return (yields / 8) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Twist") { return (yields / 3) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Twist") { return (yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Half") { return (yields / 2) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Half") { return (yields / 2) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Half") { return (yields / 2) }
        else if (itemName == "Pineapple" && bottleSize == "ea" && UniteName == "Half") { return (yields / 2) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Half") { return (yields / 2) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Wedge") { return (yields / 8) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "wedge") { return (yields / 8) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "wedge") { return (yields / 6) }
        else if (itemName == "Pineapple" && bottleSize == "ea" && UniteName == "wedge") { return (yields / 36) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "wedge") { return (yields / 6) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Wheel") { return (yields / 6) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Wheel") { return (yields / 6) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Wheel") { return (yields / 4) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Wheel") { return (yields / 5) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Half Moon") { return (yields / 12) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Half Moon") { return (yields / 12) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Half Moon") { return (yields / 8) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Half Moon") { return (yields / 10) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Peel") { return (yields / 6) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Peel") { return (yields / 6) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Peel") { return (yields / 3) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Peel") { return (yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Zest") { return (yields / 6) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Zest") { return (yields / 6) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Zest") { return (yields / 3) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Zest") { return (yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Slice") { return (yields / 12) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Slice") { return (yields / 12) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Slice") { return (yields / 8) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Slice") { return (yields / 10) }
        else if (itemName == "Pineapple" && bottleSize == "ea" && UniteName == "Slice") { return (yields / 12) }
        else if (itemName == "Cucumber" && bottleSize == "ea" && UniteName == "Slice") { return (yields) }
        else if (itemName == "Lemon" && bottleSize == "ea" && UniteName == "Spiral") { return (yields / 4) }
        else if (itemName == "orange" && bottleSize == "ea" && UniteName == "Spiral") { return (yields / 6) }
        else if (itemName == "Lime" && bottleSize == "ea" && UniteName == "Spiral") { return (yields / 3) }
        else if (itemName == "Grapefruit" && bottleSize == "ea" && UniteName == "Spiral") { return (yields / 6) }
        else if (bottleSize == "ea") { return (yields) }
        else if (bottleSize == "Sprig") { return (yields) }
        else if (bottleSize == "Dozen") { return (yields / 12) }
        else if (bottleSize == "Rim") { return (yields) }
        else {
            return (yields)
        }
    }
}

export function convertCostingValues(item ,unitName, unitvalue, quantity, cost, totalOunce)
{
    if (item == "Lime" && unitName == "ea") {
        return (cost * 1)
    }
    else if (item == "Lime" && unitName == "Ounce") {
        return (totalOunce * cost * 1)
    }
    else if (item == "Lime" && unitName == "ml") {
        return ((quantity*cost)/0.034)
    }
    else if (item == "Lime" && unitName == "wedge") {
        return ((quantity * cost)/6)
    }
    else if (item == "Lime" && unitName == "half") {
        return ((quantity * cost)/0.5)
    }
    else if (item == "Lime" && unitName == "Quarter") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lime" && unitName == "wheel") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lime" && unitName == "Half Moon") {
        return ((quantity * cost)/8)
    }
    else if (item == "Lime" && unitName == "Slice") {
        return ((quantity * cost)/8)
    }
    else if (item == "Lime" && unitName == "Peel") {
        return ((quantity * cost)/3)
    }
    else if (item == "Lime" && unitName == "Zest") {
        return ((quantity * cost)/3)
    }
    else if (item == "Lime" && unitName == "Twist") {
        return ((quantity * cost)/3)
    }
    else if (item == "Lime" && unitName == "Spiral") {
        return ((quantity * cost)/1)
    }
    else if (item == "Lemon" && unitName == "ea") {
        return (cost * 1)
    }
    else if (item == "Lemon" && unitName == "Ounce") {
        return ((totalOunce * cost)/2.25)
    }
    else if (item == "Lemon" && unitName == "ml") {
        return ((quantity * cost)/66.54)
    }
    else if (item == "Lemon" && unitName == "wedge") {
        return ((quantity * cost)/6)
    }
    else if (item == "Lemon" && unitName == "half") {
        return ((quantity * cost)/0.5)
    }
    else if (item == "Lemon" && unitName == "Quarter") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "wheel") {
        return ((quantity * cost)/5)
    }
    else if (item == "Lemon" && unitName == "Half Moon") {
        return ((quantity * cost)/10)
    }
    else if (item == "Lemon" && unitName == "Slice") {
        return ((quantity * cost)/10)
    }
    else if (item == "Lemon" && unitName == "Peel") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "Zest") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "Twist") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "Twist") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "Spiral") {
        return ((quantity * cost)/4)
    }
    else if (item == "Grapefruit" && unitName == "ea") {
        return (cost * 1)
    }
    else if (item == "Grapefruit" && unitName == "Ounce") {
        return ((totalOunce * cost)/6)
    }
    else if (item == "Grapefruit" && unitName == "ml") {
        return ((quantity * cost)/177)
    }
    else if (item == "Grapefruit" && unitName == "wedge") {
        return ((quantity * cost)/8)
    }
    else if (item == "Grapefruit" && unitName == "half") {
        return ((quantity * cost)/0.5)
    }
    else if (item == "Grapefruit" && unitName == "Quarter") {
        return ((quantity * cost)/4)
    }
    else if (item == "Grapefruit" && unitName == "wheel") {
        return ((quantity * cost)/6)
    }
    else if (item == "Grapefruit" && unitName == "Half Moon") {
        return ((quantity * cost)/12)
    }
    else if (item == "Grapefruit" && unitName == "Slice") {
        return ((quantity * cost)/12)
    }
    else if (item == "Grapefruit" && unitName == "Peel") {
        return ((quantity * cost)/6)
    }
    else if (item == "Grapefruit" && unitName == "Zest") {
        return ((quantity * cost)/6)
    }
    else if (item == "Grapefruit" && unitName == "Twist") {
        return ((quantity * cost)/8)
    }
    else if (item == "Grapefruit" && unitName == "Spiral") {
        return ((quantity * cost)/3)
    }
    else if (item == "Orange" && unitName == "ea") {
        return (cost * 1)
    }
    else if (item == "Orange" && unitName == "Ounce") {
        return ((totalOunce * cost) / 2.5)
    }
    else if (item == "Orange" && unitName == "ml") {
        return ((quantity * cost) / 73.934)
    }
    else if (item == "Orange" && unitName == "wedge") {
        return ((quantity * cost) / 8)
    }
    else if (item == "Orange" && unitName == "half") {
        return ((quantity * cost) * 0.5)
    }
    else if (item == "Orange" && unitName == "Quarter") {
        return ((quantity * cost) / 4)
    }
    else if (item == "Orange" && unitName == "wheel") {
        return ((quantity * cost) / 6)
    }
    else if (item == "Orange" && unitName == "Half Moon") {
        return ((totalOunce * cost) / 12)
    }
    else if (item == "Orange" && unitName == "Slice") {
        return ((quantity * cost) / 8)
    }
    else if (item == "Orange" && unitName == "Peel") {
        return ((quantity * cost) / 6)
    }
    else if (item == "Orange" && unitName == "Zest") {
        return ((quantity * cost) / 6)
    }
    else if (item == "Orange" && unitName == "Twist") {
        return ((quantity * cost) / 8)
    }
    else if (item == "Orange" && unitName == "Spiral") {
        return ((quantity * cost) / 2)
    }
    else if (item == "Pineapple" && unitName == "wedge") {
        return ((quantity * cost) / 36)
    }
    else if (item == "Pineapple" && unitName == "half") {
        return ((quantity * cost) / 2)
    }
    else if (item == "Pineapple" && unitName == "ea") {
        return (quantity * cost)
    }
    else if (item == "Pineapple" && unitName == "Quarter") {
        return ((quantity * cost) / 4)
    }
    else if (item == "Pineapple" && unitName == "Half Moon") {
        return ((quantity * cost) / 12)
    }
    else if (item == "Pineapple" && unitName == "Slice") {
        return ((quantity * cost) / 12)
    }
    else if (unitName == "Cube") {
        return ((quantity * cost) / 12)
    }
    else
    {
        return (totalOunce * cost)
    }
   
}

export function totalValueswithFloatGarnish(item ,unitName, unit, value,totalWithDilution) {
    
  if(item == "Egg White" && unitName == "ea") {
       return true//(totalWithDilution + value)
   }
   else if(item == "Egg Yolk" && unitName == "ea") {
       return true//(totalWithDilution + value)
   }
   else if(item == "Whole Egg" && unitName == "ea") {
       return true//(totalWithDilution + value)
   }
   else if(unitName == "187ml" || unitName == "1.5 Liter" || unitName == "1.75 Liter" || unitName == "Liter" || unitName == "Table Spoon" || unitName == "Cup" || unitName == "Pint" || unitName == "375ml" || unitName == "750ml" || unitName == "Gallon" || unitName == "Quart" || unitName == "Ounce" || unitName == "Float" || unitName == "Rinse" || unitName == "Dash" || unitName == "Spritz" || unitName == "Drop" || unitName == "ml" || unitName == "2oz" || unitName == "Bar Spoon" || unitName == "3oz" || unitName == "5oz" || unitName == "6oz" || unitName == "8oz" || unitName == "9oz" || unitName == "10oz" || unitName == "12oz" || unitName == "11.2oz" || unitName == "16oz" || unitName == "24oz" || unitName == "Table Spoon" || unitName == "Tea Spoon" || unitName == "Splash") {
       return true//(totalWithDilution + value)
   }

   else{
       return false
   }
}


export function convertCostingFloatGarnishValues(item ,unitName, unitvalue, quantity, cost, totalOunce)
{
    if (item == "Lime" && unitName == "ea") {
        return (cost * 1)
    }
    else if (item == "Lime" && unitName == "Ounce") {
        return (totalOunce * cost * 1)
    }
    else if (item == "Lime" && unitName == "ml") {
        return ((totalOunce*cost)/0.034)
    }
    else if (item == "Lime" && unitName == "wedge") {
        return ((quantity * cost)/6)
    }
    else if (item == "Lime" && unitName == "half") {
        return ((quantity * cost)/0.5)
    }
    else if (item == "Lime" && unitName == "Quarter") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lime" && unitName == "wheel") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lime" && unitName == "Half Moon") {
        return ((quantity * cost)/8)
    }
    else if (item == "Lime" && unitName == "Slice") {
        return ((quantity * cost)/8)
    }
    else if (item == "Lime" && unitName == "Peel") {
        return ((quantity * cost)/3)
    }
    else if (item == "Lime" && unitName == "Zest") {
        return ((quantity * cost)/3)
    }
    else if (item == "Lime" && unitName == "Twist") {
        return ((quantity * cost)/3)
    }
    else if (item == "Lime" && unitName == "Spiral") {
        return ((quantity * cost)/1)
    }
    else if (item == "Lemon" && unitName == "ea") {
        return (cost * 1)
    }
    else if (item == "Lemon" && unitName == "Ounce") {
        return ((totalOunce * cost)/2.25)
    }
    else if (item == "Lemon" && unitName == "ml") {
        return ((totalOunce * cost)/66.54)
    }
    else if (item == "Lemon" && unitName == "wedge") {
        return ((quantity * cost)/6)
    }
    else if (item == "Lemon" && unitName == "half") {
        return ((quantity * cost)/0.5)
    }
    else if (item == "Lemon" && unitName == "Quarter") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "wheel") {
        return ((quantity * cost)/5)
    }
    else if (item == "Lemon" && unitName == "Half Moon") {
        return ((quantity * cost)/10)
    }
    else if (item == "Lemon" && unitName == "Slice") {
        return ((quantity * cost)/10)
    }
    else if (item == "Lemon" && unitName == "Peel") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "Zest") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "Twist") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "Twist") {
        return ((quantity * cost)/4)
    }
    else if (item == "Lemon" && unitName == "Spiral") {
        return ((quantity * cost)/4)
    }
    else if (item == "Grapefruit" && unitName == "ea") {
        return (cost * 1)
    }
    else if (item == "Grapefruit" && unitName == "Ounce") {
        return ((totalOunce * cost)/6)
    }
    else if (item == "Grapefruit" && unitName == "ml") {
        return ((totalOunce * cost)/177)
    }
    else if (item == "Grapefruit" && unitName == "wedge") {
        return ((quantity * cost)/8)
    }
    else if (item == "Grapefruit" && unitName == "half") {
        return ((quantity * cost)/0.5)
    }
    else if (item == "Grapefruit" && unitName == "Quarter") {
        return ((quantity * cost)/4)
    }
    else if (item == "Grapefruit" && unitName == "wheel") {
        return ((quantity * cost)/6)
    }
    else if (item == "Grapefruit" && unitName == "Half Moon") {
        return ((quantity * cost)/12)
    }
    else if (item == "Grapefruit" && unitName == "Slice") {
        return ((quantity * cost)/12)
    }
    else if (item == "Grapefruit" && unitName == "Peel") {
        return ((quantity * cost)/6)
    }
    else if (item == "Grapefruit" && unitName == "Zest") {
        return ((quantity * cost)/6)
    }
    else if (item == "Grapefruit" && unitName == "Twist") {
        return ((quantity * cost)/8)
    }
    else if (item == "Grapefruit" && unitName == "Spiral") {
        return ((quantity * cost)/3)
    }
    else if (item == "Orange" && unitName == "ea") {
        return (cost * 1)
    }
    else if (item == "Orange" && unitName == "Ounce") {
        return ((totalOunce * cost) / 2.5)
    }
    else if (item == "Orange" && unitName == "ml") {
        return ((totalOunce * cost) / 73.934)
    }
    else if (item == "Orange" && unitName == "wedge") {
        return ((quantity * cost) / 8)
    }
    else if (item == "Orange" && unitName == "half") {
        return ((quantity * cost) * 0.5)
    }
    else if (item == "Orange" && unitName == "Quarter") {
        return ((quantity * cost) / 4)
    }
    else if (item == "Orange" && unitName == "wheel") {
        return ((quantity * cost) / 6)
    }
    else if (item == "Orange" && unitName == "Half Moon") {
        return ((totalOunce * cost) / 12)
    }
    else if (item == "Orange" && unitName == "Slice") {
        return ((quantity * cost) / 8)
    }
    else if (item == "Orange" && unitName == "Peel") {
        return ((quantity * cost) / 6)
    }
    else if (item == "Orange" && unitName == "Zest") {
        return ((quantity * cost) / 6)
    }
    else if (item == "Orange" && unitName == "Twist") {
        return ((quantity * cost) / 8)
    }
    else if (item == "Orange" && unitName == "Spiral") {
        return ((quantity * cost) / 2)
    }
    else if (item == "Pineapple" && unitName == "wedge") {
        return ((quantity * cost) / 36)
    }
    else if (item == "Pineapple" && unitName == "half") {
        return ((quantity * cost) / 2)
    }
    else if (item == "Pineapple" && unitName == "ea") {
        return (quantity * cost)
    }
    else if (item == "Pineapple" && unitName == "Quarter") {
        return ((quantity * cost) / 4)
    }
    else if (item == "Pineapple" && unitName == "Half Moon") {
        return ((quantity * cost) / 12)
    }
    else if (item == "Pineapple" && unitName == "Slice") {
        return ((quantity * cost) / 12)
    }
    else if (item == "Rosemary" && unitName == "Sprig") {
        return ((cost * quantity) / 22)
    }
    else if (item == "lemon" && unitName == "twist") {
        return ((cost * quantity) / 4)
    }
    else if (unitName == "Ounce") {
        return ((cost * 1) / quantity)
    }
    
    else if (unitName == "Rim") {
        return (cost * 0.1)
    }
    else if (unitName == "Cup") {
        return (cost * 8) * quantity
    }
    else if (unitName == "Sprig") {
        return (quantity * cost)
    }
    else if (unitName == "Quart") {
        return (cost * 32) * quantity
    }
    else if (unitName == "Gallon") {
        return (cost * 64) * quantity
    }
    
    else if (unitName == "Pint") {
        return (cost * 16) * quantity
    }
    else if (unitName == "Dash") {
        return (cost * 0.025) * quantity
    }
    else if (unitName == "Drop") {
        return (cost * 0.031) * quantity
    }
    else if (unitName == "Bar Spoon") {
        return (cost * 0.125) * quantity
    }
    else if (unitName == "Cube") {
        return (cost * 1) * quantity
    }
    else if (unitName == "Rim") {
        return (cost * 1) * quantity
    }
    else if (unitName == "Each") {
        return (cost * 1) * quantity
    }
    else if (unitName == "Float") {
        return (cost * 0.5) * quantity
    }
    else if (unitName == "Peel") {
        return (cost * 1) * quantity
    }
    else if (unitName == "Spritz") {
        return (cost * 0.003) * quantity
    }
    else if (item == "Mint" && unitName == "Leaves") {
        return ((cost / 24) * quantity)
    }
    else if (item == "Cucumber" && unitName == "Slice") {
        return (cost * quantity)
    }
    
    else if (unitName == "2oz") {
        return (cost * 2) * quantity
    }
    else if (unitName == "3oz") {
        return (cost * 3) * quantity
    }
    else if (unitName == "5oz") {
        return (cost * 5) * quantity
    }
    else if (unitName == "6oz") {
        return (cost * 6) * quantity
    }
    else if (unitName == "8oz") {
        return (cost * 8) * quantity
    }
    else if (unitName == "9oz") {
        return (cost * 9) * quantity
    }
    else if (unitName == "11.2oz") {
        return (cost * 11.2) * quantity
    }
    else if (unitName == "12oz") {
        return (cost * 12) * quantity
    }
    else if (unitName == "12oz") {
        return (cost * 12) * quantity
    }
    else if (unitName == "16oz") {
        return (cost * 16) * quantity
    }
    else if (unitName == "24oz") {
        return (cost * 24) * quantity
    }
    else if (unitName == "Spiral") {
        return (cost * quantity)
    }
   
    else if (unitName == "half") {
        return (cost * 0.5) * quantity
    }
    else if (unitName == "ea") {
        return (cost * 1) * quantity
    }
    else if (unitName == "Quarter") {
        return (cost / 4) * quantity
    }
    else if (unitName == "Rinse") {
        return (cost * 0.05) * quantity
    }
    

    else
    {
        return (totalOunce * cost)
    }
    
   
}

