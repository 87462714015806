import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import pattern1 from '../images/pattern1.png';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Utility from '../utility';
import { db, auth } from '../../src/Database_fb';
import axios from 'axios';

import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundImage: `url(${pattern1})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  cardHeader: {
    paddingTop: theme.spacing(3),
  },
}));

export default function Premum(props) {
  const navigate = useNavigate();

  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [username, setusername] = React.useState('');
  const [useremail, setuseremail] = React.useState('');

  const [state, setState] = React.useState({
    checkbox: true,
  });

  

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    Fetchuser()
  }, [])

  function Fetchuser() {
    const user = auth.currentUser;
    const subscribe = db.collection("users")
      .doc(user.uid)
      .get().then((doc) => {
        const crafts = [];
        let data = doc.data()
        setusername(data.name)
        setuseremail(data.email)
      });
  }


  const createSubscription = async () => {
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    // create a payment method
    const paymentMethod = await stripe?.createPaymentMethod({
      type: "card",
      card: elements?.getElement(CardElement),
      billing_details: {
        name: username,
        email: useremail,
      },
    });

    if (paymentMethod.error != null) {
      alert(paymentMethod.error.message);
      return;
    }
    const user = auth.currentUser;
    var reqBody = "paymentMethod=" + paymentMethod.paymentMethod.id + "&name=" + username + "&email=" + useremail + "&uid=" + user.uid + "&priceId=price_1MyIMYFC7Opl3HqE6gbubqYe";
    axios({
      method: "post",
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      url: "https://us-central1-craftiqapp.cloudfunctions.net/startSubscription",
      data: reqBody
    })
      .then(async function (response) {
        console.log('response', response);
        if (response != null && response.data.clientSecret != null) {
          // confirm the payment by the user
          const confirmPayment = await stripe?.confirmCardPayment(
            response.data.clientSecret
          );
          if (confirmPayment.paymentIntent.status == 'succeeded') {
            db.collection('users').doc(user.uid).update({
              confirmPayment: confirmPayment,
            })
            Utility.loadPremiumUser()
            alert("Your monthly subscription has been started successfuly");
            navigate(-1)
          }
          else{
            alert('Something is wrong. please try again');
          }
         
        }
        else {
          alert(response.data.message);
        }
        setPaymentLoading(false);
      }).catch((e) => {
        alert(e);
        setPaymentLoading(false);
      });
    // call the backend to create subscription
    // const response = await fetch("https://us-central1-craftiqapp.cloudfunctions.net/startSubscription", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     paymentMethod: paymentMethod?.paymentMethod?.id,
    //     name: username,
    //     email: useremail,
    //     priceId: 'price_1MyIMYFC7Opl3HqE6gbubqYe'
    //   }),
    // }).then((res) => res.json()).catch((error) => {
    //   alert(error);
    //   setPaymentLoading(false);
    // });



  };

  return (
    <section className={classes.section}>
      <Container maxWidth="lg">
        <Box py={8} textAlign="center">
          <Box mb={3}>
            <Container maxWidth="sm">
              <Typography variant="overline" color="textSecondary">PREMIUM</Typography>
              <Typography variant="h3" component="h2" gutterBottom={true}>
                <Typography variant="h3" component="span" color="primary">Industory </Typography>
                <Typography variant="h3" component="span">Version</Typography>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true}>Get more control over your Drinks</Typography>

              {/* <div>
                <Typography variant="subtitle1" component="span">{content['option1']}</Typography>
                &nbsp; <Switch name="checkbox" color="primary" checked={state.checkbox} onChange={handleChange} /> &nbsp;
                <Typography variant="subtitle1" component="span">{content['option2']}</Typography>
              </div> */}
            </Container>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
            </Grid>
            <Grid item xs={12} md={4} >
              <Card variant='elevation' >
                <CardHeader title={'PAY'} className={classes.cardHeader}></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      $1.99
                      <Typography variant="h6" color="textSecondary" component="span">/ monthly</Typography>
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">- Create new items in the Back Bar</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">- Add newly created item to your MyCraft recipes</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" >- Cost out your program based on desired  cost percentage</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>- Discover cost percentage based on menu price</Typography>
                  </Box>
                  <div
                    style={{
                      padding: "3rem",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "500px",
                        margin: "0 auto",
                      }}
                    >
                      {/* <form
                        style={{
                          display: "block",
                          width: "100%",
                        }}
                      > */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography color="textSecondary" variant="subtitle1" component="p">Add your Credit Card details</Typography>

                          <CardElement
                            className="card"
                            options={{
                              style: {
                                base: {
                                  backgroundColor: "white"
                                }
                              },
                            }}
                          />
                          <button
                            onClick={() => {
                              createSubscription()
                            }}
                            className="pay-button"
                            disabled={isPaymentLoading}
                          >
                            {isPaymentLoading ? "Loading..." : "Pay"}
                          </button>
                        </div>
                      {/* </form> */}
                    </div>
                  </div>
                  {/* <Button variant="outlined" color="primary" className={classes.primaryAction}>Buy Now</Button> */}
                  {/* <CardElement /> */}
                  {/* <Box mt={2}>
                    <Link href="#" color="primary">{content['03_secondary-action']}</Link>
                  </Box> */}
                </CardContent>
              </Card>
              {/* <Typography variant="subtitle1" component="p" color="textSecondary" paragraph={true}>Subscription billed as one payment, Recurring billing. You can manage your subscription in iTunes. Payment will be automatically initiated for the next subscription period. Cancel anytime for any reason.</Typography> */}
            </Grid>

          </Grid>

        </Box>
      </Container>

    </section>
  );
}