import React, { useEffect, useRef, useState } from "react";
import { db, auth, frbase } from '../../src/Database_fb';

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight, AiOutlineSetting } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import Itemmanager from "../context/Itemmanager";
import Floatmanager from "../context/Floatmanager";
import Garnishmanager from "../context/Garnishmanager";
import Modal from 'react-modal';
import TextField from "@mui/material/TextField";
import axios from "axios";
import FlatList from 'flatlist-react';
import { useAlert } from 'react-alert-with-buttons'

export default function ShareRecipe() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const [profileImg, setprofileImg] = useState("");
  const [name, setname] = useState("");
  const [sendername, setsendername] = useState("");
  const [email, setemail] = useState("");
  const [uid, setuid] = useState("");
  const [userToken, setuserToken] = useState("");
  const [serachEmail, setserachEmail] = useState("");
  const [searchdata, setsearchdata] = useState([]);
  const [isSearching, setisSearching] = useState(false);
  const alert = useAlert()

  const [itemrender, setitemrender] = useState([]);
  const [floatRender, setfloatRender] = useState([]);
  const [garnishrender, setgarnishrender] = useState([]);
  const [dilutionrender, setdilutionrender] = useState([]);

  const [recipeName, setrecipeName] = useState(location.state.RecipeName);
  const [dociID, setdociID] = useState(location.state.dociID)

  const [ingrediantArray, setingrediantArray] = useState(location.state.item.ingrediants)

  const [selectedBatchType, setselectedBatchType] = useState(location.state.item.batchType)
  const [TotalOunceWithDilution, setTotalOunceWithDilution] = useState(location.state.item.totalDilution)
  const [selectedBottleType, setselectedBottleType] = useState(location.state.item.bottleSize)
  const [selectedIngredient, setselectedIngredient] = useState('')
  const [selectedBottleTypeVal, setselectedBottleTypeVal] = useState(0)
  const [selectedIngredientVal, setselectedIngredientVal] = useState(location.state.item.ingrediantVal)
  const [batchQuantity, setbatchQuantity] = useState(location.state.item.quantity)
  const [AssumedBatchValue, setAssumedBatchValue] = useState(location.state.item.assumed)
  const [TotalOunces, setTotalOunces] = useState(location.state.item.totalOunces)
  const [TotalYield, setTotalYield] = useState(location.state.item.totalYields)
  const [useremail, setuseremail] = useState("")
  const [AllAppusers, setAllAppusers] = useState([])
  const [onlyLibrary, setonlyLibrary] = useState(
    location.state != null ? location.state.onlyLibrary : false
  );
  const [craftItem, setcraftItem] = useState(location.state.item);

  useEffect(() => {
    console.log(location.state.item);
    console.log(location.state.item.imageUrl ?? 'oops');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    loadAllUsers()
    loadItems()
    const user = frbase.auth().currentUser
    db
      .collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        setsendername(doc.data().name)
        setuseremail(doc.data().email)
        // console.log("email",doc.data().email);
      })
  }, []);



  function loadAllUsers() {
    var allusers = []
    db
      .collection("users")
      .get()
      .then((doc) => {
        doc.forEach((document) => {
          allusers.push({ key: document.id, ...document.data() })
        });
        setAllAppusers(allusers)
      });
  }



  function loadItems() {
    db
      .collection((onlyLibrary) ? "Library" : "MyCrafts").doc(dociID).collection("items").orderBy("order", 'desc')
      .get().then((documentSnapshot) => {

        let itemarry = [];
        let dilutionarry = [];
        let floatarry = [];
        let garnisharry = [];
        documentSnapshot.forEach((snap) => {

          let item = snap.data()
          if (item.type === "item") {
            let it = Itemmanager.getInstance();
            itemarry.push(it.setItems(item, snap.id))
          }
          if (item.type === "dilution") {
            let dil = Itemmanager.getInstance();
            dilutionarry.push(dil.setItems(item, snap.id))
          }
          if (item.type === "garnish") {
            let gar = Garnishmanager.getInstance();
            garnisharry.push(gar.setItems(item, snap.id))
          }
          if (item.type ==="float") {
            let flo = Floatmanager.getInstance();
            floatarry.push(flo.setItems(item, snap.id))
          }
        })

        let sortedItems = itemarry.sort((a, b) => (a.order > b.order) ? 1 : -1)
        setitemrender(sortedItems)
        let sorteddilution = dilutionarry.sort((a, b) => (a.order > b.order) ? 1 : -1)
        setdilutionrender(sorteddilution)
        setfloatRender(floatarry.sort((a, b) => (a.order > b.order) ? 1 : -1))
        setgarnishrender(garnisharry.sort((a, b) => (a.order > b.order) ? 1 : -1))
      })
  }


  function uploadDataToServer(item) {
    const user = frbase.auth().currentUser;
    setloading(true)
    var name = sendername
    if (sendername.length == 0) {
      name = "Someone"
    }
    let Ref = db
      .collection("MyCrafts")
    Ref.add({
      craftname: recipeName,
      status: 'active',
      uid: item.uid,
      totalOunces: TotalOunces.toString(),
      totalYields: TotalYield,
      batchType: selectedBatchType,
      bottleSize: selectedBottleType,
      quantity: batchQuantity.toString(),
      ingrediants: ingrediantArray,
      totalDilution: TotalOunceWithDilution,
      assumed: AssumedBatchValue,
      ingrediantVal: selectedIngredientVal,
      shared: true,
      sharedBy: name,
      imageUrl: location.state.item.imageUrl ?? '',
      videoUrl: location.state.item.videoUrl ?? '',
      mediatype: location.state.item.mediatype ?? '',
      senderID: user.uid,
      timestamp: frbase.firestore.FieldValue.serverTimestamp(),
      updatedAt: frbase.firestore.FieldValue.serverTimestamp()
    }).then((ref) => {
      addUpdateItems(ref.id, item)
    })


  }

  function addUpdateItems(docid, useritem) {
    var index = 0
    let combinedArray = [...itemrender, ...dilutionrender, ...garnishrender, ...floatRender];

    combinedArray.forEach((item) => {
      let docRef = db
        .collection("MyCrafts").doc(docid).collection("items")
      let data =
      {
        itemname: item.itemname,
        unitName: item.unitName,
        bottleSize: item.bottleSize,
        type: item.type,
        unitValue: item.unitValue,
        Quantity: item.Quantity,
        totalOunces: item.totalOunces,
        yields: item.yields,
        amount: item.amount,
        bottleSizeValue: item.bottleSizeValue,
        order: item.order
      }
      docRef.add(data).then((ref) => {
        index = index + 1
        if (index === combinedArray.length) {
          setloading(false)
          showAlert("Great! Recipie Shared with " + useritem.name);
          if (useritem.tokens != null) {
            var name = sendername
            if (sendername.length == 0) {
              name = "Someone"
            }
            let message = "Hey! " + name + " shared a custom recipe with you"
            sendPushnotification(useritem.tokens, message)
          }
        }
      })
    })
  }

  function showAlert(message) {
    alert.open({
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            alert.close()
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });

  }
  function sendPushnotification(tokens, Message) {
    let serverKey = "AAAAGDx3iu4:APA91bEEwhNcBGFWfjQztulSEaEzEbjRQWw34lcL30YxAn5e0GFjyq37VbvYGIYuXSyouAFj_TO4iunaiqNGyqAcFhHU79k4EQ7gTd5JoOtBlrMBqYgH1JlBc-E_iGVzO3CSzLQZibP0"
    let postParams = {
     // to: token,
     registration_ids: tokens,
      notification: {
        body: Message,
        title: "CraftIQ",
        sound: true, // or specify audio name to play
        click_action: "🚀", // action when user click notification (categoryIdentifier)
      }
    }
    let key = "key=" + serverKey
    axios({
      method: 'post',
      url: 'https://fcm.googleapis.com/fcm/send',
      data: postParams,
      headers: { 'Authorization': key, 'Content-Type': 'application/json; charset=utf-8' }
    }).then(function (response) {

    });;
  }

  function serachItem(text) {
    if (text.length == 0) {
      setsearchdata([]);
    }
    if (AllAppusers != undefined) {
      const newData = AllAppusers.filter((item) => {
        const email = item.email
          ? item.email.toUpperCase()
          : "".toUpperCase();
        const name = item.name
          ? item.name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return email.indexOf(textData) > - 1 || name.indexOf(textData) > - 1
      });
      // console.log("updated data",newData);
      // setisSearching(true);
      setsearchdata(newData);
    }
  }

  function renderCraft(item, index) {
    // console.log("data", item);
    return (
      <div
        style={{
          display: 'flex',
          height: 70,
          backgroundColor: "#e6d2a5",
          marginTop: 10,
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <img
          style={{
            height: 70,
            width: 80,
            left: 8,
            borderRadius: 15,
            resizeMode: "contain",
          }}
          src={
            item.imageUrl != ""
              ? item.imageUrl
              : require("../images/avtarImg.png")
          }
        />
        <div
          style={{

            width: 190,

            height: 70,
            justifyContent: "center",

          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: "400",

              color: "black"

            }}
          >
            {item.name}
          </p>
          <p
            style={{
              fontSize: 16,
              fontWeight: "400",


              color: "black",



            }}
          >

            {item.email}
          </p>
        </div>
        <Button
          style={{
            backgroundColor: "#ffac30",
            justifyContent: "center",
            alignItems: "center",
            width: 70,
            height: 40,
            borderRadius: 14,
            right: 12,
          }}
          onClick={() => {
            uploadDataToServer(item)
          }}
        >
          <p style={{
            color: "white",
            fontSize: 15,
            fontWeight: "400",
            fontFamily: "Copperplate",

          }}>Share</p>
        </Button>
      </div>
    )
  }

  return (
    // <>
    //   <div
    //     style={{
    //       flexDirection: "row",
    //       display: 'flex',
    //       marginHorizontal: 20,
    //       marginTop: 40,
    //       alignItems: "center",
    //       //backgroundColor:
    //       //justifyContent: "space-between",
    //     }}
    //   >
    //     <Button
    //       onClick={() => {
    //         navigate(-1)
    //       }}>
    //       <AiOutlineArrowLeft
    //         style={{
    //           width: 20,
    //           height: 20,

    //           color: "#fff",
    //         }}
    //       /></Button>

    //     <p
    //       style={{
    //         fontSize: 16,
    //         color: "white",
    //         fontWeight: "600",
    //         textAlign: 'center',
    //         width: 100,
    //       }}
    //     >
    //       Share Recipe
    //     </p>

    //   </div>
    //   <div
    //     style={{
    //       justifyContent: "space-between",
    //       flex: 1,
    //     }}
    //   >
    //     <div
    //       style={{
    //         margin: 10,
    //         //backgroundColor: "white",

    //         height: 55,
    //         justifyContent: "center",
    //       }}
    //     >

    //       <TextField
    //         placeholder="Search"
    //         InputLabelProps={{
    //           style: {
    //             fontWeight: 'bold',
    //             color: '#f7a828',
    //           }
    //         }}
    //         inputProps={{
    //           style: {
    //             height: "5px",
    //           },
    //         }}
    //         fullWidth
    //         style={{ backgroundColor: "#fff", borderRadius: 10, }}
    //         required
    //         id="cost"
    //         name="cost"

    //         hiddenLabel={true}
    //         onChange={event => {
    //           const { value } = event.target;
    //           serachItem(value)
    //         }}

    //       />
    //     </div>

    //     <FlatList
    //       list={searchdata}
    //       renderItem={renderCraft}

    //     // ListEmptyComponent={() => (
    //     //   <View
    //     //     style={{
    //     //       // backgroundColor:"red",
    //     //       justifyContent: "center",
    //     //       alignSelf: "center",
    //     //       top: RFValue(280),
    //     //     }}
    //     //   >
    //     //     <Text
    //     //       style={{
    //     //         color: "white",
    //     //         fontSize: RFValue(15),
    //     //         fontWeight: "800",
    //     //       }}
    //     //     >
    //     //       {" "}
    //     //       Search with user email or name
    //     //     </Text>
    //     //   </View>
    //     // )}
    //     ></FlatList>
    //   </div>

    //   <Backdrop
    //     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //     open={loading}
    //   // onClick={handleClose}
    //   >
    //     <CircularProgress color="inherit" />
    //   </Backdrop>



    // </>

    <>
     
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>

            <p style={{ fontSize: 25, color: "#f7a828" }}>SHARE RECIPE</p>
            <div
              style={{
                width: 20,
                height: 20,
              }}
            >
            </div>
          </div>
          <div
            style={{
              margin: 10,
              //backgroundColor: "white",

              height: 55,
              justifyContent: "center",
            }}
          >

            <TextField
              placeholder="Search user with name or email"
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  color: '#f7a828',
                }
              }}
              inputProps={{
                style: {
                  height: "5px",
                },
              }}
              fullWidth
              style={{ backgroundColor: "#fff", borderRadius: 10, }}
              required
              id="cost"
              name="cost"

              hiddenLabel={true}
              onChange={event => {
                const { value } = event.target;
                console.log('value', value.length);
                serachItem((value.length > 0) ? value : [])
              }}

            />
          </div>
          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {searchdata.map((prop, index) => {
              return (
                <Grid item xs={6} >

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 5,
                      height: "70px",
                      cursor: 'pointer',
                      //width: "100%",
                      //backgroundColor: "#fff",
                      // marginBottom: 10,
                      // marginLeft: (index % 2) ? 10 : 0
                      backgroundColor: "#e6d2a5",
                    }}

                  >

                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                      <img
                        style={{
                          height: 60,
                          width: 60,
                          left: 8,
                          borderRadius: 30,
                          resizeMode: "contain",
                          backgroundColor: 'grey'
                        }}
                        src={
                          prop.imageUrl != ""
                            ? prop.imageUrl
                            : require("../images/avtarImg.png")
                        }
                      />
                    </div>
                    <div style={{ width: '80%', alignItems: 'center' }}>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.name}
                      </p>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          marginTop: -10
                        }}
                      >
                        {prop.email}
                      </p>
                    </div>

                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <Button
                        style={{
                          backgroundColor: "#ffac30",
                          justifyContent: "center",
                          alignItems: "center",
                          width: 70,
                          height: 40,
                          borderRadius: 14,
                          right: 12,
                        }}
                        onClick={() => {
                          uploadDataToServer(prop)
                        }}
                      >
                        <p style={{
                          color: "white",
                          fontSize: 15,
                          fontWeight: "400",
                          fontFamily: "Copperplate",

                        }}>Share</p>
                      </Button>
                    </div>

                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>


      </Box>
    </>

  );
}
