import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { isDesktop } from 'react-device-detect';
//import { SegmentedControl } from 'segmented-control'
import SegmentedController from 'mui-segmented-control';
import TextField from "@mui/material/TextField";
import Select from 'react-select';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import {
  BarFromArray,
  BarToArray,
  VolumeFromArray,
  VolumeToArray,
  convertBarValues,
  convertVolumeValues
} from "../context/conversionUtil";

const Conversions = () => {
  const navigate = useNavigate();
  // const options = [
  //   'ml', 'ounce', '750ml'
  // ];
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const [isvolume, setisvolume] = useState(true);
  const [value, setValue] = useState(1);
  const [VolumeValue, setVolumeValue] = useState(1);
  const [BarValue, setBarValue] = useState(1);

  const [volumeSelect1, setvolumeSelect1] = useState({ label: "ml", value: "ml" });
  const [volumeSelect2, setvolumeSelect2] = useState({ label: "ml", value: "ml" });
  const [barSelect1, setbarSelect1] = useState({ label: "Lemon", value: "Lemon" });
  const [barSelect2, setbarSelect2] = useState({ label: "wedge", value: "wedge" });


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);


  const handleChange = (DDitem) => {
    if (isvolume) {
      setvolumeSelect1(DDitem)
      convertVolumeValues(DDitem.value, volumeSelect2.value, VolumeValue)
    }
    else {
      setbarSelect1(DDitem)
      convertBarValues(DDitem.value, barSelect2.value, BarValue)
    }
  };



  return (
    <>
      <Box
        sx={{
          overflow: 'auto',
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: 5
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' , width:'95%'}}>
          <Button
            onClick={() => {
              navigate(-1)
            }}>
            <AiOutlineArrowLeft
              style={{
                width: 20,
                height: 20,

                color: "#fff",
              }}
            /></Button>
          <div
            style={{
              // width: '20%',
              // alignItems: "flex-end",
              display: "flex",
              justifyContent: 'center',
              alignItems: 'center',
              //marginLeft: "10%",
              // marginRight: 20,
              marginTop: 10,
              borderRadius: 22,
              backgroundColor: 'white'
            }}
          >


            <SegmentedController

              color="primary"
              options={[
                {
                  label: 'Volume',
                  value: 1
                },
                {
                  label: 'Bar',
                  value: 2
                }
              ]}
              value={isvolume ? 1 : 2}
              onChange={(value) => {
                setisvolume(value == 1 ? true : false)
              }}
            />
          </div>
          <div
            style={{
              width: 20,
              height: 20,
            }}
          >
          </div>
        </div>
        <Box sx={{ width: "80%" }}>
          <p style={{ fontSize: 25, color: "#f7a828" }}>CONVERSION</p>

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={6}>
              <div style={{ backgroundColor: isvolume ? "white" : "#fff3e6", height: isDesktop ? '60vh' : '40vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }} type="submit" fullWidth variant="contained"
              >

                <div style={{ width: '300px' }}>
                  {/* <Dropdown style={{ width: '50%' }} options={options} onChange={(val) => {

                  }} value={'ml'} placeholder="Select an option" /> */}
                  <Select
                    placeholder={'ml'}
                    value={isvolume ? volumeSelect1 : barSelect1}
                    onChange={handleChange}
                    options={isvolume ? VolumeFromArray()
                      : BarFromArray()}
                  />
                </div>
                <div style={{ display: 'flex', width: '300px', height: 100, justifyContent: 'center', alignItems: 'center' }} type="submit" fullWidth variant="contained"
                >
                  <TextField color='warning' id="standard-basic" defaultValue="1" label="" variant="standard" textAlign='center'
                    style={{ width: '300px' }}
                    value={isvolume
                      ? VolumeValue.toString()
                      : BarValue.toString()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      // setName(event.target.value);
                      if (isvolume) {
                        setVolumeValue(event.target.value)
                        convertVolumeValues(volumeSelect1.value, volumeSelect2.value, event.target.value)
                      }
                      else {
                        setBarValue(event.target.value)
                        convertBarValues(barSelect1.value, barSelect2.value, event.target.value)
                      }

                    }}
                    inputProps={{ style: { fontSize: 40, textAlign: 'center', color: 'black' } }}
                  />

                </div>

                <p style={{ fontSize: 16, color: "#000" }}>Tap to change value</p>
              </div>


            </Grid>

            <Grid item xs={6}>
              <div style={{ backgroundColor: isvolume ? "#694b1b" : "#de8014", height: isDesktop ? '60vh' : '40vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }} type="submit" fullWidth variant="contained"
              >

                <div style={{ width: '300px' }}>
                  {/* <Dropdown style={{ width: '50%' }} options={options} onChange={(val) => {

                  }} value={'ml'} placeholder="Select an option" /> */}
                  <Select
                    placeholder={'ml'}
                    value={isvolume ? volumeSelect2 : barSelect2}
                    onChange={(DDitem) => {
                      if (isvolume) {
                        setvolumeSelect2(DDitem)
                        convertVolumeValues(volumeSelect1.value, DDitem.value, VolumeValue)
                      }
                      else {
                        setbarSelect2(DDitem)
                        convertBarValues(barSelect1.value, DDitem.value, BarValue)
                      }
                    }}
                    options={isvolume ? VolumeToArray()
                      : BarToArray()}
                  />
                </div>
                <div style={{ display: 'flex', width: '300px', height: 100, justifyContent: 'center', alignItems: 'center' }} type="submit" fullWidth variant="contained"
                >
                  <p
                    style={{ width: '300px', color: "white", fontSize: 40, textAlign: 'center' }}
                  >
                    {isvolume
                      ? convertVolumeValues(
                        volumeSelect1.value,
                        volumeSelect2.value,
                        VolumeValue
                      ).toFixed(3)
                      : convertBarValues(barSelect1.value, barSelect2.value, BarValue).toFixed(3)}

                  </p>

                </div>

                <p style={{ fontSize: 16, color: "#fff" }}>Tap to change value</p>
              </div>
            </Grid>

          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Conversions;
