import React, { useEffect, useRef, useState } from "react";
import { db, auth } from '../../src/Database_fb';

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft, AiOutlineRight, AiOutlineSetting } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import { useLocation } from 'react-router-dom';


export default function EditNetworks() {
  const navigate = useNavigate();
  const [mynetworkArray, setmynetworkArray] = useState([]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])


  return (
    <>

      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%' }}>
            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>
            <p style={{ fontSize: 30, color: "#D28816" }}>{location.state.NetworkName}</p>
            <div
              style={{
                width: 20,
                height: 20,
              }}
            >
            </div>
          </div>
          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >

            <Grid item xs={6} >

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: 5,
                  height: "60px",
                  cursor: 'pointer',
                  //width: "100%",
                  //backgroundColor: "#fff",
                  // marginBottom: 10,
                  // marginLeft: (index % 2) ? 10 : 0
                  backgroundImage: `url(${list_item_bg})`,
                  backgroundRepeat: "repeat",

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={() => {
                  console.log('clicked');
                  navigate("/NetworksTeams/NetworkRecipes", {
                    state: {
                      isEditor: location.state.isEditor,
                      networkid: location.state.dociID,
                      networkname: location.state.NetworkName,
                    }
                  });

                }}
              >
                <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                <div style={{ width: '5%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                </div>
                <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                  <p
                    style={{
                      fontSize: 15,
                      color: "#000",
                      textAlign: "start",
                      marginLeft: "3%",
                      //marginTop: '4%'
                    }}
                  >
                    Recipes
                  </p>
                </div>

                <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                  <AiOutlineRight
                    style={{
                      width: 15,
                      height: 15,
                      color: "#000",
                    }}
                  />
                </div>

              </div>
            </Grid>


            <Grid item xs={6} >

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: 5,
                  height: "60px",
                  cursor: 'pointer',
                  //width: "100%",
                  //backgroundColor: "#fff",
                  // marginBottom: 10,
                  // marginLeft: (index % 2) ? 10 : 0
                  backgroundImage: `url(${list_item_bg})`,
                  backgroundRepeat: "repeat",

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={() => {
                  // if (Utility.currentuser.subscribed != null && Utility.currentuser.subscribed == true) {
                  navigate("/NetworksTeams/NetworkUsers", {
                    state: {
                      networkid: location.state.dociID,
                      networkname: location.state.NetworkName,
                      isAdmin: location.state.isEditor,
                      networkEditors: location.state.networkEditors
                    }
                  });
                  // }
                  // else {
                  //   navigation.navigate("NetworkSubscription");
                  // }
                }}
              >
                <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                <div style={{ width: '5%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                </div>
                <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                  <p
                    style={{
                      fontSize: 15,
                      color: "#000",
                      textAlign: "start",
                      marginLeft: "3%",
                      //marginTop: '4%'
                    }}
                  >
                    Members
                  </p>
                </div>

                <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                  <AiOutlineRight
                    style={{
                      width: 15,
                      height: 15,
                      color: "#000",
                    }}
                  />
                </div>

              </div>
            </Grid>

          </Grid>
          <div
            style={{
              height: '70%',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >

            <Button
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              onClick={() => {
                navigate("NetworkSettings", {
                  state: {
                    networkid: location.state.dociID,
                    networkname: location.state.NetworkName,
                    canEditAll: location.state.canEditAll,
                    networkEditors: location.state.networkEditors
                  }
                });
              }}>
              <p
                style={{
                  top: 10,
                  fontSize: 17,
                  color: "white",
                  textAlign: "start",
                }}
              >
                Network Settings
              </p>
              <AiOutlineSetting
                style={{
                  width: 40,
                  height: 40,
                  marginTop: -20,
                  color: "#fff",
                }}
              /></Button>

          </div>
        </Box>

      </Box>
    </>
  );
}

