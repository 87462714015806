export default class Itemmanager {
    static myInstance = null;
    static dociID = ""
    static itemname = ""
    static unitName = ""
    static type = "item"
    static unitValue = 0.0
    static bottleSizeValue = 25.5
    static Quantity = 0.0
    static totalOunces = 0.0
    static yields = 0.0
    static amount = 0.0
    static bottleSize = "750ml"
    static order = 0.0
    static previousqty = 0.0
    
    /**
     * @returns {Itemmanager}
     */
    static getInstance() {
       // if (Itemmanager.myInstance == null) {
            Itemmanager.myInstance = new Itemmanager();
       // }

        return this.myInstance;
    }

    getItems() {
        return {itemname: this.itemname, unitName: this.unitName, bottleSize:this.bottleSize, type:this.type, unitValue:this.unitValue,Quantity:this.Quantity, totalOunces:this.totalOunces, yields:this.yields, amount:this.amount,bottleSizeValue:this.bottleSizeValue, order: this.order,dociID: this.dociID,previousqty: this.previousqty};
    }

    setItems(item, id) {
        this.dociID = id
        this.itemname = item.itemname
        this.unitName = item.unitName
        this.type = item.type
        this.unitValue = item.unitValue
        this.bottleSizeValue = item.bottleSizeValue
        this.Quantity = item.Quantity
        this.totalOunces = item.totalOunces
        this.yields = item.yields
        this.amount = item.amount
        this.bottleSize = item.bottleSize
        this.order = item.order
        this.previousqty = item.Quantity
        return this
    }
}