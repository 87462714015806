import React, { useRef, useState, useEffect, createRef, useCallback } from "react";
import Box from "@mui/material/Box";
import { BsPlusCircle } from "react-icons/bs";
import Utility from "../utility";
import TextField from "@mui/material/TextField";
import Select from 'react-select';
import SelectDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Button from "@mui/material/Button";
import { AiOutlineArrowLeft, AiOutlineRight, AiFillPrinter, AiOutlineDelete } from "react-icons/ai";
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { generateVideoThumbnails } from '../context/VideoUtil'
import ReactPlayer from 'react-player';

import {
  batchBottle,
  mainbatchBottle,
  batchType,
  batchUnit,
  Dillution,
  DillutionValues,
  floatUnit,
  garnishUnit,
  DillutionDD,
  Glassware,
  batchUnitDD,
  floatUnitDD,
  garnishUnitDD
} from "../context/craftUtil";
import {
  convertItemValues,
  genrateAssumedValue,
  totalValueswithFloatGarnish
} from "../context/conversionUtil";
import { useScreenshot } from 'use-react-screenshot'

import { db, auth, frbase, storage } from './../Database_fb';
import FlatList from 'flatlist-react';
import ActionSheet from "actionsheet-react";
import Itemmanager from "../context/Itemmanager";
import Floatmanager from "../context/Floatmanager";
import Garnishmanager from "../context/Garnishmanager";
import Prepmanager from "../context/Prepmanager";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useReactToPrint } from 'react-to-print';
import { useAlert } from 'react-alert-with-buttons'
import { ref, uploadBytesResumable, getDownloadURL, uploadString } from "firebase/storage";
import ImageViewer from 'react-simple-image-viewer';
import ModalVideo from 'react-modal-video'
import '../../node_modules/react-modal-video/scss/modal-video.scss';

Modal.setAppElement(document.getElementById('root'));

const useStateWithCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef(() => undefined);


  const setStateCB = (newState, callback) => {
    callbackRef.current = callback;
    setState(newState);
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
    callbackRef.current?.();
  }, [state]);

  return [state, setStateCB];
};

var AssumedBatchValue = 0;
var selectedIngredient = "";
var selectedIngredientVal = 0;
var TotalOunceWithDilution = 0;
var selectedBatchType = "Guest Count";
var selectedBottleType = "750ml";
var selectedBottleTypeVal = 0;
var batchQuantity = 1;
var previousbatchQuantity = 1;
var isViewingCosting = false
var shareWithUser = false
var selectedGlassware = ''

const Recipe = () => {
  const doc = new jsPDF();
  const pdfRef = createRef(null);
  const alert = useAlert()
  //let devicewidth = useRef(window.innerWidth);
  // const fs = RNFetchBlob.fs;
  const screenshotref = createRef(null)
  //const [image, takeScreenshot] = useScreenshot()
  //const getImage = () => takeScreenshot(ref.current)
  const inputref = useRef([]);
  const sheetref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const inputSearchReff = useRef([]);
  const prepinputSearchReff = useRef([]);
  const DiluinputSearchReff = useRef()
  const nextRef = useRef();
  const [loading, setloading] = useState(false);
  const [viewOnly, setviewOnly] = useState(
    location.state != null ? location.state.viewOnly : false
  );
  const [onlyLibrary, setonlyLibrary] = useState(
    location.state != null ? location.state.onlyLibrary : false
  );
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [changeBatchView, setchangeBatchView] = useState(true);
  const [render, setrender] = useState(["1"]);
  const [shareModal, setshareModal] = useState(false);
  const [itemrender, setitemrender] = useStateWithCallback([]);
  const [floatRender, setfloatRender] = useStateWithCallback([]);
  const [garnishrender, setgarnishrender] = useStateWithCallback([]);
  const [dilutionrender, setdilutionrender] = useStateWithCallback([]);
  const [recipeModal, setrecipeModal] = useState(false);
  const [recipeName, setrecipeName] = useState("");
  const [dociID, setdociID] = useState(
    location.state != null ? location.state.dociID : ""
  );
  const [newItem, setnewItem] = useState('');
  let subtitle;
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [TotalOunces, setTotalOunces] = useState(0);
  const [TotalBatchedOunces, setTotalBatchedOunces] = useState(0);
  const [TotalYield, setTotalYield] = useState(0);
  //const [selectedBatchType, setselectedBatchType] = useStateWithCallback('Guest Count')
  //const [TotalOunceWithDilution, setTotalOunceWithDilution] = useState(0)
  //const [selectedBottleType, setselectedBottleType] = useStateWithCallback('750ml')
  //const [selectedIngredient, setselectedIngredient] = useStateWithCallback('')
  //const [selectedBottleTypeVal, setselectedBottleTypeVal] = useStateWithCallback(0)
  //const [selectedIngredientVal, setselectedIngredientVal] = useStateWithCallback(0)
  const [runtimebatchQuantity, setruntimebatchQuantity] = useStateWithCallback(1)
  // const [AssumedBatchValue, setAssumedBatchValue] = useStateWithCallback(0)
  const [ingrediantArray, setingrediantArray] = useStateWithCallback([]);

  const [serachitemData, setserachitemData] = useState([]);
  const [serachprepData, setserachprepData] = useState([]);
  const [serachBatchUnitData, setserachBatchUnitData] = useState([]);
  const [serachFloatUnitData, setserachFloatUnitData] = useState([]);
  const [serachGarnishUnitData, setserachGarnishUnitData] = useState([]);
  const [serachdiltionData, setserachdiltionData] = useState([])
  const [isSeraching, setisSeraching] = useState(false);
  const [updatedInput, setupdatedInput] = useState(true)

  const [showSharebutton, setshowSharebutton] = useState((location.state != null) ? location.state.shareOnline : false)
  const [glasswareArray, setglasswareArray] = useStateWithCallback([
    "",
    "",
    "",
  ]);


  const [selectedglasware, setselectedglasware] = useState(null)
  const [recipeimageurl, setrecipeimageurl] = useState("")
  const [recipevideourl, setrecipevideourl] = useState("")
  const [mediatype, setmediatype] = useState("")
  const imageRef = useRef();
  const [imageSelected, setimageSelected] = useState(false);
  const [videoSelected, setvideoSelected] = useState(false);
  const [ImageZoomvisible, setImageZoomvisible] = useState(false);
  const [captureFullScreens, setcaptureFullScreens] = useState(false);
  const [file, setFile] = useState("");

  const [showVideoModal, setshowVideoModal] = useState({ isVisible: false, dataurl: 'https://firebasestorage.googleapis.com/v0/b/craftiqapp.appspot.com/o/recipies%2FIDjDthMWmCgyfIpvKozTJdiQ2Ts1%2FTTbPnVgXDlgeu5f82Yof%2Frecipe_vid.mp4?alt=media&token=f749b653-bc42-42a9-95ad-56faeb49bdd8' });


  useEffect(() => {


    AssumedBatchValue = 0;
    selectedIngredient = "";
    selectedIngredientVal = 0;
    TotalOunceWithDilution = 0;
    selectedBatchType = "Guest Count";
    selectedBottleType = "750ml";
    selectedBottleTypeVal = 0;
    batchQuantity = 1;
    previousbatchQuantity = 1;
    isViewingCosting = false
    shareWithUser = false


    if (location.state.dociID) {
      loadMyItems();
    }
    else {
      setitemrender([itemObject(0)])
      setfloatRender([floatObject(0)])
      setgarnishrender([garnishObject(0)])
      setdilutionrender([
        dilutionObject(0),
        dilutionObject(1),
      ])
    }

  }, []);


  function showAlert(message) {
    alert.open({
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            alert.close()
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });

  }

  async function handleChange(e) {
    // console.log(e.target.files);
    if (e.target.files[0].type.includes('video') || e.target.files[0].type.includes('image')) {
      if (e.target.files[0].size > 50000000) {
        showAlert("⚠️! Your video size is bigger then expected size. Please upload a Video upto 50 mb");
        return
      }
      setFile(e.target.files[0])
      var url = null
      if (e.target.files[0].type.includes('video')) {
        setmediatype('video')
        setvideoSelected(true)
        let thumbnails = await generateVideoThumbnails(e.target.files[0], 1)
        url = thumbnails[0]
        setrecipevideourl(URL.createObjectURL(e.target.files[0]))
      }
      else {
        setmediatype('image')
        url = URL.createObjectURL(e.target.files[0])
      }
      setimageSelected(true)
      setrecipeimageurl(url);
    }
    else {
      showAlert("⚠️! You can only select image or Video");
    }
  }

  function itemObject(order) {
    let item = Itemmanager.getInstance();
    item.order = order;
    item.dociID = ""
    item.itemname = ""
    item.unitName = ""
    item.type = "item"
    item.unitValue = 0.0
    item.bottleSizeValue = 25.5
    item.Quantity = 0.0
    item.totalOunces = 0.0
    item.yields = 0.0
    item.amount = 0.0
    item.bottleSize = "750ml"
    item.previousqty = 0.0
    return item;
  }

  function floatObject(order) {
    let item = Floatmanager.getInstance();
    item.order = order;
    item.dociID = ""
    item.itemname = ""
    item.unitName = ""
    item.type = "float"
    item.unitValue = 0.0
    item.bottleSizeValue = 25.5
    item.Quantity = 0.0
    item.previousqty = 0.0
    item.totalOunces = 0.0
    item.yields = 0.0
    item.amount = 0.0
    item.bottleSize = "750ml"
    return item;
  }

  function garnishObject(order) {
    let item = Garnishmanager.getInstance();
    item.order = order;
    item.dociID = ""
    item.itemname = ""
    item.unitName = ""
    item.type = "garnish"
    item.unitValue = 0.0
    item.bottleSizeValue = 25.5
    item.Quantity = 0.0
    item.totalOunces = 0.0
    item.yields = 0.0
    item.amount = 0.0
    item.bottleSize = "750ml"
    item.order = 0.0
    item.previousqty = 0.0
    return item;
  }
  function dilutionObject(order) {
    let item = Itemmanager.getInstance();
    item.type = "dilution";
    item.order = order;
    item.dociID = ""
    item.itemname = ""
    item.unitName = ""
    item.unitValue = 0.0
    item.bottleSizeValue = 25.5
    item.Quantity = 0.0
    item.totalOunces = 0.0
    item.yields = 0.0
    item.amount = 0.0
    item.bottleSize = "750ml"
    item.previousqty = 0.0
    return item;
  }

  function prepObject(order) {
    let item = Prepmanager.getInstance();
    item.order = order;
    item.dociID = ""
    item.itemname = ""
    item.type = "prep"
    return item;
  }

  function totalOunceDiluValue(itemsArry, diluArry) {
    var ounces = 0;
    itemsArry.forEach((item) => {
      ounces = ounces + item.totalOunces;
    });
    if (diluArry.length > 0) {
      let dilu = diluArry[0];
      if (dilu.itemname.length > 0) {
        ounces = ounces + dilu.totalOunces;
      }
    }
    TotalOunceWithDilution = ounces;


  }

  function totalOunceYieldValue() {
    totalOunceYieldWithValue(itemrender, dilutionrender);
  }

  function totalOunceYieldWithValue(itemsArry, diluArry) {
    var ounces = 0;
    var yields = 0;
    itemsArry.forEach((item) => {
      if (item.itemname.length > 0) {
        ounces = ounces + item.totalOunces;
        yields = yields + item.yields;
      }
    });

    if (diluArry.length > 0) {
      let dilu = diluArry[0];
      if (dilu.itemname.length > 0) {
        let val = dilu.totalOunces + totalitemOunceValue(itemsArry);
        TotalOunceWithDilution = val;
        let valYield = dilu.yields + totalitemYieldValue(itemsArry);
        let backup = [...diluArry];
        backup[1].totalOunces = val;
        backup[1].yields = valYield;
        setdilutionrender(backup);
        ounces = ounces + dilu.totalOunces;
        yields = yields + dilu.yields;
      }
    }

    if (ounces == 0 && yields == 0) {
      // setTotalOunces(0.0);
      // setTotalYield(0.0);
      return;
    }
    setTotalBatchedOunces(yields);
    // setTotalOunces(yields);
    setTotalYield((ounces == 0 && yields == 0) ? 0 : Math.ceil(yields / ounces));
    totalOunceDiluValue(itemsArry, diluArry);
    generateAssumed();
    var allOunces = ounces
    var allyields = yields
    floatRender.forEach((item) => {
      // console.log('item hai', item.itemname.length);
      if (item.itemname.length > 0) {
        if (totalValueswithFloatGarnish(item.itemname, item.unitName, item.unitValue, item.totalOunces, TotalOunceWithDilution)) {
          allOunces = allOunces + item.totalOunces
          allyields = allyields + item.totalOunces
        }
      }
    });

    garnishrender.forEach((item) => {
      if (item.itemname.length > 0) {
        if (totalValueswithFloatGarnish(item.itemname, item.unitName, item.unitValue, item.totalOunces, TotalOunceWithDilution)) {
          allOunces = allOunces + item.totalOunces
          allyields = allyields + item.totalOunces
        }
      }
    });

    setTotalOunces(allyields);

  }

  function totalitemOunceValue(itemsArry) {
    var total = 0;
    itemsArry.forEach((item) => {
      total = total + item.totalOunces;
    });
    return total;
  }
  function totalitemYieldValue(itemsArry) {
    var total = 0;
    itemsArry.forEach((item) => {
      total = total + item.yields;
    });
    return total;
  }

  function generateAssumed() {
    let assumed = genrateAssumedValue(
      selectedBatchType,
      selectedBottleType,
      selectedBatchType == "Batch Size"
        ? batchBottle()[selectedBottleType]
        : mainbatchBottle()[selectedBottleType],
      TotalOunceWithDilution,
      batchQuantity
    );
    AssumedBatchValue = assumed;
  }

  function refreshlist() {
    let itemarray = [...itemrender];
    itemarray.forEach((item, index) => {
      itemarray[index] = updateIndividualItem(item);
    });
    setitemrender(itemarray);

    let floatarray = [...floatRender];
    floatarray.forEach((item, index) => {
      floatarray[index] = updateIndividualItem(item);
    });
    setfloatRender(floatarray);

    let garnisharray = [...garnishrender];
    garnisharray.forEach((item, index) => {
      garnisharray[index] = updateIndividualItem(item);
    });
    setgarnishrender(garnisharray);

    let diluarray = [...dilutionrender];

    diluarray.forEach((item, index) => {
      diluarray[index] = updateIndividualItem(item);
    });
    setdilutionrender(diluarray);

    totalOunceYieldWithValue(itemarray, diluarray);
  }

  function updateIndividualItem(item) {
    if (selectedBatchType == "Guest Count") {
      item.yields = item.totalOunces * batchQuantity;
    } else if (selectedBatchType == "Batch Size") {
      item.yields = item.totalOunces * AssumedBatchValue * batchQuantity;
    } else if (selectedBatchType == "By main item") {
      var tyields = 0.0;

      if (item.itemname.length == 0) {
        tyields = 0;
      } else if (item.itemname == selectedIngredient) {
        tyields = AssumedBatchValue;
      } else {
        tyields =
          (item.totalOunces / selectedIngredientVal) * AssumedBatchValue;
      }
      item.yields = tyields;
    }
    return item;
  }

  function updateitemsArray(item, type, backup, index, ounces) {
    //  console.log('item.totalOunces',item.totalOunces);
    //  console.log('selectedIngredientVal',selectedIngredientVal);
    //  console.log('AssumedBatchValue',AssumedBatchValue);
    //  console.log('selectedIngredient',selectedIngredient);
    //  console.log('selectedBatchType',selectedBatchType);
    //  console.log('item.yields',item.yields);
    //  console.log('batchQuantity',batchQuantity);
    //  console.log('ounces',ounces);

    if (selectedBatchType == "Guest Count") {
      item.yields = ounces * batchQuantity;
    } else if (selectedBatchType == "Batch Size") {
      item.yields = ounces * AssumedBatchValue * batchQuantity;
    } else if (selectedBatchType == "By main item") {
      var tyields = 0.0;
      if (item.itemname.length == 0) {
        tyields = 0;
      } else if (item.itemname == selectedIngredient) {
        tyields = AssumedBatchValue;
      } else {
        tyields =
          (item.totalOunces / selectedIngredientVal) * AssumedBatchValue;
      }

      item.yields = tyields;
    }
    backup[index] = item;
    if (type == "item") {
      setitemrender(backup, () => {
        let dilubackup = [...dilutionrender];
        let total = totalitemOunceValue(itemrender);
        let ounces = total * dilubackup[0].unitValue;
        dilubackup[0].totalOunces = ounces;
        setdilutionrender(dilubackup, () => {
          totalOunceYieldValue();
        });
        // totalOunceYieldValue();
      });
    } else if (type == "float") {
      setfloatRender(backup, () => {
        totalOunceYieldValue();
      });
    } else if (type == "garnish") {
      setgarnishrender(backup, () => {
        totalOunceYieldValue();
      });
    } else if (type == "dilution") {
      setdilutionrender(backup, () => {
        totalOunceYieldValue();
      });
    }
  }

  // function getLibraryImage() {
  //   // ImagePicker.openPicker({
  //   //   width: 300,
  //   //   height: 400,
  //   //   cropping: true,
  //   // }).then((image) => {
  //   //   setrecipeimageurl(image.path);
  //   //   setimageSelected(true)
  //   //   imageRef.current.hide();
  //   // });
  // }
  // function getCameraImage() {
  //   // ImagePicker.openCamera({
  //   //   width: 300,
  //   //   height: 400,
  //   //   cropping: true,
  //   // }).then((image) => {
  //   //   setrecipeimageurl(image.path);
  //   //   setimageSelected(true)
  //   //   imageRef.current.hide();
  //   //   // console.log("image",image);
  //   // });
  // }

  function loadMyItems() {
    //console.log('dociID', dociID);
    setloading(true);
    db
      .collection((onlyLibrary) ? "Library" : "MyCrafts")
      .doc(dociID)
      .get()
      .then((documentSnapshot) => {
        setdociID(documentSnapshot.id);
        let craft = documentSnapshot.data();
        // console.log('craft', craft);
        selectedBatchType = craft.batchType;
        selectedBottleType = craft.bottleSize;
        batchQuantity = craft.quantity;
        previousbatchQuantity = batchQuantity
        setruntimebatchQuantity(batchQuantity)
        setingrediantArray(craft.ingrediants);
        TotalOunceWithDilution = craft.totalDilution;
        AssumedBatchValue = craft.assumed;
        selectedIngredientVal = craft.ingrediantVal;
        setrecipeName(craft.craftname);
        selectedGlassware = craft.glassware
        setselectedglasware(craft.glassware)
        setrecipeimageurl((craft.imageUrl) ? craft.imageUrl : "")
        setrecipevideourl((craft.videoUrl) ? craft.videoUrl : "")
        setmediatype((craft.mediatype) ? craft.mediatype : "")
        // console.log('craft.glasswareDetails',craft.glasswareDetails);
        if (craft.glasswareDetails != null && craft.glasswareDetails.length > 0) {
          setglasswareArray(glasswareStringArrayToObject(craft.glasswareDetails))
        }
        if (craft.ingrediants.length > 0) {
          selectedIngredient = craft.ingrediants[0];
        }
        loadItems();
      });
  }

  function glasswareStringArrayToObject(ary) {
    let prep = Prepmanager.getInstance();
    var arry = [""];

    ary.forEach((value) => {
      arry.push(value);
    });
    return arry
  }

  function loadItems() {
    db
      .collection((onlyLibrary) ? "Library" : "MyCrafts")
      .doc(dociID)
      .collection("items")
      .orderBy("order", "desc")
      .get()
      .then((documentSnapshot) => {
        let itemarry = [];
        let dilutionarry = [];
        let floatarry = [];
        let garnisharry = [];
        documentSnapshot.forEach((snap) => {

          let item = snap.data();
          if (item.type == "item") {
            let it = Itemmanager.getInstance();
            itemarry.push(it.setItems(item, snap.id));
          }
          if (item.type == "dilution") {
            let dil = Itemmanager.getInstance();
            dilutionarry.push(dil.setItems(item, snap.id));
          }
          if (item.type == "garnish") {
            let gar = Garnishmanager.getInstance();
            garnisharry.push(gar.setItems(item, snap.id));
          }
          if (item.type == "float") {
            let flo = Floatmanager.getInstance();
            floatarry.push(flo.setItems(item, snap.id));
          }
        });

        let sortedItems = itemarry.sort((a, b) => (a.order > b.order ? 1 : -1));
        //console.log('sortedItems',sortedItems);
        setitemrender(sortedItems);

        let sorteddilution = dilutionarry.sort((a, b) =>
          a.order > b.order ? 1 : -1
        );

        setdilutionrender(sorteddilution);
        setfloatRender(floatarry.sort((a, b) => (a.order > b.order ? 1 : -1)));
        setgarnishrender(
          garnisharry.sort((a, b) => (a.order > b.order ? 1 : -1))
        );


        totalOunceYieldWithValue(sortedItems, sorteddilution);
        setloading(false);
      });
  }

  function uploadDataToServer(craftname) {
    setloading(true);
    if (craftname.length > 0) {
      if (dociID != null) {
        var Ref = db.collection("MyCrafts").doc(dociID);

        if (selectedGlassware == null) {
          selectedGlassware = ''
          setselectedglasware('')
        }
        let data = {
          craftname: craftname,
          totalOunces: TotalOunces.toString(),
          totalYields: TotalYield,
          batchType: selectedBatchType,
          bottleSize: selectedBottleType,
          quantity: batchQuantity.toString(),
          ingrediants: ingrediantArray,
          totalDilution: TotalOunceWithDilution,
          assumed: AssumedBatchValue,
          ingrediantVal: selectedIngredientVal,
          glassware: selectedGlassware,
          mediatype: mediatype,
          glasswareDetails: glasswareArrayStrings(),
          updatedAt: frbase.firestore.FieldValue.serverTimestamp(),
        }
        Ref.update(data).then(() => {
          addUpdateItems(dociID);
        });
      } else {
        let data = {
          craftname: craftname,
          status: "active",
          uid: frbase.auth().currentUser.uid,
          totalOunces: TotalOunces.toString(),
          totalYields: TotalYield,
          batchType: selectedBatchType,
          bottleSize: selectedBottleType,
          quantity: batchQuantity.toString(),
          ingrediants: ingrediantArray,
          totalDilution: TotalOunceWithDilution,
          assumed: AssumedBatchValue,
          ingrediantVal: selectedIngredientVal,
          shared: false,
          mediatype: mediatype,
          glassware: selectedGlassware,
          glasswareDetails: glasswareArrayStrings(),
          timestamp: frbase.firestore.FieldValue.serverTimestamp(),
          updatedAt: frbase.firestore.FieldValue.serverTimestamp(),
        };
        db.collection("MyCrafts")
          .add(data)
          .then((ref) => {
            addUpdateItems(ref.id);
          })
        // .then(() => {
        //   addUpdateItems(dociID);
        // });
      }
    }
  }

  function glasswareArrayStrings() {
    var arry = [...glasswareArray];
    arry.splice(0, 1);
    return arry
  }
  function addUpdateItems(docid) {
    const user = frbase.auth().currentUser;
    console.log('image is selected', imageSelected);
    console.log('video is selected', videoSelected);
    if (imageSelected) {

      if (!file) {
        alert("Please choose a file first!")
      }
      const storageRef = ref(storage, `/recipies/${user.uid}/${docid}/recipe_pic.jpeg`)
      if (videoSelected) {
        uploadString(storageRef, recipeimageurl.split(',')[1], 'base64').then(function (snapshot) {
          console.log('Uploaded a base64 string!');
          getDownloadURL(snapshot.ref).then((url) => {
            db.collection("MyCrafts").doc(docid).update({
              imageUrl: url,
            });
          });
        });
      }
      else {

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // const percent = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );

            // // update progress
            // setPercent(percent);
          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              db.collection("MyCrafts").doc(docid).update({
                imageUrl: url,
              });
            });
          }
        );
      }

      if (videoSelected) {
        const storageRefVid = ref(storage, `/recipies/${user.uid}/${docid}/recipe_vid.mp4`)
        const uploadTaskVid = uploadBytesResumable(storageRefVid, file);

        uploadTaskVid.on(
          "state_changed",
          (snapshot) => {
            // const percent = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );

            // // update progress
            // setPercent(percent);
          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTaskVid.snapshot.ref).then((url) => {
              db.collection("MyCrafts").doc(docid).update({
                videoUrl: url,
              });
            });
          }
        );
      }
    }

    let combinedArray = [
      ...itemrender,
      ...dilutionrender,
      ...garnishrender,
      ...floatRender,
    ];

    if (dociID != null) {
      db
        .collection("MyCrafts")
        .doc(dociID)
        .collection("items")
        .get()
        .then(async (documentSnapshot) => {

          var bar = new Promise(async (resolve, reject) => {
            // documentSnapshot.docs.forEach((snap, index) => {
            //   console.log('snap',snap);
            //   snap.ref.delete()
            //   if (index === documentSnapshot.size - 1) resolve();
            // });
            const batchSize = documentSnapshot.size;
            if (batchSize == 0) {
              // When there are no documents left, we are done
              resolve();
              return;
            }

            // Delete documents in a batch
            const batch = db.batch();
            documentSnapshot.docs.forEach((doc) => {
              batch.delete(doc.ref);
            });
            await batch.commit();
            resolve()
            //uploadItems(combinedArray, docid)
          });
          bar.then(() => {
            Utility.sendUpdatePushNotification(docid, recipeName, user.uid)
            uploadItems(combinedArray, docid)
          });
        })
    }
    else {
      uploadItems(combinedArray, docid)
    }
  }

  function uploadItems(combinedArray, docid) {

    var indexo = 0;
    combinedArray.forEach((item) => {

      let docRef = db
        .collection("MyCrafts")
        .doc(docid)
        .collection("items");
      let data = {
        itemname: item.itemname,
        unitName: item.unitName,
        bottleSize: item.bottleSize,
        type: item.type,
        unitValue: item.unitValue,
        Quantity: item.Quantity,
        totalOunces: item.totalOunces,
        yields: item.yields,
        amount: item.amount,
        bottleSizeValue: item.bottleSizeValue,
        order: item.order,
      };

      docRef.add(data).then((ref) => {
        indexo = indexo + 1;
        if (indexo == combinedArray.length) {
          setloading(false);
          if (isViewingCosting) {
            setdociID(docid)
            isViewingCosting = false
            movetoCosting()
            return
          }
          if (shareWithUser) {
            shareWithUser = false
            let data = {
              craftname: recipeName,
              totalOunces: TotalOunces.toString(),
              totalYields: TotalYield,
              batchType: selectedBatchType,
              bottleSize: selectedBottleType,
              quantity: batchQuantity.toString(),
              ingrediants: ingrediantArray,
              totalDilution: TotalOunceWithDilution,
              assumed: AssumedBatchValue,
              ingrediantVal: selectedIngredientVal,
              glassware: selectedGlassware,
              mediatype: mediatype,
              videoUrl: recipevideourl,
              imageUrl: recipeimageurl,
              glasswareDetails: glasswareArrayStrings(),
            }
            navigate("/ShareRecipe", {
              state: {
                RecipeName: recipeName,
                dociID: dociID,
                item: data
              }
            });
            return
          }
          navigate(-1);
        }
      });
    });
  }

  function clearFullData() {

    // setitemrender([itemObject(0)], () => {
    // });
    // //setitemrender([itemObject(0)])
    // setfloatRender([floatObject(0)]);
    // setgarnishrender([garnishObject(0)]);
    setitemrender([]);
    //setitemrender([itemObject(0)])
    setfloatRender([]);
    setgarnishrender([]);
    setdilutionrender([dilutionObject(0), dilutionObject(1)]);
    setTotalOunces(0);
    setTotalYield(0);
    setTotalBatchedOunces(0)
    selectedBatchType = "Guest Count";
    TotalOunceWithDilution = 0;
    selectedBottleType = "750ml";
    selectedIngredient = "";
    selectedBottleTypeVal = 0;
    selectedIngredientVal = 0;
    batchQuantity = 1;
    setruntimebatchQuantity(1)
    previousbatchQuantity = 1;
    AssumedBatchValue = 0;
    setrecipeimageurl('')
    setrecipevideourl('')
    setmediatype('')
    setingrediantArray([]);
    setglasswareArray(["", "", ""])
    selectedGlassware = ""
    setselectedglasware('')
  }


  function renderFlatItem() {
    return (
      <div
        ref={screenshotref}
        style={{
          backgroundColor: "white",
          flex: 1,
        }}
      >
        <FlatList
          list={itemrender}
          renderItem={renderItem}
        ></FlatList>

        {!viewOnly ? (
          <Button
            onClick={() => {
              addBatchList();
            }}
            style={{
              // backgroundColor:"red",
              width: "100%",
              alignItems: 'center', height: 40,
              justifyContent: "center"
            }}
          >
            <img
              style={{
                height: 25,
                width: 25,
                // alignSelf: "center",
              }}
              src={require("../images/addNote_dark.png")}
            ></img>
          </Button>
        ) : null
        }

        <div
          style={{
            backgroundColor: "black",
            height: 30,
            alignItems: "center",
            display: 'flex'
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 15,
              fontWeight: "300",
              left: 10,
            }}
          >
            Dilution
          </p>
        </div>
        {/* <div
          style={{
            height: 70,
          }}
        > */}
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: 'flex',
            height: 50,
            flexDirection: "row",
          }}
        >
          <div style={{ width: '35%', marginLeft: 5, marginRight: 5 }}>
            <Select
              isDisabled={viewOnly}
              placeholder={dilutionrender.length > 0 ? dilutionrender[0].itemname : ""}
              value={dilutionrender.length > 0 ? dilutionrender[0].itemname : ""}
              onChange={DDitem => {
                let backup = [...dilutionrender];
                let unit = DillutionValues()[DDitem.value];
                let total = totalitemOunceValue(itemrender);
                let ounces = total * unit;
                backup[0].itemname = DDitem.value;
                backup[0].unitValue = unit;
                backup[0].totalOunces = ounces;
                updateitemsArray(backup[0], "dilution", backup, 0, ounces);
              }}
              options={DillutionDD()}
            />

          </div>
          <div
            style={{
              width: "10%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'

            }}
          />
          <div
            style={{
              width: "30%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'

            }}
          ></div>
          <div style={{
            width: '10%', marginLeft: 5, marginRight: 5, justifyContent: "center",
            alignItems: "center",
            display: 'flex'
          }}>

            <p
              style={{
                fontSize: 17,
                fontWeight: "500",
                textAlign: 'center',
                color: "black",
                width: 60
              }}
            >
              {dilutionrender.length > 0
                ? dilutionrender[0].yields.toFixed(3)
                : ""}
            </p>
          </div>
          {!viewOnly && <div
            style={{
              width: "5%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >

          </div>}
        </div>
        <div
          style={{
            backgroundColor: "black",
            height: 30,
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 15,
              fontWeight: "300",
              left: 10,
            }}
          >
            Float / Muddle / other
          </p>
        </div>
        <FlatList
          style={{ right: 0 }}
          list={floatRender}
          renderItem={renderItem}
        ></FlatList>
        {!viewOnly ? (
          <Button
            onClick={() => {
              addfloatList();
            }}
            style={{
              // backgroundColor:"red",
              width: "100%",
              alignItems: 'center', height: 40,
              justifyContent: "center"
            }}
          >
            <img
              style={{
                height: 25,
                width: 25,
                // alignSelf: "center",
              }}
              src={require("../images/addNote_dark.png")}
            ></img>
          </Button>
        ) : null
        }
        <div
          style={{
            backgroundColor: "black",
            height: 30,
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 15,
              fontWeight: "300",
              left: 10,
            }}
          >
            Garnish
          </p>
        </div>
        <FlatList
          style={{ right: 0 }}
          list={garnishrender}
          renderItem={renderItem}
        ></FlatList>
        {!viewOnly ? (
          <Button
            onClick={() => {
              addGarnishList();
            }}
            style={{
              // backgroundColor:"red",
              width: "100%",
              alignItems: 'center', height: 40,
              justifyContent: "center"
            }}
          >
            <img
              style={{
                height: 25,
                width: 25,
                // alignSelf: "center",
              }}
              src={require("../images/addNote_dark.png")}
            ></img>
          </Button>
        ) : null
        }

        {/* Preparartion */}
        <div
          style={{
            backgroundColor: "black",
            height: 30,
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 15,
              fontWeight: "300",
              left: 10,
            }}
          >
            Preparation
          </p>
        </div>
        <FlatList
          style={{ right: 0 }}
          list={glasswareArray}
          renderItem={preprenderItem}

        ></FlatList>

        {!viewOnly ? (
          <Button
            onClick={() => {
              addPreparationList();
            }}
            style={{
              // backgroundColor:"red",
              width: "100%",
              alignItems: 'center', height: 40,
              justifyContent: "center"
            }}
          >
            <img
              style={{
                height: 25,
                width: 25,
                // alignSelf: "center",
              }}
              src={require("../images/addNote_dark.png")}
            ></img>
          </Button>
        ) : null
        }


        {/* recipe image */}
        <div
          style={{
            backgroundColor: "black",
            height: 30,
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 15,
              fontWeight: "300",
              left: 10,
            }}
          >
            Image
          </p>
        </div>
        {recipeimageurl.length > 0 ? <div
          style={{
            justifyContent: 'center',
            alignItems: "center",
            flexDirection: "row",
            display: 'flex',

          }}
        >

          <Button

            onClick={() => {
              if (mediatype == 'video') {
                setshowVideoModal({
                  isVisible: true,
                  dataurl: recipevideourl
                });
              }
              else {
                openImageViewer()
              }

            }}
          >

            <div
              style={{
                height: 100,
                width: '95%',
                display: 'flex',
                borderBottomWidth: 1,
                borderColor: "grey",
                justifyContent: "center",
                alignItems: 'center',
                backgroundColor: 'gray'
              }}
            >

              <img
                style={{
                  height: 100,
                  width: (mediatype == 'video') ? 200 : 100,
                  //   transform: [{rotateY: '180deg'}],
                  //   tintColor: 'white',
                }}
                // onClick={ () => openImageViewer() }
                src={recipeimageurl}
              />

            </div>


          </Button>

          {!viewOnly && <Button
            onClick={() => {
              setrecipeimageurl('')
            }}>
            <AiOutlineDelete
              style={{
                width: 20,
                height: 20,

                color: "red",
              }}
            /></Button>}
        </div> : null}
        {!viewOnly && <input type="file" onChange={handleChange} />}
        {/* <FlatList
          list={recipeimageurl.length > 0 ? [""] : []}
          renderItem={imageItem}

        ></FlatList> */}

        {/* {!viewOnly ? (
          <input type="file" onChange={handleChange} /> 
          // <Button
          //   onClick={() => {
          //     // imageRef.current.show()
          //   }}
          //   style={{
          //     // backgroundColor:"red",
          //     width: "100%",
          //     alignItems: 'center', height: 40,
          //     justifyContent: "center"
          //   }}
          // >
          //   <img
          //     style={{
          //       height: 25,
          //       width: 25,
          //       // alignSelf: "center",
          //     }}
          //     src={require("../images/addNote_dark.png")}
          //   ></img>
          // </Button>
        // ) : null
        // }*/}

        <div
          style={{
            height: 150,

          }}
        >
          <div
            style={{
              // display:'flex',
              backgroundColor: "#e3e3e1",
              // alignItems:'center',
              //justifyContent:'center'

            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  // flexDirection: "column",
                  justifyContent: "space-between",
                  //height: 65,
                  ///backgroundColor: "red",
                  width: 1000 / 3
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: "white",
                    textAlign: "center",
                    // width: RFValue(100),
                    backgroundColor: "#363d47",
                    // right: 5,
                  }}
                >
                  Total Yield
                </p>
                <p

                  style={{
                    fontSize: 15,
                    fontWeight: "300",
                    color: "black",
                    textAlign: "center",
                    textAlignVertical: 'center',
                    // width: RFValue(100),
                    // backgroundColor: "#363d47",
                    // right: 5,
                    marginBottom: 5,
                    // width: RFValue(100)
                  }}
                >
                  {TotalYield.toFixed(3)}
                </p>
              </div>
              <div
                style={{
                  //flexDirection: "column",
                  justifyContent: "space-between",
                  // height: 45,
                  width: 1000 / 3
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: "white",
                    textAlign: "center",
                    // width: RFValue(100),
                    backgroundColor: "#363d47",
                    //right: 2,
                  }}
                >
                  Batched Ounces
                </p>
                <p

                  style={{
                    fontSize: 15,
                    textAlignVertical: 'center',
                    fontWeight: "300",
                    color: "black",
                    textAlign: "center",
                    // width: RFValue(900),
                    // backgroundColor: "#363d47",
                    // right: RFValue(5),
                    marginBottom: 5,
                    //width: RFValue(100)
                  }}
                >
                  {TotalBatchedOunces.toFixed(3)}
                </p>
              </div>

              <div
                style={{
                  //flexDirection: "column",
                  justifyContent: "space-between",
                  // height: 45,
                  width: 1000 / 3
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: "white",
                    textAlign: "center",
                    // width: RFValue(100),
                    backgroundColor: "#363d47",
                    //right: 2,
                  }}
                >
                  Total Ounces
                </p>
                <p

                  style={{
                    fontSize: 15,
                    textAlignVertical: 'center',
                    fontWeight: "300",
                    color: "black",
                    textAlign: "center",
                    // width: RFValue(900),
                    // backgroundColor: "#363d47",
                    // right: RFValue(5),
                    marginBottom: 5,
                    //width: RFValue(100)
                  }}
                >
                  {TotalOunces.toFixed(3)}
                </p>
              </div>

            </div>
            {/* <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: RFValue(10),
              }}
            >


            </View> */}
          </div>
        </div>
      </div>
    );
  }

  function updateQuantityLogic(item, text, index) {

    let qty = parseFloat(text);
    qty = (isNaN(qty)) ? 0 : qty
    //  if (!isNaN(qty)) {
    if (selectedBatchType == "By main item") {
      if (item.itemname == selectedIngredient) {
        selectedIngredientVal = item.totalOunces;
      }
    }
    if (item.type == "item") {
      let ounces = convertItemValues(
        item.itemname,
        item.unitName,
        item.unitValue,
        qty,
        TotalOunceWithDilution
      );
      let backup = [...itemrender];
      item.totalOunces = ounces;
      item.Quantity = qty;
      item.previousqty = qty;
      updateitemsArray(item, item.type, backup, index, ounces);
    }
    if (item.type == "float") {
      let ounces = convertItemValues(
        item.itemname,
        item.unitName,
        item.unitValue,
        qty,
        TotalOunceWithDilution
      );
      let backup = [...floatRender];
      item.totalOunces = ounces;
      item.Quantity = qty;
      updateitemsArray(item, item.type, backup, index, ounces);
    }
    if (item.type == "garnish") {
      let ounces = convertItemValues(
        item.itemname,
        item.unitName,
        item.unitValue,
        qty,
        TotalOunceWithDilution
      );
      let backup = [...garnishrender];
      item.totalOunces = ounces;
      item.Quantity = qty;
      updateitemsArray(item, item.type, backup, index, ounces);
    }
    // }

  }



  function sizeOptions(item) {
    if (item.type == "item") {
      return batchUnitDD()
    }
    else if (item.type == "float") {
      return floatUnitDD()
    }
    else if (item.type == "garnish") {
      return garnishUnitDD()
    }
    else {
      return []
    }
    // item.type == "item"
    //   ? isSeraching ? serachBatchUnitData : Object.keys(batchUnit())
    //   : item.type == "float"
    //     ? isSeraching ? serachFloatUnitData : Object.keys(floatUnit())
    //     : item.type == "garnish"
    //       ? isSeraching ? serachGarnishUnitData : Object.keys(garnishUnit())
    //       : []
  }

  function capitalizeFirstLetter(str) {

    // converting first letter to uppercase
    const capitalized = str.replace(/^./, str[0].toUpperCase());

    return (str == 'ounce') ? capitalized : str;
  }



  function renderItem(item, index) {
    let isNaN = (maybeNaN) => maybeNaN != maybeNaN;
    return (
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            //height: "45px",
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: "35%",
              padding: 5,
            }}
          >
            <Select
              isDisabled={viewOnly}
              // inputValue="hello"
              //theme={theme}
              //styles={{ height: "45px", }}

              placeholder={item.itemname.length > 0 ? item.itemname : ""}
              value={item.itemname.length > 0 ? item.itemname : ""}
              onChange={DDitem => {
                if (item.type == "item") {

                  let backup = [...itemrender];
                  item.itemname = DDitem.value;
                  if (item.Quantity == 0 && item.unitValue == 0) {
                    item.Quantity = 1;
                    item.unitName = "ounce";
                    item.unitValue = 1.0;
                  }
                  let ounces = convertItemValues(
                    DDitem.value,
                    item.unitName,
                    item.unitValue,
                    item.Quantity,
                    TotalOunceWithDilution
                  );
                  item.totalOunces = ounces;
                  let array = [...ingrediantArray];
                  if (array.length == 0) {
                    selectedIngredient = DDitem.value;
                    selectedIngredientVal = item.totalOunces;
                  }
                  if (index < array.length) {
                    array[index] = DDitem.value;
                  } else {
                    array.push(DDitem.value);
                  }
                  setingrediantArray(array);
                  updateitemsArray(item, item.type, backup, index, ounces);
                }
                if (item.type == "float") {
                  let backup = [...floatRender];
                  item.itemname = DDitem.value;
                  if (item.Quantity == 0 && item.unitValue == 0) {
                    item.Quantity = 1;
                    item.unitName = "ounce";
                    item.unitValue = 1.0;
                  }
                  let ounces = convertItemValues(
                    DDitem.value,
                    item.unitName,
                    item.unitValue,
                    item.Quantity,
                    TotalOunceWithDilution
                  );
                  item.totalOunces = ounces;
                  updateitemsArray(item, item.type, backup, index, ounces);
                }
                if (item.type == "garnish") {
                  let backup = [...garnishrender];
                  item.itemname = DDitem.value;
                  if (item.Quantity == 0 && item.unitValue == 0) {
                    item.Quantity = 1;
                    item.unitName = "ounce";
                    item.unitValue = 1.0;
                  }
                  let ounces = convertItemValues(
                    DDitem.value,
                    item.unitName,
                    item.unitValue,
                    item.Quantity,
                    TotalOunceWithDilution
                  );
                  item.totalOunces = ounces;
                  updateitemsArray(item, item.type, backup, index, ounces);
                }
              }}
              options={Utility.itemsArray}
            />
          </div>
          <div
            style={{
              width: "10%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'

            }}
          >
            <TextField
              disabled={viewOnly}
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  color: '#f7a828',
                }
              }}
              inputProps={{
                style: {
                  height: "5px",
                },
              }}
              defaultValue={item.Quantity.toString()}

              style={{ backgroundColor: "#fff", borderRadius: 10, }}
              required
              id="cost"
              name="cost"
              //autoComplete="email"
              //autoFocus
              hiddenLabel={true}
              onChange={event => {
                const { value } = event.target;
                if (value.length > 0) {
                  updateQuantityLogic(item, value, index)
                }
                else {
                  item.Quantity = value
                  if (item.type == "item") {
                    let backup = [...itemrender];
                    backup[index] = item
                    setitemrender(backup);
                  } else if (item.type == "float") {
                    let backup = [...floatRender];
                    backup[index] = item
                    setfloatRender(backup);
                  } else if (item.type == "garnish") {
                    let backup = [...garnishrender];
                    backup[index] = item
                    setgarnishrender(backup);
                  }
                }
              }}

            />
          </div>
          <div
            style={{
              width: "30%",
              padding: 5,
            }}
          >
            <Select
              isDisabled={viewOnly}
              placeholder={item.unitName.length > 0 ? item.unitName : ""}
              //placeholder={'chocolate'}
              value={item.unitName.length > 0 ? capitalizeFirstLetter(item.unitName) : ""}
              onChange={DDitem => {
                if (item.type == "item") {
                  let unit = batchUnit()[DDitem.value];
                  let ounces = convertItemValues(
                    item.itemname,
                    DDitem.value,
                    unit,
                    item.Quantity,
                    TotalOunceWithDilution
                  );
                  let backup = [...itemrender];
                  item.unitName = DDitem.value;
                  item.totalOunces = ounces;
                  item.unitValue = unit;
                  updateitemsArray(item, item.type, backup, index, ounces);
                }
                if (item.type == "float") {
                  let unit = floatUnit()[DDitem.value];
                  let ounces = convertItemValues(
                    item.itemname,
                    DDitem.value,
                    unit,
                    item.Quantity,
                    TotalOunceWithDilution
                  );
                  let backup = [...floatRender];
                  item.unitName = DDitem.value;
                  item.totalOunces = ounces;
                  item.unitValue = unit;
                  updateitemsArray(item, item.type, backup, index, ounces);
                }
                if (item.type == "garnish") {
                  let unit = garnishUnit()[DDitem.value];
                  let ounces = convertItemValues(
                    item.itemname,
                    DDitem.value,
                    unit,
                    item.Quantity,
                    TotalOunceWithDilution
                  );
                  let backup = [...garnishrender];
                  item.unitName = DDitem.value;
                  item.totalOunces = ounces;
                  item.unitValue = unit;
                  updateitemsArray(item, item.type, backup, index, ounces);
                }
              }}
              options={sizeOptions(item)}
            />
          </div>

          <div
            style={{
              // marginRight: "1%",
              width: "10%",
              // height: "20%",
              padding: 5,
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
              //backgroundColor:'red'
            }}
          >
            <p style={{ color: "#000" }}>
              {isNaN(item.yields) ? "" : item.yields.toFixed(3)}
            </p>
          </div>
          {!viewOnly && <div
            style={{
              // marginRight: "1%",
              width: "5%",
              // height: "20%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => {
                if (item.type == "item") {
                  itemrender.splice(index, 1);
                  setitemrender([...itemrender], () => {
                    totalOunceYieldValue();
                  });

                }
                if (item.type == "float") {
                  floatRender.splice(index, 1);
                  setfloatRender([...floatRender]);
                }

                if (item.type == "garnish") {
                  garnishrender.splice(index, 1);
                  setgarnishrender([...garnishrender]);
                }
              }}>
              <AiOutlineDelete
                style={{
                  width: 20,
                  height: 20,

                  color: "red",
                }}
              /></Button>
          </div>}
        </div>

        <div
          style={{
            width: "100%",
            height: '1px',
            backgroundColor: 'blue'
          }}
        />

      </div>
    );
  }


  function preprenderItem(item, index) {
    if (index == 0) {
      return (

        <div
          style={{
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            borderBottomWidth: 1,
            borderBottomColor: "#80b3ff",
          }}
        >

          <p

            style={{
              fontSize: 15,
              marginLeft: '5%',
              fontWeight: "500",
              textAlign: 'center',
              color: "black",
              width: 60,

            }}
          >
            Glassware
          </p>

          <div
            style={{
              width: '40%',
              marginRight: 20
            }}
          >
            <Select
              //isClearable={true}
              isDisabled={viewOnly}
              placeholder={selectedglasware}
              value={selectedglasware}
              onChange={DDitem => {
                setselectedglasware(DDitem.value)
                selectedGlassware = DDitem.value
              }}
              onInputChange={(value) => {
                setselectedglasware(value)
              }}
              options={Glassware()}
              onMenuClose={() => {
                setselectedglasware(selectedglasware)
                selectedGlassware = selectedglasware
              }}
            />



          </div>

        </div>
      );
    }
    else {
      return preprenderInputItem(item, index)
    }
  }

  function preprenderInputItem(item, index) {
    // console.log('item',item);
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            flexDirection: "row",
          }}
        >

          <div
            style={{
              // height: 60,
              width: '95%',
              borderBottomWidth: 1,
              borderColor: "grey",
              justifyContent: "center",
              display: 'flex',
              alignItems: "center",
            }}
          >
            <TextField
              disabled={viewOnly}
              placeholder="description"
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  color: "black",
                  textAlign: 'left',
                  left: 0,
                  fontSize: 13,
                  fontWeight: "500",
                }
              }}
              inputProps={{
                style: {
                  //height: "10px",
                  //width:'100px'
                },
              }}
              multiline={true}
              value={item.cost}
              style={{ backgroundColor: "#fff", borderRadius: 10, }}
              required
              id="cost"
              fullWidth
              name="cost"
              hiddenLabel={true}
              defaultValue={item}
              onChange={event => {
                const { value } = event.target;
                glasswareArray[index] = value
              }}
            />

          </div>

        </div>

        <div
          style={{
            width: "100%",
            height: '1px',
            backgroundColor: 'blue'
          }}
        />

      </div>
    );
  }

  function imageItem(item, index) {
    return (

      <div
        style={{
          justifyContent: 'center',
          alignItems: "center",
          flexDirection: "row",
          display: 'flex',
        }}
      >

        <Button
          onClick={() => {
            if (mediatype == 'video') {
              setshowVideoModal({
                isVisible: true,
                dataurl: recipevideourl
              });
            }
            else {
              openImageViewer()
            }
          }}
        >

          <div
            style={{
              height: 100,
              width: '95%',
              display: 'flex',
              borderBottomWidth: 1,
              borderColor: "grey",
              justifyContent: "center",
              alignItems: 'center',
              backgroundColor: 'red'
            }}
          >



            <img
              style={{
                height: 100,
                width: (mediatype == 'video') ? 200 : 100,

                //   transform: [{rotateY: '180deg'}],
                //   tintColor: 'white',
              }}
              src={recipeimageurl}
            />




          </div>


        </Button>


      </div>
    );
  }

  function addBatchList() {
    setitemrender((Counter) => [...Counter, itemObject(itemrender.length)]);
  }
  function addfloatList() {
    setfloatRender((floatCounter) => [
      ...floatCounter,
      floatObject(floatRender.length),
    ]);
  }
  function addGarnishList() {
    setgarnishrender((garnishCounter) => [
      ...garnishCounter,
      garnishObject(garnishrender.length),
    ]);
  }

  function addPreparationList() {
    setglasswareArray((obj) => [
      ...obj,
      "",
    ]);
  }

  function sharePressed() {
    // ref.current.capture().then(uri => {


    navigate("Sharemedia", {
      state: {
        //recipeImage: uri,
        rname: recipeName
      }
    })
    // });
  }

  function movetoCosting() {
    navigate("CostRecipe", {
      state: {
        headername: "Costing",
        dociID: dociID,
        viewOnly: viewOnly,
        craftName: recipeName,
        isShopping: true,
        myItemArray: [...itemrender],
        dilutionArray: [...dilutionrender],
        floatArray: [...floatRender],
        ingrediantArray: [...ingrediantArray],
        garnishArray: [...garnishrender],
        QTYValue: batchQuantity,
        selectedBatchType: selectedBatchType,
        selectedBottleType: selectedBottleType,
        selectedIngredient: selectedIngredient,
        selectedIngredientVal: selectedIngredientVal,
        selectedBottleTypeVal: selectedBottleTypeVal,
        TotalOunceWithDilution: TotalOunceWithDilution,
        AssumedBatchValue: AssumedBatchValue,
        TotalOunces: TotalOunces,
        TotalYield: TotalYield,
      }
    });
  }


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  function mainContent() {
    return (

      <div
        ref={pdfRef}
        style={{
          flex: 1,
          backgroundColor: "white",
          top: 10,
        }}
      >
        {changeBatchView ? (
          <Button
            onClick={() => {
              setchangeBatchView(false);
            }}
            // disabled={viewOnly}
            style={{
              display: 'flex',
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              //   backgroundColor: "red",
              height: 30,
              width: '100%',
              paddingHorizontal: 15,
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "300",
                color: "black",
              }}
            >
              Batching
            </p>
            <img
              style={{
                height: 16,
                width: 12,
                //   transform: [{rotateY: '180deg'}],
                //   tintColor: 'white',
              }}
              src={require("../images/arrow.png")}
            />
          </Button>
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#363d47",
                height: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  backgroundColor: "#61656d",
                  justifyContent: "center",
                  alignItems: "center",
                  width: '30%',
                  borderRightWidth: 3,
                  borderColor: "#8e9299",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    color: "white",
                    fontWeight: "300",
                    textAlign: 'center'
                  }}
                >
                  Batch Type
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  // backgroundColor:"#61656d",
                  justifyContent: "center",
                  alignItems: "center",
                  width: '30%',
                  borderRightWidth: 3,
                  //left: -10,
                  borderColor: "#8e9299",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    color: "white",
                    fontWeight: "300",
                    textAlign: 'center'
                    //width: '100%'
                  }}
                >
                  Batch bottle
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  //   backgroundColor:"#61656d",
                  justifyContent: "center",
                  alignItems: "center",
                  width: '10%',
                  borderRightWidth: 3,
                  borderColor: "#8e9299",
                  left: -12,

                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    color: "white",
                    //width: '100%',
                    textAlign: 'center',
                    textAlign: 'center'
                  }}
                >
                  Qty
                </p>
              </div>
              <div
                style={{
                  width: '30%',
                  display: 'flex',
                  //  backgroundColor:"#61656d",
                  justifyContent: "center",
                  alignItems: "center",
                  // right: 10,
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    color: "white",
                    fontWeight: "400",
                    right: 5,
                    textAlign: 'center'
                  }}
                >
                  Main {'\n'}ingredient
                </p>
              </div>
            </div>
            <div
              style={{
                // height:50,
                // backgroundColor:"red",
                display: 'flex',
                borderBottomWidth: 1,
                borderColor: "red",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: '30%', marginLeft: 5, marginRight: 5 }}>
                <SelectDropdown
                  disabled={viewOnly}
                  value={selectedBatchType}
                  options={batchType()}
                  onChange={(value, index) => {
                    //console.log(value);
                    if (value.value != "Guest Count") {

                      if (TotalOunceWithDilution == 0) {
                        showAlert(
                          "⚠️! You have to select one item to batch it");
                        return;
                      } else {
                        selectedBatchType = value.value;
                        generateAssumed();
                      }
                    }

                    selectedBatchType = value.value;

                    if (value.value == "By main item") {
                      if (selectedIngredient.length > 0) {
                        var selectedVal = 0;
                        itemrender.forEach((item) => {
                          if (item.itemname == selectedIngredient) {
                            selectedVal = item.totalOunces;
                          }
                        });
                        selectedIngredientVal = selectedVal;
                      }
                    }

                    if (TotalOunceWithDilution != 0) {
                      totalOunceYieldValue();
                    }
                    refreshlist();
                  }}

                />

              </div>
              <div style={{ width: '30%', marginLeft: 5, marginRight: 5 }}>
                <SelectDropdown

                  value={selectedBottleType}
                  disabled={viewOnly}
                  options={selectedBatchType == "Batch Size"
                    ? Object.keys(batchBottle())
                    : Object.keys(mainbatchBottle())}
                  onChange={(value, index) => {
                    if (selectedBatchType != "Guest Count") {
                      if (TotalOunceWithDilution == 0) {
                        showAlert(
                          "⚠️! You have to select one item to batch it");
                        return;
                      } else {
                        selectedBottleType = value.value;
                        //setselectedBottleType(value, () => {
                        generateAssumed();
                        // })
                      }
                      //return
                    }
                    selectedBottleType = value.value;

                    if (selectedBatchType == "Batch Size") {
                      selectedBottleTypeVal = batchBottle()[value.value];
                    } else {
                      selectedBottleTypeVal = mainbatchBottle()[value.value];
                    }
                    if (TotalOunceWithDilution != 0) {
                      totalOunceYieldValue();
                    }
                    refreshlist();
                  }}
                />
              </div>
              <div style={{
                width: '10%', display: 'flex',
                justifyContent: "center",
                alignItems: "center",
              }}>
                <TextField
                  disabled={viewOnly}
                  InputLabelProps={{
                    style: {

                      textAlign: "center",
                      fontWeight: "300",
                      color: "black",
                      // left: -10,
                      height: 35,
                      fontSize: 15,
                      //flex: 1,
                      //flexWrap: 'wrap'
                    }
                  }}
                  inputProps={{
                    style: {
                      //width: "30px",
                      height: "5px",
                    },
                  }}
                  fullWidth
                  defaultValue={runtimebatchQuantity.toString()}
                  style={{ backgroundColor: "#fff", borderRadius: 10, }}
                  required
                  id="cost"
                  name="cost"
                  //autoComplete="email"
                  //autoFocus
                  hiddenLabel={true}
                  onChange={event => {
                    const { value } = event.target;
                    // console.log(value);
                    let qty = (isNaN(value)) ? 0 : value
                    if (qty > 0) {
                      batchQuantity = parseFloat(qty);
                      previousbatchQuantity = batchQuantity
                      if (selectedBatchType != "Guest Count") {
                        generateAssumed();
                      }
                      if (selectedBatchType == "By main item") {
                        
                        if (selectedIngredient.length > 0) {
                          var selectedVal = 0;
                          itemrender.forEach((item) => {
                            if (item.itemname == selectedIngredient) {
                              selectedVal = item.totalOunces;
                            }
                          });
                          selectedIngredientVal = selectedVal;
                        }
                      }
                      if (TotalOunceWithDilution != 0) {
                        totalOunceYieldValue();
                      }
                     
                      refreshlist();
                    }
                    else {
                      batchQuantity = previousbatchQuantity
                      setruntimebatchQuantity(batchQuantity)
                    }
                    // setruntimebatchQuantity(text)
                  }}
                />

              </div>
              <div style={{ width: '30%', marginLeft: 5, marginRight: 5 }}>
                <SelectDropdown
                  dropdownStyle={{
                    alignItems: "center",
                    height: 30,
                    justifyContent: "center",
                  }}
                  disabled={viewOnly}
                  value={
                    ingrediantArray.length > 0 ? ingrediantArray[0] : ""
                  }
                  options={ingrediantArray}
                  onChange={(value, index) => {
                    selectedIngredient = value.value;
                    var selectedVal = 0.0;
                    itemrender.forEach((item) => {
                      if (item.itemname == value.value) {
                        selectedVal = item.totalOunces;
                      }
                    });
                    selectedIngredientVal = selectedVal;
                    refreshlist();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            display: 'flex',
            // backgroundColor:"grey",
            height: '40px',

            borderBottomWidth: 1,
            borderBottomColor: "#80b3ff",
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '35%',
              // backgroundColor:"red",
              alignItems: "center",
              justifyContent: "center",
              height: 30,
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "300",
                color: "black",
                textAlign: 'center'
              }}
            >
              Item
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              width: '10%',
              // backgroundColor:"grey",
              alignItems: "center",
              justifyContent: "center",
              height: 30,
              //left: -15
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "300",
                color: "black",
                textAlign: 'center'
                //left: 12,
              }}
            >
              Qty
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              width: '30%',
              //backgroundColor:"red",
              alignItems: "center",
              justifyContent: "center",
              height: 30,
              //left: -15
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "300",
                color: "black",
                textAlign: 'center'
              }}
            >
              Unit
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              width: '15%',
              // backgroundColor:"grey",
              alignItems: "center",
              justifyContent: "center",
              height: 30,
            }}
          >
            <p
              style={{
                fontSize: 13,
                fontWeight: "300",
                //backgroundColor:"red",
                color: "black",
                textAlign: 'center'
                //right: 5,
                // width:80,
                // height:30
              }}
            >
              Total Ounces
            </p>
          </div>
          {!viewOnly && <div
            style={{
              width: "5%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >

          </div>}
        </div>

        <div
          style={{
            backgroundColor: "white",
            flex: 1,
          }}
        >

          <FlatList
            //  scrollEnabled={false}
            list={render}
            renderItem={renderFlatItem}
          ></FlatList>
        </div>

      </div>

    )
  }

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });


  const handleGeneratePdf = () => {

    //   html2canvas(screenshotref.current).then(canvas => {
    //     //document.body.appendChild(canvas);  // if you want see your screenshot in body.
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF();
    //     pdf.addImage(imgData, 'PNG', 0, 0);
    //     pdf.save("download.pdf"); 
    // });

    // const doc = new jsPDF({
    //   format: 'a4',
    //   unit: 'px',
    // });

    // // Adding the fonts.
    // //doc.setFont('Inter-Regular', 'normal');

    // doc.html(screenshotref.current, {
    //   async callback(doc) {
    //     await doc.save('document');
    //   },
    // });

    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    });

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');

    // doc.html(reportTemplateRef.current, {
    //   async callback(doc) {
    //     await doc.save('document');
    //   },
    // });
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#D28816';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const openImageViewer = useCallback((index) => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <>
      <div style={{ flex: 1 }} id="pdf" >
        <div
          style={{
            flexDirection: "row",
            display: 'flex',
            //marginHorizontal: 20,
            // marginTop: 40,
            alignItems: "center",
            justifyContent: "space-between",
            width: '98%'
          }}
        >
          <Button
            onClick={() => {
              navigate(-1)
            }}>
            <AiOutlineArrowLeft
              style={{
                width: 20,
                height: 20,

                color: "#fff",
              }}
            /></Button>




          <p
            style={{
              fontSize: 17,
              color: "white",
              fontWeight: "400",
              //backgroundColor:'red',
              //width: 200, 
              left: 10,
              textAlign: 'center'
            }}
          >
            {recipeName}
          </p>




          <div>

            <Button
              onClick={() => {
                let premium_subscribed = (Utility.currentuser != null && Utility.currentuser.premium_subscribed)
                if (premium_subscribed == false) {
                  navigate("/Premium")
                  return
                }
                setIsOpen(true);
              }}>

              <p style={{ color: 'white', fontSize: 13, }}>Create New Item</p>

            </Button>

            <Button
              onClick={() => {
                // handlePrint()
                let combinedArray = [
                  ...itemrender,
                  ...dilutionrender,
                  ...garnishrender,
                  ...floatRender,
                ];
                navigate('PDFTemplate', {
                  state: {
                    totalOunces: TotalOunces,
                    totalYields: TotalYield,
                    totalBatchedOunces: TotalBatchedOunces,
                    recipename: recipeName,
                    recipeimageurl: recipeimageurl,
                    itemrender: itemrender,
                    dilutionrender: dilutionrender,
                    garnishrender: garnishrender,
                    floatRender: floatRender,
                    glassware: selectedGlassware,
                    glasswareArray: glasswareArrayStrings()
                  }
                })
              }}>
              <AiFillPrinter
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>
            <Button
              //disabled={viewOnly}
              onClick={() => sheetref.current.open()}>
              <p
                style={{
                  fontSize: 13,
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Next
              </p>
            </Button>
          </div>

        </div>

        {mainContent()}





      </div>




      <Modal
        isOpen={recipeModal}
        onRequestClose={() => {
          setrecipeModal(false)
        }}
        style={customStyles}
        contentLabel=""
      >
        <h2 >Add Recipe name</h2>
        <div
          style={{
            // width: "90%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'

          }}
        >
          <TextField
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: '#f7a828',
              }
            }}
            inputProps={{
              style: {
                height: "5px",
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: 10, }}
            required
            value={recipeName}
            id="recipe"
            name="recipe"
            autoFocus
            hiddenLabel={true}
            onChange={event => {
              const { value } = event.target;
              setrecipeName(value)
            }}
          />
        </div>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setrecipeModal(false);
            uploadDataToServer(recipeName)
          }}
        >
          Submit
        </Button>

        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setrecipeModal(false);
          }}
        >
          Cancel
        </Button>
      </Modal>

      <Modal

        isOpen={shareModal}

        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setshareModal(false)
        }}
        style={customStyles}
        contentLabel=""
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: 'flex',
            flex: 1,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "70%",
              alignItems: "center",
              // display: 'flex',
              borderRadius: 15,
            }}
          >

            <Button
              onClick={() => {
                setshareModal(false);
                shareWithUser = true
                setTimeout(() => {
                  setrecipeModal(true);
                }, 200);

              }}
              style={{
                width: "100%",
                height: 35,
                borderBottomColor: "grey",
                borderBottomWidth: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#1c68ff",
                }}
              >
                Share with User
              </p>
            </Button>
            {/* <Button
              style={{
                width: "100%",
                height: 40,
                borderBottomColor: "grey",
                borderBottomWidth: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setshareModal(false);
                sharePressed()
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#1c68ff",
                }}
              >
                Share Online
              </p>
            </Button> */}
            <Button
              onClick={() => {
                setshareModal(false);
                navigate("/NetworksTeams", {
                  state: {
                    RecipeName: recipeName,
                    dociID: dociID,
                    shareRecipe: true
                  }
                });

              }}
              style={{
                width: "100%",
                height: 40,
                borderBottomColor: "grey",
                borderBottomWidth: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#1c68ff",
                }}
              >
                Share with Network
              </p>
            </Button>

            <Button
              onClick={() => setshareModal(false)}
              style={{
                width: "100%",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "600",
                  color: "#1c68ff",
                }}
              >
                Cancel
              </p>
            </Button>
          </div>
        </div>
      </Modal>


      <ActionSheet ref={sheetref}>
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: '10%', marginRight: '10%' }}>
          <Button
            style={{ backgroundColor: "#f7a828" }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              sheetref.current.close()
              setTimeout(() => {
                setshareModal(true)
              }, 200);
            }}
          >
            Share Recipe
          </Button>

        </div>

        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: '10%', marginRight: '10%' }}>

          <Button
            style={{ backgroundColor: "#f7a828" }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              sheetref.current.close()
              let premium_subscribed = (Utility.currentuser != null && Utility.currentuser.premium_subscribed)
              if (premium_subscribed == false) {
                navigate("/Premium")
                return
              }
              isViewingCosting = true
              if (dociID != null) {
                movetoCosting()
                return
              }
              setTimeout(() => {
                setrecipeModal(true);
              }, 200);

            }}
          >
            Cost Recipe
          </Button>
        </div>




        {!viewOnly ? (<div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: '10%', marginRight: '10%' }}><Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            sheetref.current.close()
            setTimeout(() => {
              setrecipeModal(true);
            }, 200);
          }}
        >
          Finish Recipe
        </Button></div>) : null}

        {!viewOnly ? (<div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: '10%', marginRight: '10%' }}><Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            sheetref.current.close()
            clearFullData();
          }}
        >
          Clear Template
        </Button></div>) : null}


        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: '10%', marginRight: '10%' }}>

          <Button
            style={{ backgroundColor: "#f7a828" }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              sheetref.current.close()
              navigate("ItemDetails", {
                state: {
                  headername: "Shopping List",
                  dociID: dociID,
                  viewOnly: viewOnly,
                  craftName: recipeName,
                  isShopping: true,
                  myItemArray: [...itemrender],
                  dilutionArray: [...dilutionrender],
                  floatArray: [...floatRender],
                  ingrediantArray: [...ingrediantArray],
                  garnishArray: [...garnishrender],
                  QTYValue: batchQuantity,
                  selectedBatchType: selectedBatchType,
                  selectedBottleType: selectedBottleType,
                  selectedIngredient: selectedIngredient,
                  selectedIngredientVal: selectedIngredientVal,
                  selectedBottleTypeVal: selectedBottleTypeVal,
                  TotalOunceWithDilution: TotalOunceWithDilution,
                  AssumedBatchValue: AssumedBatchValue,
                  TotalOunces: TotalOunces,
                  TotalYield: TotalYield,
                }
              });
            }}
          >
            Shopping List
          </Button>
        </div>

        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: '10%', marginRight: '10%' }}>

          <Button
            style={{ backgroundColor: "#f7a828" }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              sheetref.current.close()
              navigate("ItemDetails", {
                state: {
                  headername: "Exact Amount",
                  dociID: dociID,
                  viewOnly: viewOnly,
                  craftName: recipeName,
                  isShopping: false,
                  myItemArray: [...itemrender],
                  dilutionArray: [...dilutionrender],
                  floatArray: [...floatRender],
                  ingrediantArray: [...ingrediantArray],
                  garnishArray: [...garnishrender],
                  QTYValue: batchQuantity,
                  selectedBatchType: selectedBatchType,
                  selectedBottleType: selectedBottleType,
                  selectedIngredient: selectedIngredient,
                  selectedIngredientVal: selectedIngredientVal,
                  selectedBottleTypeVal: selectedBottleTypeVal,
                  TotalOunceWithDilution: TotalOunceWithDilution,
                  AssumedBatchValue: AssumedBatchValue,
                  TotalOunces: TotalOunces,
                  TotalYield: TotalYield,
                }
              });
            }}
          >
            Exact Amount
          </Button>
        </div>

        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: '10%', marginRight: '10%' }}>

          <Button
            style={{ backgroundColor: "transparent", color: 'black' }}
            // backgroundColor={"red"}
            type="submit"
            fullWidth
            variant="contained"
            // sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              sheetref.current.close()
            }}
          >
            Cancel
          </Button>
        </div>


      </ActionSheet>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {showSharebutton ? <div
        style={{
          // backgroundColor: 'red',
          // display: 'flex',
          position: 'absolute',
          alignItems: "flex-end",
          marginHorizontal: 20,
          marginTop: 5,
          marginBottom: 10,
          left: 0,
          bottom: 20
        }}
      >
        <Button
          onClick={() => {
            sharePressed()
          }}
          style={{
            height: 60,
            width: 60,
            justifyContent: "center",
            alignItems: "center",
            //backgroundColor: "#ffac30",
            borderRadius: 32,
          }}
        >
          <img
            style={{
              height: 50,
              width: 50,
            }}
            src={require("../images/sharebt.png")}
          />
        </Button>
      </div> : null}


      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel=""
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Add Item</h2>
        <div
          style={{
            // width: "90%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'

          }}
        >
          <TextField
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: '#f7a828',
              }
            }}
            inputProps={{
              style: {
                height: "5px",
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: 10, }}
            required
            id="item"
            name="item"
            autoFocus
            hiddenLabel={true}
            onChange={event => {
              const { value } = event.target;
              setnewItem(value)
            }}
          />
        </div>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setIsOpen(false)
            Utility.AddItem(newItem)
          }}
        >
          Add
        </Button>

        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setIsOpen(false)
          }}
        >
          Cancel
        </Button>
      </Modal>

      {isViewerOpen && (
        <ImageViewer
          src={[recipeimageurl]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={false}
          onClose={closeImageViewer}
        />
      )}



      <Modal
        isOpen={showVideoModal.isVisible}
        onRequestClose={() => {
          setshowVideoModal({
            isVisible: false,
            dataurl: '',
          })
        }}
        style={customStyles}
        contentLabel=""
      >
        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setshowVideoModal({
              isVisible: false,
              dataurl: '',
            })
          }}
        >
          Close
        </Button>

        <ReactPlayer
          url={showVideoModal.dataurl}
          // width="100%"
          // height="100%"
          controls
          playing
          loop
        />
      </Modal>


    </>
  )
}

export default Recipe;
