import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

import InputBox from "../Component/InputBox";
import { AiOutlineUser } from "react-icons/ai";
import { AiFillLock } from "react-icons/ai";
import { BsFillEnvelopeFill } from "react-icons/bs";
import backgroundimg from '../images/backbar_login.png';
import splashBG from '../images/splashBG.png';
// import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { db, auth, frbase } from '../../src/Database_fb';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Utility from '../utility';
import { getAuth , createUserWithEmailAndPassword} from "firebase/auth";
import { useAlert } from 'react-alert-with-buttons'

// import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="grey"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://thecraftiq.com">
        The CraftIQ
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme();

export default function Signup({ setAuth }) {
  const alert = useAlert()

  const navigate = useNavigate();
  const [loading, setloading] = React.useState(false);
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let param = {
      email: data.get("email"),
      password: data.get("password"),
      name: data.get("name"),
    }

    if (reg.test(param.email) === false) {
      showAlert("Please enter valid email address");
     // emailSiginIn()
    }
    else if (param.name.length === 0) {
      showAlert("Please enter your name");
     }
    else if (param.password.length === 0) {
      showAlert("Please enter password");
     }
    else {
      emailSiginIn(param.email, param.password, param.name)
    }

    
  };

  function emailSiginIn(email, pass, name) {
    setloading(true)
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, pass)
      .then((userCredential) => {
        let username = email.split('@')[0];
        const user = userCredential.user;
        let data = {
            email: email.toLowerCase(),
            name: name,
            username: username,
            status: "active",
            premium_subscribed: false,
            uid: user.uid,
            updatedAt: frbase.firestore.FieldValue.serverTimestamp(),
            phone: "",
            imageUrl: "",
            deviceType: "Web",
            loginDevice: "Web",
            //fcmToken: Utility.FCMToken,
            tokens: frbase.firestore.FieldValue.arrayUnion(Utility.FCMToken)
          }
        db.collection("users")
          .doc(user.uid)
          .set(data)
          .then(() => {
            Utility.loadBackbar() 
            Utility.loadUser()
            setAuth(true);
            setloading(false)
          })

      })
      .catch(error => {
        setloading(false)
        if (error.code === 'auth/email-already-in-use') {
          showAlert('That email address is already in use!')
        }

        if (error.code === 'auth/invalid-email') {
          showAlert('That email address is invalid!')
        }

      });

  }

  function showAlert(message) {
    alert.open({
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            alert.close() 
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });
    
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${backgroundimg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            // backgroundColor: "red",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              //   my: 8,
              //   mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // backgroundColor: "#694b1b",
              backgroundImage: `url(${splashBG})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Box
              sx={{
                my: 16,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // backgroundColor: "#694b1b",

              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
              <div>
                <p style={{ color: "#f7a828", fontSize: 30 }}>WELCOME</p>
                <p style={{ color: "#fff" }}>Signup to your account</p>
              </div>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >


                <TextField
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                      color: '#f7a828'
                    }
                  }}
                  //sx={{ input: { color: 'red' } }}
                  style={{ backgroundColor: "#fff", borderRadius: 10 }}
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  //autoComplete="name"
                  autoFocus
                />
                <TextField
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                      color: '#f7a828'
                    }
                  }}
                  //sx={{ input: { color: 'red' } }}
                  style={{ backgroundColor: "#fff", borderRadius: 10 }}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                      color: '#f7a828'
                    }
                  }}
                  style={{ backgroundColor: "#fff", borderRadius: 10 }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}

                <Button
                  style={{ backgroundColor: "#f7a828" }}
                  // backgroundColor={"red"}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign up
                </Button>
                <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item>
                    <p style={{ display: "flex" }}>
                      <p style={{ fontSize: 14, color: "#fff", }}>
                        Already have account? {"  "}
                      </p>
                      <p style={{ fontSize: 14, color: "transparent", }}>
                        {".."}
                      </p>
                      <Link onClick={()=>{
                        navigate(-1)
                      }} variant="body2">
                        <p style={{ color: "#f7a828" }}>{' '}{" Login here"}</p>
                      </Link>
                    </p>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Box>
          
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      
    </ThemeProvider>
  );
}
const styles = {

}
