
export function itemDrop() {
  return [
    "Gin",
    "Whisky",
    "Vodka",
    "Rum",
    "Tequila",
    "Mezcal",
    "Cognac",
    "Bitters",
    "Vermouth | Dry",
    "Vermouth | Sweet",
    "1792 | 12 YR",
    "1792 | Bottled in Bond",
    "1792 | Full Proof",
    "1792 | Single Barrel",
    "1792 | Sweet Wheat",
    "Aberlour | 12 YR",
    "Aberlour | 16 YR",
    "Aberlour | 18 YR",
    "ABK | Hell | Das Blau",
    "Absinthe",
    "Absolut | Elyx",
    "Absolut | Lime",
    "ABSOLUT CITRON",
    "ABSOLUT MANDRIN",
    "ABSOLUT VODKA",
    "Acai berries",
    "Agave Nectar",
    "Akal Chai | Reserve",
    "Akashi | Single Malt",
    "Akashi | White Oak",
    "Almond",
    "Amaretto",
    "AMARETTO BOLS",
    "Amaretto di Saronno",
    "Amaretto di Saronno | Reserva",
    "Amaro",
    "Amaro Montenegro",
    "Amaro Nonino",
    "AMARO SFUMATO RABARBARO",
    "Ambhar | Anejo",
    "Ambhar | Plata",
    "Ambhar | Reposado",
    "Amer Picon Bitters",
    "Amethyst Lavender",
    "Amrut | Single Malt | Peated",
    "Ancho Reyes",
    "Ancho Reyes | Verde",
    "Angel's Envy | Bourbon",
    "Angel's Envy | Cask Strength | 2017",
    "Angel's Envy | Cask Strength | 2018",
    "Angel's Envy | Cask Strength | 2019",
    "Angel's Envy | Rum Cask Finish | Rye",
    "Angostora",
    "Antica Formula Carpano Vermouth",
    "Aperol",
    "Apple",
    "Apple Brandy",
    "Applejack",
    "Appleton Estate",
    "Apricot",
    "Apricot Brandy",
    "Ardbeg | 10 YR",
    "Ardbeg | Corryvreckan",
    "Ardbeg | Uigeadail",
    "Armagnac",
    "Arran | 10 YR |Arran",
    "Arran | 14 YR | Arran",
    "Auchentoshan | American Oak",
    "Auchentoshan | Three Wood",
    "Austin Eastciders | Original Dry",
    "Averna Amaro",
    "Aviation",
    "Avion | Reposado",
    "Avion Reserva 44 | Extra Añejo",
    "Azunia | Añejo | 2 YR",
    "Azunia | Blanco",
    "B&B",
    "Bacardí | Cuatro | Añejo",
    "Bacardí | Maestro de Ron | Gran Reserva",
    "Bacardí | Ocho | Reserva",
    "BACARDI BANANA RUM",
    "BACARDI LIGHT RUM",
    "BACARDI LIMON RUM",
    "Bacardi Rum",
    "Bacon",
    "Bailey's Irish Cream",
    "Baker's | 13 YR | Single Barrel",
    "Baker's | 7 YR",
    "Balcones | Baby Blue",
    "Balcones | Brimstone 106",
    "Balcones | Rumble Cask Reserve",
    "Balcones | Single Malt",
    "Balcones | Texas Rye",
    "Balcones | True Blue",
    "Bandero | Blanco",
    "Barton's Vodka",
    "Basil",
    "Basil Hayden | 10 YR",
    "Basil Hayden | 8 YR",
    "Basil Hayden | Dark Rye",
    "Basil Hayden | Two By Two",
    "Bastille | 1789",
    "Beef Bouillon",
    "Beef Broth",
    "Beefeater",
    "Beefeater | London Dry",
    "Beer",
    "Bell",
    "Belvedere",
    "Belvedere | Intense",
    "Belvedere | Lake Bartężek | Single Estate",
    "Belvedere | Smogóry Forest | Single Estate",
    "BELVEDERE CITRUS",
    "Benedictine",
    "Benromach | Organic | 10 YR",
    "Bernheim Wheat | 7 YR | KY",
    "Bib & Tucker",
    "Black",
    "Blackberries",
    "Blanton's",
    "Blanton's | Gold Edition",
    "Blended Scotch",
    "Blood Oath | The Pact No. 1",
    "Blood Oath | The Pact No. 2",
    "Blood Oath | The Pact No. 3",
    "Bloody Mary Mix",
    "Blue Cheese Olive",
    "Blueberries",
    "Blueberry",
    "Bogart's Gin",
    "Bogart's Vodka",
    "Bombay | London Dry",
    "Bombay | Sapphire | East | London Dry",
    "Bombay | Sapphire | London Dry",
    "Booker's",
    "Booker's | 30th Anniversary",
    "Botanist | Islay Dry",
    "Bowmore | 12 YR",
    "Bowmore | 15 YR",
    "Bowmore | 15 YR | Darkest Islay",
    "Boysenberry",
    "Brandy",
    "Breckenridge | Bourbon",
    "Brown Sugar",
    "Bruichladdich | 1986 | 30 YR",
    "Bruichladdich | Black Art | 1990",
    "Bruichladdich | Octomore | 08.3",
    "Bruichladdich | Octomore | 09.1",
    "Bruichladdich | Octomore | 10.1",
    "Bruichladdich | Octomore | 10.3",
    "Bruichladdich | Port Charlotte | Islay Single Malt | 10 YR",
    "Bruichladdich | Port Charlotte | Scottish Barley",
    "Bruichladdich | Port Charlotte | Second Edition | 10 YR",
    "Bruichladdich | The Classic Laddie",
    "Buchanan's | 12 YR",
    "Buchanan's | 18 YR",
    "Buchanan's | Master",
    "Bud Lite",
    "Budweiser",
    "Buffalo Trace",
    "Buffalo Trace | Experimental Collection | Seasoned Staves | 36 Months",
    "Bulleit | 10 YR",
    "Bulleit | Barrel Strength | Batch No. 2",
    "Bulleit | Bourbon",
    "Bulleit | Rye",
    "Bulleit Rye",
    "Bunnahabhain | 18 YR",
    "Bunnahabhian | 12 YR",
    "Bushmills",
    "Bushmills | Single Malt | 16 YR",
    "Bushmills | Single Malt | 21 YR",
    "Cachaça",
    "Calumet Farms",
    "Calvados",
    "Calyx",
    "Camarena | Silver",
    "Campari",
    "Canadian Club",
    "Candied Ginger",
    "Cantaloupe",
    "Capo | Amaro del Capo | Vecchio",
    "Captain Morgan | Spiced",
    "Caramel",
    "Caravedo | Mosto Verde",
    "Carrot",
    "Carvella | Limoncello",
    "Casa Dragones | Blanco",
    "Casa Dragones | Joven",
    "Casa Noblé | Crystal | Blanco",
    "Casa Noblé | Reposado",
    "Casamigos | Añejo",
    "Casamigos | Blanco",
    "Casamigos | Mezcal",
    "Casamigos | Reposado",
    "Casamigos Blanco",
    "Cashew",
    "Castello Banfi | Grappa",
    "Castelvetrano",
    "Celery",
    "Celery Salt",
    "Celery Stick",
    "Chambord",
    "Champagne",
    "Chareau Aloe Liqueur",
    "Chartreuse  | Green VEP",
    "Chartreuse  | Yellow VEP",
    "Chartreuse | Green",
    "Chartreuse | Yellow",
    "Chartruese | Green",
    "Chartruese | Yellow",
    "Cherry",
    "Cherry Heering",
    "Chestnut",
    "Chimay | Blue",
    "Chivas Regal | 12 YR",
    "Chivas Regal | 18 YR",
    "Chivas Regal | 25 YR",
    "Chocolate",
    "Chopin",
    "Christian Brothers Brandy",
    "Cincoro | Anejo",
    "Cincoro | Reposado",
    "Cinnamon",
    "Ciroc",
    "CIROC RED BERRY",
    "Clase Azul | Anejo",
    "Clase Azul | Día de Muertos",
    "Clase Azul | Guerrero | Mezcal",
    "Clase Azul | Mezcal",
    "Clase Azul | Plata",
    "Clase Azul | Reposado",
    "Clase Azul | Ultra Añejo",
    "Clove",
    "Cocchi Americano",
    "Coconut",
    "Codigo | 1530 | Rosa",
    "Coffee",
    "Cognac | V.S.",
    "Cognac | V.S. ",
    "Cognac | V.S.O.P",
    "Cognac | X.O.",
    "Cointreau",
    "Cointreau",
    "Cola",
    "Colonel E.H. Taylor | Amaranth",
    "Colonel E.H. Taylor | Barrel Proof",
    "Colonel E.H. Taylor | Warehouse C | Bourbon",
    "Combier | Pamplemousse",
    "Compass Box | Asyla",
    "Compass Box | Great King Street Glasgow",
    "Compass Box | Hedonism",
    "Compass Box | Oak Cross",
    "Compass Box | Orangerie",
    "Compass Box | Peat Monster 10th Aniversary | 2013",
    "Compass Box | Spice Tree",
    "Connemara | Peated Single Malt",
    "Coors Light",
    "Cornichons",
    "Courvoisier | VS",
    "Courvoisier | VSOP",
    "Courvoisier | XO",
    "Cranberries",
    "Cranberry Juice",
    "Cream",
    "Cream of Coconut",
    "Crème de Cacao | Dark",
    "Crème de Cacao | White",
    "Crème de Cassis",
    "Crème de Menthe | Green",
    "Crème de Menthe | White",
    "Crème de Mure",
    "CREME DE VIOLETTE",
    "Crème Yvette",
    "Crown Royal",
    "Crown Royal | Black",
    "Crown Royal | Reserve",
    "Crown Royal | Rye",
    "Crown Royal | XO",
    "Crown Royal | XR",
    "CROWN ROYAL REGAL APPLE",
    "Cruzan",
    "Cruzan | Blackstrap",
    "Crystal Head",
    "Cucumber",
    "Cumin Tincture",
    "Cutwater | Devil's Share | Small Batch | CA",
    "Cynar",
    "D'USSÉ | VSOP",
    "Daily's | Grenadine",
    "Daily's | Simple Syrup",
    "Daily's | Sweet and Sour",
    "Daily's | Triple Sec",
    "Dalwhinnie | 15 YR",
    "DARON FINE CALVADOS",
    "Deep Eddy | Ruby Red",
    "Dehydrated Lemon",
    "Del Maguey | Pechuga",
    "Del Maguey | Tobala",
    "Del Maguey | Wild Jabali",
    "Delamain | Pale & Dry",
    "Delamain | Vesper",
    "Dewar's | Signature",
    "Dewar's | The Ancestor | 12 YR",
    "Dewar's | The Vintage | 18 YR",
    "Dewar's | White Label",
    "Dewar's | White Label | Scratched Cask",
    "Dewar's Aberfeldy | 12 YR",
    "Dewars",
    "Disaranno | Velvet Cream Liqour",
    "Domaine de Canton Ginger Liqueur",
    "Domaine de la Romanee Conti | Marc de Bourgogne | '95",
    "Don Julio | 1942",
    "Don Julio | 70th Anniversary",
    "Don Julio | Añejo",
    "Don Julio | Blanco",
    "Don Julio | Real",
    "Don Julio | Reposado",
    "Don Pancho | Origenes | Reserva | 18 YR",
    "Don Q | Añejo",
    "Don Q | Double Aged | Sherry Cask",
    "Dorda | Double Chocolate",
    "Dorda | Sea Salt Caramel",
    "Dos Equis",
    "Double Cross",
    "Drambuie",
    "Drambuie",
    "Dry Curaçao",
    "Dry Sack",
    "Dry Vermouth",
    "Dubonnet | Blanc",
    "Dubonnet | Red",
    "Dubonnet Blanc",
    "Dubonnet Rouge",
    "E.H. Taylor | 18 Year Marriage",
    "E.H. Taylor | Single Barrel",
    "E.H. Taylor | Small Batch",
    "E.H. Taylor | Straight Rye",
    "Eagle Rare | 17 YR",
    "Eagle Rare | Single Barrel",
    "Edible Flowers",
    "Edinburgh Gin Distillery | Rhubarb and Ginger",
    "Egg White",
    "Egg Yolk",
    "Eggs White",
    "El Silencio | Espadin | Mezcal",
    "Elderberry",
    "Elijah Craig | 18 YR",
    "Elijah Craig | 23 YR | Single Barrel",
    "Elijah Craig | Single Barrel 18 yr | Bourbon",
    "Elijah Craig | Small Batch",
    "Elijah Craig | Small Batch | Barrel Proof",
    "Elijah Craig | Toasted Barrel",
    "Elmer T. Lee | 100 Year Tribute",
    "Elmer T. Lee | Single Barrel",
    "Enchanted Rock",
    "Evan Williams | Single Barrel",
    "Evan Williams Kitchen Bourbon",
    "Everclear",
    "Ezra Brooks | Old Ezra | Barrel Strength | 7 YR",
    "Falernum",
    "Fat Ice",
    "Fennel",
    "Fernet Branca",
    "Fever Tree | Club Soda",
    "Fever Tree | Ginger Ale",
    "Fever Tree | Ginger Beer",
    "Fever Tree | Pink Grapefruit",
    "Fever Tree | Tonic Water",
    "FIREBALL WHISKEY",
    "Flower Pedals",
    "Fonseca Bin 27",
    "Forty Creek",
    "Forty Creek | Confederation",
    "Four Roses",
    "Four Roses | Single Barrel",
    "Four Roses | Small Batch",
    "Four Roses | Small Batch | 130th Anniversary",
    "Frangelico",
    "Fre | Non-Alcoholic | Sparkling Brut",
    "Fresh Limes",
    "Gabriel Boudier | Caramel Liqueur",
    "Galliano",
    "Garrison Brothers | Balmorhea",
    "Garrison Brothers | Cowboy Bourbon",
    "Garrison Brothers | Single Barrel",
    "Garrison Brothers | Small Batch",
    "Garrison Brothers | Straight Bourbon",
    "Genever",
    "George Dickel | 8 year",
    "George Dickel | 8 YR",
    "George Dickel | Barrel Select",
    "George Dickel | Green Label",
    "George Dickel | Rye",
    "George T. Stagg",
    "GERMAIN ROBIN XO",
    "Giffard | Banane du Brésil",
    "Gin | London Dry",
    "Gin | Plymouth",
    "GIN MARE GIN",
    "Ginger",
    "Ginger Shrub",
    "Ginja D'Obidos Wild Cherry Liqueur",
    "Glen Moray | 12 YR",
    "Glen Moray | 18 YR",
    "Glen Moray | Chardonnay Cask Finish",
    "Glen Moray | Classic Peated",
    "Glen Scotia | 15 YR",
    "Glendronach | 12 YR",
    "Glendronach | 18 YR",
    "Glendronach | 21 YR | Parialment",
    "Glenfarclas | 25 YR",
    "Glenfiddich | 12 YR",
    "Glenfiddich | 14 YR",
    "Glenfiddich | 18 YR",
    "Glenfiddich | 21 YR",
    "Glenfiddich | 26 YR",
    "glenfiddich | 30 YR",
    "Glenfiddich | 50 YR | 1oz",
    "Glenfiddich | Grand Cru | 23 YR",
    "Glenfiddich | IPA",
    "Glenfiddich | Project XX",
    "Glenfiddich | Reserva Rum Cask | 21 YR",
    "Glenfiddich | Solera Reserve | 15 YR",
    "Glenglassaugh | Evolution",
    "Glenglassaugh | Revival",
    "Glenkinchie | 12 YR",
    "Glenmorangie | 10 YR",
    "Glenmorangie | Extremely Rare | 18 YR",
    "Glenmorangie | Grand Vintage | 1991",
    "Glenmorangie | Lasanta | 12 YR",
    "Glenmorangie | Nectar d'Or | 12 YR",
    "Glenmorangie | Pride | 1974 | 1oz",
    "Glenmorangie | Quinta Ruban | 12 YR",
    "Glenmorangie | Signet",
    "Glenrothes | Whisky Maker's Cut",
    "Godiva | White",
    "Gooseberries",
    "Gosling's | Black Seal",
    "Goslings Family | Reserve | Old Rum",
    "Grán Patrón | Burdeos",
    "Grán Patrón | Piedra | Extra Añejo",
    "Grán Patrón | Platinum",
    "Grand Marnier",
    "Grand Marnier | 100 YR",
    "Grapefruit",
    "Grapefruit Juice",
    "Grapes",
    "GRAPPA JACOPO POLI MOSCATO",
    "Gray Whale",
    "Green Beans",
    "Green Pepper",
    "Green Tabasco",
    "Grenadine",
    "Grey Goose",
    "GREY GOOSE CHERRY NOIR VODKA",
    "GREY GOOSE L'ORANGE",
    "GREY GOOSE LE CITRON",
    "GREY GOOSE PEAR",
    "Guinness | Stout",
    "Gum syrup",
    "Hakushu | Single Malt | 12 YR",
    "Hakushu| Single Malt | 18 YR",
    "Harvey's Bristol Cream Sherry",
    "Hayman's Old Tom Gin",
    "Heaven Hill | Barrel Proof | 27 YR",
    "Heaven's Door | Double Barrel",
    "Heaven's Door | Straight Rye",
    "Heaven's Door | Tennessee Bourbon",
    "Heavy Cream",
    "Heineken",
    "Heineken | 0.0",
    "Hendrick's",
    "Hennessy | Paradis | Extra Rare",
    "Hennessy | Paradis Impérial",
    "Hennessy | Privilege | VSOP",
    "Hennessy | Richard",
    "Hennessy | VS",
    "Hennessy | XO",
    "Henry McKenna | Single Barrel | 10 YR",
    "Herman Marshall | Bourbon",
    "Herman Marshall | Single Malt",
    "Herman Marshall | Texas Rye",
    "Herradura | Legend",
    "Herradura | Selección Suprema",
    "Hibiki | 17 YR",
    "Hibiki | 21 YR",
    "Hibiki | Japanese Harmony",
    "High West | American Prairie | UT",
    "High West | Campfire | UT",
    "High West | Double Rye",
    "High West | Midwinter's Nights Dram | UT",
    "High West | Rendezvous",
    "High West | The 36th Vote",
    "High West | Yippee Ki-Yay",
    "Highland Park | 12 YR | Orkney",
    "Highland Park | 18 YR | Orkney",
    "Highland Park | 21 YR",
    "Highland Park | 40 YR",
    "Highland Park | Dark Orgins | Orkney",
    "Highland Park | Magnus | Orkney",
    "Hochstadter's",
    "Honey",
    "Honeydew",
    "Horseradish",
    "Hudson | Baby Bourbon | Small Batch | NY",
    "Hudson | Manhattan",
    "Hudson | NY Corn | NY",
    "Ice",
    "Ichiro's | Malt & Grain",
    "Illegal Mezcal| Joven",
    "ITALICUS CORDIAL",
    "J&B",
    "Jack Daniel's | Eric Church | Single Barrel",
    "Jack Daniels | Gentleman Jack",
    "Jack Daniels | No. 27 | Maple Finish",
    "Jack Daniels | No.7",
    "Jack Daniels | Sinatra Select",
    "Jack Daniels | Single Barrel",
    "Jack Daniels | Single Barrel | Rye",
    "JAGERMEISTER",
    "Jaja | Blanco",
    "Jaja | Reposado",
    "Jalapeno",
    "Jalepeno",
    "Jalepeno Tincture",
    "Jamaican Rum",
    "Jameson",
    "Jameson | 18 YR",
    "Jameson | Black Barrel",
    "Jameson | Caskmates | Cask Edition",
    "Jefferson's | Ocean Aged at Sea",
    "Jefferson's | The Manhattan",
    "Jefferson's Reserve",
    "Jefferson's Reserve | Old Rum Cask Finish",
    "Jefferson's Reserve | Pritchard Hill Cabernet Cask",
    "Jim Beam",
    "Jim Beam | Distiller's Cut",
    "John Powers",
    "Johnnie Walker | Black",
    "Johnnie Walker | Blue",
    "Johnnie Walker | Gold Reserve",
    "Johnnie Walker | Green",
    "Johnnie Walker | King George V",
    "Johnnie Walker | Platinum",
    "Johnnie Walker | Red",
    "Jura | 10 YR | Origin",
    "Kahlua",
    "Kaiyo | Cask Strength | Mizunara",
    "Kaiyo | Mizunara",
    "Kalamata",
    "Kamiki",
    "Karbach | Hopadillo",
    "Kavalan | Classic",
    "Kavalan | Concertmaster",
    "Kavalan | Vinho Barrique | Cask Strength",
    "Kentucky Owl | 11 YR",
    "Kentucky Owl | Confiscated",
    "Kentucky Owl | Dry State",
    "Kerrygold Irish Cream",
    "Ketel One",
    "KETEL ONE BOTANICAL CUCUMBER & MINT VODKA",
    "KETEL ONE CITROEN VODKA",
    "Kikori",
    "Kilbeggan | Single Grain",
    "Kirk & Sweeney | 18 YR",
    "Kitchen Wine Red",
    "Kitchen Wine White",
    "Kiwi",
    "Knob Creek | 25th Anniversary Single Barrel",
    "Knob Creek | 9 YR",
    "Knob Creek | Limited Edition | 2001",
    "Knob Creek | Single Barrel Reserve 120 Proof",
    "Knob Creek Rye",
    "Koval | Single Barrel",
    "Krogstad Aquavit",
    "Kronan Swedish Punsch",
    "Kübler Absinthe",
    "Lagavulin | 16 YR",
    "Lagavulin | 25 YR",
    "Lagavulin | 8 YR",
    "Lagavulin | Distiller's Edition | 2013",
    "Laphroaig | 10 YR",
    "Laphroaig | Lore",
    "Laphroaig | Quarter Cask",
    "Laphroaig | Triple Wood",
    "Larceny",
    "Larressingle | VSOP",
    "Larressingle | XO",
    "Lavender",
    "Legent | Bourbon",
    "Lemon",
    "Lemon Juice",
    "Lemon zest",
    "Licor 43",
    "Lillet |  Blanc",
    "Lillet | Rose",
    "Lime",
    "Lime Juice",
    "LIMONCELLO DI CAPRI",
    "Liquid Alchemist | Apple Spice Syrup",
    "Little Book | Chapter 2 Noe Simple Task | US, CAN",
    "Loch Lomond | 18 YR",
    "Lollipops",
    "Longrow Rundlets & Kilderkins | 11 YR",
    "Lot #40 | Rye",
    "Lotus Root",
    "Luxardo Cherries",
    "Luxardo Cherry",
    "Luxardo Marachino Liqueur",
    "Luxardo Maraschino Liqueur",
    "Lychee",
    "Macadamia",
    "Macallan | No. 6",
    "Macallan | Reflexion",
    "Macallan | Relflexion",
    "Maestro Dobel | Reposado",
    "Maker's Mark",
    "Maker's Mark | 46",
    "Maker's Mark | Cask Strength",
    "MALIBU COCONUT RUM",
    "Mangos",
    "Manzanilla",
    "Maraschino Cherry",
    "Mars Shinshu | Iwai | Tradition",
    "Martell | Cordon Bleu",
    "Martell | XO",
    "Martinique Rum",
    "Masterson's | Rye | 10 YR",
    "MAURIN QUINA LIQUEUR",
    "Medlars",
    "Meletti Amaro",
    "Michelob | Ultra",
    "Michter's | Barrel Strength | Rye",
    "Michter's | Single Barrel | 10 YR",
    "Michter's | Single Barrel | 20 YR",
    "Michter's | Single Barrel | 25 YR",
    "Michter's | Single Barrel | Straight Rye | 10 YR",
    "Michter's | Small Batch | Bourbon",
    "Michter's | Small Batch | Sour Mash | KY",
    "Michter's | Toasted Barrel Finish | Sour Mash | KY",
    "Michter's American Whiskey",
    "Midleton | Very Rare",
    "Midleton | Very Rare | 2017",
    "Midleton | Very Rare | 2018",
    "MIDORI MELON SUNTO CORDIAL",
    "Milagro | Select Barrel Reserve | Añejo",
    "Milagro | Silver",
    "Miller Lite",
    "Mint",
    "Mitchell & Son | Green Spot | Single Pot Still",
    "Mitchell & Son | Red Spot | 15 YR",
    "Mitchell & Son | Yellow Spot | 12 YR",
    "Monin | Banana | Puree",
    "Monin | Hibiscus",
    "Monin | Lavendar",
    "Monin | Mango | Puree",
    "Monin | Peach | Puree",
    "Monin | Pomegranite",
    "Monin | Spiced Brown Sugar",
    "Monin | Strawberry | Puree",
    "Monin | Watermelon",
    "Monkey 47 | Schwarzwald Dry",
    "Monkey Shoulder",
    "Mount Gay | Black Barrel",
    "Mount Gay | Eclipse",
    "Myers's | Dark",
    "NA Bev",
    "New Amsterdam",
    "Nikka | Coffey | Grain",
    "NIkka | From the Barrel",
    "Nikka | Miyagikyo | Single Malt",
    "Nikka | Taketsuru | Pure Malt",
    "Nikka | Yoichi | Single Malt",
    "Nikolaihof Vodka",
    "Noah's Mill | 114.3 Proof",
    "Noble Oak | Double Oak | Bourbon",
    "Noble Oak | Double Oak | Rye",
    "Noilly Prat",
    "Nolet's | Silver",
    "Nolet's | The Reserve",
    "Nutmeg",
    "O.F.C | 1993",
    "Oban | 14 YR",
    "Oban | 18 YR",
    "Oban | Distiller's Edition | 2017",
    "Oban | Little Bay",
    "Ocho Plata Tequila",
    "Ohishi  | Single Sherry Cask",
    "Okra",
    "Old Charter Oak | French Oak",
    "Old Charter Oak | Mongolian Oak",
    "Old Fitzgerald | 11 YR",
    "Old Fitzgerald | Bottle-in-Bond | 13 YR",
    "Old Fitzgerald | Bottle-in-Bond | 14 YR",
    "Old Forester | 100 Proof",
    "Old Forester | 100 Proof | RYE",
    "Old Forester | 1870",
    "Old Forester | 1897",
    "Old Forester | 1910",
    "Old Forester | 1920",
    "Old Forester | 86 Proof",
    "Old Forester | Birthday Bourbon",
    "Olive",
    "Olive Juice",
    "Olives",
    "Onion",
    "Orange",
    "Orange Flower Water",
    "Orange Juice",
    "Orgeat Syrup",
    "Orphan Barrel | Forager's Keep | 26 YR",
    "Orphan Barrel | Muckety-Muck | 24 YR",
    "Orphan Barrel | Rhetoric | 24 YR",
    "Orphan Barrel | Rhetoric | 25 YR",
    "Over Proof Rum",
    "Oxley",
    "Pappy Van Winkle | 15 YR",
    "Pappy Van Winkle | 20 YR",
    "Pappy Van Winkle | 23 YR",
    "Parker's | Heritage Collection | Barrel Finished",
    "Partida | Elegante",
    "Passion Fruit SyrupPatrón | Añejo",
    "Patrón | Reposado",
    "Patrón | Silver",
    "PATRON CITRONGE ORANGE LIQUEUR",
    "Patrón en Lalique | Series 1",
    "PATRON XO CAFE COFFEE LIQUEUR",
    "PAUL MASSON VS BRANDY",
    "Peach",
    "Peach Schnapps",
    "Pear",
    "Pecans",
    "Peerless | Bourbon",
    "Peerless | Mastro's Exclusive | Chocolate Oak",
    "Peerless Kentucky",
    "Pendleton",
    "Pendleton | 1910",
    "Pendleton | Midnight",
    "Pepper",
    "Pepperconi",
    "Pernod | Absinthe",
    "PERNOD CORDIAL",
    "Peroni",
    "Peychaud Bitters",
    "Peychaud's | Aperitivo",
    "PIERRE FERRAND DRY CURACAO CORDIAL",
    "Pikesville | 6 YR | Rye",
    "Pilsner Urquell",
    "Pimm's #1",
    "PIMMS CUP #1 CORDIAL",
    "Pineapple",
    "Pineapple Juice",
    "Pinenuts",
    "Pisco",
    "Pistachio",
    "PLANTATION PINEAPPLE RUM",
    "Plum",
    "Poli | Sarpa Barrique",
    "Port",
    "prarie organic vodka",
    "Prichard's",
    "Proper #12",
    "Prosecco",
    "Puerto Rican Rum",
    "Pumpkin",
    "Pura Vida | Gold",
    "Quince",
    "Rabbit Hole | Heigold",
    "Ranger Creek | .36",
    "Ranger Creek | .44",
    "Raspberries",
    "Raspberry Puree",
    "Raspberry Syrup",
    "Reagans | Orange Bitters",
    "Rebecca Creek | Blended",
    "Rebecca Creek | Small Batch",
    "Red Pepper",
    "Red Tabasco",
    "Red Wine",
    "Redbreast | 12 YR",
    "Redbreast | Lastau Edition | Sherry Cask",
    "Redbreast | Pot Still | 15 YR",
    "Redemption",
    "Redwood Empire | American Whisky | CA",
    "Rémy Martin | 1738",
    "Rémy Martin | Louis XIII",
    "Rémy Martin | Louis XIII | Black Pearl",
    "Remy Martin | VSOP",
    "Remy Martin | XO",
    "Reyka",
    "Rhum Agricole",
    "Rip Van Winkle | 10 YR",
    "Rip Van Winkle | Lot B | 12 YR",
    "Rittenhouse",
    "ROCA Patrón | Anejo",
    "ROCA Patrón | Reposado",
    "ROCA Patrón | Silver",
    "Rock Hill Farms",
    "Rock N Roll | Cristalino",
    "Ron del Barrilto | 3 Star",
    "Ron Zacapa | 23 YR",
    "Rosemary",
    "Rosemary Strawberry Syrup",
    "Rowan's Creek",
    "Rum | 151",
    "Rum | Dark",
    "Rum | Jamaican",
    "Rum | Light",
    "Rum | Navy Strength",
    "Rum | Spiced",
    "Rumchata",
    "Rumple Minze",
    "Russell's | Reserve",
    "Russell's | Reserve | Single Barrel",
    "Sagamore |  Cask Strength | Rye",
    "Sagamore | Rye",
    "Sagamore | Spirit | Double Oak",
    "Sage",
    "Sailor Jerry | Spiced",
    "salt",
    "Sam Adams | Boston Lager",
    "Samaroli | 'S Peaty",
    "Samaroli | Fettercairn | 95",
    "Samaroli | Jamaca Rhapsody",
    "Sambuca Black",
    "Sambuca Romana",
    "Sazerac | 18 YR",
    "Sazerac | Louisiana Rye | 6 YR",
    "Scotch",
    "Scrappy Bitters | Firewater Tincture",
    "Screwball Peanut Butter",
    "Seagram's | 7 Crown | CT",
    "Seagram's | V.O",
    "Sherry | Amontillado",
    "Sherry | Fino",
    "Sherry | Oloroso",
    "Sherry | PX",
    "Shiner | Bock",
    "Shrub/Tincture",
    "Simple Syrup",
    "Skrewball Peanut Butter",
    "SMITH & CROSS JAMAICA RUM",
    "Smooth Ambler | Contradiction | WV",
    "Solerno Blood Orange",
    "SOUTHERN COMFORT 70 CORDIAL",
    "Speyburn | 10 YR",
    "Springbank | 10 YR",
    "Springbank | 15 YR",
    "Sprite",
    "Squash",
    "Squirt",
    "St Arnold",
    "St Pauli ",
    "St-Germain Elderflower Liqueur",
    "St. George Absinthe",
    "St. Lucia Distillers | Forgotten Casks Chairman's Reserve",
    "Stagg Jr. | 130 Proof",
    "Star Anise",
    "Stella Artois",
    "Stolichnaya",
    "Stolichnaya | Elit",
    "Stolichnaya | Vanilla",
    "STOLICHNAYA BLUEBERI VODKA",
    "Stranahan's | Sherry Cask | Batch No. 5 | CO",
    "Strawberries",
    "Strawberry",
    "Sugar",
    "Sugar Cube",
    "Sumac Tincture",
    "Suntory | Toki",
    "Suze",
    "SVEDKA GRAPEFRUIT JALAPENO VODKA",
    "SVEDKA VANILLA VODKA",
    "SVEDKA VODKA",
    "Sweet Vermouth",
    "Syndicate 58/6 | 12 YR",
    "Syrup",
    "tabasco",
    "Tajin",
    "Talisker | 10 YR | Skye",
    "Talisker | 18 YR | Skye",
    "Talisker | Distiller's Edition | 2012 | Skye",
    "Talisker | Storm | Skye",
    "Tanqueray | London Dry",
    "Tanqueray | Nº TEN",
    "Teeling | Single Grain",
    "Teeling | Small Batch",
    "Templeton | 10 YR",
    "Templeton | 4 YR",
    "Templeton | 6 YR",
    "TEMPUS FUGIT GRAN CLASSICO BITTERS",
    "Tequila | Anejo",
    "Tequila | Añejo",
    "Tequila | Blanco",
    "Tequila | Cristalino",
    "Tequila | Extra Anejo",
    "Tequila | Extra Añejo",
    "Tequila | Reposado",
    "Tequila | Silver",
    "Teremana | Reposado",
    "Tesseron | Lot No.53 | Perfection | XO",
    "Tesseron | Lot No.76 | Passion | XO",
    "The Balvenie | 30 YR",
    "The Balvenie | 40 YR | 1oz",
    "The Balvenie | Caribbean Cask | 14 YR",
    "The Balvenie | Double Wood | 12 YR",
    "The Balvenie | Double Wood | 17 YR",
    "The Balvenie | Peat Week | 2003 | 14 YR",
    "The Balvenie | Portwood | 21 YR",
    "The Balvenie | Single Barrel | 12 YR",
    "The Balvenie | Single Barrel | 15 YR",
    "The Balvenie | Single Barrel | 25 YR",
    "The BenRiach | Curiositas Peated Malt",
    "The Dalmore | 12 YR",
    "The Dalmore | 15 YR",
    "The Dalmore | 18 YR",
    "The Dalmore | 25 YR",
    "The Dalmore | 35 YR",
    "The Dalmore | Cigar Malt Reserve",
    "The Dalmore | King Alexander",
    "The Dalmore | Portwood",
    "The Duncan Taylor by Bowmore 1982",
    "The Glenlivet | 12 YR",
    "The Glenlivet | 14 YR | Cognac Cask",
    "The Glenlivet | 18 YR",
    "The Glenlivet | 25 YR",
    "The Glenlivet | Archive | 21 YR",
    "The Glenlivet | French Oak | 15 YR",
    "The Glenlivet | Nadurra Oloroso | 16 YR",
    "The Glenrothes | 1995",
    "The Glenrothes | 1998",
    "The Glenrothes | 2001",
    "The Macallan | 12 YR",
    "The Macallan | 18 YR",
    "The Macallan | 25 YR",
    "The Macallan | 30 YR",
    "The Macallan | Double Cask | 12 YR",
    "The Macallan | Edition No.4",
    "The Macallan | Edition No.5",
    "The Macallan | Edition No.6",
    "The Macallan | Estate",
    "The Macallan | Exceptional Single Cask | #22156/07",
    "The Macallan | Exceptional Single Cask | #3019/06",
    "The Macallan | Exceptional Single Cask | #6513/05",
    "The Macallan | Exceptional Single Cask | #9064/03",
    "The Macallan | Fine Oak | 15 YR",
    "The Macallan | Fine Oak | 17 YR",
    "The Macallan | Fine Oak | 21 YR",
    "The Macallan | M",
    "The Macallan | Rare Cask",
    "The Tyrconnell | 16 YR",
    "The Tyrconnell | Madeira Cask Finished | 10 YR",
    "The Tyrconnell | Single Malt ",
    "Thomas Handy | Sazerac",
    "THREE OLIVES VANILLA",
    "Tia Maria",
    "Titanium | Blanco",
    "Tito's",
    "Tomato Juice",
    "Treaty Oak | Barrel Reserve",
    "Triple Sec",
    "Tuaca",
    "Tullamore D.E.W.",
    "Tullamore D.E.W. | Single Malt | 18 YR",
    "TX | Blended Whiskey",
    "TX | Staight Bourbon",
    "U-10 Shrimp",
    "Ultimat",
    "Uncle Nearest | 1884 | Small Batch",
    "Van Winkle Family Reserve | 13 YR",
    "Vanilla Extract",
    "Veneno | Blanco",
    "Veneno | Extra Anejo",
    "Veneno | Reposado",
    "Virginia Black | VA",
    "Vita Coco Original",
    "Viva | XXXII | Reposado",
    "VOLCAN BLANCO TEQUILA",
    "Volcan De Mi Tierra | Blanco",
    "Volcan De Mi Tierra | Cristalino",
    "Vya Sweet Vermouth",
    "Water",
    "Water Chestnut",
    "Watermelon",
    "Weihenstephaner | Hefeweiss",
    "WELL AMARETTO",
    "WELL BLUE CURACAO",
    "WELL BRANDY",
    "WELL BUTTERSCOTCH SCHNAP",
    "WELL CACAO DARK",
    "WELL CACAO WHITE",
    "WELL CASSIS",
    "WELL DRY VERMOUTH",
    "WELL MENTHE GREEN",
    "WELL MENTHE WHITE",
    "WELL PEACH SCHNAPPS",
    "WELL PEPPERMINT SCHN",
    "WELL SLOE GIN",
    "WELL SOUR APPLE",
    "Weller | CYPB",
    "Weller | Full Proof",
    "Weller | Single Barrel",
    "Weller | Special Reserve",
    "Weller | Wheated Bourbon | 12 YR",
    "Whiskey | Scotch",
    "Whiskey Hollow | Texas Whiskey",
    "Whisky | Bourbon",
    "Whisky | Irish",
    "Whisky | Japanese",
    "Whisky | Rye",
    "Whisky | Tennesse",
    "Whistle Pig | The Boss Hog | Samauri Scientist",
    "Whistlepig | 15 YR",
    "Whistlepig | 18 YR",
    "Whistlepig | Boss Hog | Black Prince | 14 YR",
    "Whistlepig | Boss Hog | Spirt of Muave | 13 YR",
    "Whistlepig | Farmstock",
    "Whistlepig | Old World | 12 YR",
    "Whistlepig | Straight Rye | 10 YR",
    "Whistlepig | Straight Rye | Single Barrel | 10 YR",
    "Whistlepig | The Boss Hog | Magellan's Atlantic",
    "White Rum",
    "White Wine",
    "Whole Egg",
    "Wild Turkey | 101",
    "Wild Turkey | 81",
    "Wild Turkey | Longbranch",
    "Wild Turkey | Master's Keep | Decades",
    "Willet | 8 YR",
    "Willet Reserve | Pot Still",
    "William Larue Weller",
    "Wine",
    "Wine | Red",
    "Wine | Rose",
    "Wine | White",
    "Woodford Reserve",
    "Woodford Reserve | Double Oaked",
    "Woodford Reserve | Master's Collection",
    "Woodford Reserve Rye",
    "Woodinville | Straight Bourbon  | WA",
    "Worcestershire",
    "Writer's Tears",
    "Yacht Club Vodka",
    "Yamazaki | 12 YR",
    "Yamazaki | 18 YR",
    "Yamazaki | 25 YR",
    "Yellow Pepper",
    "Yellow Rose | Maple Finish",
    "Yellow Rose | Outlaw",
    "Yellow Rose | Straight Rye",
    "Yogurt",
    "Zing Zang Bloody Mary Mix",
  ].sort((a, b) => a.localeCompare(b))
}

export function loadUserDetail() {

}

export function batchType() {
  return ["Guest Count", "Batch Size", "By main item"];
}

export function newbatchType() {
  return [{ label: "Guest Count", value: "Guest Count" },
  { label: "Batch Size", value: "Batch Size" },
  { label: "By main item", value: "By main item" }];
}

export function ShoppingBottleSizeItems() {
  return { "Liter": 33.85, "187ml": 6.358, "Ounce": 1, "1.75 Liter": 59.175, "750ml": 25.5, "Gallon": 128, "Pint": 16, "Quart": 32, "5oz": 5, "12oz": 12, "16oz": 16, "375ml": 12.75, "Cup": 8, "Tea Spoon": 0.167, "Table Spoon": 0.5, "ml": 0.034, "11.2oz": 11.2, "1.5 Liter": 50.721, "24oz": 24, "10oz": 10, "9oz": 9, "6oz": 6, "3oz": 3, "Cube": 1, "2oz": 2, "ea": 1 }

}

export function ShoppingBottleSizeItemsDD() {
  return [
    { label: "Liter", value: "Liter" },
    { label: "187ml", value: "187ml" },
    { label: "Ounce", value: "Ounce" },
    { label: "1.75 Liter", value: "1.75 Liter" },
    { label: "750ml", value: "750ml" },
    { label: "Gallon", value: "Gallon" },
    { label: "Pint", value: "Pint" },
    { label: "Quart", value: "Quart" },
    { label: "5oz", value: "5oz" },
    { label: "12oz", value: "12oz" },
    { label: "16oz", value: "16oz" },
    { label: "375ml", value: "375ml" },
    { label: "Cup", value: "Cup" },
    { label: "Tea Spoon", value: "Tea Spoon" },
    { label: "Table Spoon", value: "Table Spoon" },
    { label: "ml", value: "ml" },
    { label: "11.2oz", value: "11.2oz" },
    { label: "1.5 Liter", value: "1.5 Liter" },
    { label: "24oz", value: "24oz" },
    { label: "10oz", value: "10oz" },
    { label: "9oz", value: "9oz" },
    { label: "6oz", value: "6oz" },
    { label: "3oz", value: "3oz" },
    { label: "Cube", value: "Cube" },
    { label: "2oz", value: "2oz" },
    { label: "ea", value: "ea" },
  ]
}

export function ShoppingFloatSizeItems() {
  return { "Liter": 33.85, "187ml": 6.358, "Ounce": 1, "1.75 Liter": 59.175, "750ml": 25.5, "Gallon": 128, "Pint": 16, "Quart": 32, "5oz": 5, "12oz": 12, "16oz": 16, "375ml": 12.75, "Cup": 8, "Tea Spoon": 0.167, "Table Spoon": 0.5, "ml": 0.034, "11.2oz": 11.2, "1.5 Liter": 50.721, "24oz": 24, "10oz": 10, "9oz": 9, "6oz": 6, "3oz": 3, "Cube": 1, "2oz": 2, "ea": 1 }
}

export function batchBottle() {
  return { "Liter": 33.184, "187ml": 6.323, "Ounce": 1, "1.5 Liter": 50.721, "1.75 Liter": 57.175, "750ml": 25.3605, "Gallon": 128, "Pint": 16, "Quart": 32, "5oz": 5, "8oz": 8, "12oz": 12, "16oz": 16, "375ml": 12.6803, "Cups": 8 };
}
export function mainbatchBottle() {
  return { "750ml": 25.36, "Liter": 33.184, "16oz": 16, "5oz": 5, "375ml": 12.6803, "12oz": 12, "Cups": 8, "Ounce": 1, "1.5 Liter": 50.721, "1.75 Liter": 57.175, "187ml": 6.323, "Gallon": 128, "8oz": 8 }
}

export function batchUnit() {
  return { "Ounce": 1, "Dash": 0.03125, "Drop": 0.03125, "Bar Spoon": 0.125, "Cup": 8, "Cube": 0.0, "Pint": 16, "Taste": 0.0, "Quart": 32, "Gallon": 128, "750ml": 25.3605, "375ml": 12.071, "187ml": 0.0, "egg white": 0.5, "Rinse": 0.0125, "ml": 0.0338, "Splash": 0.2, "5oz": 5, "3oz": 3, "2oz": 2, "6oz": 6, "8oz": 8, "9oz": 9, "10oz": 10, "11.2oz": 11.2, "12oz": 12, "16oz": 16, "24oz": 24, "ea": 1, "half": 0.5, "Sprinkle": 0.0, "Quarter": 4, "Grams": 0.03968, "Tea Spoon": 0.166667, "Liter": 33.814, "1.75 Liter": 59.175, "1.5 Liter": 50.721, "Table Spoon": 0.5, "Mint Sprig": 160, "Mint Leaves": 59.175, "Sage Sprig": 1088, "Sage Leaves": 9.6, "Float": 0.5, "Dozen": 12, "U-10 Shrimp": 10 };
}

export function batchUnitDD() {
  return [
    { label: "Ounce", value: "Ounce" },
    { label: "Dash", value: "Dash" },
    { label: "Drop", value: "Drop" },
    { label: "Bar Spoon", value: "Bar Spoon" },
    { label: "Cup", value: "Cup" },
    { label: "Cube", value: "Cube" },
    { label: "Pint", value: "Pint" },
    { label: "Taste", value: "Taste" },
    { label: "Quart", value: "Quart" },
    { label: "Gallon", value: "Gallon" },
    { label: "750ml", value: "750ml" },
    { label: "375ml", value: "375ml" },
    { label: "187ml", value: "187ml" },
    { label: "egg white", value: "egg white" },
    { label: "ml", value: "ml" },
    { label: "Splash", value: "Splash" },
    { label: "5oz", value: "5oz" },
    { label: "3oz", value: "3oz" },
    { label: "2oz", value: "2oz" },
    { label: "6oz", value: "6oz" },
    { label: "8oz", value: "8oz" },
    { label: "9oz", value: "9oz" },
    { label: "10oz", value: "10oz" },
    { label: "11.2oz", value: "11.2oz" },
    { label: "12oz", value: "12oz" },
    { label: "16oz", value: "16oz" },
    { label: "24oz", value: "24oz" },
    { label: "ea", value: "ea" },
    { label: "half", value: "half" },
    { label: "Sprinkle", value: "Sprinkle" },
    { label: "Quarter", value: "Quarter" },
    { label: "Grams", value: "Grams" },
    { label: "Tea Spoon", value: "Tea Spoon" },
    { label: "Liter", value: "Liter" },
    { label: "1.75 Liter", value: "1.75 Liter" },
    { label: "1.5 Liter", value: "1.5 Liter" },
    { label: "Table Spoon", value: "Table Spoon" },
    { label: "Mint Sprig", value: "Mint Sprig" },
    { label: "Mint Leaves", value: "Mint Leaves" },
    { label: "Sage Sprig", value: "Sage Sprig" },
    { label: "Sage Leaves", value: "Sage Leaves" },
    { label: "Float", value: "Float" },
    { label: "Dozen", value: "Dozen" },
    { label: "U-10 Shrimp", value: "U-10 Shrimp" },
  ]
}

export function batchBottleNew () {
  return [{ label: "Liter", value: "Liter" },
  { label: "187ml", value: "187ml" },
  { label: "Ounce", value: "Ounce" },
  { label: "1.5 Liter", value: "1.5 Liter" },
  { label: "1.75 Liter", value: "1.75 Liter" },
  { label: "750ml", value: "750ml" },
  { label: "Gallon", value: "Gallon" },
  { label: "Pint", value: "Pint" },
  { label: "Quart", value: "Quart" },
  { label: "5oz", value: "5oz" },
  { label: "8oz", value: "8oz" },
  { label: "12oz", value: "12oz" },
  { label: "16oz", value: "16oz" },
  { label: "375ml", value: "375ml" },
  { label: "Cups", value: "Cups" },]
}

export function mainbatchBottleNew() {
 return [{ label: "750ml", value: "750ml" },
  { label: "Liter", value: "Liter" },
  { label: "16oz", value: "16oz" },
  { label: "5oz", value: "5oz" },
  { label: "375ml", value: "375ml" },
  { label: "12oz", value: "12oz" },
  { label: "Cups", value: "Cups" },
  { label: "Ounce", value: "Ounce" },
  { label: "1.5 Liter", value: "1.5 Liter" },
  { label: "1.75 Liter", value: "1.75 Liter" },
  { label: "187ml", value: "187ml" },
  { label: "Gallon", value: "Gallon" },
  { label: "8oz", value: "8oz" },]
}

export function Dillution() {
  return ["Dilution 35%", "Dilution 34%", "Dilution 33%", "Dilution 32%", "Dilution 31%", "Dilution 30%", "Dilution 29%", "Dilution 28%", "Dilution 27%", "Dilution 26%", "Dilution 25%", "Dilution 24%", "Dilution 23%", "Dilution 22%", "Dilution 21%", "Dilution 20%", "Dilution 19%", "Dilution 18%", "Dilution 17%", "Dilution 16%", "Dilution 15%", "Dilution 14%", "Dilution 13%", "Dilution 12%", "Dilution 11%", "Dilution 10%"];
}

export function DillutionDD() {
  return[
    { label: "Dilution 35%", value: "Dilution 35%" },
    { label: "Dilution 34%", value: "Dilution 34%" },
    { label: "Dilution 33%", value: "Dilution 33%" },
    { label: "Dilution 32%", value: "Dilution 32%" },
    { label: "Dilution 31%", value: "Dilution 31%" },
    { label: "Dilution 30%", value: "Dilution 30%" },
    { label: "Dilution 29%", value: "Dilution 29%" },
    { label: "Dilution 28%", value: "Dilution 28%" },
    { label: "Dilution 27%", value: "Dilution 27%" },
    { label: "Dilution 26%", value: "Dilution 26%" },
    { label: "Dilution 25%", value: "Dilution 25%" },
    { label: "Dilution 24%", value: "Dilution 24%" },
    { label: "Dilution 23%", value: "Dilution 23%" },
    { label: "Dilution 22%", value: "Dilution 22%" },
    { label: "Dilution 21%", value: "Dilution 21%" },
    { label: "Dilution 20%", value: "Dilution 20%" },
    { label: "Dilution 19%", value: "Dilution 19%" },
    { label: "Dilution 18%", value: "Dilution 18%" },
    { label: "Dilution 17%", value: "Dilution 17%" },
    { label: "Dilution 16%", value: "Dilution 16%" },
    { label: "Dilution 15%", value: "Dilution 15%" },
    { label: "Dilution 14%", value: "Dilution 14%" },
    { label: "Dilution 13%", value: "Dilution 13%" },
    { label: "Dilution 12%", value: "Dilution 12%" },
    { label: "Dilution 11%", value: "Dilution 11%" },
    { label: "Dilution 10%", value: "Dilution 10%" },
  ]
}

export function Glassware() {
  return [
    { label: "Bucket", value: "Bucket" },
    { label: "Carafe", value: "Carafe" },
    { label: "Collins", value: "Collins" },
    { label: "Copper Mug", value: "Copper Mug" },
    { label: "Coupe", value: "Coupe" },
    { label: "Double Old Fashioned", value: "Double Old Fashioned" },
    { label: "Flute", value: "Flute" },
    { label: "Glencairn", value: "Glencairn" },
    { label: "Goblet", value: "Goblet" },
    { label: "Highball", value: "Highball" },
    { label: "Hurricane", value: "Hurricane" },
    { label: "Irish Coffee Glass", value: "Irish Coffee Glass" },
    { label: "Julep", value: "Julep" },
    { label: "Lowball", value: "Lowball" },
    { label: "Margarita | Welled", value: "Margarita | Welled" },
    { label: "Margarita Glass", value: "Margarita Glass" },
    { label: "Margarita | Saucer", value: "Margarita | Saucer" },
    { label: "Martini", value: "Martini" },
    { label: "Nick and Nora", value: "Nick and Nora" },
    { label: "Old Fashioned", value: "Old Fashioned" },
    { label: "Parfait", value: "Parfait" },
    { label: "Pilsner", value: "Pilsner" },
    { label: "Pint", value: "Pint" },
    { label: "Pitcher", value: "Pitcher" },
    { label: "Poco Grande", value: "Poco Grande" },
    { label: "Pousse-Café", value: "Pousse-Café" },
    { label: "Punch Bowl", value: "Punch Bowl" },
    { label: "Red Wine", value: "Red Wine" },
    { label: "Rocks", value: "Rocks" },
    { label: "Sherry", value: "Sherry" },
    { label: "Shot Glass", value: "Shot Glass" },
    { label: "Snifter", value: "Snifter" },
    { label: "Stemless Cocktail", value: "Stemless Cocktail" },
    { label: "Tiki Mug", value: "Tiki Mug" },
    { label: "Tumbler", value: "Tumbler" },
    { label: "White Wine", value: "White Wine" },
    { label: "Wine Glass | Stemless", value: "Wine Glass | Stemless" },
    { label: "Zombie", value: "Zombie" },
  ]
}
export function DillutionValue() {
  return [0.35, 0.34, 0.33, 0.32, 0.31, 0.30, 0.29, 0.28, 0.27, 0.26, 0.25, 0.24, 0.23, 0.22, 0.21, 0.20, 0.19, 0.18, 0.17, 0.16, 0.15, 0.14, 0.13, 0.12, 0.11, 0.10];
}

export function DillutionValues() {
  return {"Dilution 35%":0.35, "Dilution 34%":0.34, "Dilution 33%":0.33, "Dilution 32%":0.32, "Dilution 31%":0.31, "Dilution 30%":0.30, "Dilution 29%":0.29, "Dilution 28%":0.28, "Dilution 27%":0.27, "Dilution 26%":0.26, "Dilution 25%":0.25, "Dilution 24%":0.24, "Dilution 23%":0.23, "Dilution 22%":0.22, "Dilution 21%":0.21, "Dilution 20%":0.20, "Dilution 19%":0.19, "Dilution 18%":0.18, "Dilution 17%":0.17, "Dilution 16%":0.16, "Dilution 15%":0.15, "Dilution 14%":0.14, "Dilution 13%":0.13, "Dilution 12%":0.12, "Dilution 11%":0.11, "Dilution 10%":0.10};
}

export function floatUnit() {
  return { "Ounce": 1, "Dash": 0.03125, "Bar Spoon": 0.125, "Rinse": 0.0125, "Grams": 0.04, "Float": 0.5, "Twist": 1, "Peel": 1, "wedge": 1, "Slice": 1, "Rim": 0.02, "Zest": 1, "ea": 1, "Spritz": 0.003, "Sprig": 1, "half": 0.5, "Quarter": 0.25, "wheel": 1, "Half Moon": 1, "ml": 0.034, "Drop": 0.031, "2oz": 2, "5oz": 5, "6oz": 6, "9oz": 9, "10oz": 10, "3oz": 3, "12oz": 12, "11.2oz": 11.2, "Sprinkle": 1, "Cube": 1, "Table Spoon": 0.5, "Tea Spoon": 0.167, "Splash": 0.2, "Spiral": 1, "Leaves": 1, "375ml": 12.75, "Quart": 32, "Gallon": 128, "750ml": 25.36, "8oz": 8, "16oz": 16, "Pint": 16, "Cup": 8, "Liter": 33.184, "1.75 Liter": 57.175, "187ml": 6.323, "24oz": 24, "1.5 Liter": 50.721 }
}

export function floatUnitDD() {
  return [
    { label: "Ounce", value: "Ounce" },
    { label: "Dash", value: "Dash" },
    { label: "Bar Spoon", value: "Bar Spoon" },
    { label: "Rinse", value: "Rinse" },
    { label: "Grams", value: "Grams" },
    { label: "Float", value: "Float" },
    { label: "Twist", value: "Twist" },
    { label: "Peel", value: "Peel" },
    { label: "wedge", value: "wedge" },
    { label: "Slice", value: "Slice" },
    { label: "Rim", value: "Rim" },
    { label: "Zest", value: "Zest" },
    { label: "ea", value: "ea" },
    { label: "Spritz", value: "Spritz" },
    { label: "Sprig", value: "Sprig" },
    { label: "half", value: "half" },
    { label: "Quarter", value: "Quarter" },
    { label: "wheel", value: "wheel" },
    { label: "Half Moon", value: "Half Moon" },
    { label: "ml", value: "ml" },
    { label: "Drop", value: "Drop" },
    { label: "2oz", value: "2oz" },
    { label: "5oz", value: "5oz" },
    { label: "6oz", value: "6oz" },
    { label: "9oz", value: "9oz" },
    { label: "10oz", value: "10oz" },
    { label: "3oz", value: "3oz" },
    { label: "12oz", value: "12oz" },
    { label: "11.2oz", value: "11.2oz" },
    { label: "Sprinkle", value: "Sprinkle" },
    { label: "Cube", value: "Cube" },
    { label: "Table Spoon", value: "Table Spoon" },
    { label: "Tea Spoon", value: "Tea Spoon" },
    { label: "Splash", value: "Splash" },
    { label: "Spiral", value: "Spiral" },
    { label: "Leaves", value: "Leaves" },
    { label: "375ml", value: "375ml" },
    { label: "Quart", value: "Quart" },
    { label: "Gallon", value: "Gallon" },
    { label: "750ml", value: "750ml" },
    { label: "8oz", value: "8oz" },
    { label: "16oz", value: "16oz" },
    { label: "Pint", value: "Pint" },
    { label: "Cup", value: "Cup" },
    { label: "Liter", value: "Liter" },
    { label: "1.75 Liter", value: "1.75 Liter" },
    { label: "187ml", value: "187ml" },
    { label: "24oz", value: "24oz" },
    { label: "1.5 Liter", value: "1.5 Liter" },
  ]
}

export function garnishUnit() {
  return { "Ounce": 1, "5oz": 5, "3oz": 3, "2oz": 2, "6oz": 6, "8oz": 8, "9oz": 9, "10oz": 0.0, "11.2oz": 11.2, "12oz": 12, "16oz": 16, "24oz": 24, "ea": 1, "half": 0.5, "ml": 0.034, "Float": 0.5, "Dash": 0.03125, "Bar Spoon": 0.125, "Rim": 0.02, "Spritz": 0.003, "Drop": 0.031, "Peel": 1, "Spiral": 1, "Rinse": 0.0125, "Grams": 0.04, "Twist": 1, "wedge": 1, "Slice": 1, "Zest": 1, "Sprig": 1, "Quarter": 0.25, "wheel": 1, "Half Moon": 1, "Sprinkle": 1, "Cube": 1, "Table Spoon": 0.5, "Tea Spoon": 0.167, "Splash": 0.2, "Leaves": 1, "375ml": 12.75, "Quart": 32, "Gallon": 128, "750ml": 25.36, "Pint": 16, "Cup": 8, "Liter": 33.184, "1.75 Liter": 57.175, "187ml": 6.323, "1.5 Liter": 50.721 }
}

export function garnishUnitDD() {
  return [
    { label: "Ounce", value: "Ounce" },
    { label: "5oz", value: "5oz" },
    { label: "3oz", value: "3oz" },
    { label: "2oz", value: "2oz" },
    { label: "6oz", value: "6oz" },
    { label: "8oz", value: "8oz" },
    { label: "9oz", value: "9oz" },
    { label: "10oz", value: "10oz" },
    { label: "11.2oz", value: "11.2oz" },
    { label: "12oz", value: "12oz" },
    { label: "16oz", value: "16oz" },
    { label: "24oz", value: "24oz" },
    { label: "ea", value: "ea" },
    { label: "half", value: "half" },
    { label: "ml", value: "ml" },
    { label: "Float", value: "Float" },
    { label: "Dash", value: "Dash" },
    { label: "Bar Spoon", value: "Bar Spoon" },
    { label: "Rim", value: "Rim" },
    { label: "Spritz", value: "Spritz" },
    { label: "Drop", value: "Drop" },
    { label: "Peel", value: "Peel" },
    { label: "Spiral", value: "Spiral" },
    { label: "Rinse", value: "Rinse" },
    { label: "Grams", value: "Grams" },
    { label: "Twist", value: "Twist" },
    { label: "wedge", value: "wedge" },
    { label: "Slice", value: "Slice" },
    { label: "Zest", value: "Zest" },
    { label: "Sprig", value: "Sprig" },
    { label: "Quarter", value: "Quarter" },
    { label: "wheel", value: "wheel" },
    { label: "Half Moon", value: "Half Moon" },
    { label: "Sprinkle", value: "Sprinkle" },
    { label: "Cube", value: "Cube" },
    { label: "Table Spoon", value: "Table Spoon" },
    { label: "Tea Spoon", value: "Tea Spoon" },
    { label: "Splash", value: "Splash" },
    { label: "Leaves", value: "Leaves" },
    { label: "375ml", value: "375ml" },
    { label: "Quart", value: "Quart" },
    { label: "Gallon", value: "Gallon" },
    { label: "750ml", value: "750ml" },
    { label: "Pint", value: "Pint" },
    { label: "Cup", value: "Cup" },
    { label: "Liter", value: "Liter" },
    { label: "1.75 Liter", value: "1.75 Liter" },
    { label: "187ml", value: "187ml" },
    { label: "1.5 Liter", value: "1.5 Liter" },
  ]
}


export function sizeDrop() {
  return [
    { key: "750ml", value: 25.361 },
    { key: "Liter", value: 33.814 },
    { key: "187ml", value: 6.323 },
    { key: "375ml", value: 12.071 },
    { key: "1.5 Liter", value: 50.721 },
    { key: "3 Liter", value: 101.442 },
    { key: "4 Liter", value: 135.256 },
    { key: "5 Liter", value: 169.07 },
    { key: "6 Liter", value: 202.884 },
    { key: "9 Liter", value: 304.326 },
    { key: "12 Liter", value: 405.768 },
    { key: "ml", value: 0.406 },
    { key: "Quart", value: 32 },
    { key: "Gallon", value: 128 },
    { key: "Cup", value: 8 },
    { key: "Pint", value: 16 },
    { key: "12oz", value: 12 },
    { key: "16oz", value: 16 },
    { key: "5oz", value: 5 },
    { key: "1.75 Liter", value: 59.175 },
    { key: "ea", value: 1 },
    { key: "Mint Sprig", value: 160 },
    { key: "Mint Leaves", value: 59.175 },
    { key: "Sage Sprig", value: 1088 },
    { key: "Sage Leaves", value: 9.6 },
    { key: "Ounce", value: 1 },
    { key: "Dozen", value: 12 },
    { key: "2oz", value: 2 },
    { key: "3oz", value: 3 },
    { key: "4oz", value: 4 },
    { key: "6oz", value: 6 },
    { key: "8oz", value: 8 },
    { key: "10oz", value: 10 },
    { key: "11.2oz", value: 11.2 },
    { key: "22oz", value: 22 },
    { key: "24oz", value: 24 },
    { key: "U-10 Shrimp", value: 10 },
    { key: "Tea Spoon", value: 2 },
    { key: "Table Spoon", value: 0.5 },
    { key: "Bar Spoon", value: 0.125 },
    { key: "Gram", value: 0.035274 },
    { key: "Kilogram", value: 35.274 },
    { key: "LB", value: 16 },
    { key: "15.5 Gal Keg", value: 1984 },
    { key: "13.2 Gal Keg", value: 1689.6 },
    { key: "7.75 Gal Keg", value: 992 },
    { key: "5.16 Gal Keg", value: 661 },
    { key: "5.0 Gal Keg", value: 640 },
  ]
}


export function sizeDropNew() {
  return [
    { label: "750ml", value: "750ml" },
    { label: "Liter", value: "Liter" },
    { label: "187ml", value: "187ml" },
    { label: "375ml", value: "375ml" },
    { label: "1.5 Liter", value: "1.5 Liter" },
    { label: "3 Liter", value: "3 Liter" },
    { label: "4 Liter", value: "4 Liter" },
    { label: "5 Liter", value: "5 Liter" },
    { label: "6 Liter", value: "6 Liter" },
    { label: "9 Liter", value: "9 Liter" },
    { label: "12 Liter", value: "12 Liter" },
    { label: "ml", value: "ml" },
    { label: "Quart", value: "Quart" },
    { label: "Gallon", value: "Gallon" },
    { label: "Cup", value: "Cup" },
    { label: "Pint", value: "Pint" },
    { label: "12oz", value: "12oz" },
    { label: "16oz", value: "16oz" },
    { label: "5oz", value: "5oz" },
    { label: "1.75 Liter", value: "1.75 Liter" },
    { label: "ea", value: "ea" },
    { label: "Mint Sprig", value: "Mint Sprig" },
    { label: "Mint Leaves", value: "Mint Leaves" },
    { label: "Sage Sprig", value: "Sage Sprig" },
    { label: "Sage Leaves", value: "Sage Leaves" },
    { label: "Ounce", value: "Ounce" },
    { label: "Dozen", value: "Dozen" },
    { label: "2oz", value: "2oz" },
    { label: "3oz", value: "3oz" },
    { label: "4oz", value: "4oz" },
    { label: "6oz", value: "6oz" },
    { label: "8oz", value: "8oz" },
    { label: "10oz", value: "10oz" },
    { label: "11.2oz", value: "11.2oz" },
    { label: "22oz", value: "22oz" },
    { label: "24oz", value: "24oz" },
    { label: "U-10 Shrimp", value: "U-10 Shrimp" },
    { label: "Tea Spoon", value: "Tea Spoon" },
    { label: "Table Spoon", value: "Table Spoon" },
    { label: "Bar Spoon", value: "Bar Spoon" },
    { label: "Gram", value: "Gram" },
    { label: "Kilogram", value: "Kilogram" },
    { label: "LB", value: "LB" },
    { label: "15.5 Gal Keg", value: "15.5 Gal Keg" },
    { label: "13.2 Gal Keg", value: "13.2 Gal Keg" },
    { label: "7.75 Gal Keg", value: "7.75 Gal Keg" },
    { label: "5.16 Gal Keg", value: "5.16 Gal Keg" },
    { label: "5.0 Gal Keg", value: "5.0 Gal Keg" },
  ]
}
