import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import pattern1 from '../images/pattern1.png';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Utility from '../utility';
import { db, auth, frbase } from '../../src/Database_fb';
import axios from 'axios';
import Modal from 'react-modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import green_tick from '../images/green_tick.png';

import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    section: {
        backgroundImage: `url(${pattern1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    cardHeader: {
        paddingTop: theme.spacing(3),
    },
}));

export default function NetworkSubscription(props) {
    const navigate = useNavigate();
    const [cardModal, setcardModal] = useState(false);
    const [loading, setloading] = React.useState(false);

    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [username, setusername] = React.useState('');
    const [useremail, setuseremail] = React.useState('');
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [selectedplan, setselectedplan] = useState({ identifier: "Friends and Family", id: 0 })

    const [state, setState] = React.useState({
        checkbox: true,
    });


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        Fetchuser()
    }, [])

    function Fetchuser() {
        const user = auth.currentUser;
        const subscribe = db.collection("users")
            .doc(user.uid)
            .get().then((doc) => {
                const crafts = [];
                let data = doc.data()
                setusername(data.name)
                setuseremail(data.email)
            });
    }


    const createSubscription = async () => {

        if (!stripe || !elements) {

            return;
        }
        setloading(true)
        const user = auth.currentUser;

        setPaymentLoading(true);
        // create a payment method
        const paymentMethod = await stripe?.createPaymentMethod({
            type: "card",
            card: elements?.getElement(CardElement),
            billing_details: {
                name: username,
                email: useremail,
            },
        });

        setcardModal(false);
        if (paymentMethod.error != null) {
            alert(paymentMethod.error.message);
            setloading(false)
            return;
        }

        var reqBody = "paymentMethod=" + paymentMethod.paymentMethod.id + "&name=" + username + "&email=" + useremail + "&uid=" + user.uid + "&priceId=" + selectedplan.price_id;
        axios({
            method: "post",
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            url: "https://us-central1-craftiqapp.cloudfunctions.net/startNetworkSubscription",
            data: reqBody
        })
            .then(async function (response) {
                console.log('response', response);
                if (response != null && response.data.clientSecret != null) {
                    // confirm the payment by the user
                    const confirmPayment = await stripe?.confirmCardPayment(
                        response.data.clientSecret
                    );
                    if (confirmPayment.paymentIntent.status == 'succeeded') {
                        db.collection("users")
                            .doc(user.uid)
                            .update({
                                subscribed: true,
                                max_member: Utility.numberofMembers(selectedplan.identifier),
                                membership: "Paid",
                                selectedPlan: selectedplan.id,
                                membership_name: Utility.membershipName(selectedplan.identifier),
                                updatedAt: frbase.firestore.FieldValue.serverTimestamp()
                            }).then(() => {
                                Utility.loadUser()
                                navigate(-1)
                            })

                        setloading(false)
                    }
                    else {
                        setloading(false)
                        alert('Something is wrong. please try again');
                    }

                }
                else {
                    setloading(false)
                    alert(response.data.message);
                }
                setPaymentLoading(false);
            }).catch((e) => {
                setloading(false)
                alert(e);
                setPaymentLoading(false);
            });

    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    return (
        <section className={classes.section}>
            <Container maxWidth="lg">
                <Box py={8} textAlign="center">
                    <Box mb={3}>
                        <Container maxWidth="sm">
                            {/* <Typography variant="overline" color="textSecondary">Network</Typography> */}
                            <Typography variant="h3" component="h2" gutterBottom={true}>
                                <Typography variant="h3" component="span" color="primary">Packages </Typography>
                                <Typography variant="h3" component="span">Version</Typography>
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" paragraph={true}>Select a program based on your business or usage needs</Typography>
                        </Container>
                    </Box>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={4} >
                            <Card variant='elevation' >

                                <CardHeader title={'Friends and Family'} className={classes.cardHeader}
                                    action={
                                        <div style={{ top: '0px', right: '0px', width: 40, height: 40, alignItems:'center', justifyContent:'center', display:'flex' }}>
                                          {Utility.currentuser.max_member == 5 && <img src={green_tick}
                                                style={{
                                                    width: 30,
                                                    height: 30,

                                                    color: "#fff",
                                                }}
                                            />}
                                        </div>
                                    }
                                ></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            Free
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">Add up to 5 members</Typography>
                                    </Box>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                       {Utility.currentuser.max_member != 5 && <button
                                            onClick={() => {

                                                setloading(true)
                                                const user = auth.currentUser;
                                                db.collection("users")
                                                    .doc(user.uid)
                                                    .update({
                                                        subscribed: true,
                                                        max_member: 5,
                                                        membership: "Free",
                                                        selectedPlan: 0,
                                                        membership_name: "Friends and Family",
                                                        updatedAt: frbase.firestore.FieldValue.serverTimestamp()
                                                    }).then(() => {
                                                        setloading(false)
                                                        Utility.loadUser()
                                                        //alert("Your network monthly subscription has been started successfuly");
                                                        navigate(-1)
                                                    })
                                            }}
                                            className="pay-button"
                                            disabled={isPaymentLoading}
                                        >
                                            Continue
                                        </button>}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>


                        <Grid item xs={12} md={4} >
                            <Card variant='elevation' >
                                <CardHeader title={'Bar Program'} className={classes.cardHeader}
                                action={
                                    <div style={{ top: '0px', right: '0px', width: 40, height: 40, alignItems:'center', justifyContent:'center', display:'flex' }}>
                                      {Utility.currentuser.max_member == 15 && <img src={green_tick}
                                            style={{
                                                width: 30,
                                                height: 30,

                                                color: "#fff",
                                            }}
                                        />}
                                    </div>
                                }
                                ></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            $29.99
                                            <Typography variant="h6" color="textSecondary" component="span">/ monthly</Typography>
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">Add up to 15 members</Typography>
                                    </Box>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                       {Utility.currentuser.max_member != 15 && <button
                                            onClick={() => {
                                                setselectedplan({ identifier: "bar_program", id: 1, price_id: 'price_1Mz0IFFC7Opl3HqEsvOfJ6jn' })
                                                setcardModal(true)
                                            }}
                                            className="pay-button"
                                            disabled={isPaymentLoading}
                                        >
                                            {isPaymentLoading ? "Loading..." : "Pay"}
                                        </button>}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Card variant='elevation' >
                                <CardHeader title={'Restaurant Program'} className={classes.cardHeader}
                                action={
                                    <div style={{ top: '0px', right: '0px', width: 40, height: 40, alignItems:'center', justifyContent:'center', display:'flex' }}>
                                      {Utility.currentuser.max_member == 50 && <img src={green_tick}
                                            style={{
                                                width: 30,
                                                height: 30,

                                                color: "#fff",
                                            }}
                                        />}
                                    </div>
                                }
                                ></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            $99.99
                                            <Typography variant="h6" color="textSecondary" component="span">/ monthly</Typography>
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">Add up to 50 members</Typography>
                                    </Box>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        {Utility.currentuser.max_member != 50 &&  <button
                                            onClick={() => {
                                                setselectedplan({ identifier: "restaurant_program", id: 2, price_id: 'price_1Mz0J0FC7Opl3HqESAmKMDo7' })
                                                setcardModal(true)
                                            }}
                                            className="pay-button"
                                            disabled={isPaymentLoading}
                                        >
                                            {isPaymentLoading ? "Loading..." : "Pay"}
                                        </button>}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </Box>
            </Container>


            <Container maxWidth="lg">
                <Box py={8} textAlign="center">
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={4} >
                            <Card variant='elevation' >
                                <CardHeader title={'Catering'} className={classes.cardHeader}
                                action={
                                    <div style={{ top: '0px', right: '0px', width: 40, height: 40, alignItems:'center', justifyContent:'center', display:'flex' }}>
                                      {Utility.currentuser.max_member == 100 && <img src={green_tick}
                                            style={{
                                                width: 30,
                                                height: 30,

                                                color: "#fff",
                                            }}
                                        />}
                                    </div>
                                }
                                ></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            $199.99
                                            <Typography variant="h6" color="textSecondary" component="span">/ monthly</Typography>
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">Add up to 100 members</Typography>
                                    </Box>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                      {Utility.currentuser.max_member != 100 &&  <button
                                            onClick={() => {
                                                setselectedplan({ identifier: "catering", id: 3, price_id: 'price_1Mz0JHFC7Opl3HqEpdYAoDXt' })
                                                setcardModal(true)
                                            }}
                                            className="pay-button"
                                            disabled={isPaymentLoading}
                                        >
                                           {isPaymentLoading ? "Loading..." : "Pay"}
                                        </button>}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>


                        <Grid item xs={12} md={4} >
                            <Card variant='elevation' >
                                <CardHeader title={'Corporate Tier 1'} className={classes.cardHeader}
                                action={
                                    <div style={{ top: '0px', right: '0px', width: 40, height: 40, alignItems:'center', justifyContent:'center', display:'flex' }}>
                                      {Utility.currentuser.max_member == 250 && <img src={green_tick}
                                            style={{
                                                width: 30,
                                                height: 30,

                                                color: "#fff",
                                            }}
                                        />}
                                    </div>
                                }
                                ></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            $449.99
                                            <Typography variant="h6" color="textSecondary" component="span">/ monthly</Typography>
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">Add up to 250 members</Typography>
                                    </Box>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                      {Utility.currentuser.max_member != 250 &&  <button
                                            onClick={() => {
                                                setselectedplan({ identifier: "corporate", id: 4, price_id: 'price_1Mz0JSFC7Opl3HqEwXb17gWo' })
                                                setcardModal(true)
                                            }}
                                            className="pay-button"
                                            disabled={isPaymentLoading}
                                        >
                                            {isPaymentLoading ? "Loading..." : "Pay"}
                                        </button>}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Card variant='elevation' >
                                <CardHeader title={'Corporate Tier 2'} className={classes.cardHeader}
                                action={
                                    <div style={{ top: '0px', right: '0px', width: 40, height: 40, alignItems:'center', justifyContent:'center', display:'flex' }}>
                                      {Utility.currentuser.max_member == 500 && <img src={green_tick}
                                            style={{
                                                width: 30,
                                                height: 30,

                                                color: "#fff",
                                            }}
                                        />}
                                    </div>
                                }
                                ></CardHeader>
                                <CardContent>
                                    <Box px={1}>
                                        <Typography variant="h3" component="h2" gutterBottom={true}>
                                            $899.99
                                            <Typography variant="h6" color="textSecondary" component="span">/ monthly</Typography>
                                        </Typography>
                                        <Typography color="textSecondary" variant="subtitle1" component="p">Add up to 500 members</Typography>
                                    </Box>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                      {Utility.currentuser.max_member != 500 &&  <button
                                            onClick={() => {
                                                setselectedplan({ identifier: "corporate2", id: 5, price_id: 'price_1Mz0JZFC7Opl3HqEX0veo2us' })
                                                setcardModal(true)
                                            }}
                                            className="pay-button"
                                            disabled={isPaymentLoading}
                                        >
                                            {isPaymentLoading ? "Loading..." : "Pay"}
                                        </button>}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </Box>
            </Container>

            <Modal
                isOpen={cardModal}
                onRequestClose={() => {
                    setcardModal(false)
                }}
                style={customStyles}
                contentLabel=""
            >
                <div style={{ justifyContent: 'center', alignItems: 'center', width: 400 }}>
                    <h2 >Enter your card details</h2>

                    {/* <form id="payment-form"> */}
                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />

                    <button
                        onClick={() => {

                            createSubscription()
                        }}
                        className="pay-button"
                        disabled={processing || disabled || succeeded}
                        id="submit"
                    >
                        <span id="button-text">
                            {processing ? (
                                <div className="spinner" id="spinner"></div>
                            ) : (
                                "Pay now"
                            )}
                        </span>
                    </button>
                    {/* Show any error that happens when processing the payment */}
                    {error && (
                        <div className="card-error" role="alert">
                            {error}
                        </div>
                    )}
                    {/* Show a success message upon completion */}
                    <p className={succeeded ? "result-message" : "result-message hidden"}>
                        Payment succeeded, see the result in your
                        <a
                            href={`https://dashboard.stripe.com/test/payments`}
                        >
                            {" "}
                            Stripe dashboard.
                        </a> Refresh the page to pay again.
                    </p>
                    {/* </form> */}


                    <Button
                        style={{ backgroundColor: "transparent", color: 'black' }}
                        // backgroundColor={"red"}
                        type="submit"
                        fullWidth
                        variant="contained"
                        // sx={{ mt: 3, mb: 2 }}
                        onClick={() => {
                            setcardModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </section>
    );
}