import React, { useEffect, useRef, useState } from "react";
import { db, auth } from '../../src/Database_fb';

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight } from "react-icons/ai";
import tutorial from '../images/tutorial.png';
import aboutIcon from '../images/aboutIcon.png';
import deleteaccount from '../images/deletaccount.png';
import infoIcon from '../images/infoIcon.png';
import logoutIcon from '../images/logoutIcon.png';
import mylib from '../images/mylib.png';
import backgroundimg from '../images/backbar.png';
import { getAuth, signOut, deleteUser } from "firebase/auth";
import { useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert-with-buttons'
import Utility from "../utility";
export default function Setting() {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert()

  const [libraryArray, setlibraryArray] = useState([]);
  const [OptionsArray, setOptionsArray] = useState([]);
  const [loading, setloading] = React.useState(true);
  const [profileImg, setprofileImg] = React.useState('');
  const [username, setusername] = React.useState('');
  const settings = [
    {
      id: "1",
      title: "Tutorial",
      subtitle: "",
      img: tutorial,
      showArrow: true
    },
    {
      id: "2",
      title: "My Library",
      subtitle: "",
      img: mylib,
      showArrow: true
    },
    {
      id: "3",
      title: "About CraftIQ",
      subtitle: "",
      img: aboutIcon,
      showArrow: true
    },
    {
      id: "4",
      title: "Information CraftIQ",
      subtitle: "Terms & Conditions/Privacy Policy",
      img: infoIcon,
      showArrow: true
    },
    {
      id: "5",
      title: "Contact US",
      subtitle: "CraftIQ",
      img: mylib,
      showArrow: true
    },
    {
      id: "6",
      title: "Delete Account",
      subtitle: "Delete your account",
      img: deleteaccount,
      showArrow: true
    },
    {
      id: "7",
      title: "App Version",
      subtitle: "1.0",
      img: mylib,
      showArrow: false
    }
  ];

  var premium_subscribed = false
  var subscribed = false
  

  useEffect(() => {
    setOptionsArray([])

    if (Utility.currentuser != null && Utility.currentuser.premium_subscribed != null) {
      premium_subscribed = Utility.currentuser.premium_subscribed
    }
    if (Utility.currentuser != null && Utility.currentuser.subscribed != null) {
      subscribed = Utility.currentuser.subscribed
    }

    if (premium_subscribed || subscribed) {
      settings.push({
        id: "8",
        title: "Cancel Subscription",
        subtitle: "",
        img: logoutIcon,
        showArrow: true
      })
      settings.push({
        id: "9",
        title: "Log Out",
        subtitle: "",
        img: logoutIcon,
        showArrow: true
      })
    }
    else {
      settings.push({
        id: "8",
        title: "Log Out",
        subtitle: "",
        img: logoutIcon,
        showArrow: true
      })
    }
    setOptionsArray(settings)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    Fetchuser()
  }, [])

  function Fetchuser() {
    const user = auth.currentUser;
    const subscribe = db.collection("users")
      .doc(user.uid)
      .get().then((doc) => {
        const crafts = [];
        let data = doc.data()
        setprofileImg(data.imageUrl)
        setusername(data.name)
        setloading(false)
      });
  }

  const handleLogout = () => {
    alert.open({
      message: "Logout ? Are you sure ?",
      buttons: [
        {
          label: "Yes, Logout",
          onClick: () => {
            //implement your function here for the button1 click
            alert.close() //Remeber that customly created button does not close the alert by default! 
            //You must add the close function manually!
            const auth = getAuth();
            signOut(auth).then(() => {
              navigate('/')
              location.state.setauth(false)

            }).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              alert(errorMessage + " " + errorCode);
            });
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });
  };

  const cancelSubscription = () => {
    alert.open({
      message: "Cancel ? Are you sure to cancel subscription ?",
      buttons: [
        {
          label: "Yes, Logout",
          onClick: () => {
            //implement your function here for the button1 click
            alert.close() //Remeber that customly created button does not close the alert by default! 
            //You must add the close function manually!

          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });
  };

  const handleDelete = () => {
    alert.open({
      message: "Delete ? Are you sure ?",
      buttons: [
        {
          label: "Yes, Delete",
          onClick: () => {
            //implement your function here for the button1 click
            alert.close() //Remeber that customly created button does not close the alert by default! 
            //You must add the close function manually!
            const auth = getAuth();
            deleteUser(auth).then(() => {
              navigate('/')
              location.state.setauth(false)

            }).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              alert(errorMessage + " " + errorCode);
            });
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });
  };


  const handleInformation = () => {
    alert.open({
      message: "Information CraftIQ",
      buttons: [
        {
          label: "Terms & Conditions",
          onClick: () => {
            alert.close()
            window.open('https://thecraftiq.com/terms-and-conditions/', '_blank', 'noreferrer');
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        },
        {
          label: "Privacy Policy",
          onClick: () => {
            alert.close()
            window.open('https://thecraftiq.com/privacy-policy/', '_blank', 'noreferrer');
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });
  };



  return (
    <>
      {/* <div
        style={{
          width: '20%',
          // alignItems: "flex-end",
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          //marginLeft: "10%",
          // marginRight: 20,
          marginTop: 10,
        }}
      >
        <Button
          onClick={() => {
            navigate(-1)
          }}>
          <AiOutlineArrowLeft
            style={{
              width: 20,
              height: 20,

              color: "#fff",
            }}
          /></Button>
      </div> */}
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p style={{ fontSize: 30, color: "#D28816" }}>SETTINGS</p>
        <Box sx={{ width: "90%" }}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{
              width: '100%', height: 180,
              borderRadius: 40,
              backgroundImage: `url(${backgroundimg})`,
              backgroundRepeat: "repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              alignItems: 'center', display: 'flex', justifyContent: 'center'
            }} >
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <img style={{ width: 120, height: 120, borderRadius: 60, backgroundColor: 'grey' }} src={profileImg} />
              </div>

            </div>
            <p
              style={{
                fontSize: 17,
                color: "white",
                textAlign: "start",
              }}
            >
              {username}
            </p>


            <Button
              onClick={() => {
                navigate('Profile')
              }}
              style={{ textDecorationLine: 'underline', backgroundColor: 'transparent', top: -10 }}
              type="submit"
              variant="contained"
            >
              Edit Profile
            </Button>
          </div>

          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {OptionsArray.map((prop, key) => {
              return (
                <Grid key={key} item xs={6}>
                  <div
                    key={key}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 0,
                      height: "80px",
                      cursor: 'pointer',
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      if (prop.id == 1) {
                        navigate('/Tutorial')
                      }
                      if (prop.id == 2) {
                        navigate('/MyCraft')
                      }
                      if (prop.id == 3) {
                        navigate('/About')
                      }
                      if (prop.id == 4) {
                        handleInformation()
                      }
                      if (prop.id == 5) {
                        window.open('https://thecraftiq.com/contact/', '_blank', 'noreferrer');
                      }
                      if (prop.id == 6) {
                        handleDelete()
                      }
                      if (prop.id == 8) {


                        if (premium_subscribed || subscribed) {
                          cancelSubscription()
                        }
                        else {
                          handleLogout()
                        }

                      }
                      if (prop.id == 9) {
                        handleLogout()
                      }
                      console.log('clicked');
                    }}
                  >
                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                      <img style={{ widows: 50, height: 50 }} src={prop.img} alt="tutorial" />
                    </div>
                    <div style={{ width: '75%', alignItems: 'center', display: prop.subtitle.length > 0 ? 'flow-root' : 'flex' }}>
                      <p
                        style={{
                          fontSize: 17,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.title}
                      </p>
                      {prop.subtitle.length > 0 && <p
                        style={{
                          fontSize: 12,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          marginTop: '-3%',
                        }}
                      >
                        {prop.subtitle}
                      </p>}
                    </div>
                    <div style={{ width: '10%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      {prop.showArrow > 0 && <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      />}
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>


      </Box>
    </>
  );
}

