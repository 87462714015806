// import React from "react";

// const Login = () => {
//   return <></>;
// };

// export default Login;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import backgroundimg from '../images/backbar_login.png';
import splashBG from '../images/splashBG.png';
import { db, auth, frbase } from '../../src/Database_fb';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getAuth, signInWithEmailAndPassword , createUserWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import Utility from "../utility";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="grey"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://thecraftiq.com">
        The CraftIQ
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login({ setAuth }) {
  const navigate = useNavigate();
  const [forgotEmail, setforgotEmail] = useState("")
  const [forgotModal, setforgotModal] = useState(false);

  const [loading, setloading] = React.useState(false);
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let param = {
      email: data.get("email"),
      password: data.get("password"),
      name: data.get("name"),
    }

    if (reg.test(param.email) === false) {
      alert("Please enter valid email address");
     // emailSiginIn()
    }
    else if (param.password.length == 0) {
      alert("Please enter password");
     }
    else {
      startSignIn(param.email, param.password)
    }
  };

  function startSignIn(email, pass) {
    setloading(true)
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email.toLowerCase(), pass)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user != null) {
         // const user = auth.currentUser;
          db.collection("users")
            .doc(user.uid)
            .update({
              tokens: frbase.firestore.FieldValue.arrayUnion(Utility.FCMToken),
              //fcmToken: Utility.FCMToken,
              loginDevice: 'Web'
            })
            .then(() => {
            });

          setloading(false)
          Utility.loadBackbar() 
          Utility.loadUser()

         // localStorage.setItem('email', email.toLowerCase());
          //localStorage.setItem('password', password);
          setAuth(true);
        }
      })
      .catch((error) => {
        setloading(false)
        const errorCode = error.code;
        const errorMessage = error.message;
        //alert(errorMessage + " " + errorCode);
        alert(errorMessage + " " + errorCode)
      });
  }

  const forgotPassword = (Email) => {
    setloading(true)
    const auth = getAuth();
    sendPasswordResetEmail(auth, Email.toLowerCase())
      .then(function (user) {
        setloading(false)
        alert("Please check your email...");
      })
      .catch(function (error) {
        setloading(false)
        if (error.code === "auth/user-not-found") {
          alert("No user found with this email");
        } else {
          alert(error.code);
        }
      });
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${backgroundimg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            // backgroundColor: "red",
            alignItems:'center',
            justifyContent:'center'
          }}
        />
         
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              //   my: 8,
              //   mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
             // backgroundColor: "#694b1b",
              backgroundImage: `url(${splashBG})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Box
              sx={{
                my: 16,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
               
               // backgroundColor: "#694b1b",
                
              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
              <div>
                <p style={{ color: "#f7a828", fontSize: 30 }}>WELCOME</p>
                <p style={{ color: "#fff" }}>log in to your account</p>
              </div>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                {/* <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Name"
                  name="email"
                  autoComplete="email"
                  autoFocus
                /> */}
                <TextField
                 InputLabelProps={{
                  style: {   
                    fontWeight:'bold',                
                    color: '#f7a828'
                  } }} 
                //sx={{ input: { color: 'red' } }}
                  style={{ backgroundColor: "#fff" , borderRadius: 10}}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                InputLabelProps={{
                  style: {   
                    fontWeight:'bold',                
                    color: '#f7a828'
                  } }} 
                  style={{ backgroundColor: "#fff",borderRadius: 10 }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
                <Grid item xs>
                  <Link onClick={()=>{
                    setforgotModal(true)
                  }} variant="body2">
                    <p style={{ color: "#fff", textAlign: "end" }}>
                      Forgot password?
                    </p>
                  </Link>
                </Grid>
                <Button
                  style={{ backgroundColor: "#f7a828" }}
                  // backgroundColor={"red"}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  LOGIN
                </Button>
                <Grid container sx={{alignItems:'center', justifyContent:'center' }}>
                  <Grid item>
                    <p style={{ display: "flex"}}>
                      <p style={{ fontSize: 14, color: "#fff",}}>
                        New User? {"  "}
                      </p>
                      <p style={{ fontSize: 14, color: "transparent",}}>
                       {".."}
                      </p>
                      <Link variant="body2" onClick={()=>{
                        navigate('Signup')
                      }}>
                        <p style={{ color: "#f7a828" }}>{' '}{" Register here"}</p>
                      </Link>
                    </p>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        isOpen={forgotModal}
        onRequestClose={() => {
          setforgotModal(false)
        }}
        style={customStyles}
        contentLabel=""
      >
        <h2 >Forgot password?</h2>
        <div
          style={{
            // width: "90%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'

          }}
        >
          <TextField
          placeholder="Enter email address"
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: '#f7a828',
              }
            }}
            inputProps={{
              style: {
                height: "5px",
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: 10, }}
            required
            value={forgotEmail}
            id="forgot"
            name="forgot"
            autoFocus
            hiddenLabel={true}
            onChange={event => {
              const { value } = event.target;
              setforgotEmail(value)
            }}
          />
        </div>

        <Button
          style={{ backgroundColor: "#f7a828" }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setforgotModal(false);
            forgotEmail == "" ? alert("Enter the email") : forgotPassword(forgotEmail);
          }}
        >
          Submit
        </Button>

        <Button
          style={{ backgroundColor: "transparent", color: 'black' }}
          // backgroundColor={"red"}
          type="submit"
          fullWidth
          variant="contained"
          // sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setforgotModal(false);
          }}
        >
          Cancel
        </Button>
      </Modal>

    </ThemeProvider>
  );
}
