import React, { useEffect, useRef, useState } from "react";
import { db, auth, frbase } from '../../src/Database_fb';
import Switch from "react-switch";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineRight, AiOutlineSetting } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import Itemmanager from "../context/Itemmanager";
import Floatmanager from "../context/Floatmanager";
import Garnishmanager from "../context/Garnishmanager";
import Modal from 'react-modal';
import TextField from "@mui/material/TextField";
import axios from "axios";
import CraftDialog from "../Component/CraftDialog";
import { useAlert } from 'react-alert-with-buttons'

export default function NetworkSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const [myCraft, setmyCraft] = useState([]);
  const alert = useAlert()

  const [showDelete, setshowDelete] = useState(false);
  const [docid, setdocid] = useState("");
  const [selectedIndex, setselectedIndex] = useState(0);
  const [selectedItem, setselectedItem] = useState(null);
  const [isAllEdifEnabled, setisAllEdifEnabled] = useState(location.state.canEditAll);
  const toggleSwitch = () => setisAllEdifEnabled(previousState => {
    disableEnableAllUserPermissions(!previousState)
    return !previousState
  });
  const [isSpecificEnabled, setisSpecificEnabled] = useState(location.state.networkEditors.length > 1);
  const toggleSpecificSwitch = () => setisSpecificEnabled(previousState => {
    disableUserPermissions(!previousState)
    return !previousState
  });


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    loadAllUsers()
  }, []);


  async function loadAllUsers() {
    let userpromises = [];
    location.state.networkEditors.forEach((doc) => {
      if (doc != frbase.auth().currentUser.uid) {
        userpromises.push(db.collection("users").doc(doc).get())
      }
    })
    const AllUsers = await Promise.all(userpromises)
    const users = [];
    AllUsers.forEach((doc) => {
      let data = doc.data()
      users.push({ key: doc.id, ...data })
    })
    setmyCraft(users)
  }



  function deleteCraft() {
    db
      .collection("MyNetwork")
      .doc(location.state.networkid)
      .update({
        editors: frbase.firestore.FieldValue.arrayRemove(selectedItem.key)
      })
    location.state.networkEditors.splice(selectedIndex, 1);
    myCraft.splice(selectedIndex, 1);
    setmyCraft([...myCraft]);
    if (myCraft.size == 0) {
      setisSpecificEnabled(false)
    }
  }

  function disableEnableAllUserPermissions(isON) {
    db
      .collection("MyNetwork")
      .doc(location.state.networkid)
      .update({
        editAll: isON
      })
  }


  function disableUserPermissions(isOn) {
    if (!isOn) {

      alert.open({
        message: "Remove all editors ? Are your sure ?",
        buttons: [
          {
            label: "Yes, Sure",
            onClick: () => {
              db
                .collection("MyNetwork")
                .doc(location.state.networkid)
                .update({
                  editors: frbase.firestore.FieldValue.arrayRemove([])
                })
              db
                .collection("MyNetwork")
                .doc(location.state.networkid)
                .update({
                  editors: frbase.firestore.FieldValue.arrayUnion(frbase.auth().currentUser.uid)
                })
              alert.close()
            },
            style: {
              backgroundColor: "#f7a828",
              borderRadius: 15,
              color: "white",
            }
          },
          {
            label: "No",
            onClick: () => {
              alert.close()
            },
            style: {
              backgroundColor: "#fff",
              borderRadius: 15,
              color: "black",
            }
          }
        ],
      });
    }

  }




  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  return (
    <>
      
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>
            <p style={{ fontSize: 30, color: "#D28816" }}>Network Settings</p>
            <div
              style={{
                width: 20,
                height: 20,
              }}
            >
            </div>
          </div>

          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={6} >

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: 5,
                  height: "60px",
                  cursor: 'pointer',
                  // backgroundImage: `url(${list_item_bg})`,
                  // backgroundRepeat: "repeat",
                  // backgroundSize: "cover",
                  // backgroundPosition: "center",
                  backgroundColor: 'white'
                }}
              >
                {/* <img style={{ width: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" /> */}

                <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                  <p
                    style={{
                      fontSize: 15,
                      color: "#000",
                      textAlign: "start",
                      marginLeft: "3%",
                      //marginTop: '4%'
                    }}
                  >
                    Allow all to edit
                  </p>
                </div>

                <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                  <Switch onChange={toggleSwitch} checked={isAllEdifEnabled} />

                  {/* <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      /> */}
                </div>

              </div>
            </Grid>


            <Grid item xs={6} >

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: 5,
                  height: "60px",
                  cursor: 'pointer',
                  // backgroundImage: `url(${list_item_bg})`,
                  // backgroundRepeat: "repeat",
                  // backgroundSize: "cover",
                  // backgroundPosition: "center",
                  backgroundColor: 'white'
                }}
              >
                {/* <img style={{ width: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" /> */}

                <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                  <p
                    style={{
                      fontSize: 15,
                      color: "#000",
                      textAlign: "start",
                      marginLeft: "3%",
                      //marginTop: '4%'
                    }}
                  >
                    Allow specific users only
                  </p>
                </div>

                <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                  <Switch onChange={toggleSpecificSwitch} checked={isSpecificEnabled} />
                  {/* <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      /> */}
                </div>

              </div>
            </Grid>

          </Grid>

          <p style={{ fontSize: 30, color: "#D28816" }}>Edit user permission below:</p>
          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {myCraft.map((prop, index) => {
              return (
                <Grid item xs={6} >

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 5,
                      height: "60px",
                      cursor: 'pointer',
                      //width: "100%",
                      //backgroundColor: "#fff",
                      // marginBottom: 10,
                      // marginLeft: (index % 2) ? 10 : 0
                      backgroundImage: `url(${list_item_bg})`,
                      backgroundRepeat: "repeat",

                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => {
                      setselectedIndex(index);
                      setselectedItem(prop)

                      setshowDelete(true)
                    }}
                  >
                    <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                    <div style={{ width: '5%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                      <p
                        // className="App"
                        style={{
                          fontSize: 12,
                          //height: 40,
                          color: "#000",
                          textAlign: "start",
                          //marginLeft: '35%',
                          //marginTop: '32%'

                        }}
                      >

                      </p>
                    </div>
                    <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.username}
                      </p>
                    </div>

                    <div style={{ width: '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <AiOutlineRight
                        style={{
                          width: 15,
                          height: 15,
                          color: "#000",
                        }}
                      />
                    </div>

                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
      </Box>


      <CraftDialog
        show={showDelete}
        title={'Delete User'}
        body={'Are you sure you want to delete this?'}
        handleToClose={() => {
          setshowDelete(false)
        }}
        handleToOk={() => {
          setshowDelete(false)
          deleteCraft();
        }}
      />
    </>
  );
}

