import firebase from "firebase/compat/app"
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
//import { getMessaging } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";

// import { onBackgroundMessage } from "firebase/messaging/sw";

// // eslint-disable-next-line no-undef
// importScripts("https://www.gstatic.com/firebasejs/8.2.0/firebase-app.js");
// // eslint-disable-next-line no-undef
// importScripts("https://www.gstatic.com/firebasejs/9.6.8/firebase-messaging.js");
// // Initialize the Firebase app in the service worker by passing the generated config





const firebaseConfig = {
  apiKey: "AIzaSyDG3lxf4Mq5pNeHvfDb43UaP8ggKprVzn0",
  authDomain: "craftiqapp.firebaseapp.com",
  databaseURL: "https://craftiqapp-default-rtdb.firebaseio.com",
  projectId: "craftiqapp",
  storageBucket: "craftiqapp.appspot.com",
  messagingSenderId: "104093682414",
  appId: "1:104093682414:web:8df22214f1635cfb6841ff",
  measurementId: "G-MYBYVZQ21K"
};
const firebaseApp = firebase.initializeApp(firebaseConfig)
//const messaging = firebase.messaging();
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const frbase = firebase
const messaging = getMessaging(firebaseApp);

export { auth, db, frbase, storage,messaging };


