

import React, { useEffect, useRef, useState } from "react";
import { db, auth, frbase } from '../../src/Database_fb';

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import splashBG from '../images/splashBG.png';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineFilter } from "react-icons/ai";
import clip_normal from '../images/clip_normal.png';
import list_item_bg from '../images/list_item_bg.png';
import Modal from 'react-modal';
import Itemmanager from "../context/Itemmanager";
import Floatmanager from "../context/Floatmanager";
import Garnishmanager from "../context/Garnishmanager";
import { useAlert } from 'react-alert-with-buttons'
import TextField from "@mui/material/TextField";
import SelectDropdown from 'react-dropdown';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#D28816",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CraftiqLibrary() {
  const navigate = useNavigate();
  const [libraryArray, setlibraryArray] = useState([]);
  const [filteredlibraryArray, setfilteredlibraryArray] = useState([]);
  const [modalVisible, setmodalVisible] = useState(false);
  const [searchmodalVisible, setsearchmodalVisible] = useState(false);
  const [recipeName, setrecipeName] = useState("");
  const [loading, setloading] = useState(false);
  const [docid, setdocid] = useState("");
  const [selectedIndex, setselectedIndex] = useState(0);
  const [itemRef, setitemRef] = useState(null);
  const alert = useAlert()
  const [searchValue, setSearchValue] = useState("")
  const [selectedSearch, setselectedSearch] = useState("Recipe Name")
  const [myFilterArray, setmyFilterArray] = useState([])
  const [Tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [tagsFilters, settagsFilters] = useState([]);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    FetchCraftsLibrary()
  }, [])

  function FetchCraftsLibrary() {
    setloading(true)
    const user = auth.currentUser;
    const subscribe = db.collection("Library")
      .get()
      // .where("uid", "==", user.uid)
      // .orderBy("timestamp", "asc")
      .then(async (doc) => {
        const crafts = [];
        const library = [];
        const promises = [];

        doc.forEach((snap) => {
          library.push({ key: snap.id, ...snap.data() });
          promises.push(db.collection("Library").doc(snap.id).collection('items').get());
        });

        Promise.all(promises).then(values => {
          values.forEach((doc, index) => {
            let items = [];
            doc.docs.forEach((snappy, index) => {
              items.push({ key: snappy.id, ...snappy.data() });
            })
            let lib = library[index]
            crafts.push({ ...lib, items: items });
          })
          let sorted = crafts.sort((a, b) => a.craftname.localeCompare(b.craftname))
          setlibraryArray(sorted);
          setfilteredlibraryArray(sorted)
          setloading(false)
        })
        //   await Promise.all(promises).then((results) => {
        //     results.forEach((snap, index) => {
        //       console.log('result',snap.docs.data());
        //       let lib = library[index]
        //       crafts.push({...lib, ...snap.data() });
        //     });
        //   });
        //  // let sorted = crafts.sort((a, b) => a.craftname.localeCompare(b.craftname))
        //   console.log('crafts', crafts[0]);
        //   setlibraryArray(crafts);
        //   setfilteredlibraryArray(crafts)
        //   setloading(false)
      });
  }

  function loadItemsToCopy() {
    setloading(true)

    db.collection("Library").doc(docid).collection("items").orderBy("order", 'desc')
      .get().then((documentSnapshot) => {

        let itemarry = [];
        let dilutionarry = [];
        let floatarry = [];
        let garnisharry = [];
        documentSnapshot.forEach((snap) => {

          let item = snap.data()
          if (item.type == "item") {
            let it = Itemmanager.getInstance();
            itemarry.push(it.setItems(item, snap.id))
          }
          if (item.type == "dilution") {
            let dil = Itemmanager.getInstance();
            dilutionarry.push(dil.setItems(item, snap.id))
          }
          if (item.type == "garnish") {
            let gar = Garnishmanager.getInstance();
            garnisharry.push(gar.setItems(item, snap.id))
          }
          if (item.type == "float") {
            let flo = Floatmanager.getInstance();
            floatarry.push(flo.setItems(item, snap.id))
          }
        })
        copyDataToServer(itemarry, dilutionarry, floatarry, garnisharry)
      })
  }


  function copyDataToServer(itemrender, dilutionrender, floatRender, garnishrender) {
    const user = frbase.auth().currentUser;
    setloading(true)
    let Ref = db
      .collection("MyCrafts")
    Ref.add({
      craftname: recipeName,
      uid: user.uid,
      totalOunces: itemRef.totalOunces.toString(),
      totalYields: itemRef.totalYields,
      batchType: itemRef.batchType,
      bottleSize: itemRef.bottleSize,
      quantity: itemRef.quantity.toString(),
      ingrediants: itemRef.ingrediants,
      totalDilution: itemRef.totalDilution,
      assumed: itemRef.assumed,
      ingrediantVal: itemRef.ingrediantVal,
      timestamp: frbase.firestore.FieldValue.serverTimestamp(),
      updatedAt: frbase.firestore.FieldValue.serverTimestamp()
    }).then((ref) => {
      addUpdateItems(ref.id, itemrender, dilutionrender, floatRender, garnishrender)
    })


  }

  function addUpdateItems(docid, itemrender, dilutionrender, floatRender, garnishrender) {
    var index = 0
    let combinedArray = [...itemrender, ...dilutionrender, ...garnishrender, ...floatRender];


    combinedArray.forEach((item) => {
      let docRef = db
        .collection("MyCrafts").doc(docid).collection("items")
      let data =
      {
        itemname: item.itemname,
        unitName: item.unitName,
        bottleSize: item.bottleSize,
        type: item.type,
        unitValue: item.unitValue,
        Quantity: item.Quantity,
        totalOunces: item.totalOunces,
        yields: item.yields,
        amount: item.amount,
        bottleSizeValue: item.bottleSizeValue,
        order: item.order
      }
      docRef.add(data).then((ref) => {
        index = index + 1
        if (index == combinedArray.length) {
          setloading(false)

          alert.open({
            message: "Great! Recipe Batched to your craft",
            buttons: [
              {
                label: "Edit Now",
                onClick: () => {
                  alert.close()
                  navigate("Recipe", {
                    state: {
                      dociID: docid,
                    }
                  });
                },
                style: {
                  backgroundColor: "#f7a828",
                  borderRadius: 15,
                  color: "white",
                }
              },

              {
                label: "Later",
                onClick: () => {
                  alert.close()
                },
                style: {
                  backgroundColor: "#fff",
                  borderRadius: 15,
                  color: "black",
                }
              }
            ],
          });
        }
      })
    })
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const customAdvanceStyles = {
    content: {
      width: '45%',
      height: 150,
      alignItems: 'center',
      justifyContent: 'center',
      top: '30%',
      left: '25%',
      // right: '50%',
      // bottom: '40%',
      // marginRight: '-20%',
      // transform: 'translate(-20%, -20%)',
      backgroundColor: 'white'
    },
  };
  function showAlert(message) {
    alert.open({
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            alert.close()
          },
          style: {
            backgroundColor: "#f7a828",
            borderRadius: 15,
            color: "white",
          }
        }
      ],
    });

  }


  function searchingItem(e) {
    let text = e.target.value
    setSearchValue(text)
    if (text.length == 0) {
      setfilteredlibraryArray(libraryArray)
      return;
    }
    if (selectedSearch == 'Recipe Name') {
      setfilteredlibraryArray(libraryArray.filter(name => name.craftname.match(new RegExp(text, "i"))))
    }
    else {

      if (Tags.length == 5) {
        showAlert("Sorry! You can not add more then 5 tags ", [
          {
            text: "Ok",
          },
        ]);
        return
      }
      var searchArry = [...[text], ...Tags]

      let searched = (tagsFilters.length > 0 ? tagsFilters : libraryArray).filter(item => item.items.find(name => {
        //name.match(new RegExp(text, "i"))
        return name.itemname.match(new RegExp(text, "i"))
        //|| Tags.find(tag => name.match(new RegExp(tag, "i")))
      }))
      //setfilteredlibraryArray(libraryArray.filter(item => item.ingrediants.find(name => name.match(new RegExp(text, "i")))))
      // let searched = libraryArray.filter(item => item.ingrediants.find(name => {
      //   //name.match(new RegExp(text, "i"))
      //   return (name.match(new RegExp(text, "i")) || Tags.find(tag => name.match(new RegExp(tag, "i"))))
      // }))

      // if (searched.length == 0) {
      //   setSearchData(myCraft)
      //   return;
      // }

      setfilteredlibraryArray(searched)
    }
    ///{libraryArray.filter(name => (name.ingrediants.filter(item => item.match(new RegExp(searchValue, "i"))))).map((prop, index) => {
  }

  // const deleteTag = (index) => {
  //   let prevState = [...Tags]
  //   prevState = prevState.filter((tag, i) => i !== index)
  //   setTags(prevState)
  //   let searched = libraryArray.filter(item => item.ingrediants.find(name => {
  //     return (prevState.find(tag => name.match(new RegExp(tag, "i"))))
  //   }))
  //   if (searched.length == 0) {
  //     setfilteredlibraryArray(libraryArray)
  //     return;
  //   }
  //   setfilteredlibraryArray([...searched])
  // }

  const deleteTag = (index) => {
    let prevState = [...Tags]
    prevState = prevState.filter((tag, i) => i !== index)
    setTags(prevState)
    let reversed = prevState.reverse()
    let updatedArray = []
    reversed.forEach((text) => {
      updatedArray = (updatedArray.length > 0 ? updatedArray : libraryArray).filter(item => item.items.find(name => {
        return (name.itemname.match(new RegExp(text, "i")))
      }))
    })
    if (updatedArray.length == 0) {
      setfilteredlibraryArray(libraryArray)
      settagsFilters([])
      return;
    }
    settagsFilters([...updatedArray])
    setfilteredlibraryArray([...updatedArray])
  }


  const onKeyDown = (e) => {

    if (selectedSearch == 'Recipe Name') {
      return
    }

    const { key } = e;
    const trimmedInput = searchValue.trim();

    if (key === ',' && trimmedInput.length && !Tags.includes(trimmedInput)) {
      e.preventDefault();
      // let searched = libraryArray.filter(item => item.ingrediants.find(name => name.match(new RegExp(trimmedInput, "i"))))
      setTags(prevState => [...prevState, trimmedInput]);
      settagsFilters(filteredlibraryArray)
      // setfilteredlibraryArray(prevState => [...prevState, ...searched].filter((val,id,array) => array.indexOf(val) == id));
      setSearchValue('');
    }
    if (key === 'Enter' && trimmedInput.length && !Tags.includes(trimmedInput)) {
      e.preventDefault();
      // let searched = libraryArray.filter(item => item.ingrediants.find(name => name.match(new RegExp(trimmedInput, "i"))))
      setTags(prevState => [...prevState, trimmedInput]);
      settagsFilters(filteredlibraryArray)
      // setfilteredlibraryArray(prevState => [...prevState, ...searched].filter((val,id,array) => array.indexOf(val) == id));
      setSearchValue('');
    }

    if (key === "Backspace" && !searchValue.length && Tags.length && isKeyReleased) {
      const tagsCopy = [...Tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setSearchValue(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 15,
          backgroundImage: `url(${splashBG})`,
          backgroundRepeat: "repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Box sx={{ width: "95%" }}>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <Button
              onClick={() => {
                navigate(-1)
              }}>
              <AiOutlineArrowLeft
                style={{
                  width: 20,
                  height: 20,

                  color: "#fff",
                }}
              /></Button>

            <p style={{ fontSize: 30, color: "#D28816" }}>CRAFT LIBRARY</p>
            <div
              style={{
                width: 20,
                height: 20,
              }}
            >
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >

            <div
              style={{
                width: "80%",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TextField
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                    color: '#f7a828',
                  }
                }}
                inputProps={{
                  style: {
                    height: "5px",
                  },
                }}
                placeholder={(selectedSearch == 'Recipe Name') ? 'Search by name...' : 'Press enter or comma to add new search tag...'}
                fullWidth
                style={{ backgroundColor: "#fff", borderRadius: 10, }}
                required
                id="search"
                name="search"
                hiddenLabel={true}
                value={searchValue}
                onChange={e => searchingItem(e)}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
              />
              <div style={{ backgroundColor: 'red', marginLeft: -5, width: '20%' }}>
                <SelectDropdown
                  value={selectedSearch}
                  options={['Recipe Name', 'Ingredient']}
                  onChange={(value, index) => {
                    setfilteredlibraryArray(libraryArray)
                    setSearchValue('')
                    setselectedSearch(value.value)
                  }}
                />
              </div>
              {/* <Button
                onClick={() => {
                  // navigate(-1)
                  setsearchmodalVisible(true)
                }}>
                <AiOutlineFilter
                  style={{
                    width: 20,
                    height: 20,

                    color: "#fff",
                  }}
                /></Button> */}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {Tags.map((tag, index) => (
              <div className="tag">
                {tag}
                <button onClick={() => deleteTag(index)}>x</button>
              </div>
            ))}
          </div>

          <div style={{ height: 20 }} />
          <Grid
            container
            //height={200}
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {filteredlibraryArray.map((prop, index) => {
              return (
                <Grid item xs={6}>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 5,
                      height: "60px",
                      cursor: 'pointer',
                      //width: "100%",
                      backgroundImage: `url(${list_item_bg})`,
                      backgroundRepeat: "repeat",

                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      // marginBottom: 10,
                      // marginLeft: (index % 2) ? 10 : 0
                    }}
                    onClick={() => {
                      setselectedIndex(index);
                      setmodalVisible(true);
                      setrecipeName(prop.craftname);
                      setdocid(prop.key);
                      setitemRef(prop)
                    }}
                  >
                    <img style={{ widows: 50, height: 50, marginLeft: 10 }} src={clip_normal} alt="tutorial" />
                    <div style={{ width: '10%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <p
                        // className="App"
                        style={{
                          fontSize: 12,
                          //height: 40,
                          color: "#000",
                          textAlign: "start",
                          //marginLeft: '35%',
                          //marginTop: '32%'

                        }}
                      >
                        #{index + 1}
                        {/* <br/>{craf.craftname} */}
                      </p>
                    </div>
                    <div style={{ width: '80%', alignItems: 'center', display: 'flex' }}>
                      <p
                        style={{
                          fontSize: 15,
                          color: "#000",
                          textAlign: "start",
                          marginLeft: "3%",
                          //marginTop: '4%'
                        }}
                      >
                        {prop.craftname}
                      </p>
                    </div>

                    <div style={{ width: '10%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <AiOutlineArrowRight
                        style={{
                          width: 10,
                          height: 10,
                          color: "#000",
                        }}
                      />
                    </div>

                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              height: 20,
              width: '100%',
            }}
          ></div>
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>


      </Box>


      <Modal

        isOpen={modalVisible}

        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setmodalVisible(false)
        }}
        style={customStyles}
        contentLabel=""
      >
        <p
          style={{
            fontSize: 15,
            color: "#000",
            textAlign: 'center',

            //marginLeft: "3%",
            //marginTop: '4%'
          }}
        >
          Please Select type
        </p>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: 'flex',
            flex: 1,
          }}
        >

          <div
            style={{
              backgroundColor: "white",
              width: "70%",
              alignItems: "center",
              // display: 'flex',
              borderRadius: 15,
            }}
          >

            <Button
              onClick={() => {
                setmodalVisible(false);
                loadItemsToCopy()

              }}
              style={{
                width: "100%",
                height: 35,
                borderBottomColor: "grey",
                borderBottomWidth: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#1c68ff",
                }}
              >
                Batch it
              </p>
            </Button>
            <Button
              style={{
                width: "100%",
                height: 40,
                borderBottomColor: "grey",
                borderBottomWidth: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setmodalVisible(false);
                navigate("/Recipe", {
                  state: {
                    dociID: docid,
                    viewOnly: true,
                    onlyLibrary: true,
                  }
                });
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#1c68ff",
                }}
              >
                View
              </p>
            </Button>


            <Button
              onClick={() => setmodalVisible(false)}
              style={{
                width: "100%",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "600",
                  color: "#1c68ff",
                }}
              >
                Cancel
              </p>
            </Button>
          </div>
        </div>
      </Modal>




      <Modal
        isOpen={searchmodalVisible}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setsearchmodalVisible(false)
        }}
        style={customAdvanceStyles}
        contentLabel=""
      >
        <p
          style={{
            fontSize: 15,
            color: "#000",
            textAlign: 'center',

            //marginLeft: "3%",
            //marginTop: '4%'
          }}
        >
          Advance Search
        </p>
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: "center",
            display: 'flex',
            flex: 1,
          }}
        >
          <div
            style={{
              width: "45%",
              // flex: 1,
            }}
          >
            <SelectDropdown
              //value={selectedBottleType}
              //disabled={viewOnly}
              options={['value 1', 'value 2']}
              onChange={(value, index) => {

              }}
            />
          </div>

          <div
            style={{
              width: "45%",
              // flex: 1,
            }}
          >
            <SelectDropdown
              //value={selectedBottleType}
              //disabled={viewOnly}
              options={['value 1', 'value 2']}
              onChange={(value, index) => {

              }}
            />
          </div>


        </div>

        <div
          style={{
            backgroundColor: "white",
            width: "70%",
            alignItems: "center",
            // display: 'flex',
            borderRadius: 15,
          }}
        >





          <Button
            onClick={() => setsearchmodalVisible(false)}
            style={{
              width: "100%",
              height: 40,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: "600",
                color: "#1c68ff",
              }}
            >
              Reset
            </p>
          </Button>
        </div>

      </Modal>
    </>
  );
}

